import React, { useState, useEffect } from "react";
import { Col, Row, Modal, ModalBody, Input, Label, Form } from "reactstrap";
import { Container} from "reactstrap";
import { Link } from "react-router-dom";
import { AppConfiguration } from "read-appsettings-json";
import { GetRequest, PostRequest } from '../../../noser-dataaccess'
import axios from 'axios'

//Job Images
import jobImage1 from "../../../assets/images/featured-job/img-01.png";
import jobImage2 from "../../../assets/images/featured-job/img-02.png";
import jobImage3 from "../../../assets/images/featured-job/img-03.png";
import jobImage4 from "../../../assets/images/featured-job/img-04.png";
import { useNavigate } from "react-router-dom";

const JobVacancy = () => {
  // Navigate to another screen 
  const navigate = useNavigate();
  const navigateApp = useNavigate();
  const navigateEmployer = useNavigate();

  //Apply Now Model
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);
  const [jobVacancy, setJobVacancy] = useState([]);
  const [compProfile, setJson] = useState([]);
  const [validate, setValidate] = useState("");
  const [isHide, setOnhide] = useState(false);

  
  useEffect(() => {
    GetCompanyProfile()
    const isValidate = JSON.parse(sessionStorage.getItem("userCollection"))
    if (isValidate === null){
      setValidate("0")
    }
    else {
      if(isValidate[0].isEmployer === "1"){
        setValidate("1")
      }
      else {
        setValidate("0")
      }
    }
  }, []);
  const GetCompanyProfile = async() => {
    let filter_data = {"isDeleted": "0"}
    const dataTmp = []
    const Params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "CompanyProfile",
      "filter_data" : filter_data
    }
    axios
    .post(AppConfiguration.Setting().mongoJobspace + "action/get", Params)
    .then(res => {
      let data = res.data
      // console.log("data company prof")
      // console.log(data.CompanyProfile)
      data.CompanyProfile.map(function(itm,i){
        const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
        const json = JSON.parse(strJson.replace(/'/g,'"'));
        dataTmp.push(json)
      })       
      setJson(dataTmp)  
      GetDataJobposting(dataTmp); 
    })
  };
  const GetDataJobposting =async(_dataList)=> {
    let dataListTmp = []
    try{
      let resp = await GetRequest({
        url : AppConfiguration.Setting().mongoJobspace + "action/get",
        params : {
          "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
          "_collection" : "JobPosting",
          "filter_data" : {}
        }
      })
      console.log("resp.JobPosting")
      console.log(resp.JobPosting)
      if(resp.status==="1"){
        // setCompanyId(companyProfList[0].id)
        resp.JobPosting.forEach((itm,idx)=> {
          // // console.log("_dataList.filter(x=> x.userId===itm.companyId)")
          // // console.log(_dataList.filter(x=> x.userId===itm.companyId))
          let expTmp = itm.experienceMin + " " + "-" + " " + itm.experienceMax
          // let locCity =   _dataList.filter(x=> x.userId===itm.companyId).length>0 ?
          //                 _dataList.find(x=> x.userId===itm.companyId).locationCity: ""
          // let locPrvnce = _dataList.filter(x=> x.userId===itm.companyId).length>0 ?
          //                 _dataList.find(x=> x.userId===itm.companyId).locationProvince: ""
          let _salaryRangeTmp = toTitleCase(itm.salaryCurrency) + " " + itm.salaryMin + " " + "-" + " " + toTitleCase(itm.salaryCurrency)  + " " + itm.salaryMax
          
          let _location = _dataList.filter(x=> x.userId===itm.companyId).length>0 ?
                          _dataList.find(x=> x.userId===itm.companyId).city: ""
            let noCapsLabelLoc = (_location).toLowerCase()
          let LocTmp = toTitleCase(noCapsLabelLoc)
          
          let tmCompname = _dataList.filter(x=> x.userId===itm.companyId).length>0 ?
                            _dataList.find(x=> x.userId===itm.companyId).companyName: ""
          let noCapsLabel = (tmCompname).toLowerCase()
          let compName = toTitleCase(noCapsLabel)
          dataListTmp.push({
            companyName : compName,
            location : LocTmp,
            companyId : itm.companyId,
            createdBy : itm.createdBy,
            createdDate : itm.createdDate,
            description : itm.Urgency,
            endDate : itm.endDate,
            experienceMax : itm.experienceMax,
            experienceMin : itm.experienceMin,
            id : itm.id,
            isClosed : itm.isClosed,
            isDeleted : itm.isDeleted,
            isPosted : itm.isPosted,
            keyWords : itm.keyWords,
            level : itm.level,
            postingId : itm.postingId,
            qualifications : itm.qualifications,
            responsibility : itm.responsibility,
            salaryBasis : itm.salaryBasis,
            salaryCurrency : itm.salaryCurrency,
            salaryMax : itm.salaryMax,
            salaryMin : itm.salaryMin,
            skillsExperience : itm.skillsExperience,
            startDate : itm.startDate,
            timeStamp : itm.timeStamp,
            title : itm.title,
            workType : itm.workType,
            urgency : itm.Urgency,
            updatedBy : itm.updatedBy,
            updatedDate : itm.updatedDate,
            exp : expTmp,
            salaryRange : _salaryRangeTmp,
            notes : itm.jobPostingNote,
            workLocation : itm.workLocation,
          })
        })
      }
      setJobVacancy(dataListTmp.sort((a, b) => a.id < b.id ? 1 : -1))
    }
    catch(error){
      // setMessage(error.message)
      alert("error: " + error.message)
    }
  };

  function toTitleCase(str) {
    const titleCase = str
      .toLowerCase()
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  
    return titleCase;
  }

  function handleNavigate(key) {
    // alert("test")
    // console.log("validate")
    // console.log(validate)
    if(validate==="1"){
      console.log("if")
      sessionStorage.setItem("key", JSON.stringify(key.title))
      navigateEmployer("/JobDescEmployer");
    }
    else {
      console.log("else")
      sessionStorage.setItem("key", JSON.stringify(key.id))
      navigate("/JobDetailsDescription");
    }
  };
  function handleNavigateApplication(itms) {
      sessionStorage.setItem("key", JSON.stringify(itms.id))
      sessionStorage.setItem("companyId", JSON.stringify(itms.companyId))
      navigateApp("/ApplicationPage");
  };
  
  function handleClick() {
    setOnhide(true)
    // navigate("/JobGrid");
  };
  return (
    <React.Fragment>
      
      <section className="bg-home" id="home">
        <div className="sm-overlay"></div>
        <Container style={{ marginTop: "-8.5%"}}>
          {isHide === false ? 
            <Row className="justify-content-center">
              <Col lg={12}>
                <div className="text-center" style={{color:"#088B1E"}}>
                  <h1 className="fw-semibold mb-3"style={{fontSize: "25px"}}>
                    Search Between More Than{" "}
                    <span className="text-warning fw-bold" style={{color:"#A6AB14", fontSize: "25px"}}>10,000+ </span>
                    Available Jobs.
                  </h1>
                  <p style={{fontSize: "18px"}}>
                    Find jobs, create traceable resumes and enrich your applications.
                  </p>
                </div>
              </Col>
            </Row>
          : 
          null
          }

          <Form action="#"style={{marginTop: "1%"}}>
            <div className="registration-form">
              <Row className="g-0">
                <Col lg={3}>
                  <div className="filter-search-form filter-border mt-3 mt-lg-0">
                    <i className="mdi mdi-search-web" style={{ color: "grey"}}></i>
                    <Input
                      type="search"
                      id="job-title"
                      className="form-control filter-input-box"
                      placeholder="Job, Company Name"
                      style={{backgroundColor: "#e7e7e7"/* , color: "#000000" */}}
                    />
                    {/* <select
                      className="form-control" 
                      id="nameControlInput" 
                      placeholder="Employment Type" 
                      style={{ borderStyle: "outset"}}
                      onChange={ e => handleChangeEmploymentType(e)}
                    >
                      {empJobType.map((resp) => (
                        <option key={resp.id} value={resp.department}>
                          {resp.department}
                        </option>
                      ))}
                    </select> */}
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="filter-search-form filter-border mt-3 mt-lg-0" style={{marginLeft: "-5%"}}>
                    <i className="uil uil-map-marker" style={{ color: "grey"}}></i>
                      <Input
                        type="search"
                        id="job-title"
                        className="form-control filter-input-box"
                        placeholder="Location"
                        style={{backgroundColor: "#e7e7e7"}}
                      />

                      {/* <select
                        className="form-control" 
                        id="nameControlInput" 
                        placeholder="Employment Type" 
                        style={{ borderStyle: "outset"}}
                        onChange={ e => handleChangeEmploymentType(e)}
                      >
                        {empJobType.map((resp) => (
                          <option key={resp.id} value={resp.department}>
                            {resp.department}
                          </option>
                        ))}
                      </select> */}
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="filter-search-form filter-border mt-3 mt-lg-0" style={{marginLeft: "-5%"}}>
                    <i className="uil uil-clipboard-notes" style={{ color: "grey"}}></i>
                    <Input
                      type="search"
                      id="job-title"
                      className="form-control filter-input-box"
                      placeholder="Job Type, Classification"
                      style={{backgroundColor: "#e7e7e7"/* , color: "#000000" */}}
                    />
                    {/* <select
                      className="form-control" 
                      id="nameControlInput" 
                      placeholder="Employment Type" 
                      style={{ borderStyle: "outset"}}
                      onChange={ e => handleChangeEmploymentType(e)}
                    >
                      {empJobType.map((resp) => (
                        <option key={resp.id} value={resp.department}>
                          {resp.department}
                        </option>
                      ))}
                    </select> */}
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="mt-3 mt-lg-0 h-100">
                    <button
                      className="btn btn-success submit-btn w-100 h-100"
                      type="submit"
                      onClick={handleClick}
                    >
                      <i className="uil uil-search me-1"></i>
                      Find Jobs
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Form>

          <Row>
            <Col lg={12}>
              <ul className="treding-keywords list-inline mb-0 text-white-50 mt-4 mt-lg-3 text-center">
                <li className="list-inline-item text-white" style={{color:"#088B1E"}}>
                  {/* <i className="mdi mdi-tag-multiple-outline text-warning fs-18"></i>{" "} */}
                  <label style={{color:"#088B1E", fontSize: "14px"}}>Trending Keywords:</label>
                </li>
                <li className="list-inline-item">
                  <Link to="#"> <label style={{color:"#088B1E", fontSize: "14px"}}>Design,</label></Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#"> <label style={{color:"#088B1E", fontSize: "14px"}}>Development,</label></Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#"> <label style={{color:"#088B1E", fontSize: "14px"}}>Manager,</label></Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#"> <label style={{color:"#088B1E", fontSize: "14px"}}>Senior</label></Link>
                </li>
              </ul>
            </Col>
          </Row>
          <div className={isHide === true ? 'display-block' : 'display-none'}>
            {jobVacancy.map((jobVacancyDetails, key) => (
              <div
                key={key}
                className={
                  jobVacancyDetails.addclassNameBookmark === true
                    ? "job-box bookmark-post card mt-4"
                    : "job-box bookmark-post card mt-4"
                }
                style={{backgroundColor : "#F0FEED", marginBottom: "-1.2%", borderColor: "green", border: "0.5px solid green"}}
              >
                <div className="p-4" onClick={e => handleNavigate(jobVacancyDetails)}>
                  <Row >
                    <Col lg={1}>
                      <Link /* onClick={e => handleNavigate(jobVacancyDetails)} */>
                        <img
                          src={jobVacancyDetails.companyImg}
                          alt=""
                          className="img-fluid rounded-3"
                        />
                      </Link>
                    </Col>
                    <Col lg={3}>
                      <div className="mt-3 mt-lg-0">
                        <Label className="mb-1" style={{fontSize: "18px"}}>
                          <Link /* onClick={e => handleNavigate(jobVacancyDetails)} */ /* to="/jobdetails" */ className="text-dark" >
                            <b>{jobVacancyDetails.title}</b>
                          </Link>{" "}
                        </Label>
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item">
                            <p className="mb-0" style={{fontSize: "16px", color: "#000000"}}>
                              {jobVacancyDetails.companyName}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    
                    <Col lg={2}>
                      <div className="mt-3 mt-lg-0">
                        <li className="list-inline-item">
                          <p className="mb-0" style={{fontSize: "14px", color: "#000000"}}>
                            <i className="mdi mdi-map-marker"></i>{" "}
                            {jobVacancyDetails.workLocation}
                          </p>
                        </li>
                      </div>
                    </Col>

                    <Col lg={3}>
                      <div className="mt-3 mt-lg-0">
                        <li className="list-inline-item">
                          <p className="mb-0" style={{fontSize: "14px", color: "#000000"}}>
                            <i className="uil uil-wallet"></i>{" "}
                            {jobVacancyDetails.salaryRange}
                          </p>
                        </li>
                      </div> 
                    </Col>
                    <Col lg={3}>
                    {jobVacancyDetails.workType === "" ? 
                      null
                    :
                      <button type="submit" className="btn btn-success noser-button-md" 
                        style={{minWidth : "40%", 
                        textAlign : "center", 
                        borderRadius : 5, 
                        // color : "whitesmoke", 
                        // backgroundColor : "green", 
                        borderWidth : "0px", 
                        marginLeft : "5%",
                        fontSize: "14px",
                        padding : "8px"}}
                      >
                        {jobVacancyDetails.workType}
                      </button>
                    }
                    {jobVacancyDetails.urgency === "" ? 
                      null
                    :
                      <button type="submit" className="btn btn-success noser-button-md" 
                        style={{minWidth : "40%", 
                        textAlign : "center", 
                        fontSize: "14px",
                        borderRadius : 5, 
                        color : "whitesmoke", 
                        backgroundColor : "green", 
                        borderWidth : "0px", 
                        marginLeft : "15%",
                        padding : "8px"}}
                      >
                        {jobVacancyDetails.urgency}
                      </button>
                    }
                    </Col>
                  </Row>
                </div>
                <div className="p-3" style={{ backgroundColor : "#E0FDDB"}}>
                  <Row className="justify-content-between">
                      <Col lg={1}>
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item" style={{ color: "#000000"}}>
                            Experience:
                          </li>
                        </ul>
                        </Col>
                        <Col lg={4} >
                          <Link to="#" /* className="text-muted" */ style={{fontSize: "14px", color: "#000000", textAlign: "justify", marginLeft: "1%"}}>
                            {jobVacancyDetails.exp}
                          </Link>
                        </Col>
                        
                        <Col lg={4}>
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item">
                            <Link to="#" /* className="text-muted" */ style={{fontSize: "14px", color: "#000000"}}>
                            Notes: &nbsp;{jobVacancyDetails.notes}
                            </Link>
                          </li>
                        </ul>
                      </Col>

                    <Col sm={2}>
                      {validate==="1" ? 
                        null
                        :
                        <div className="text-md-end" onClick={(e)=> handleNavigateApplication(jobVacancyDetails)}>
                          <Link
                            // to="/ApplicationPage"
                            className="primary-link"
                            style={{fontSize: "14px", color: "#000000"}}
                          >
                            <b style={{ color: "green"}}>Apply Now</b><i className="mdi mdi-chevron-double-right" style={{ color: "green"}}></i>
                          </Link>
                        </div>
                      }
                    </Col>
                  </Row>
                </div>
              </div>
            ))}
          </div>
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal isOpen={modal} toggle={openModal} centered>
              <ModalBody className="modal-body p-5">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Apply For This Job
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="mb-3">
                  <Label for="nameControlInput" className="form-label">
                    Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="nameControlInput"
                    placeholder="Enter your name"
                  />
                </div>
                <div className="mb-3">
                  <Label for="emailControlInput2" className="form-label">
                    Email Address
                  </Label>
                  <Input
                    type="email"
                    className="form-control"
                    id="emailControlInput2"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="mb-3">
                  <Label for="messageControlTextarea" className="form-label">
                    Message
                  </Label>
                  <textarea
                    className="form-control"
                    id="messageControlTextarea"
                    rows="4"
                    placeholder="Enter your message"
                  ></textarea>
                </div>
                <div className="mb-4">
                  <Label className="form-label" for="inputGroupFile01">
                    Resume Upload
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="inputGroupFile01"
                  />
                </div>
                <button type="submit" className="btn btn-success w-100">
                  Send Application
                </button>
              </ModalBody>
            </Modal>
          </div>
        </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobVacancy;
