import React from "react";
import { Col, Container, Row } from "reactstrap";
import JobGridHeader from "./JobGridHeader";
import JobVacancy from "./JobVacancy";
import JobVacancyNew from "./JobVacancyNew";
import Section from "./Section";
import Selected from "./Selected";
import Pagination from "../JobList2/Pagination";
import Layout1 from "../../Home/Layout1/Layout1";

const JobGrid = () => {
  document.title = "Job Grid | JobSpace - Job Listing Template | Themesdesign";
  return (
    <React.Fragment>
      <Layout1 />
      {/* <section className="section">  */}
        <Container>
          {/* <JobGridHeader />
          <Selected /> */}
          <Row style={{marginTop: "-20%", marginBottom: "1.5%"}}>
            <Col lg={12}>
              <JobVacancy />
            </Col>
            {/* <Col lg={6}>
              <JobVacancyNew />
            </Col> */}
          </Row>
          {/* <Pagination /> */}
        </Container>
      {/* </section> */}
    </React.Fragment>
  );
};

export default JobGrid;
