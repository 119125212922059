import React, { useState } from "react";
import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Spinner,
  Alert
} from "reactstrap";
import useForm from "../../wcomponents/SignUp/useForm";
import Icon from "../../wcomponents/Icon/Icon";
import "../../wcomponents/w.css"


import { Link } from "react-router-dom";

//import images
import flagUs from "../../assets/images/flags/us.jpg";
import flagSp from "../../assets/images/flags/spain.jpg";
import flagGr from "../../assets/images/flags/germany.jpg";
import flagIt from "../../assets/images/flags/italy.jpg";
import flagRu from "../../assets/images/flags/russia.jpg";
import flagPh from "../../assets/images/flags/ph.png";

const TopBar = ({submitForm }) => {
  const [passState, setPassState] = useState(false);
  const [passState1, setPassState1] = useState(false);

  const { handleChange, handleSubmit, values, errors,loading,errorVal } = useForm(
    submitForm,
  );

  const iconTobar = [
    {
      id: 1,
      classname: "uil uil-whatsapp"
    },
    {
      id: 2,
      classname: "uil uil-facebook-messenger-alt"
    },
    {
      id: 3,
      classname: "uil uil-instagram"
    },
    {
      id: 4,
      classname: "uil uil-envelope"
    },
    {
      id: 5,
      classname: "uil uil-twitter-alt"
    }
  ];
  //Language Dropdown
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  //Signup Modal
  const [modal, setModal] = useState(false);

  const openModal = () => setModal(!modal);

  return (
    <React.Fragment>
      <div className="top-bar" style={{ zIndex: 1030 }}>
        <Container fluid className="custom-container">
          <Row className="g-0 align-items-center">
            <Col md={7}>
              <ul className="list-inline mb-0 text-center text-md-start">
                <li className="list-inline-item">
                  <p className="fs-13 mb-0">
                    {" "}
                    <i className="mdi mdi-map-marker"></i> Your Location:{" "}
                    <Link to="#" className="text-dark">
                      Philippines
                    </Link>
                  </p>
                </li>
                <li className="list-inline-item">
                  <ul className="topbar-social-menu list-inline mb-0">
                    {(iconTobar || []).map((icon, key) => (
                      <li className="list-inline-item" key={key}>
                        <Link to="/" className="social-link">
                          <i className={icon.classname}></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </Col>

            <Col md={5}>
              <ul className="list-inline mb-0 text-center text-md-end">
                <li className="list-inline-item py-2 me-2 align-middle">
                  <span
                    onClick={openModal}
                    role="button"
                    className="text-dark fw-medium fs-13"
                  >
                    <i className="uil uil-lock"></i>
                    Sign Up
                  </span>
                  <Modal
                    isOpen={modal}
                    toggle={openModal}
                    role="dialog"
                    centered
                  >
                    <ModalBody className="p-5">
                      <div className="position-absolute end-0 top-0 p-3">
                        <button
                          type="button"
                          className="btn-close"
                          onClick={openModal}
                        ></button>
                      </div>
                      <div className="auth-content">
                      {errorVal && (
                        <div className="mb-3">
                          <Alert color="danger" className="alert-icon">
                            {" "}
                            <Icon name="alert-circle" /> Unable to login with credentials{" "}
                          </Alert>
                        </div>
                      )}
                        <div className="w-100">
                          <div className="text-center mb-4">
                            <h5>Sign Up</h5>
                            <p className="text-muted">
                              Sign Up and get access to all the features of
                              Job Space
                            </p>
                          </div>
                          <Form action="#" className="auth-form" onSubmit={handleSubmit}>
                            <FormGroup>
                              <label /* className="form-label" */ htmlFor="name"> First Name </label>
                              <div className="form-control-wrap">
                                <input
                                  variant="outlined"
                                  bsize="sm"
                                  type="text"
                                  //id="name"
                                  name="firstName"
                                  placeholder="Enter your firstName"
                                  //ref={register({ required: true })}
                                  className="form-control-lg form-control"
                                  autoComplete="off"
                                  value={values.firstName} 
                                  onChange={handleChange}
                                  required
                                />
                                { errors.firstName && <p className="invalid">{errors.firstName} </p>}
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <label /* className="form-label" */ htmlFor="name"> Last Name </label>
                              <div className="form-control-wrap">
                                <input
                                  bsize="sm"
                                  type="text"
                                  //id="name"
                                  name="lasttName"
                                  placeholder="Enter your lasttName"
                                  //ref={register({ required: true })}
                                  className="form-control-lg form-control"
                                  autoComplete="off"
                                  value={values.lasttName} 
                                  onChange={handleChange}
                                  required
                                />
                                { errors.firstName && <p className="invalid">{errors.lasttName} </p>}
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <div className="form-label-group">
                                <label  /* className="form-label" */  htmlFor="email">
                                  Email Address
                                </label>
                              </div>
                              <div className="form-control-wrap">
                                <input
                                  bsize="sm"
                                  //size="sm"
                                  //style={{height : "20px", lineHeight : "20px",margin : "-3px", marginRight : "3px"}}
                                  type="text"
                                  //id="email"
                                  name="email"
                                  //ref={register({ required: true })}
                                  className="form-control-lg form-control"
                                  placeholder="Enter your email address"
                                  autoComplete="off"
                                  value={values.email} 
                                  onChange={handleChange}
                                  required
                                />
                                {errors.email && <p className="invalid">{errors.email}</p>}
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <div className="form-label-group">
                                <label /* className="form-label" */ htmlFor="username">
                                  Username
                                </label>
                              </div>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  bsize="sm"
                                  //id="username"
                                  name="username"
                                  //ref={register({ required: true })}
                                  className="form-control-lg form-control"
                                  placeholder="Enter your username"
                                  autoComplete="off"
                                  value={values.username}  
                                  onChange={handleChange}
                                  required
                                />
                                {errors.username && <p className="invalid">{errors.username}</p>}
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <label /* className="form-label" */ htmlFor="contact">
                                Contact
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="numeric"
                                  bsize="sm"
                                  //id="contact"
                                  name="contact"
                                  placeholder="Enter your contact number"
                                  //ref={register({ required: true })}
                                  className="form-control-lg form-control"
                                  autoComplete="off"
                                  value={values.contact} 
                                  onChange={handleChange}
                                  required
                                />
                                {errors.contact && <p className="invalid">{errors.contact}</p>}
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <div className="form-label-group">
                                <label /* className="form-label" */ htmlFor="password">
                                  Password
                                </label>
                              </div>
                              <div className="form-control-wrap">
                                <a
                                  href="#password"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setPassState(!passState);
                                  }}
                                  className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                                >
                                  <Icon name="eye" className="passcode-icon icon-show"></Icon>

                                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                                </a>
                                <input
                                  type= {passState ? "text" : "password"}
                                  //id="password"
                                  bsize="sm"
                                  name="password"
                                  //ref={register({ required: "This field is required" })}
                                  placeholder="Enter your password"
                                  className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                                  value={values.password} 
                                  onChange={handleChange}
                                  required
                                />
                                {errors.password && <span className="invalid">{errors.password}</span>}
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <div className="form-label-group">
                                <label /* className="form-label" */ htmlFor="confirm_password">
                                  Confirm Password
                                </label>
                              </div>
                              <div className="form-control-wrap">
                                <a
                                  href="#confirm_password"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setPassState1(!passState1);
                                  }}
                                  className={`form-icon lg form-icon-right passcode-switch ${passState1 ? "is-hidden" : "is-shown"}`}
                                >
                                  <Icon name="eye" className="passcode-icon icon-show"></Icon>

                                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                                </a>
                                <input
                                  type= {passState1 ? "text" : "password"}
                                  //id="confirm_password"
                                  name="confirm_password"
                                  bsize="sm"
                                  //ref={register({ required: "This field is required" })}
                                  placeholder="Enter your password"
                                  className={`form-control-lg form-control ${passState1 ? "is-hidden" : "is-shown"}`}
                                  value={values.confirm_password} 
                                  onChange={handleChange}
                                  required
                                />
                                {errors.confirm_password && <span className="invalid">{errors.confirm_password}</span>}
                              </div>
                            </FormGroup>
                            
                            
                            <FormGroup className="mb-4">
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="flexCheckDefault"
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  I agree to the{" "}
                                  <Link
                                    to="/"
                                    className="text-primary form-text text-decoration-underline"
                                  >
                                    Terms and conditions
                                  </Link>
                                </Label>
                              </div>
                            </FormGroup>
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-primary w-100"
                              >
                                Sign Up
                              </button>
                            </div>
                          </Form>
                          <div className="mt-3 text-center">
                            <p className="mb-0">
                              Already a member ?{" "}
                              <Link
                                to="/signin"
                                className="form-text text-primary text-decoration-underline"
                              >
                                {" "}
                                Sign-in{" "}
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>
                </li>
                <li className="list-inline-item align-middle">
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    className="d-inline-block language-switch"
                  >
                    <DropdownToggle tag="button" type="button" className="btn">
                      <img src={flagPh} alt="" height="16" />
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end" end>
                      
                      <DropdownItem
                        to="/"
                        className="dropdown-item notify-item language"
                        data-lang="eng"
                      >
                        <img src={flagUs} alt="" className="me-1" height="12" />
                        <span className="align-middle">English</span>
                      </DropdownItem>
                      <DropdownItem
                        to="/"
                        className="dropdown-item notify-item language"
                        data-lang="sp"
                      >
                        <img src={flagSp} alt="" className="me-1" height="12" />
                        <span className="align-middle">Spanish</span>
                      </DropdownItem>
                      <DropdownItem
                        to="/"
                        className="dropdown-item notify-item language"
                        data-lang="gr"
                      >
                        <img src={flagGr} alt="" className="me-1" height="12" />
                        <span className="align-middle">German</span>
                      </DropdownItem>
                      <DropdownItem
                        to="/"
                        className="dropdown-item notify-item language"
                        data-lang="it"
                      >
                        <img src={flagIt} alt="" className="me-1" height="12" />
                        <span className="align-middle">Italian</span>
                      </DropdownItem>
                      <DropdownItem
                        to="/"
                        className="dropdown-item notify-item language"
                        data-lang="ru"
                      >
                        <img src={flagRu} alt="" className="me-1" height="12" />
                        <span className="align-middle">Russian</span>
                      </DropdownItem>
                      <DropdownItem
                        to="/"
                        className="dropdown-item notify-item language"
                        data-lang="ph"
                      >
                        <img src={flagPh} alt="" className="me-1" height="12" />
                        <span className="align-middle">Tagalog</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TopBar;
