import React, { useState,useEffect } from "react";

//import lightLogo from "../../assets/images/logo-light.png";
//import darkLogo from "../../assets/images/logo-dark.png";

import signInImage from "../../assets/images/auth/sign-in.png";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

const SignOut = () => {
  const navigate = useNavigate();
  useEffect(
    () => {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/")
       
    },
    []
  );
  document.title = "Sign Out | Job Space - Job Listing Template | Themesdesign";
  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  
                <div className="p-5 text-white" style={{backgroundColor:"#088B1E", width : "400px"}}>
                            <div className="w-100">
                              <div className="text-center mb-4">
                                <h5>You are Logged Out</h5>
                                <p className="text-white-70">
                                  Thank you for using JobSpace
                                </p>
                              </div>
                               <Link
                                to="/"
                                className="btn btn-white btn-hover w-100"
                              >
                                Sign In
                              </Link>
                              {/*
                              <div className="mt-3 text-center">
                                <p className="mb-0">
                                  Don't have an account ?{" "}
                                  <Link
                                    to="/signup"
                                    className="fw-medium text-white text-decoration-underline"
                                  >
                                    {" "}
                                    Sign Up{" "}
                                  </Link>
                                </p>
                              </div> */}
                            </div>
                          </div>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignOut;
