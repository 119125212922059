import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Container,
  Collapse,
  NavbarToggler,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  
} from "reactstrap";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import classname from "classnames";
import withRouter from "../../components/withRouter"

//import darkLogo from "../../assets/images/logo-dark.png";
//import lightLogo from "../../assets/images/logo-light.png";
import userImage2 from "../../assets/images/user/img-02.jpg";
import jobImage4 from "../../assets/images/featured-job/img-04.png";
import userImage1 from "../../assets/images/user/img-01.jpg";
import jobImage from "../../assets/images/featured-job/img-01.png";
import profileImage from "../../assets/images/profile.jpg";
import registerImage from "../../assets/images/add-user.png";
import headerLogo from "../../assets/images/banner-logo.png";
import { GetRequest, PostRequest } from '../../noser-dataaccess'
import { AppConfiguration } from "read-appsettings-json";
import axios from 'axios'

//w import+
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Card, CardBody, Input, } from "reactstrap";
import {Form, FormGroup, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";


const NavBar = (props) => {
  //w start
  const [show, setShow] = useState(false);
  const [pagePath, setPagePath] = useState(true)
  const navigate = useNavigate();
  const handleClose = () => {
    setShow(false)
    localStorage.clear()
    sessionStorage.clear()
    setLoading(false);
  }
  const handleShow = () => setShow(true);
  const [wdata,setwData] = useState([])
  const { errors, register, handleSubmit } = useForm();
  const [value1, setValue1] = useState("")
  const [value2, setValue2] = useState("")
  const [value3, setValue3] = useState("")
  const [loading, setLoading] = useState(false);
  const handleGetRegister = async() => {
    setLoading(true)
    console.log("handleGetRegister")
    let filter_data = {
      "isDeleted" : "0", 
      "email"     : value1, 
      "password"  : value3
    }
    
    const Params = {
      "_collection" : "UserCollection",
      "filter_data" : filter_data
    }
    console.log("Params")
    console.log(Params)
    await axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {

      let dataTmp = []

        let dataa = res.data
        console.log(res)
        console.log("data")
        console.log(dataa)
        let newstr2=""
        dataa.UserCollection.map(function(itm,i){
            let strJson = "{" + itm.substring(46, itm.length)
            let strJson2 = itm.substring(18, itm.length)
            newstr2 = strJson2.split("')")[0]
            let json = JSON.parse(strJson.replace(/'/g,'"'));
            json.headerId = newstr2
            dataTmp.push(json)
        })    
        console.log("dataTmp")
        console.log(dataTmp)
        console.log(dataTmp[0].isEmployer)
        
        console.log(dataTmp[0].userId)
        setwData(dataTmp)  
        let tmptmp = dataTmp[0].userId
        let firstName = dataTmp[0].firstName
        let lastName = dataTmp[0].lastName
        let employer = dataTmp[0].isEmployer
        if(res.status === 200){
          handleGetCompanyCollection(tmptmp,firstName,lastName,employer) 
          sessionStorage.setItem("userCollection", JSON.stringify(dataTmp))
          sessionStorage.setItem("userCollectionArr", JSON.stringify([dataTmp]))
          sessionStorage.setItem("userdata", JSON.stringify(dataTmp))
          sessionStorage.setItem("userdataArray", JSON.stringify([dataTmp]))
         
        }
    })
    .catch(error =>{
        alert("error: " + "Email or Password are incorrect")
        setLoading(false)
    })
  };

  const handleGetCompanyCollection = async(tmptmp,firstName,lastName,employer) => {
    console.log(tmptmp)
    let filter_data = {
      "isDeleted" : "0", 
      "userId" : tmptmp,
     /*  "firstName" : firstName,
      "lastName"  : lastName, */
    }
    
    const Params = {
      "_collection" : "CompanyProfile",
      "filter_data" : filter_data
    }
    console.log("Params")
    console.log(Params)
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {

      let dataTmp = []

        let dataaa = res.data
        console.log("data")
        console.log(dataaa)
        let newstr2=""
        dataaa.CompanyProfile.map(function(itm,i){
          let strJson = "{" + itm.substring(46, itm.length)
          let strJson2 = itm.substring(18, itm.length)
          newstr2 = strJson2.split("')")[0]
          let json = JSON.parse(strJson.replace(/'/g,'"'));
          json.headerId = newstr2
          dataTmp.push(json)
        })    
        //console.log("dataTmp")
        sessionStorage.setItem("CompanyProfile", JSON.stringify(dataTmp))
        
      if(employer === "1"){
        const obj = [
          {"name" : "Register Company", "menuId" : "002", "link" : "/#"},
        ]
        const objSign = [
          {"name" : "Signout", "link" : "/signout"},
          {"name" : "Register", "link" : "/signup"},
        ]
        window.sessionStorage.setItem('results', JSON.stringify({"menuId" : "002"}));
        //window.sessionStorage.setItem('resultsSign', JSON.stringify(objSign));
        
        
        if(pagePath){
          navigate("/")
          setLoading(false)
          setShow(false)
          window.location.reload()
        }
        else{
          return null
        }
      }else {
         
        const obj = [
          {"name" : "View Applicants", "menuId" : "001", "link" : "/JobDetailsDescription"},
          {"name" : "Candidate Shortless", "menuId" : "001", "link" : "/#"},
          {"name" : "Job Posting", "menuId" : "001", "link" : "/#"},
          {"name" : "Employer", "menuId" : "001", "link" : "/CreateJobPosting"},
        ] 
        const objSign = [
          {"name" : "Sign Out", "link" : "/signout"},
          {"name" : "Register", "link" : "/signup"},
        ]
        window.sessionStorage.setItem('results', JSON.stringify({"menuId" : "001"}));
        //window.sessionStorage.setItem('resultsSign', JSON.stringify(objSign));
        
        if(pagePath){
          navigate("/")
          setLoading(false)
          setShow(false)
          window.location.reload()
          
        }
        else{
          return null
        }

      } 
        
    })
    .catch(error =>{
        alert("error: " + error)
    })
  };
  
  const [applicationUpper, setUserName]=useState('');

  const handleSname =(event)=> {
    setValue1({ email : event.target.value})
    // console.log(event.target.value)
  };
  const handleUserId =(event)=> {
    setValue2({ userid : event.target.value})
  };
  const handlePassword =(event)=> {
    setValue3({ passcode : event.target.value})
  };

  const onFormSubmit = async() => {
    console.log("asdasdasd")
   // setLoading(true);
    handleGetRegister(value1, value3)
    console.log(wdata)
  };
  //w end
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [home, setHome] = useState(false);
  
  // START EMPLOYER MENU
  const [company, setCompany] = useState(false);
  const [recruitment, setRecruitment] = useState(false);
  const [jobposting, setJobPosting] = useState(false);
  const [profile, setProfile] = useState(false);

  
  const [userProfiles, setProfiles] = useState(false);

  const [pages, setPages] = useState(false);
  const [blog, setBlog] = useState(false);

  //Notification Dropdown
  const [notification, setNotification] = useState(false);
  const dropDownnotification = () => setNotification((prevState) => !prevState);

  //user Profile Dropdown
  const [userProfile, setUserProfile] = useState(false);
  const dropDownuserprofile = () => setUserProfile((prevState) => !prevState);

  //scroll navbar
  const [navClass, setnavClass] = useState(false);

  const ref = useRef();
 

  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log("event")
      console.log(ref)
      if(ref.current === null){

      }else {
        if (!ref.current.contains(event.target)) {
          setRecruitment(false)
          setJobPosting(false)
          setProfile(false)
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = window.pageYOffset;
    if (scrollup > 0) {
      setnavClass("nav-sticky");
    } else {
      setnavClass("");
    }
  }

  
  
  
  const [getResult, setResult] = useState(JSON.parse(sessionStorage.getItem("results")));
  const [getUserName, setuserName] = useState(JSON.parse(sessionStorage.getItem("userdata")));
  const [getName, setName] = useState("");
  const [getBsicInfo, setBsicInfo] = useState("");
  const [isVisibleCompany, setIsVisibleCompany] = useState(false);
  const [isVisibleAccess, setIsVisibleAccess] = useState(false);
  const [isVisibleCandidate, setVisibleCandidate] = useState(false);
  const [isVisibleCandidateSignin, setVisibleCandidateSignin] = useState(false);
  const [getTest, setTest] = useState({});

  const [getTempArray, setTempArray] = useState([
    {"name" : "View Applicants", "menuId" : "001"},
    {"name" : "Candidate Shortless", "menuId" : "001"},
    {"name" : "Job Posting", "menuId" : "001"},
    {"name" : "Register Company", "menuId" : "002"},
    {"name" : "Company", "menuId" : "002"}
]);

  useEffect(() => {

    window.scrollTo({ top: 0, behavior: "smooth" });
    const pathName = props.router.location.pathname;
    var matchingMenuItem = null;
    var ul = document.getElementById("navbarCollapse");
    var items = ul.getElementsByTagName("a");
    removeActivation(items);
    for (var i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }

    console.log("getUserName")
    console.log(getUserName)
    if(getUserName !== null){
      getUserName.forEach(itm => {
        setName(itm.firstName)
      });
      GetCandidateRegisteredCheckList();
    }
    if(getResult !== null){
      if(getResult.menuId === "001"){
        setIsVisibleCompany(false)
        setIsVisibleAccess(false)
        setVisibleCandidate(false)
        setVisibleCandidateSignin(true)
      }
      else if(getResult.menuId === "002"){
        setIsVisibleCompany(true)
        setIsVisibleAccess(false)
        setVisibleCandidate(false)
        setVisibleCandidateSignin(false)
      }
    }else{
      setVisibleCandidate(true)
    }
  },[props.router.location.pathname]);
  

  const GetCandidateRegisteredCheckList = async() => {
    //// console.log("dataTmp GetJobPosted")
    //// console.log(dataTmp)
    let filter_data = {"isDeleted": "0", "userId" : getUserName[0].userId}
    let JobPostedTmp = []
    const Params = {
      "_collection" : "CandidateRegisteredCheckList",
      "filter_data" : filter_data
    }
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {

        let data = res.data
        let newstr2=""
      let tempArr = []
        data.CandidateRegisteredCheckList.map(function(itm,i){
            let strJson = "{" + itm.substring(46, itm.length)
            let strJson2 = itm.substring(18, itm.length)
            newstr2 = strJson2.split("')")[0]
            let json = JSON.parse(strJson.replace(/'/g,'"'));
            json.headerId = newstr2
            tempArr.push(json)
        })       
        console.log("CandidateRegisteredCheckList")
        console.log(tempArr)
        if(tempArr.length === 0){

        }else{
          setBsicInfo(tempArr[0].basicInfoCandidate)
        }
        
        // setJson(dataTmp)  
    })
    .catch(error =>{
        alert("error GetJobPosted: " + error)
    })
  };
  
  
  const onclickCandidate = () => {
    console.log(getBsicInfo)
    if(getBsicInfo === "1"){
      navigate("/ApplicationFormView");
    }else{
      navigate("/ApplicationFormCreate");
    }
    //window.open("http://localhost:3000/ApplicationFormView")
    
  };
  
  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active");
        }
      }
    }
  };

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement.parentElement.parentElement;

    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  
  
  const GetMenuList = async() => {
    const params =  {
      "dbname" 	          :	  "JobSpaceDBUAT",
      "_collection"	      : 	"TestMenu",
      "filter_data"       :   {}
      }  
    
    await axios
    .post(AppConfiguration.Setting().mongoJobspace + "action/get",  params )
    .then(res =>{
        const data = res.data
        let rest = [];
        data.TestMenu.map(function(itm,i){

          let strJson = itm.replace("_","").replace("ObjectId(","").replace(")","")
          let json = JSON.parse(strJson.replace(/'/g,'"'));
          rest.push(json)

        })
          setTempArray(rest)
          console.log("rest")
          console.log(rest)
    })
    .catch(error =>{
        console.log("error: " + error)
    })  
  
  }



  const onClickRecruitment = (e) => {
    if(recruitment === false){
      setRecruitment(true)
      setJobPosting(false)
      setProfile(false)
    }else{
      setRecruitment(false)
    }
  };

  const onClickJobPosting = (e) => {
    if(jobposting === false){
      setJobPosting(true)
      setRecruitment(false)
      setProfile(false)
    }else{
      setJobPosting(false)
    }
  };

  const onClickProfile = (e) => {
    if(profile === false){
      setProfile(true)
      setRecruitment(false)
      setJobPosting(false)
    }else{
      setProfile(false)
    }
  };
  
  
  const setUserProfiles = (e) => {
    if(userProfiles === false){
      console.log("1")
      setJobPosting(false)
      setRecruitment(false)
      setProfile(false)
    }else{
      console.log("2")
    }
  };

  return (
    <React.Fragment>
      <nav
        className={"navbar navbar-expand-lg fixed-top sticky p-0 " + navClass}
        id="navigation" ref={ref}
      >
        <Container fluid className="custom-container">
          <Link className="navbar-brand fw-bold me-auto" to="/" style={{color:"#FFF"}}>
            <img src={headerLogo} height="62" alt="" className="logo-dark" />
            {/* <img src={lightLogo} height="22" alt="" className="logo-light" /> */}
            
          </Link>
          <div>
            <NavbarToggler
              className="me-3"
              type="button"
              onClick={() => toggle()}
            >
              <i className="mdi mdi-menu"></i>
            </NavbarToggler>
          </div>
          <Collapse
            isOpen={isOpen}
            className="navbar-collapse"
            id="navbarCollapse"
          >
            
            {/* START COMPANY */}
            {isVisibleCompany && (
              <ul className="navbar-nav mx-auto navbar-center">
                {/* <NavItem className="dropdown dropdown-hover">
                  <NavLink
                    to="/#"
                    id="recruitmentdrop"
                    role="button"
                    //onClick={() => setRecruitment(!recruitment)}
                    //onClick={generate}
                    onClick={() => onClickRecruitment(!recruitment)}
                  >
                  RECRUITMENT <div className="arrow-down"></div>
                  <ul
                    className={classname("dropdown-menu dropdown-menu-center", {
                      show: recruitment,
                      hide: jobposting
                    })}
                    aria-labelledby="recruitmentdrop"
                  >
                    <li>
                      <Link className="dropdown-item" to="/ListOfCandidate">
                      List of Candidates
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/PreScreenCandidates">
                      Prescreened
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/ShortListed">
                      Shortlisted
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/KeepWarmCandidates">
                      Keep Warm
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/NotQualifiedCandidates">
                      Not Qualified
                      </Link>
                    </li>
                  </ul>
                  </NavLink>
                </NavItem> */}
                {/* <NavItem className="dropdown dropdown-hover">
                  <NavLink
                    to="/#"
                    id="jobpostingdropdown"
                    role="button"
                    //onClick={() => setJobPosting(!jobposting)}
                    onClick={() => onClickJobPosting(!jobposting)}
                  >
                  JOB POSTING <div className="arrow-down"></div>
                    <ul
                      className={classname("dropdown-menu dropdown-menu-center", {
                        show: jobposting,
                      })}
                      aria-labelledby="jobpostingdropdown"
                    >
                      <li>
                        <Link className="dropdown-item" to="/CreateJobPosting">
                        Post a Job
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/EmployerJobPostingList">
                        Job Posting
                        </Link>
                      </li>
                    </ul>
                  </NavLink>
                </NavItem> */}
                {/* <NavItem className="dropdown dropdown-hover">
                  <NavLink
                    to="/#"
                    id="homedrop"
                    role="button"
                    //onClick={() => setProfile(!profile)}
                    onClick={() => onClickProfile(!profile)}
                  >
                  PROFILE <div className="arrow-down"></div>
                    <ul
                      className={classname("dropdown-menu dropdown-menu-center", {
                        show: profile,
                      })}
                      aria-labelledby="jobsdropdown"
                    >
                      <li>
                        <Link className="dropdown-item" to="/companyprofile">
                        Company Profile
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/#">
                        About Us
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/#">
                        List of User
                        </Link>
                      </li>
                    </ul>
                  </NavLink>
                </NavItem> */}
                  {/* <NavItem className="dropdown dropdown-hover">
                    <NavLink
                      to="/CompanyRegister"
                      id="homedrop"
                      role="button"
                      onClick={() => setHome(!home)}
                    >
                    <Link className="dropdown-item" to="/CompanyRegister">
                    <span > Company Register </span>
                    </Link>
                    </NavLink>
                  </NavItem> */}
              </ul>
            )}
            {/* START COMPANY */}

            {/* START EMPLOYER MANAGEMENT */}
            {isVisibleAccess && (
              <ul className="navbar-nav mx-auto navbar-center">

                  <NavItem className="dropdown dropdown-hover">
                    <NavLink
                      to="/signup"
                      id="homedrop"
                      role="button"
                      onClick={() => setHome(!home)}
                    >
                    <Link className="dropdown-item" to="/signup">
                    <span > Register Company </span>
                    </Link>
                    </NavLink>
                  </NavItem>
                </ul>
            )}
            {/* END EMPLOYER MANAGEMENT */}

            {/* START CANDIDATE */}
            {isVisibleCandidate && (
              <ul className="navbar-nav mx-auto navbar-center">
                  {/* <NavItem className="dropdown dropdown-hover">
                    <NavLink
                      to="/#"
                      id="homedrop"
                      role="button"
                      onClick={() => setHome(!home)}
                    >
                    <Link className="dropdown-item" to="/#">
                    <span > JOB OPPORTUNITIES </span>
                    </Link>
                    </NavLink>
                  </NavItem>
                  <NavItem className="dropdown dropdown-hover">
                    <NavLink
                      to="/"
                      id="homedrop"
                      role="button"
                      onClick={() => setHome(!home)}
                    >
                    <Link className="dropdown-item" to="/">
                    <span > PROFILE </span>
                    </Link>
                    </NavLink>
                  </NavItem>
                  <NavItem className="dropdown dropdown-hover">
                    <NavLink
                      to="/AppliedJobs"
                      id="homedrop"
                      role="button"
                      onClick={() => setHome(!home)}
                    >
                    <Link className="dropdown-item" to="/AppliedJobs">
                    <span > APPLICATIONS </span>
                    </Link>
                    </NavLink>
                  </NavItem> */}
                </ul>
            )}
            {/* END CANDIDATE */}

            {/* START CANDIDATE SIGNIN */}
            {isVisibleCandidateSignin && (
              <ul className="navbar-nav mx-auto navbar-center">
                  {/* <NavItem className="dropdown dropdown-hover">
                    <NavLink
                      to="/ApplicationPage"
                      id="homedrop"
                      role="button"
                      onClick={() => setHome(!home)}
                    >
                    <Link className="dropdown-item" to="/ApplicationPage">
                    <span > JOB POSTING </span>
                    </Link>
                    </NavLink>
                  </NavItem> */}
                  {/* <NavItem className="dropdown dropdown-hover">
                    <NavLink
                      to="/CreateJobPosting"
                      id="homedrop"
                      role="button"
                      onClick={() => setHome(!home)}
                    >
                    <Link className="dropdown-item" to="/CreateJobPosting">
                    <span > POST A JOB </span>
                    </Link>
                    </NavLink>
                  </NavItem>
                  <NavItem className="dropdown dropdown-hover">
                    <NavLink
                      to="/CreateJobPosting"
                      id="homedrop"
                      role="button"
                      onClick={() => setHome(!home)}
                    >
                    <Link className="dropdown-item" to="/CreateJobPosting">
                    <span > POST A JOB </span>
                    </Link>
                    </NavLink>
                  </NavItem> */}
                </ul>
            )}
            {/* END CANDIDATE SIGNIN */}
          </Collapse>



            {/* START CANDIDATE */}
            {isVisibleCandidate && (
              <ul className="header-menu list-inline d-flex align-items-center mb-0">
                <NavItem className="dropdown dropdown-hover">
                  <NavLink
                    to="/signin"
                    id="productdropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    onClick={() => setBlog(!blog)}
                  >
                    
                  <Link className="dropdown-item"  onClick={handleShow}>Sign In</Link>
                  </NavLink>
                </NavItem>&nbsp;&nbsp;&nbsp;&nbsp;
                <NavItem className="dropdown dropdown-hover">
                  <NavLink
                    to="/signup"
                    id="productdropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    onClick={() => setBlog(!blog)}
                  >
                  <Link className="dropdown-item" to="/signup">
                  <img
                    src={registerImage}
                    alt="user-pic"
                    width="30"
                    height="30"
                  />
                  <span style={{fontFamily : "arial, sans-serif", fontWeight : 100}}>Register</span> 
                  </Link>
                    
                  </NavLink>
                </NavItem>
            </ul>
            )}
            <Modal show={show} onHide={handleClose} centered size="sm">
              <Modal.Header closeButton
              >
                <Modal.Title style={{fontSize : "15px", fontWeight : "900"}}>Sign in</Modal.Title>
              </Modal.Header>
              <Form action="/" className="auth-form" onSubmit={handleSubmit(onFormSubmit)}>
                <Modal.Body>
                  <div className="mb-3">
                    <label
                      htmlFor="usernameInput"
                      className="form-label"
                    >
                      Username
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      id="usernameInput"
                      placeholder="Enter email address"
                      required
                      onChange={event => setValue1(event.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="passwordInput"
                      className="form-label"
                    >
                      Password
                    </label>
                    <Input
                      type="password"
                      className="form-control"
                      id="passwordInput"
                      placeholder="Enter your password"
                      onChange={event => setValue3(event.target.value)}
                      required
                    />
                  </div>
                </Modal.Body>
                <div className="mb-4 ">
                 
                  <div className="form-check">
                 
                    <input
                      className=" "
                      type="checkbox"
                      id="flexCheckDefault"
                    />&nbsp;&nbsp;
                    <Link to="/resetpassword" className="float-end ">
                    Forgot Password?&nbsp;&nbsp;&nbsp;&nbsp;
                    </Link>
                    <label className="form-check-label" htmlFor="flexCheckDefault" >
                    Remember me
                    </label>
                  </div>
                </div>
             
              
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="success" type="submit">
                  {loading ? <Spinner size="sm" color="light" /> : "Sign in"}
                  </Button>
                
                </Modal.Footer>
              </Form>
            </Modal>
            {/* END CANDIDATE */}

            {/* START COMPANY */}
            {isVisibleCompany && (
              // <ul className="header-menu list-inline d-flex align-items-center mb-0">
              //   <NavItem className="dropdown dropdown-hover">
              //     <NavLink
              //       to="/signout"
              //       id="productdropdown"
              //       role="button"
              //       data-bs-toggle="dropdown"
              //       onClick={() => setBlog(!blog)}
              //     >
                    
              //     <Link className="dropdown-item" to="/signout">
              //     Sign Out
              //     </Link>
              //     </NavLink>
              //   </NavItem>
              // </ul>

            <ul className="header-menu list-inline d-flex align-items-center mb-0">
              <Dropdown
                onClick={() => setUserProfile(!userProfile)}
                //onClick={() => onClickRecruitment(!recruitment)}
                isOpen={userProfile}
                toggle={dropDownuserprofile}
                className="list-inline-item"
              >
                <DropdownToggle
                  to="#"
                  className="header-item"
                  id="userdropdown"
                  type="button"
                  tag="a"
                  aria-expanded="false"
                  onClick={() => setUserProfiles(!userProfiles)}
                >
                  <img
                    src={profileImage}
                    alt="mdo"
                    width="35"
                    height="35"
                    className="rounded-circle me-1"
                  />{" "}
                  <span className="d-none d-md-inline-block fw-medium" style={{color:"#59D187"}}>
                    Hi {getName}!
                  </span>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-end"
                  aria-labelledby="userdropdown"
                  end
                >
                  {/* START RECRUITMENT */}
                  <li>
                    <Link className="dropdown-item" to="/ListOfCandidate">
                    List of Candidates
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/PreScreenCandidates">
                    Prescreened
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/ShortListed">
                    Shortlisted
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/KeepWarmCandidates">
                    Keep Warm
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/NotQualifiedCandidates">
                    Not Qualified
                    </Link>
                  </li>
                  {/* END RECRUITMENT */}
                  <hr/>
                  {/* START JOB POSTING */}
                  <li>
                    <Link className="dropdown-item" to="/EmployerJobPostingList">
                    Job Posting
                    </Link>
                  </li>
                  {/* END JOB POSTING */}
                  <hr/>
                  {/* START COMPANY REGISTER */}
                  {/* <li>
                    <Link className="dropdown-item" to="/CompanyRegister">
                    Company Register
                    </Link>
                  </li> */}
                  {/* END COMPANY REGISTER */}
                  {/* <hr/> */}
                  {/* START PROFILE */}
                  <li>
                    <Link className="dropdown-item" to="/companyRegisterview">
                      My Profile
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/CompanyDocumentsView">
                     My Documents
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/#">
                    About Us
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/#">
                    List of User
                    </Link>
                  </li>
                  {/* END PROFILE */}
                  <hr/>
                  {/* <li>
                    <Link className="dropdown-item" to="/companyRegisterview">
                    Company Profile
                    </Link>
                  </li> */}
                  <li>
                    <Link className="dropdown-item" to="/signout">
                      Logout
                    </Link>
                  </li>
                </DropdownMenu>
              </Dropdown>
            </ul>
            )}
            {/* END COMPANY */}

            {/* START CANDIDATE SIGNIN */}
            {isVisibleCandidateSignin && (
            <ul className="header-menu list-inline d-flex align-items-center mb-0">
              <Dropdown
                onClick={() => setUserProfile(!userProfile)}
                isOpen={userProfile}
                toggle={dropDownuserprofile}
                className="list-inline-item"
              >
                <DropdownToggle
                  to="#"
                  className="header-item"
                  id="userdropdown"
                  type="button"
                  tag="a"
                  aria-expanded="false"
                  onClick={() => setUserProfiles(!userProfiles)}
                >
                  <img
                    src={profileImage}
                    alt="mdo"
                    width="35"
                    height="35"
                    className="rounded-circle me-1"
                  />{" "}
                  <span className="d-none d-md-inline-block fw-medium" style={{color:"#59D187"}}>
                    Hi {getName}!
                  </span>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-end"
                  aria-labelledby="userdropdown"
                  end
                >
                <li>
                  <Link className="dropdown-item" to="/AppliedJobs">
                    Applications
                  </Link>
                </li>
                {/* <li>
                  <Link className="dropdown-item" to="/CompanyRegisterView">
                    Company Profile
                  </Link>
                </li> */}
                  <li  onClick={onclickCandidate}>
                    <Link className="dropdown-item">
                    Candidate Profile
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/signout">
                      Logout
                    </Link>
                  </li>
                </DropdownMenu>
              </Dropdown>
            </ul>
            )}
            {/* END CANDIDATE */}
            
            {/* START ACCESS */}
            {isVisibleAccess && (
            <ul className="header-menu list-inline d-flex align-items-center mb-0">
              <Dropdown
                onClick={() => setUserProfile(!userProfile)}
                isOpen={userProfile}
                toggle={dropDownuserprofile}
                className="list-inline-item"
              >
                <DropdownToggle
                  to="#"
                  className="header-item"
                  id="userdropdown"
                  type="button"
                  tag="a"
                  aria-expanded="false"
                  onClick={() => setUserProfiles(!userProfiles)}
                >
                  <img
                    src={profileImage}
                    alt="mdo"
                    width="35"
                    height="35"
                    className="rounded-circle me-1"
                  />{" "}
                  <span className="d-none d-md-inline-block fw-medium" style={{color:"#59D187"}}>
                    Hi {getName}!
                  </span>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-end"
                  aria-labelledby="userdropdown"
                  end
                >
                  <li>
                    <Link className="dropdown-item" to="/myprofile">
                      My Profile
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/signout">
                      Logout
                    </Link>
                  </li>
                </DropdownMenu>
              </Dropdown>
            </ul>
            )}
            {/* END ACCESS */}
        </Container>
      </nav>
    </React.Fragment>
  );
};

export default withRouter(NavBar);
