import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";


import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Modal,
  ModalBody,
 // Form,
  FormGroup,
  Label,
  Spinner,
  Alert,
  //Button
  
} from "reactstrap";
import axios from 'axios';
import { AppConfiguration } from "read-appsettings-json";
import moment from 'moment';
import useForm from "./useForm";
import {  Card,   CardBody } from "reactstrap";

import lightLogo from "../../assets/images/logo-light.png";
import darkLogo from "../../assets/images/logo-dark.png";

import signUpImage from "../../assets/images/auth/sign-up.png";
//import useForm from "../../wcomponents/SignUp/useForm";
import Icon from "../../wcomponents/Icon/Icon";
import "../../wcomponents/w.css"
import NavBar from "../../Layout/CommonLayout/NavBar"//"../CommonLayout/NavBar";
import ScrolltoTop from "../../components/ScrolltoTop";
import TopBar from "../../Layout/CommonLayout/TopBar";
import { setSelection } from "@testing-library/user-event/dist/cjs/event/selection/setSelection.js";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';



const ContinueR=()=>{
    const [userdata, setUserdata] = useState(JSON.parse(sessionStorage.getItem("userdata")));
    const navigate = useNavigate();

    useEffect(
        () => {
          console.log(userdata)
        },
        []
    );

   
    const Companyregisterr=()=>{
        navigate("/CompanyRegister");

    }
    const Companydocuments=()=>{
        navigate("/CompanyDocuments");

    }
    const continuerr=()=>{
        sessionStorage.setItem("userdata", JSON.stringify(userdata))
            window.sessionStorage.setItem('results', JSON.stringify({"menuId" : "002"}));
            navigate("/");
    }
    
    return (
        <React.Fragment>
            <NavBar/>
            <div className="" >
                <Container  className="mt100">
                    <Row>
                        <label className="labelML labeltext">Continue your registration</label>
                    </Row>
                    <div className="continueRcard center">
                        <Row>
                          <Col className="mt-5"></Col>
                        </Row>
                        
                        <Row>
                            <Col>
                                <Button onClick={Companydocuments} type="file" className="btnBorder mb-2" variant="success">
                                    Upload Documents
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <text className="labeltext">
                                Upload your corporate document to initially
                            </text>
                        </Row>
                        <Row>
                            <text className="labeltext mb-5">
                                proceed with job posting
                            </text>
                        </Row>
                        
                        <Row>
                            <Col>
                                <Button onClick={Companyregisterr} className="btnBorder mb-2" variant="success">
                                    Continue Registration
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <text className="labeltext">
                                Continue your profile so you can post and do resume
                            </text>
                        </Row>
                        <Row>
                            <text className="labeltext mb-5">
                               search to find your best candidate
                            </text>
                        </Row>

                        <Row>
                            <Col>
                                <Button
                                onClick={continuerr} className="btnBorder mb-2" variant="success">
                                    Continue Later
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <text className="labeltext mb-5">
                                you can only view candidate basic profile
                            </text>
                        </Row>
                        
                    </div>
                </Container>
            <ScrolltoTop />
            </div>
        </React.Fragment>
    )
}

export default ContinueR