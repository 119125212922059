import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Card, CardBody, Col, Row, Input, Label, select, Text, Modal, ModalBody, } from "reactstrap";
import { Link } from "react-router-dom";
import moment from 'moment';

import axios from 'axios'
import { useNavigate } from "react-router-dom";

const AppliedJobs = () => {
const navigate = useNavigate();
const [ appliedList , setAppliedList] = useState([])
const [ date , setDate] = useState("")
const [ flterUser , setUserLogin] = useState(JSON.parse(sessionStorage.getItem("userCollection")))

useEffect(() => {
  GetAppliedJobs()
  setDate(new Date())
},[]);
const GetAppliedJobs =async()=> {
  // console.log("_dataList")
  // console.log(_dataList)
  const dataTmp=[]
  const Params = {
    // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
    "_collection" : "Candidates",
    "filter_data" : {"employeeId" : flterUser[0].userId}
  }
  axios
  .post("http://206.189.95.201:8098/action/get", Params)
  .then(res => {
    let data = res.data
    // console.log(data)
    data.Candidates.map(function(itm,i){
      const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
      const json = JSON.parse(strJson.replace(/'/g,'"'));
      dataTmp.push(json)
    })   
    console.log("dataTmp")
    console.log(dataTmp)
    let newArry = []
    dataTmp.map(function(itm,i){
      let _status = ""
      if(itm.status==="NW"){
      _status = "New"
      }
      else if(itm.status==="PS"){
      _status = "Pre-screen"
      }
      else if(itm.status==="KW"){
      _status = "Keep warm"
      }
      else if(itm.status==="SL"){
      _status = "Shortlisted"
      }
      else if(itm.status==="NQ"){
      _status = "Unmatched"
      }
      else{
        _status = ""
      }
      newArry.push({
        city: itm.city,
        companyName: itm.companyName,
        contact: itm.contact,
        createdBy: itm.createdBy,
        createdDate: itm.createdDate,
        dataPrivacy: itm.dataPrivacy,
        dateApplied: itm.dateApplied,
        educLevel: itm.educLevel,
        email: itm.email,
        employeeId: itm.employeeId,
        employementType: itm.employementType,
        endDate: itm.endDate,
        expectedSalary: itm.expectedSalary,
        experience: itm.experience,
        id: itm.id,
        industry: itm.industry,
        isDeleted: itm.isDeleted,
        isModified: itm.isModified,
        isRegister: itm.isRegister,
        jobDepartment: itm.jobDepartment,
        jobDocNo: itm.jobDocNo,
        level: itm.level,
        message: itm.message,
        modifiedDate: itm.modifiedDate,
        modifiedby: itm.modifiedby,
        name: itm.name,
        postingId: itm.postingId,
        qualification: itm.qualification,
        resume: itm.resume,
        status: _status,
        title: itm.title,
      })
    })
    setAppliedList(newArry)
  })
}

return (
  <React.Fragment>
    <Row >
      <Col sm={4}>
      </Col>
      <Col sm={4} >
        <Card style={{backgroundColor: "#F0FEED", marginTop : "25%"}} >
          <CardBody className="p-4" >
            <Row >
              <h9 style={{color: "#00000", fontSize : "14px", fontWeight: "bolder", textAlign: "left"}}>
                <label>Applications</label>
              </h9>
            </Row>
            {appliedList.map((AppliedTmp, key) => (
              <div
                key={key}
                style={{backgroundColor : "#F0FEED"}}
              >
                <Card style={{backgroundColor: "white", marginTop : "5%"}} >
                  <CardBody className="p-4" >
                    <Row>
                      <label style={{fontSize : "14px"}}>
                        Position: &nbsp;&nbsp;&nbsp; <b style={{color:"#0A6219", fontSize : "14px"}}>{AppliedTmp.title}</b>
                      </label>
                    </Row>
                    <Row className="mt-1">
                      <label style={{fontSize : "14px"}}>
                        Company Name: &nbsp;&nbsp;&nbsp; <b >{AppliedTmp.companyName}</b>
                      </label>
                    </Row>
                    <Row className="mt-1">
                      <label style={{fontSize : "14px"}}>
                        Applied Date: &nbsp;&nbsp;&nbsp;{AppliedTmp.dateApplied}
                      </label>
                    </Row>
                    <Row className="mt-1">
                      <label style={{fontSize : "14px"}}>
                        Application Status: &nbsp;&nbsp;&nbsp;<b style={{color:"#0A6219", fontSize : "14px"}}>{AppliedTmp.status}</b>
                      </label>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            ))}
          </CardBody>
        </Card>
      </Col>
      <Col sm={4}>
      </Col>
    </Row>
  </React.Fragment>
)};
export default AppliedJobs;