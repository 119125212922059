// import { React,Component, axios, AppConfiguration } from './noser-hris-component';
import axios from 'axios'

const GetRequest = async(props) => {
  console.log("GetRequest")
    // console.log(response)
  let response = {
    "status" : "",
    "message" : "",
    "JobPosting":[]
  }
  let collection = new Array()
   console.log("props")
   console.log(props)
   console.log("1")
   await axios
   .post(props.url , props.params)
   .then(res => {
     console.log("2")
     console.log("res.data")
     console.log(res.data)
     const data = res.data
     console.log("data")
     console.log(data)
            //data[props.params._collection].map(function(itm,i){
      data["JobPosting"].map(function(itm,i){
        let strJson = "{" + "'id':" + itm.substring(17, 43) + "," + itm.substring(46, itm.length)
        let json = JSON.parse(strJson.replace(/'/g,'"'));
          collection.push(json)
          console.log("3")
      })
      response = {
        status : "1",
        "message" : "success",
        "JobPosting":collection
      }
      // console.log("response")
      // console.log(response)
      // console.log("4")
      })
      .catch(error=>{
       response = {
        status : "0",
        "message" : "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
        "JobPosting":[]
       }
       console.log("5")
   }) 
return response
} 
const PostRequest = async(props) => {
    let response = {
        "status" : "",
        "message" : ""
    }
    let collection = new Array()
    await axios
      .post(props.url , props.params)
      .then(res => {
            //const data = res.data
            response = {
                status : "1",
                "message" : "success"
            }
      })
      .catch(error=>{
        response = {
            status : "0",
            "message" : "An error occured while processing your request, Please contact your System Administrator for : " + error.message
        }
      })
    return response
} 

export { GetRequest, PostRequest }