import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";


import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Spinner,
  Alert,
  //Button
} from "reactstrap";
import axios from 'axios';
import { AppConfiguration } from "read-appsettings-json";
import moment from 'moment';
import useForm from "./useForm";
import {  Card,   CardBody } from "reactstrap";

import lightLogo from "../../assets/images/logo-light.png";
import darkLogo from "../../assets/images/logo-dark.png";

import signUpImage from "../../assets/images/auth/sign-up.png";
//import useForm from "../../wcomponents/SignUp/useForm";
import Icon from "../../wcomponents/Icon/Icon";
import "../../wcomponents/w.css"
import NavBar from "../../Layout/CommonLayout/NavBar"//"../CommonLayout/NavBar";
import ScrolltoTop from "../../components/ScrolltoTop";
import TopBar from "../../Layout/CommonLayout/TopBar";
import { setSelection } from "@testing-library/user-event/dist/cjs/event/selection/setSelection.js";
import Button from 'react-bootstrap/Button';


const CompanySuccess=()=>{
    const [userdata, setUserdata] = useState(JSON.parse(sessionStorage.getItem("userdata")));
    const navigate = useNavigate();
    const [pagePath, setPagePath] = useState(true)


    useEffect(
        () => {
          console.log(userdata)
        },
        []
    );

    const lipat=()=>{
        //navigate("/signin");
       
    }
    const continuesR=()=>{
        window.sessionStorage.setItem('results', JSON.stringify({"menuId" : "002"}));


        //navigate("/ContinueR");
        if(pagePath){
            navigate("/")
        }
    }
    
    return (
        <React.Fragment>
            {/* <TopBar /> */}
            <NavBar/>
            {/* <div className="headerMT CFVcard "></div> */}
            <div className="center">
                <Label className='mb-5 mt150 CFVlabel'> Congratulations! Your Company is now verified.</Label>
                <Row>
                    <Col></Col>
                    <Col></Col>
                    <Col><Button onClick={continuesR} sm={5} variant="success"> Proceed </Button></Col>
                    <Col></Col>
                    <Col></Col>
                    
                </Row>
            </div>
            <ScrolltoTop/>
        </React.Fragment>
    )  
   
}

export default CompanySuccess