import React, { useState,useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Modal,
  ModalBody,
  //Form,
  FormGroup,
  Label,
  Spinner,
  /* Alert, */
  Button
} from "reactstrap";
import axios from 'axios';
import { AppConfiguration } from "read-appsettings-json";
import moment from 'moment';
//import useForm from "./useForm";
import InputGroup from 'react-bootstrap/InputGroup';
import Resizer from "react-image-file-resizer";
import {  Card,   CardBody } from "reactstrap";
import lightLogo from "../../assets/images/logo-light.png";
import darkLogo from "../../assets/images/logo-dark.png";
import signUpImage from "../../assets/images/auth/sign-up.png";
//import useForm from "../../wcomponents/SignUp/useForm";
import Icon from "../../wcomponents/Icon/Icon";
import "../../wcomponents/w.css"
import NavBar from "../../Layout/CommonLayout/NavBar"//"../CommonLayout/NavBar";
import ScrolltoTop from "../../components/ScrolltoTop";
import TopBar from "../../Layout/CommonLayout/TopBar";
import { setSelection } from "@testing-library/user-event/dist/cjs/event/selection/setSelection.js";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { FormControl } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
// import Alert from 'react-bootstrap/Alert';
import { useForm } from "react-hook-form";
import { render } from "react-dom";
//import TextareaAutosize from "react-textarea-autosize";

import {Alert} from "reactstrap";

const CompanyDocuments = ({submitForm }) => {
    const [userdata, setUserdata] = useState(JSON.parse(sessionStorage.getItem("userdata")));
    const navigate = useNavigate();
    const [pagePath, setPagePath] = useState(true)
    const [show, setShow] = useState(false);
    const [dissableBtn, setDissableBtn] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const { errors, register, handleSubmit, control } = useForm();

    const [secCertificate,setSecCertififacte] =useState({});
    const [secCertBase64,setsecCertBase64] =useState("");

    const [companyArticles, setcompanyArticles] = useState({})
    const [companyArticlesBase64, setcompanyArticlesBase64] = useState("")

    const [BirCertificate, setBirCertificate] = useState({})
    const [BirCertificateBase64, setBirCertificateBase64] = useState("")

    const [businessPermit, setbusinessPermit] = useState({})
    const [businessPermitBase64, setbusinessPermitBase64] = useState("")

    const [companyOwnerId, setcompanyOwnerId] = useState({})
    const [companyOwnerIdBase64, setcompanyOwnerIdBase64] = useState("")

    const [doleRegistered, setdoleRegistered] = useState({})
    const [doleRegisteredBase64, setdoleRegisteredBase64] = useState("")

    const [peza,setPeza] = useState({})
    const [pezaBase64, setpezaBase64] = useState("")




    const [headerId, setheaderId] = useState("")
    const [arr1, setarr] = useState([])
    const [isshow, setisshow] = useState(false)
    const [color, setcolor] = useState("")
    const [message, setmessage] = useState("")
    const [fade, setfade] = useState("")
    const [alerttype, setalerttype] = useState("")
    const [disableSave, setdisableSave] = useState(false);
    const [selectetdFile, setSelectedFile] = useState([]);
    const [fileBase64String, setFileBase64String] = useState("");

    useEffect(
        () => {
            console.log("CompanyDocuments")
          //handleGetCompanyChecklist()
        },
        []
    );
    const handleCheckBox =(event)=>{
        console.log(event.target.checked)
        if(event.target.checked === true){
            setIsChecked(true)
            setDissableBtn(false)
            //setAgree("YES")
            //console.log("yes")
          }else{
            setIsChecked(false)
            setDissableBtn(true)
            //setAgree("NO")
            //console.log("no")
          }
    };
    
    const onFormSubmit=()=>{
        sessionStorage.setItem("userdata", JSON.stringify(userdata))
        window.sessionStorage.setItem('results', JSON.stringify({"menuId" : "002"}));

        let secOBJ = {
            "name" : secCertificate.name,
            "size" : secCertificate.size,
            "type" : secCertificate.type,
            "path" : "opt/noser",
            "base64" : secCertBase64,
            "isModified" : "0",
            "modifiedDate" : ""
        };
        let companyArticlesOBJ = {
            "name" : companyArticles.name,
            "size" : companyArticles.size,
            "type" : companyArticles.type,
            "path" : "opt/noser",
            "base64" : companyArticlesBase64,
            "isModified" : "0",
            "modifiedDate" : ""
        };
        let birCertificatelesOBJ = {
            "name" : BirCertificate.name,
            "size" : BirCertificate.size,
            "type" : BirCertificate.type,
            "path" : "opt/noser",
            "base64" : BirCertificateBase64,
            "isModified" : "0",
            "modifiedDate" : ""
        };
        let businessPermitOBJ = {
            "name" : businessPermit.name,
            "size" : businessPermit.size,
            "type" : businessPermit.type,
            "path" : "opt/noser",
            "base64" : businessPermitBase64,
            "isModified" : "0",
            "modifiedDate" : ""
        };
        let companyOwnerIdOBJ = {
            "name" : companyOwnerId.name,
            "size" : companyOwnerId.size,
            "type" : companyOwnerId.type,
            "path" : "opt/noser",
            "base64" : setcompanyOwnerIdBase64,
            "isModified" : "0",
            "modifiedDate" : ""
        };
        let doleRegisteredOBJ = {
            "name" : doleRegistered.name,
            "size" : doleRegistered.size,
            "type" : doleRegistered.type,
            "path" : "opt/noser",
            "base64" : doleRegisteredBase64,
            "isModified" : "0",
            "modifiedDate" : ""
        };
        let pezaOBJ = {
            "name" : peza.name,
            "size" : peza.size,
            "type" : peza.type,
            "path" : "opt/noser",
            "base64" : pezaBase64,
            "isModified" : "0",
            "modifiedDate" : ""
        };
        

        let obj = {
            "secCertificate" : secOBJ,
            "companyArticles" : companyArticlesOBJ,
            "birCertificate" : birCertificatelesOBJ,
            "businessPermit" : businessPermitOBJ,
            "companyOwnerId"    :companyOwnerIdOBJ,
            "doleRegistered"    : doleRegisteredOBJ,
            "peza"      : pezaOBJ,
            "userId"    : userdata[0].userId
        };
        console.log(obj)

        const params = {
            // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
            "_collection" : "CompanyDocuments",
            "doc_data" : obj
        }
        console.log("params")
        console.log(params)
        axios
        .post(AppConfiguration.Setting().mongoJobspace + "action/add", params )
        .then(res => {
           
        console.log("res")
        console.log(res.status)
        console.log(res.data)
        if(res.status === 200){
            handleGetCompanyChecklist()
        }
        
        })
        .catch(error=>{
            console.log("error: " + error)
            console.log("ewwow")
        }) 
        //navigate("/ContinueR");
       
    };

    const handleGetCompanyChecklist = async() => {
        console.log("handleGetCompanyChecklist")
        let filter_data = {
          "isDeleted" : "0", 
          "userId" : userdata.userId,
          //"isDeleted" : "0",
         /*  "firstName" : firstName,
          "lastName"  : lastName, */
        }
        
        const Params = {
          "_collection" : "CompanyRegisteredCheckList",
          "filter_data" : filter_data
        }
        console.log("Params")
        console.log(Params)
        console.log("s")
         axios
         .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {

            console.log("res.data")
            console.log(res)
    
            let dataTmp = []
            let a
            let dataaa = res.data
            console.log("data")
            console.log(dataaa)
            let newstr2=""
            dataaa.CompanyRegisteredCheckList.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let strJson2 = itm.substring(18, itm.length)
                newstr2 = strJson2.split("')")[0]
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                json.headerId = newstr2
                console.log(json.headerId)
                //setheaderId(json.headerId)
                dataTmp.push(json)
                a = json
              })    
             // setheaderId(dataTmp[0].h)
              //updateCompanyRegisteredCheckList()
              console.log("dataTmp")
              console.log(dataTmp)
              dataTmp.forEach(element => {
                  element.businessPermit ="1"
              });
              setheaderId(dataTmp[0].headerId)
              //let b =  
              setarr(dataTmp)
              if(res.status === 200){
                  updateCompanyRegisteredCheckList(a,dataTmp)
              }
        })
        .catch(error =>{
            alert("error: " + error)
        })
    };

    const updateCompanyRegisteredCheckList=(a,dataTmp)=>{
        console.log(headerId)
        console.log(a)
        console.log(arr1)
        //console.log(dataTmp[0])

        let d = new Date();
        let createddate = moment(d).format('MM/DD/YYYY');
        let w = d.toLocaleTimeString()
        //User for Client ID
        let today = new Date()
        let clientId = moment(today).format('MMYYYYhhmmss');
        let obj = {
          "userId" : userdata.userId,
          "basicInfoCompany" :a.basicInfoCompany,
          "businessPermit" :"1",
          "isDeleted" : "0",
          "createdDate"             :a.createdDate,
          "timestamp"               : a.timestamp,
          "isDraft"                 : "0",
          "isModifiedDate"          : w
        }
        //let 

        const updateParams = {

            "_collection" : "CompanyRegisteredCheckList",
            "doc_data"    : obj,
            "_id"         :a. headerId,
  
        }

      console.log(" toUpdate")
      console.log(updateParams)
  
      axios
      .post("http://206.189.95.201:8098/action/update_one", updateParams)
      .then(res => {
        let data = res.data
        console.log(data)
        if(res.status === 200){
            handleGetCompanyChecklist2()
        }
       
       
        }).catch(error =>{
            alert("error: " + error)
        })
    }

    const handleGetCompanyChecklist2 = async() => {
        console.log("handleGetCompanyChecklist")
        let filter_data = {
          "isDeleted" : "0", 
          "userId" : userdata.userId,
          //"isDeleted" : "0",
         /*  "firstName" : firstName,
          "lastName"  : lastName, */
        }
        
        const Params = {
          "_collection" : "CompanyRegisteredCheckList",
          "filter_data" : filter_data
        }
        console.log("Params")
        console.log(Params)
        console.log("s")
         axios
         .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {

            console.log("res.data")
            console.log(res)
    
            let dataTmp = []
            let a
            let dataaa = res.data
            console.log("data")
            console.log(dataaa)
            let newstr2=""
            dataaa.CompanyRegisteredCheckList.map(function(itm,i){
              let strJson = "{" + itm.substring(46, itm.length)
              let strJson2 = itm.substring(18, itm.length)
              newstr2 = strJson2.split("')")[0]
              let json = JSON.parse(strJson.replace(/'/g,'"'));
              json.headerId = newstr2
              console.log(json.headerId)
              //setheaderId(json.headerId)
              dataTmp.push(json)
              //a = json
            })    
           // setheaderId(dataTmp[0].h)
            //updateCompanyRegisteredCheckList()
            console.log("dataTmp")
            console.log(dataTmp)
            /* dataTmp.forEach(element => {
                element.basicInfoCompany ="1"
            });*/
            if(res.status === 200){
                copyToClipboard()
              
            } 
           
            //sessionStorage.setItem("CompanyProfile", JSON.stringify(dataTmp))
        })
        .catch(error =>{
            alert("error: " + error)
        })
    };

    
  const copyToClipboard = (e) => {
    const timeoutId = setTimeout(() => {
        console.log("timeoutId")
        console.log(timeoutId)
        setdisableSave(true)
        setalerttype("Success!")
        setisshow(true)
        setcolor("success")
        setmessage("Succesfully submitted")
        setfade(true)
      }, 3000);
    const timeoutIds = setTimeout(() => {
        console.log("timeoutId")
        console.log(timeoutIds)
        navigate("/CompanyDocuments")
        navigate("/DocumentsSuccess")
      }, 5000);
    //   if(timeoutId === 3){
    //     setdisableSave(true)
    //     setalerttype("Success!")
    //     setisshow(true)
    //     setcolor("success")
    //     setmessage("Success")
    //     setfade(true)
    //   }
  };
    ///base641
    const secFile = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        setsecCertBase64(base64);
        setSecCertififacte(file);
    };
    const companyArticleschange = async(e)=>{
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        setcompanyArticles(file)
        setcompanyArticlesBase64(base64)
    };
    const birCertificatechange = async(e)=>{
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        setBirCertificate(file)
        setBirCertificateBase64(base64) 
    };
    const businessPermitChange = async(e)=>{
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        setbusinessPermit(file)   
        setbusinessPermitBase64(base64)
    };
    const companyOwnerIdChange = async(e)=>{
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        setcompanyOwnerId(file)   
        setcompanyOwnerIdBase64(base64)
    };
    const DOLEregisteredChange = async(e)=>{
         const file = e.target.files[0];
        const base64 = await convertBase64(file);
        setdoleRegistered(file)
        setdoleRegisteredBase64(base64)   
    };
    const PEZAchange = async(e)=>{
         const file = e.target.files[0];
        const base64 = await convertBase64(file);
        setPeza(file)
        setpezaBase64(base64)   
    };
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
            resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
            reject(error);
            };
        });
    };





    //base64 2
   /*  const secFile = (e) => {
        setSelectedFile(e.target.files);
        console.log(e.target.files[0]);
        console.log(e.target.files[0].name);
        console.log(e.target.files[0].size);
        console.log(e.target.files[0].type);
    };
     */
    const encodeFileBase64 = (file) => {
        var reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
            var Base64 = reader.result;
            console.log(Base64);
            setFileBase64String(Base64);
            };
            reader.onerror = (error) => {
            console.log("error: ", error);
            };
        }
    };
    const decodeFileBase64 = (base64String) => {
        // From Bytestream to Percent-encoding to Original string
        return decodeURIComponent(
        atob(base64String)
            .split("")
            .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16).slice(-2));
            })
            .join("")
        );
    };
    const decodeBase64 = decodeFileBase64(
        fileBase64String.substring(fileBase64String.indexOf(",") + 1)
    );

    

    return (
        <React.Fragment  >
         {/*  <TopBar /> */}
           <NavBar />
          <div > 
            
                  <Container fluid='true' >
                   
                   <Row className="mt-3"></Row>
                   <Row className="mt-3"></Row>
                   
                    <Row className="justify-content-center">
                    
                    <Col sm={5} >
                        <div className="CRcard-two">
                           <Form onSubmit={handleSubmit(onFormSubmit)}>
                                <Alert isOpen={isshow} color={color} fade={fade} className={isshow ? 'display-block' : 'display-none'}>
                                    <div className={color}></div> 
                                    {message}
                                </Alert>
                                <Row>
                                    <Form.Text className=" ml5percent mt-4 ">To ensure the legitimacy of your company and the job hiring you posted,</Form.Text>
                                </Row>
                                <Row>
                                    <Form.Text className="ml5percent  ">below list of company documents are required</Form.Text>
                                </Row>
                                <Row>
                                <h4 className=" ccpLabel mt-4 mb-2">Upload Company Documents</h4>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <Form.Label className="ml5percent mt-2">SEC Certificate of Registration</Form.Label>
                                    </Col>
                                    <Col sm={5}>
                                        <Form.Control
                                            style={{width: "280px"}}
                                            className="" type="file" size="sm"
                                            //name="secCertificate"
                                            //value={secCertificate} 
                                            onChange={secFile}
                                            required
                                        />
                                    </Col>
                                    <Col sm={1}></Col>
                                </Row>
                               
                                <Row className="mt-3">
                                    <Col sm={6}>
                                        <Form.Label className="ml5percent mt-2">Company Articles of Incorporation</Form.Label>
                                    </Col>
                                    <Col sm={5}>
                                        <Form.Control
                                            required
                                            style={{width: "280px"}}   
                                            className="asd" 
                                            type="file" 
                                            size="sm" 
                                            onChange={companyArticleschange}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={6}>
                                        <Form.Label className="ml5percent mt-2">BIR Certificate of Registration </Form.Label>
                                    </Col>
                                    <Col sm={5}>
                                        <Form.Control  
                                            required  
                                            style={{width: "280px"}}   
                                            className="asd" 
                                            type="file" 
                                            size="sm" 
                                            onChange={birCertificatechange}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={6}>
                                        <Form.Label className="ml5percent mt-2">Business Permit</Form.Label>
                                    </Col>
                                    <Col sm={5}>
                                            <Form.Control  
                                            required  
                                            style={{width: "280px"}}   
                                            className="asd" 
                                            type="file" 
                                            size="sm" 
                                            onChange={businessPermitChange}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={6}>
                                        <Form.Label className="ml5percent mt-2">Company Owner/President ID </Form.Label>
                                    </Col>
                                    <Col sm={5}>
                                        <Form.Control   
                                            style={{width: "280px"}}   
                                            className="asd" 
                                            type="file" 
                                            size="sm" 
                                            onChange={companyOwnerIdChange}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={6}>
                                        <Form.Label className="ml5percent mt-2">DOLE Registration</Form.Label>
                                    </Col>
                                    <Col sm={5}>
                                        <Form.Control 
                                            style={{width: "280px"}}  
                                            className="asd" 
                                            type="file" 
                                            size="sm" 
                                            onChange={DOLEregisteredChange}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={6}>
                                        <Form.Label className="ml5percent mt-2">PEZA/Free Port Certificate (Optional)</Form.Label>
                                    </Col>
                                    <Col sm={5}>
                                        <Form.Control
                                          style={{width: "280px"}}   
                                          className="asd" 
                                          type="file" 
                                          size="sm" 
                                          onChange={PEZAchange}
                                        />
                                    </Col>
                                </Row>
                                {/* <div className=" mb-1 margincheck form-check mt-5">
                                    
                                    <input
                                      className=" form-check"
                                      type="checkbox"
                                      //id="flexCheckDefault"
                                      onChange={handleCheckBox}
                                      //value={isChecked}
                                      name="isChecked"
                                      isValid
                                    />
                                    <h1
                                      className=" checktxt form-check-label uploadDocs"
                                      htmlFor="flexCheckDefault"
                                     
                                    >
                                      By clicking �SUBMIT� you confirm that the details and documents
                                        stated and uploaded are correct  and true. You also agree to 
                                        JobSpace�s Privacy  Policy, on collection of personal data ensures
                                        veracity of corporate documents prior to use of JobSpace
                                        job posting features{" "}
                                    </h1>
                                    <text
                                      className=" checktxt form-check-label"
                                      htmlFor="flexCheckDefault"
                                     
                                    >
                                        
                                    </text>
                                </div> */}
                                <Row className="mt-5">
                                    <Col sm={1}></Col>
                                    <Col sm={1}>
                                        
                                        <input
                                            className="mt-2 ml-4 form-check"
                                            type="checkbox"
                                            //id="flexCheckDefault"
                                            onChange={handleCheckBox}
                                            //value={isChecked}
                                            name="isChecked"
                                            //isValid
                                        />
                                    </Col>
                                    <Col sm={9}>
                                        <h1
                                        className=" checktxt form-check-label uploadDocs"
                                        htmlFor="flexCheckDefault"
                                        
                                        >
                                        By clicking �SUBMIT� you confirm that the details and documents
                                            stated and uploaded are correct  and true. You also agree to 
                                            JobSpace�s Privacy  Policy, on collection of personal data ensures
                                            veracity of corporate documents prior to use of JobSpace
                                            job posting features{" "}
                                        </h1>
                                    </Col>
                                    <Col sm={1}></Col>
                                </Row>
                                <div className="center">
                                    {/* <Button
                                        type="submit"
                                        className="btn btn-hover mr-auto mb-5 mt-5"
                                        color="success"
                                        size="sm"
                                        onClick={handleSubmitFILES}
                                        >
                                            SUBMIT
                                    </Button> */}
                                    
                                    {!isshow && <Button type="submit"
                                        className="btn btn-hover mr-auto mb-5 mt-5"
                                        color="success"
                                        size="sm"
                                        disabled={dissableBtn}
                                       
                                         /* onClick={onFormSubmit} */>Submit</Button>}
                                </div>
                                {/* <Button  onClick={copyToClipboard} className="" size="sm" variant="dark">BACK</Button> */}
                               
                           </Form>
                        </div>
                    </Col>

                        
                      
                    </Row>
                    {/* <Alert className="alerthieght" show={isshow} variant="success">
                        <h5 className="successsubmited"> Succesfully submitted</h5>
                        
                            <Button className="btn btn-hover mr-auto mb-5 mt-5"
                            color="danger"
                            size="sm"  onClick={() => setShow(false)} variant="outline-success">
                                Close 
                            </Button>
                        
                    </Alert> */}
                  </Container>
                
                  <ScrolltoTop />
          </div>
        </React.Fragment>
      );

}
/* render(<CompanyDocuments />, document.querySelector("#root"));*/
document.querySelector("#root")
export default CompanyDocuments; 