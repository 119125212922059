import React, { useState, useCallback,useEffect } from "react";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import { useNavigate  } from "react-router-dom"
import axios from 'axios';


//Import Image
import lightLogo from "../../assets/images/logo-light.png";
import darkLogo from "../../assets/images/logo-dark.png";

import signInImage from "../../assets/images/auth/sign-in.png";
import { Link } from "react-router-dom";
import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { GetRequest, PostRequest } from '../../noser-dataaccess';
import { AppConfiguration } from "read-appsettings-json";
import ScrolltoTop from "../../components/ScrolltoTop";





const SignIn = () => {
  document.title = "Sign In | JobSpace - Job Listing Template | Themesdesign";
  const [wdata,setwData] = useState([])
  const { errors, register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");
  const [NOSERUSRData, setNOSERUSRData] = useState([]);
  const [details, setDetails] = useState([])
  const [value1, setValue1] = useState("")
  const [value2, setValue2] = useState("")
  const [value3, setValue3] = useState("")
  const [respArray, setRespArray] = useState([])
  const [valueDataRegister, setValueDataRegister] = useState([]);

  useEffect(
    () => {
      localStorage.clear();
      sessionStorage.clear();
      //handleGetCompanyCollection()
    },
    []
  );

  const handleGetRegister = async() => {
    let filter_data = {
      "isDeleted" : "0", 
      "email"     : value1, 
      "password"  : value3
    }
    
    const Params = {
      "_collection" : "UserCollection",
      "filter_data" : filter_data
    }
    console.log("Params")
    console.log(Params)
    await axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {

      let dataTmp = []

        let dataa = res.data
        console.log(res)
        console.log("data")
        console.log(dataa)
        let newstr2=""
        dataa.UserCollection.map(function(itm,i){
            let strJson = "{" + itm.substring(46, itm.length)
            let strJson2 = itm.substring(18, itm.length)
            newstr2 = strJson2.split("')")[0]
            let json = JSON.parse(strJson.replace(/'/g,'"'));
            json.headerId = newstr2
            dataTmp.push(json)
        })    
        console.log("dataTmp")
        console.log(dataTmp)
        console.log(dataTmp[0].isEmployer)
        
        console.log(dataTmp[0].userId)
        setwData(dataTmp)  
        let tmptmp = dataTmp[0].userId
        let firstName = dataTmp[0].firstName
        let lastName = dataTmp[0].lastName
        let employer = dataTmp[0].isEmployer
        if(res.status === 200){
          handleGetCompanyCollection(tmptmp,firstName,lastName,employer) 
          sessionStorage.setItem("userCollection", JSON.stringify(dataTmp))
          sessionStorage.setItem("userCollectionArr", JSON.stringify([dataTmp]))
          sessionStorage.setItem("userdata", JSON.stringify(dataTmp))
          sessionStorage.setItem("userdataArray", JSON.stringify([dataTmp]))

        }
       
      
       
    })
    .catch(error =>{
        alert("error: " + error)
    })
  };

  const handleGetCompanyCollection = async(tmptmp,firstName,lastName,employer) => {
    console.log(tmptmp)
    let filter_data = {
      "isDeleted" : "0", 
      "userId" : tmptmp,
     /*  "firstName" : firstName,
      "lastName"  : lastName, */
    }
    
    const Params = {
      "_collection" : "CompanyProfile",
      "filter_data" : filter_data
    }
    console.log("Params")
    console.log(Params)
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {

      let dataTmp = []

        let dataaa = res.data
        console.log("data")
        console.log(dataaa)
        let newstr2=""
        dataaa.CompanyProfile.map(function(itm,i){
          let strJson = "{" + itm.substring(46, itm.length)
          let strJson2 = itm.substring(18, itm.length)
          newstr2 = strJson2.split("')")[0]
          let json = JSON.parse(strJson.replace(/'/g,'"'));
          json.headerId = newstr2
          dataTmp.push(json)
        })    
        //console.log("dataTmp")
        sessionStorage.setItem("CompanyProfile", JSON.stringify(dataTmp))
      if(employer === "1"){
        const obj = [
          {"name" : "Register Company", "menuId" : "002", "link" : "/#"},
        ]
        const objSign = [
          {"name" : "Signout", "link" : "/signout"},
          {"name" : "Register", "link" : "/signup"},
        ]
        window.sessionStorage.setItem('results', JSON.stringify({"menuId" : "002"}));
        //window.sessionStorage.setItem('resultsSign', JSON.stringify(objSign));
        
        
        if(pagePath){
          navigate("/")
        }
        else{
          return null
        }
      }else {
         
        const obj = [
          {"name" : "View Applicants", "menuId" : "001", "link" : "/JobDetailsDescription"},
          {"name" : "Candidate Shortless", "menuId" : "001", "link" : "/#"},
          {"name" : "Job Posting", "menuId" : "001", "link" : "/#"},
          {"name" : "Employer", "menuId" : "001", "link" : "/CreateJobPosting"},
        ] 
        const objSign = [
          {"name" : "Sign Out", "link" : "/signout"},
          {"name" : "Register", "link" : "/signup"},
        ]
        window.sessionStorage.setItem('results', JSON.stringify({"menuId" : "001"}));
        //window.sessionStorage.setItem('resultsSign', JSON.stringify(objSign));
        
        if(pagePath){
          navigate("/")
        }
        else{
          return null
        }

      } 
        
    })
    .catch(error =>{
        alert("error: " + error)
    })
  };
  

  


  
  const [applicationUpper, setUserName]=useState('');

  const [pagePath, setPagePath] = useState(true)
  const navigate = useNavigate()

  const handleSname =(event)=> {
    setValue1({ email : event.target.value})
    // console.log(event.target.value)
  };
  const handleUserId =(event)=> {
    setValue2({ userid : event.target.value})
  };
  const handlePassword =(event)=> {
    setValue3({ passcode : event.target.value})
  };

  const onFormSubmit = async() => {
    setLoading(true);
    handleGetRegister(value1, value3)
    console.log(wdata)
  };

  /* const onFormSubmit = async() => {
    //setLoading(true);
    //handleGetNOSERUSR(value1, value2, value3)
    if(applicationUpper === "COMPANY"){
      const obj = [
        {"name" : "View Applicants", "menuId" : "001", "link" : "/JobDetailsDescription"},
        {"name" : "Candidate Shortless", "menuId" : "001", "link" : "/#"},
        {"name" : "Job Posting", "menuId" : "001", "link" : "/#"},
        {"name" : "Employer", "menuId" : "001", "link" : "/CreateJobPosting"},
      ]
      const objSign = [
        {"name" : "Sign Out", "link" : "/signout"},
        {"name" : "Register", "link" : "/signup"},
      ]
      window.sessionStorage.setItem('results', JSON.stringify({"menuId" : "001"}));
      //window.sessionStorage.setItem('resultsSign', JSON.stringify(objSign));
      
      if(pagePath){
        navigate("/")
      }
      else{
        return null
      }

    }else if(applicationUpper === "ADMIN"){
      const obj = [
        {"name" : "Register Company", "menuId" : "002", "link" : "/#"},
      ]
      const objSign = [
        {"name" : "Signout", "link" : "/signout"},
        {"name" : "Register", "link" : "/signup"},
      ]
      window.sessionStorage.setItem('results', JSON.stringify({"menuId" : "002"}));
      //window.sessionStorage.setItem('resultsSign', JSON.stringify(objSign));
      
      if(pagePath){
        navigate("/")
      }
      else{
        return null
      }

    }
    // else if(applicationUpper === "CANDIDATE"){
    //   const obj = [
    //     {"name" : "", "menuId" : "002", "link" : "/jobgrid"},
    //   ]
    //   const objSign = [
    //     {"name" : "Sign out", "link" : "/signout"},
    //     {"name" : "", "link" : "/signup"},
    //   ]
    //   window.sessionStorage.setItem('results', JSON.stringify(obj));
    //   window.sessionStorage.setItem('resultsSign', JSON.stringify(objSign));
      
    //   if(pagePath){
    //     navigate("/")
    //   }
    //   else{
    //     return null
    //   }

    // }
    else{
    }
  
  }; */


  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="g-0">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              {/* <img
                                src={lightLogo}
                                alt=""
                                className="logo-light"
                              />
                              <img
                                src={darkLogo}
                                alt=""
                                className="logo-dark"
                              /> */}
                              JOBSPACE
                            </Link>
                            <div className="mt-5">
                              <img
                                src={signInImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody className="auth-content p-5 h-100 text-white"  style={{backgroundColor:"#088B1E"}}>
                            <div className="w-100">
                              <div className="text-center mb-4">
                                <h5>Welcome Back !</h5>
                                <p className="text-white-70">
                                  Sign in to continue to JobSpace.
                                </p>
                              </div>
                              <Form action="/" className="auth-form" onSubmit={handleSubmit(onFormSubmit)}>
                                <div className="mb-3">
                                  <label
                                    htmlFor="usernameInput"
                                    className="form-label"
                                  >
                                    Username
                                  </label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="usernameInput"
                                    placeholder="Enter email address"
                                    required
                                    onChange={event => setValue1(event.target.value)}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="passwordInput"
                                    className="form-label"
                                  >
                                    Password
                                  </label>
                                  <Input
                                    type="password"
                                    className="form-control"
                                    id="passwordInput"
                                    placeholder="Enter your password"
                                    onChange={event => setValue3(event.target.value)}
                                    //required
                                  />
                                </div>
                                <div className="mb-4">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexCheckDefault"
                                    />
                                    <Link
                                      to="/resetpassword"
                                      className="float-end text-white"
                                    >
                                      Forgot Password?
                                    </Link>
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      Remember me
                                    </label>
                                  </div>
                                </div>
                                <div className="text-center">
                                  <button
                                    type="submit"
                                    className="btn btn-white btn-hover w-100"
                                    //onClick={handleSignin}
                                  >
                                    Sign In
                                  </button>
                                </div>
                              </Form>
                              <div className="mt-4 text-center">
                                <p className="mb-0">
                                  Don't have an account ?{" "}
                                  <Link
                                    to="/signup"
                                    className="fw-medium text-white text-decoration-underline"
                                  >
                                    {" "}
                                    Sign Up{" "}
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
            <ScrolltoTop />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
