import React, { useRef, useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Input, Label, select, Modal, ModalBody,} from "reactstrap";
import { Form } from "react-bootstrap";

//Import Images
import { Route, Link, Routes, useLocation, useSearchParams, useNavigate   } from "react-router-dom";
import { AppConfiguration } from "read-appsettings-json";
import { GetRequest, PostRequest } from '../../noser-dataaccess'
import axios from 'axios'
import dateFormat from 'dateformat';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";

const JobDescEmployer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const currentPath = window.location.href;
  /* Redirect Pages  */
  const navigateSignIn = useNavigate();
  const navigateSignUp = useNavigate();
  const navigateCandidProf = useNavigate();
  const navigateSuccScreen = useNavigate();
  const navigateResume = useNavigate();
  const navigateJobPost = useNavigate();
  const navigateDirectApply = useNavigate();
  const navigateAppliedJob = useNavigate();
  const navigateCompOvrview = useNavigate();
  /* End Redirect Pages */

  /* Modals */
  const [applydisbled, setApplyDsbld] = useState(true);
  const [submitResume, setModalResume] = useState(false);
  const openModalResume = () => setModalResume(!submitResume);
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);
  const [modalOtp, setModalOtp] = useState(false);
  const openModalOtp = () => setModalOtp(!modalOtp);
  const [modalScreen1, setfrstModal] = useState(false);
  const openfrstModal = () => setfrstModal(!modalScreen1);
  const [modalScreen2, setScndModal] = useState(false);
  const openScndModal = () => setScndModal(!modalScreen2);
  const [modalScreen3, setThrdModal] = useState(false);
  const openThrdModal = () => setThrdModal(!modalScreen3);
  const [applybyresume, setModalApplyByResume] = useState(false);
  const openModalApplyResume = () => setModalApplyByResume(!applybyresume);
  /* End modals */

  /* Job Details */
  const [jobDetails, setJobDetails] = useState([])
  const [idSlctd, setKeySession] = useState(JSON.parse(sessionStorage.getItem("key")));
  const [toValidate, setTovalidate] = useState(JSON.parse(sessionStorage.getItem("validate")));
  const [jobDesc, setJobDiscription] = useState("")
  const [skillAndExp, setSkillExpr] = useState([])
  const [responsibility, setResponsibilities] = useState([])
  // const [qualifications, setQualifications] = useState([])
  const [companyName, setCompanyName] = useState("")
  const [title, setTitle] = useState("")
  const [companyNumber, setCompNumber] = useState("")
  const [companyEmail, setCompEmail] = useState("")
  const [companyWebsite, setCompPage] = useState("")
  const [companyLocation, setCompanyLocation] = useState("")
  const [landMark, setLocation] = useState("")
  const [jobNotes, setJobNotes] = useState("")

  /* Job Overview Details */
  const [jobType, setJobType] = useState("")
  const [category, setCategory] = useState("")
  const [jobOverviewLocation, setJobOverviewLocation] = useState("")
  const [offeredSalary, setOfferedSalary] = useState("")
  const [posted, setPosted] = useState("")
  const [experience, setExperience] = useState([])
  const [gender, setGender] = useState("0")
  const [industry, setIndustry] = useState("")
  const [_qualification, setQualifications] = useState("")
  const [qualification, setQualification] = useState([])
  const [level, setLevel] = useState("")
  const [applied, setApplied] = useState("")
  const [applicationDate, setApplicationDate] = useState("")
  const [companyId, setCompanyId] = useState("")
  const [jobPostingId, setJobPostingId] = useState("")
  const [jobDepartment, setjobDept] = useState([])
  const [employementType, setApplicantType] = useState([])
  const [educLevel, setEdLevel] = useState([])
  const [empJobType, setEmpJobType] = useState([])
  const [jobDetailsPostingId, setPostingJobId] = useState("")
  const [validate, setValidate] = useState("")
  const [numberApplcants, SetNumApplicants] = useState("") 
  const [workingDays, setWorkingDays] = useState("") 
  const [expRequired, setExpRequired] = useState("") 
  const [profileId, setprofileId] = useState("") 
  const [generateLink, setgenerateLink] = useState("") 
  
  
  // drop down job overview
  const [_jobDepartment, setJobDepartment] = useState("")
  const [_jobEmploymentType, setEmploymentType] = useState("")
  const [_jobExprnc, setJobExp] = useState("")
  const [_jobQualification, setJobQuali] = useState("")
  const [_jobEducLvl, setJobEducLvl] = useState("")
  // const [modal, setModal] = useState(false);
  // const openModal = () => setModal(!modal);
  const [checked, setChecked] = React.useState(true);
  const [filesResume, setFilePdf] = useState("") 
  const [dataPrivacy, setDataPrivacy] = useState("N")
  const [message, setMessage] = useState("")
  const [isCheckbox, setCheckbox] = useState(true) 
  const [ date , setDate] = useState("")
  const [employeeIdTmp, setEmployeeId] = useState("")
  const [_createdDate, setCreatedDate] = useState("") 
  const [_endDate, setEnddate] = useState("") 
  const [emailAdd, setEmail] = useState("")
  const [fullName, setFullName] = useState("")
  const [contactNo, setContactNo] = useState("")
  const [isCompleted, setDataRsitrct] = useState("") 

  const [employeeNameDDL, setemployeeName] = useState([])
  const [copySuccess, setCopySuccess] = useState('');
  const textRef = useRef();
  const [compIndustry, setCompIndustry] = useState("")
  const [getWorkLocation, setWorkLocation] = useState("")

  useEffect(() => {
    // OnLoadScreen()
    GetCompanyProfile()
    const isValidate = JSON.parse(sessionStorage.getItem("userCollection"))
    if (isValidate === null){
      setValidate("0")
      setEmployeeId("")
      GetTestRestrict("")
      setEmail("")
      setFullName("")
      setContactNo("")
    }
    else {
      setValidate("1")
      setEmployeeId(isValidate[0].userId)
      let fullNameTmp = isValidate[0].firstName + " " + isValidate[0].lastName
      setEmail(isValidate[0].email)
      setFullName(fullNameTmp)
      setContactNo(isValidate[0].contact)
      GetTestRestrict(isValidate[0].userId)
    }
  },[]);
  function handleParamUpdate() {
    // update query parameters
    queryParams.set("paramName", "newValue");

    // create new search string and navigate to it
    const newSearch = `?${queryParams.toString()}`;
    navigate({ search: newSearch });
  }
  
  /* Get items */
  async function OnLoadScreen(){
    setJobType("")
    setCategory("")
    setJobOverviewLocation("")
    setOfferedSalary("")
    setPosted("")
    setExperience("")
    setGender("0")
    setIndustry("")
    // setQualification("")
    setLevel("")
    setApplied("")
  }
  /* Proper Case */
  function toTitleCase(str) {
    const titleCase = str
      .toLowerCase()
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  
    return titleCase;
  }
  /* End */
  const GetEmployeeDDL = async() => {
        
    const params = {
        "IpAddress":"0.0.0.0",
        "ClientId":"",
        "UserId":"0"
    }
    await axios
    .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetJobSpaceEmployeeProfiles", params)
    .then(res => {
        const data = res.data.employees;
        console.log("GetJobSpaceEmployeeProfiles")
        console.log(data)
        setemployeeName(data)
    })
    .catch(error=>{
        // this.setState({
        //     isloading   :   false,
        //     alerttype   :   "Error!",
        //     isshow      :   true,
        //     color       :   "danger",
        //     message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
        //     fade        :   true
        // })
    })
    GetCompanyProfile()
  };
  const GetCompanyProfile = async() => {
    let filter_data = {"isDeleted": "0"}
    const dataTmp = []
    const Params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "CompanyProfile",
      "filter_data" : filter_data
    }
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {
      let data = res.data
      data.CompanyProfile.map(function(itm,i){
        const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
        const json = JSON.parse(strJson.replace(/'/g,'"'));
        dataTmp.push(json)
      })       
        
      // setJson(dataTmp)  
      console.log("dataTmp")
      console.log(dataTmp)
      GetJobDescription(dataTmp); 
    })
  };
  const GetJobDescription =async(_dataList)=> {
    try{
      let filter_data = {}
      // if(String(toValidate)==="0"){
      //   filter_data = {"postingId" : idSlctd}
      // }
      // else{
      //   filter_data = {"title" : idSlctd}
      // }
      let resp = await GetRequest({
        url : AppConfiguration.Setting().mongoJobspace + "action/get",
        params : {
          "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
          "_collection" : "JobPosting",
          "filter_data" : {}
        }
      })
      // //console.log("resp")
      // //console.log(resp)
      if(resp.status==="1"){
        setJobPostingId(idSlctd)
        let jobPostingList = []
        let companyProfList = []
        // if(String(toValidate)==="0") {
          jobPostingList = resp.JobPosting.filter(x=>x.title===toTitleCase(idSlctd))
          companyProfList = _dataList.filter(List => jobPostingList.some(itm => itm.companyId === List.userId))
        // }
        // else{
        //   jobPostingList = resp.JobPosting.filter(x=>x.title===idSlctd)
        //   companyProfList = _dataList.filter(List => jobPostingList.some(itm => itm.companyId === List.userId))
        // }
        console.log("companyProfList")
        console.log(companyProfList)

        // Job Posting
        setJobDiscription(jobPostingList[0].description)
        setTitle(jobPostingList[0].title)
        setSkillExpr(jobPostingList[0].skillsExperience)
        setResponsibilities(jobPostingList[0].responsibility)
        setQualification(jobPostingList[0].qualifications)
        setJobDetails(resp.JobPosting.filter(x=>x.id===idSlctd))
        setjobDept(jobPostingList[0].jobDepartment)
        setApplicantType(jobPostingList[0].applicantType)
        setEdLevel(jobPostingList[0].educationalLevel)
        let _salaryTmp = toTitleCase(jobPostingList[0].salaryCurrency) + " " + jobPostingList[0].salaryMin + " " + "-" + " " + toTitleCase(jobPostingList[0].salaryCurrency)  + " " + jobPostingList[0].salaryMax
        // let _salaryTmp = jobPostingList[0].salaryMin + " " + "-" + " " + jobPostingList[0].salaryMax
        setOfferedSalary(_salaryTmp)
        setEmpJobType(jobPostingList[0].jobTime)
        setJobNotes(jobPostingList[0].jobPostingNote)
        setWorkingDays(jobPostingList[0].workingDaysAndHrs)
        // setIndustry(jobPostingList[0].keyWords)
        setCreatedDate(jobPostingList[0].createdDate)
        setEnddate(jobPostingList[0].endDate)

        // let x1 = jobPostingList[0].jobDepartment
        // let x2 = jobPostingList[0].jobTime
        // let x3 = jobPostingList[0].applicantType
        // let x4 = jobPostingList[0].qualifications
        // let x5 = jobPostingList[0].educationalLevel

        setJobDepartment(jobPostingList[0].dept)
        setEmploymentType(jobPostingList[0].workType)
        setJobExp(jobPostingList[0].aplicantExp)
        setExpRequired(jobPostingList[0].experienceMin)
        setJobEducLvl(jobPostingList[0].education)
        setPostingJobId(jobPostingList[0].postingId)

        let applicationDate = dateFormat(jobPostingList[0].endDate).split(" ")
        // let s1 = applicationDate[0]
        let s2 = applicationDate[1]
        let s3 = applicationDate[2]
        let s4 = applicationDate[3]
        let joinDate = s2 + " " + s3 + ", " + s4
        let spliAppEnd = joinDate
        setApplicationDate(spliAppEnd)

        // company  profile
          let compTmp = (companyProfList[0].companyName).toLowerCase()
          console.log("compTmp")
          console.log(compTmp)
          let toCaseComp = toTitleCase(compTmp)
          setCompanyName(toCaseComp)
          setCompanyId(companyProfList[0].id)
          setCompNumber(companyProfList[0].contactNumberPhone)
          let emailTmp = (companyProfList[0].email).toLowerCase()
          let toCaseEmail = toTitleCase(emailTmp)
          setCompEmail(toCaseEmail)
          let webTmp = (companyProfList[0].website).toLowerCase()
          let toCaseWeb = toTitleCase(webTmp)
          setCompPage(toCaseWeb)
          let _location = /* companyProfList[0].baranggay + " " +  */companyProfList[0].city + " " + companyProfList[0].country //+ " " + companyProfList[0].locationStreet + " " + companyProfList[0].locationCity + " " + companyProfList[0].locationProvince + " " + companyProfList[0].locationZipCode
          let lowerLoc = (_location).toLowerCase()
          let toCaseLoc = toTitleCase(lowerLoc)
          setCompanyLocation(toCaseLoc)
          let landMark = (companyProfList[0].city).toLowerCase() //+ "," + " " + fltrData[0].locationProvince
          // let landMarkTmp = companyProfList[0].email
          let toCaseLandmark = toTitleCase(landMark)
          setLocation(toCaseLandmark)
          GetNumOfApplicants(jobPostingList[0].title)
          setWorkLocation(jobPostingList[0].workLocation)
          setCompIndustry(jobPostingList[0].industry)
      }
    }
    catch(error){
      // setMessage(error.message)
      // alert("error: " + error.message)
    }
  };
  const GetNumOfApplicants = async(postingId) => {
    let filter_data = {"title" : postingId}
    const _dataTmp = []
    const Params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "Candidates",
      "filter_data" : filter_data
    }
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {
      let data = res.data
      data.Candidates.map(function(itm,i){
        const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
        const json = JSON.parse(strJson.replace(/'/g,'"'));
        _dataTmp.push(json)
      })
      console.log("String(parseFloat(data.Candidates.length) + 1)")
      console.log(_dataTmp)
      SetNumApplicants(String(parseFloat(data.Candidates.length))); 
    })
  };

  function toTitleCase(str) {
    const titleCase = str
      .toLowerCase()
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  
    return titleCase;
  }
  
  const GetTestRestrict = async(empId) => {
    // // //console.log("userCol")
    // // //console.log(typeof userCol === "undefined")
    let fltrUser = ""
    // let _fltrUser = userCol.length===0 || userCol === null ? "" : userCol
    if(typeof empId === "undefined"){
      // //console.log("if")
      fltrUser = ""
    }
    else{
      // //console.log("else")
      fltrUser = empId
    }
    // //console.log("empId")
    // //console.log(empId)
    let filter_data = {"isDeleted": "0", "userId" : fltrUser}
    const dataTmp = []
    const Params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "CandidateRegisteredCheckList",
      "filter_data" : filter_data
    }
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {
      let data = res.data
      if(data.CandidateRegisteredCheckList.length === 0){
        setDataRsitrct("0"); 
      }
      else{
        // let getUserId = JSON.parse(sessionStorage.getItem("userCollection"))
        data.CandidateRegisteredCheckList.map(function(itm,i){
          const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
          const json = JSON.parse(strJson.replace(/'/g,'"'));
          dataTmp.push(json)
        })       
          
        // setJson(dataTmp)  
        setDataRsitrct(dataTmp[0].basicInfoCandidate); 
        console.log("dataTmp[0].basicInfoCandidate")
        console.log(dataTmp)
      }
    })
    GetEmployeeDDL()
  };

  /* End Get Items */

  /* Function Items */
  const submitClick =()=>{

    const dataList ={
      department : _jobDepartment,
      offeredSalary : offeredSalary,
      location : landMark,
      employementType : _jobEmploymentType,
      experience : _jobExprnc,
      industry : "",
      qualification : _jobQualification,
      level : "",
      educationLevel : _jobEducLvl,
      NoOfApplcnts : "",
      companyId : companyId,
      jobPostingId : idSlctd,
      status : "NW",
      
      isDeleted : "0",
      isModified : "0",
      modifiedBy : "romel",
      modifiedDate : "04/30/2024",
      createdBy : "romel",
      createdDate : "04/30/2024",
    }
    // console.log("dataList")
    // console.log(dataList)
    
    const params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "JobOverview",
      "doc_data" : dataList
    }

    axios
    .post(AppConfiguration.Setting().mongoJobspace + "action/add", params )
    .then(resp =>{
      let data = resp.data
      if(resp.data==="success"){
        alert("Success")
        OnLoadScreen()
      }
      else{
        alert("error")
      }
    })
    .catch(error =>{
      alert("error: " + error)
    })
  }

  /* End Function Items */

  /* On Change Items */

  const onChangeCategory =(e)=>{
    setCategory(e.target.value)
  }
  const onChangeJobOverViewLocation =(e)=>{
    setJobOverviewLocation(e.target.value)
  }
  const onChangeExperience =(e)=>{
    setExperience(e.target.value)
  }
  const onChangeIndustry =(e)=>{
    setIndustry(e.target.value)
  }
  const onChangeLevel =(e)=>{
    setLevel(e.target.value)
  }
  const onChangeApplied =(e)=>{
    setApplied(e.target.value)
  }
  const handleChangeSwitch =(e)=>{
    // setChecked(true);
    // // console.log(e)
    sessionStorage.setItem("companyId", JSON.stringify(companyId))
      sessionStorage.setItem("key", JSON.stringify(jobPostingId))
      // // console.log(jobPostingId)
      navigate("/ApplicationPage");
  }
  const handleClickSwitch =()=>{
    alert("clicked")
  }
  function handleNavCompany (){
    // alert("test")
    navigateCompOvrview("/JobDescEmployer");
  }

   /* End On Change Items */

   /* Modal Functions */
   const handleOpenModal =(e)=>{
    setfrstModal(false)
    setScndModal(true)
  }
  const handleOpenModal2 =(e)=>{
    setScndModal(false)
    setThrdModal(true)
  }
  const handleOpenResume =(e)=>{
    // setScndModal(false)
    setThrdModal(false)
    // setModalResume(true)
    navigateDirectApply("/ApplyCompProf")
  }
  const handleDirectbyResume =()=>{
    // setfrstModal(false)
    // setCheckbox(true)
    // setDataPrivacy("N")
    // setFilePdf("")
    // setModalApplyByResume(true)
    navigateResume("/ApplyResume")
  }
   /*  End Modal Functions */

  const handleNavigateProfile = () =>{
    navigateCandidProf("/ApplicationFormCreate");
  }
  const NavigateJobPost = () =>{
    navigateJobPost("/JobDetailsDescription");
    setThrdModal(false)
  }
  const UploadResume=(e)=>{
    // console.log(e.target.files[0])
    if(typeof e.target.files[0] === "undefined"){
      setFilePdf("")
    }
    else {
      setFilePdf(e.target.files[0].name)
    }
  }
  const onChangeCheckboxResume=(e)=>{
    // console.log(e.target.checked)
    if(e.target.checked===true){
      // alert("checked")
      setDataPrivacy("Y")
      if(message==="") {
        setCheckbox(true)
      }
      else {
        setCheckbox(false)
      }
      // setOpenModalPrivcy(true)
      // openModal(openModal)
    }
    else{
      // alert("not checked")
      setDataPrivacy("N")
      setCheckbox(true)
    }
  } 
  const handleApplySignedIn = () =>{
    const dateTmp = moment(date).format('LLLL').split(", ")
    const s1 = dateTmp[1]
    const s2 = dateTmp[2]
    const combDate = s1 + " " + s2
    // // console.log("combDate")
    // // console.log(combDate)

    const data = {
      email : emailAdd,
      name : fullName,
      contact : contactNo,
      message : message,
      jobDepartment : _jobDepartment,
      employementType : _jobEmploymentType,
      experience : _jobExprnc,
      qualification : _jobQualification,
      educLevel : _jobEducLvl,
      expectedSalary : offeredSalary,
      city : landMark,
      industry : "",
      level : "",
      isRegister : "1",
      resume : filesResume,
      dataPrivacy : dataPrivacy,
      postingId : jobDetailsPostingId,
      title : title,
      companyName : companyName,
      employeeId : employeeIdTmp,
      status : "NW",
      createdDate : _createdDate,
      endDate : _endDate,
      jobDocNo : numberApplcants,
      dateApplied : combDate,

      createdDate : moment(date).format("MM/DD/YYYY"),
      createdBy : employeeIdTmp,
      isModified : "0",
      modifiedDate : moment(date).format("MM/DD/YYYY"),
      modifiedby : employeeIdTmp,
      isDeleted : "0",
    }
    // console.log("data")
    // console.log(data)
    
    const params = {
      "_collection" : "Candidates",
      "doc_data" : data
    }

    axios
    .post(AppConfiguration.Setting().mongoJobspace + "action/add", params )
    .then(resp =>{
      // let data = resp.data
      if(resp.data==="success"){
        sessionStorage.setItem("successdata", JSON.stringify(data))
        navigateSuccScreen("/SuccessScreen")
      }
      else{
        alert("error")
      }
    })
    .catch(error =>{
      alert("error: " + error)
    })
  }
  function handleNavigateApplicants (){
    // // // console.log("key")
    // // // console.log(key)
    sessionStorage.removeItem("JobPostingList")
    sessionStorage.removeItem("validate")
    let key = {
      postingId : jobDetailsPostingId,
      title : title
    }
    sessionStorage.setItem("JobPostingList", JSON.stringify(key))
    sessionStorage.setItem("validate", JSON.stringify(1))
    // navigateJobDetails("/JobDescEmployer")
  }

  const handleChangeEmployee = (e) => {
    console.log("e")
    console.log(e)
    
    if(e.length == 0) {
      setprofileId("")
      setgenerateLink("")
      return
    }
    else{
      queryParams.set("postingId", jobDetailsPostingId);
      //const newSearch = `?${queryParams.toString()}&profileid=${e[0].profileId}`;
      const url = `?postingId=${jobDetailsPostingId}&profileId=${e[0].profileId}&employeeId=${e[0].employeeId}`;
      navigate({ search: url });
      setgenerateLink("http://167.71.204.65:3000/JobDetailsDescription" + url)
    }
  }

  const copyToClipboard = (e) => {
    let copyText = textRef.current.value;
    let isCopy = copy(copyText);
    console.log("isCopy")
    console.log(isCopy)
    if (isCopy === true) {
      toast.success("Copied!");
    }
  };
  return (
    <React.Fragment>
      <Card className="job-detail overflow-hidden mt-1">
        <CardBody className="p-4" style={{ marginTop : "4%"}}>
          <Row>
            <Col sm={5} style={{ marginLeft: "6%"}}>
              <div>
                <Row>
                  <Col md={8}>
                    {/* <h4 className="mt-3" style={{color:"#376837"}}>{title}</h4>
                    <h5 className="mb-1" onClick={handleNavCompany}>{companyName}</h5> */}
                    <Label className="mt-2" style={{color:"#0A6219", fontSize: "20px"}}><b>{title}</b></Label><br />
                    <Label className="mb-1 " onClick={handleNavCompany} style={{ fontSize: "18px", marginTop: "-1%", color: "#00000"}}>{companyName}</Label>
                  </Col>
                </Row>
                <Row>
                  <div className="mt-3 mt-lg-0">
                    <li className="list-inline-item">
                      <p className="mb-0" style={{fontSize: "14px", color: "#00000"}}>
                        <i className="mdi mdi-map-marker"></i>{" "}
                        {landMark}
                      </p>
                    </li>
                  </div>
                </Row>
                <Row>
                  <div className="mt-3 mt-lg-0">
                    <li className="list-inline-item">
                      <p className="mb-0" style={{fontSize: "14px", color: "#00000"}}>
                        <i className="mdi mdi-clock"></i>{" "}
                          {workingDays}
                      </p>
                    </li>
                  </div>
                </Row>
              </div>

              <div className="mt-4">
                <Row className="g-2">
                  <p style={{color:"#376837", fontSize: "14px"}}>
                    <b>{jobNotes}</b>
                  </p>
                </Row>
              </div>

              <div className="mt-4">
                <Label className="mb-3" style={{fontSize: "14px", fontWeight: "bold", }}>Job Description:</Label> 
                <div className="job-detail-desc" style={{marginLeft: "1%"}} >
                  <p className="mb-0" style={{fontSize: "14px", textAlign:"justify", color: "#00000"}}>{jobDesc}</p>
                </div>
              </div>

              <div className="mt-4">
                <Label className="mb-3" style={{fontSize: "14px", fontWeight: "bold"}}>Job Responsibilities:</Label> 
                <div style={{marginLeft: "1%", fontSize : "14px"}} >
                  {responsibility.map((itm, key)=> 
                    <div className="job-detail-desc mt-2" /* key={d.id } */ style={{textAlign:"justify"}}>
                      <ul className="job-detail-list list-unstyled mb-0">
                        <Row  style={{marginTop: "-2%"}}>
                          <Col sm={1}>
                            <i className="mdi mdi-circle-small" style={{color: "#000000"}}></i>
                          </Col>
                          <Col sm={11}>
                            <Label style={{marginLeft: "-5%", color: "#00000"}}>
                              {itm.responsibility}
                            </Label>
                          </Col>
                        </Row>
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-4">
                <Label className="mb-3" style={{fontSize: "14px", fontWeight: "bolder"}}>Skills Required:</Label> 
                <div style={{marginLeft: "1%", fontSize : "14px"}} >
                  {skillAndExp.map((itm, key)=> 
                    <div className="job-detail-desc mt-2" /* key={d.id } */ style={{textAlign:"justify"}}>
                      <ul className="job-detail-list list-unstyled mb-0">
                        <Row  style={{marginTop: "-2%"}}>
                          <Col sm={1}>
                            <i className="mdi mdi-circle-small" style={{color: "#000000"}}></i>
                          </Col>
                          <Col sm={11}>
                            <Label style={{marginLeft: "-5%", color: "#00000"}}>
                              {itm.skill}
                            </Label>
                          </Col>
                        </Row>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col sm={3}>
              <Card className="mt-5" style={{ borderRadius: 5, borderColor: "#a4a4a4"}}>
                <CardBody>
                  <Label className="modal-title" id="staticBackdropLabel" style={{fontSize: "14"}}>
                    <b>Job Overview</b>
                  </Label>
                  <Row className="mt-2">
                    <Label column sm="5" style={{color : "#00000"}}>
                      Employment Type:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Employment Type"
                        style={{borderRadius: 0 ,fontSize: "14px", color: "#000000", borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={_jobEmploymentType}
                        // onChange={onChangeOfferedSalary}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Label column sm="5" style={{color : "#00000"}}>
                      Industry:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Industry"
                        style={{borderRadius: 0 ,fontSize: "14px", color: "#000000", borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={compIndustry}
                        onChange={onChangeExperience}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Label column sm="5" style={{color : "#00000"}}>
                    Location:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Location"
                        style={{borderRadius: 0 ,fontSize: "14px", color: "#000000", borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={getWorkLocation}
                        // onChange={onChangeJobOverViewLocation}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Label column sm="5" style={{color : "#00000"}}>
                      Offered Salary:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Offered Salary"
                        style={{ borderRadius: 0 ,fontSize: "14px", color: "#000000", borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={offeredSalary}
                        // onChange={onChangeCategory}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Label column sm="5" style={{color : "#00000"}}>
                      Department:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Department"
                        style={{ borderRadius: 0 ,fontSize: "14px", color: "#000000", borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={_jobDepartment}
                        // disabled={true}
                        // onChange={onChangeJobType}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Label column sm="5" style={{color : "#00000"}}>
                      Experience:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Experience"
                        style={{borderRadius: 0 ,fontSize: "14px", color: "#000000", borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={expRequired}
                        // onChange={onChangeExperience}
                      />
                    </Col>
                  </Row>
                  <Label style={{marginTop: "15%", fontSize: "14px"}}>
                    Application ends: <b>{applicationDate}</b>
                  </Label>
                  <br />
                  <Label>
                    <b>{jobDetailsPostingId}</b>
                  </Label>
                  <Row>
                    <Label style={{fontSize: "14px"}}>
                      Applicants : &nbsp; <Link style={{color: "#0A6219"}}  to="/ListOfCandidate" onClick={() =>handleNavigateApplicants()}><b >{numberApplcants}</b></Link>
                    </Label>
                  </Row>
                  <Col>
                    {/* <Row className="mt-3">
                      <Col>
                      {validate==="1" ? 
                        <button type="submit" className="btn btn-success" to="datapolicy" onClick={openfrstModal} style={{width : "80%", marginLeft: "15%"}}>
                          Apply Now
                        </button>
                      :
                        <button type="submit" className="btn btn-success" to="datapolicy" onClick={handleChangeSwitch} style={{width : "80%", marginLeft: "15%"}}>
                          Apply Now
                        </button>
                      }
                      </Col>
                      <Col>
                        <button type="submit" className="btn" disabled={validate==="0"? true : false} onClick={submitClick} style={{width : "80%", backgroundColor: "#E0FDDB"}}>
                          Save
                        </button>
                      </Col>
                    </Row> */}
                  </Col>
                </CardBody>
              </Card>
            </Col>
            <Col sm={3}>
              <Card className="mt-5" style={{ borderRadius: 5, borderColor: "#a4a4a4", width: "80%"}}>
                <CardBody>
                  <Row className="mt-2">
                    <h5 className="modal-title" id="staticBackdropLabel">
                      <Col lg={3}>
                      </Col>
                      <Col lg={9}>
                        <h5 className="mb-1">
                          {companyName}
                        </h5>
                      </Col>
                    </h5>
                  </Row>
                  <Row className="mt-2">
                    <Label className="mdi mdi-phone">&nbsp;&nbsp;&nbsp;{companyNumber}</Label>
                  </Row>
                  <Row className="mt-2">
                    <Label className="mdi mdi-mail">&nbsp;&nbsp;&nbsp;{companyEmail}</Label>
                  </Row>
                  <Row className="mt-2">
                    <Label className="uil uil-globe">&nbsp;&nbsp;&nbsp;{companyWebsite}</Label>
                  </Row>
                  <Row className="mt-2">
                    <Label className="mdi mdi-map-marker">&nbsp;&nbsp;&nbsp;{companyLocation}</Label>
                  </Row>
                  <div className="mt-3">
                    <Link
                      onClick={openModal}
                      data-bs-toggle="modal"
                      style={{color : "green"}}
                    >
                      Share Link
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={1}></Col>
          </Row>
        </CardBody>
      </Card>

      <div
        className="modal fade"
        id="hireNow"
        tabIndex="-1"
        aria-labelledby="hireNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modal} toggle={openModal} centered>
            <ModalBody className="p-5">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  GENERATE LINK
                </h5>
                <hr/>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Col sm="12">
              <Typeahead       
                                      
                  labelKey='employeeName'
                  id="basic-examplex"
                  onChange={handleChangeEmployee}
                  options={employeeNameDDL}
                  placeholder="Select employee"
              />
              </Col>
            </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                <Col sm="12">
                  <Form.Control type="text" disabled readOnly value={generateLink} ref={textRef}/>
                </Col>
              </Form.Group>
              <button type="submit" className="btn btn-primary w-100"  onClick={copyToClipboard}>
                Copy Link
              </button>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JobDescEmployer;
