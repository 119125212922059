import React, { useState,useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Modal,
  ModalBody,
  //Form,
  FormGroup,
  Label,
  Spinner,
  /* Alert, */
  Button
} from "reactstrap";
import {  ButtonToolbar} from "react-bootstrap";


import axios from 'axios';
import { AppConfiguration } from "read-appsettings-json";
import moment from 'moment';
import useForm from "./useForm";
import InputGroup from 'react-bootstrap/InputGroup';

import {  Card,   CardBody } from "reactstrap";

import lightLogo from "../../assets/images/logo-light.png";
import darkLogo from "../../assets/images/logo-dark.png";

import signUpImage from "../../assets/images/auth/sign-up.png";
//import useForm from "../../wcomponents/SignUp/useForm";
import Icon from "../../wcomponents/Icon/Icon";
import "../../wcomponents/w.css"
import NavBar from "../../Layout/CommonLayout/NavBar"//"../CommonLayout/NavBar";
import ScrolltoTop from "../../components/ScrolltoTop";
import TopBar from "../../Layout/CommonLayout/TopBar";
import { setSelection } from "@testing-library/user-event/dist/cjs/event/selection/setSelection.js";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { FormControl } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import { Audio,ThreeDots } from 'react-loader-spinner'
import  "../../wcomponents/w.css"

//import Button from 'react-bootstrap/Button';



const CompanyRegisterEdit = ({submitForm }) => {
    const [userdata, setUserdata] = useState(JSON.parse(sessionStorage.getItem("userCollection")));
    const navigate = useNavigate();
    const [pagePath, setPagePath] = useState(true)
    const [show, setShow] = useState(false);
    const [dissableBtn, setDissableBtn] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const steps = ["Company Profile", "Upload Documents"];
    const [currentStep, setCurrentStep] = useState(1);
    const [complete, setComplete] = useState(false);
    const [CRdata, setCRdata] = useState();
    const [show1, setShow1] = useState(false);
    const [companyName, setcompanyName]=useState("")
    const [contactNumberPhone, setcontactNumberPhone]=useState("")
    const [email,setemail ] = useState("")
    const [website, setwebsite ] = useState("")
    const [buildingName, setbuildingName ] = useState("")
    const [secRegNo, setsecRegNo ] = useState("")
    const [city, setcity ] = useState("")
    const [birNo, setbirNo ] = useState("")
    const [stateProvinf, setstateProvinf ] = useState("")
    const [sssRegNo, setsssRegNo ] = useState("")
    const [streetName, setstreetName ] = useState("")
    const [philHealth, setphilHealth ] = useState("")
    const [baranggay, setbaranggay ] = useState("")
    const [hdmfNo, sethdmfNo ] = useState("")
    const [country, setcountry ] = useState("")
    const [zipCode, setzipCode ] = useState("")
    const [fullnamePresident, setfullnamePresident ] = useState("")
    const [contactPresident, setcontactPresident ] = useState("")
    const [emailPresident, setemailPresident ] = useState("")
    const [identificationType, setidentificationType ] = useState("")
    const [tinNo, settinNo ] = useState("")
    const [identificationNo, setidentificationNo ] = useState("")
    const [screen1, setScreen1]= useState(true)
    const [screen2, setScreen2]= useState(false)
    const [loading, setLoading] = useState(false);
    const [headerId, setheaderId] = useState("")
    const [employerId, setemployerId] = useState("")
    const [createddate, setcreateddate] = useState("")
    const [timestamp, settimestamp] = useState("")

    const [passState, setPassState] = useState(false);
    const [passState1, setPassState1] = useState(false);
    /* const { handleChange, handleSubmit, values, errors,loading,errorVal,isChecked,isEmployer,dissableBtn,handleCheckBox,handleEmployer } = useForm(
      submitForm,
      validate
    ); */

    const [values, setValues] = useState({
        companyName             : "",
        contactNumberPhone      :"",
        email                   :"",
        website                 :"",
        buildingName            :"",
        secRegNo                :"",
        city                    :"",
        birNo                   :"",
        stateProvinf            :"",
        sssRegNo                :"",
        streetName              :"",
        philHealth              :"",
        baranggay               :"",
        hdmfNo              :"",
        country                 :"",
        zipCode                 :"",
        fullnamePresident       :"",
        contactPresident        :"",
        emailPresident          :"",
        identificationType      :"",
        tinNo                   :"",
        identificationNo        :"",
    });

    useEffect(
        () => {
            setShow1(true)
            setLoading(true)
          //localStorage.clear();
          //sessionStorage.clear();
            //setTimeout(()=>{},100000)
            //handleData();
           // forLoop();
            //forLoop2();
            handleGetCompanyRegister()
        },
        []
    );

    const handleGetCompanyRegister = async() => {
       
        let filter_data = {
          "isDeleted" : "0", 
          "userId" : userdata[0].userId
        }
        
        const Params = {
          "_collection" : "CompanyProfile",
          "filter_data" : filter_data
        }
        console.log("Params")
        console.log(Params)
        await axios
        .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {
    
          let dataTmp = []
    
            let dataa = res.data
            console.log(res)
            console.log("data")
            console.log(dataa)
            let newstr2=""
            let json
            dataa.CompanyProfile.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let strJson2 = itm.substring(18, itm.length)
                newstr2 = strJson2.split("')")[0]
                 json = JSON.parse(strJson.replace(/'/g,'"'));
                json.headerId = newstr2
                console.log(json)
                dataTmp.push(json)
                //CRdata.push(json)
                //setCRdata(json)
            })    
            console.log("dataTmp")
            console.log(dataTmp)
            setValues({...values, 
                companyName             :dataTmp[0].companyName,
                contactNumberPhone      :dataTmp[0].contactNumberPhone,
                email                   :dataTmp[0].email,
                website                 :dataTmp[0].website,
                buildingName            :dataTmp[0].buildingName,
                secRegNo                :dataTmp[0].secRegNo,
                city                    :dataTmp[0].city,
                birNo                   :dataTmp[0].birNo,
                stateProvinf            :dataTmp[0].stateProvinf,
                sssRegNo                :dataTmp[0].sssRegNo,
                streetName              :dataTmp[0].streetName,
                philHealth              :dataTmp[0].philHealth,
                baranggay               :dataTmp[0].baranggay,
                hdmfNo                  :dataTmp[0].hdmfNo,
                country                 :dataTmp[0].country,
                zipCode                 :dataTmp[0].zipCode,
                fullnamePresident       :dataTmp[0].fullnamePresident,
                contactPresident        :dataTmp[0].contactPresident,
                emailPresident          :dataTmp[0].emailPresident,
                identificationType      :dataTmp[0].identificationType,
                tinNo                   :dataTmp[0].tinNo,
                identificationNo        :dataTmp[0].identificationNo,
            })
            /* setcompanyName(dataTmp[0].companyName)
            setcontactNumberPhone(dataTmp[0].contactNumberPhone)
            setemail(dataTmp[0].email)
            setwebsite(dataTmp[0].website)
            setbuildingName(dataTmp[0].website)
            setsecRegNo(dataTmp[0].secRegNo)
            setcity(dataTmp[0].city)
            setbirNo(dataTmp[0].birNo)
            setstateProvinf(dataTmp[0].stateProvinf)
            setsssRegNo(dataTmp[0].sssRegNo)
            setstreetName(dataTmp[0].streetName)
            setphilHealth(dataTmp[0].philHealth)
            setbaranggay(dataTmp[0].baranggay)
            sethdmfNo(dataTmp[0].hdmfNo)
            setcountry(dataTmp[0].country)
            setzipCode(dataTmp[0].zipCode)
            setfullnamePresident(dataTmp[0].fullnamePresident)
            setcontactPresident(dataTmp[0].contactPresident)
            setemailPresident(dataTmp[0].emailPresident)
            setidentificationType(dataTmp[0].identificationType)
            settinNo(dataTmp[0].tinNo)
            setidentificationNo(dataTmp[0].identificationNo) */
            setheaderId(dataTmp[0].headerId)
            setemployerId(dataTmp[0].employerId)
            setcreateddate(dataTmp[0].createddate)
            settimestamp(dataTmp[0].timestamp)
            //setCRdata(dataTmp)

            setShow1(false)
            setLoading(false)
        })
        .catch(error =>{
            alert("error: " + error)
            setLoading(true)
        })
    };

    

    const handleUpdate=(event)=>{
        event.preventDefault();
       setLoading(true)
       let d = new Date();
        let createddate1 = moment(d).format('MM/DD/YYYY');
        let w = d.toLocaleTimeString()
        let today = new Date()
        let clientId = moment(today).format('MMYYYYhhmmss');

        let dataList = {
            "employerId"            : employerId,
            "userId"                : userdata[0].userId,
            "companyName"           : values.companyName,
            "contactNumberPhone"    : values.contactNumberPhone,
            "email"                 : values.email, 
            "website"               : values.website,
            "buildingName"          : values.buildingName,
            "secRegNo"              : values.secRegNo,
            "city"                  : values.city,
            "birNo"                 : values.birNo,
            "stateProvinf"          : values.stateProvinf, 
            "sssRegNo"              : values.sssRegNo, 
            "streetName"            : values.streetName,
            "philHealth"            : values.philHealth,
            "baranggay"             : values.baranggay,
            "hdmfNo"                : values.hdmfNo, 
            "country"               : values.country,
            "zipCode"               : values.zipCode,
            "fullnamePresident"     : values.fullnamePresident,
            "contactPresident"      : values.contactPresident,
            "emailPresident"        : values.emailPresident, 
            "identificationType"    : values.identificationType, 
            "tinNo"                 : values.tinNo, 
            "identificationNo"      : values.identificationNo, 

            "createddate"   : createddate,
            "modifieddate"  : createddate1,
            "timestamp"     : timestamp,
            "isModified"    : w,
            "isModifiedDate"    : createddate1,
            "isDeleted"     : "0",
            "isDraft"       : "0",
            "roleId"          : "",
        }
        console.log(dataList)

        const updateParams = {

            "_collection" : "CompanyProfile",
            "doc_data"    : dataList,
            "_id"         :headerId,
  
        }

        console.log(" toUpdate")
        console.log(updateParams)
    
        axios
        .post("http://206.189.95.201:8098/action/update_one", updateParams)
        .then(res => {
            let data = res.data
            console.log(data)
            if(res.status === 200){
                setLoading(false)
                navigate("/CompanyRegisterView")
            }
        
        
        }).catch(error =>{
            alert("error: " + error)
        })
    };
    const handleBack=(e)=>{
        //navigate("/CampanyRegisterView")
        navigate("/CompanyRegisterView")
    }

    

    const handleSubmitFILES=(event)=>{
        event.preventDefault();
        console.log("submit file")
    };

    const handleChange = e => {
        console.log(e.target.value)
        const { name, value } = e.target;
        setValues({
        ...values,
        [name]: value
        });
        
        console.log(values)
        let a = []
        
        a.push(values)
        if(e.target.value.length  > 0 ){
            a.forEach(element => {
                if(element.contactNumberPhone !== "" && element.email !== "" && element.website !== "" && element.buildingName !== "" && element.secRegNo !== "" &&
                    element.streetName !== "" && element.philHealth !== "" && element.baranggay !== "" && element.hdmfNo !== "" && element.country !== "" &&
                    element.zipCode !== "" && element.fullnamePresident !== "" && element.contactPresident !== "" && element.emailPresident !== "" && element.identificationType !== "" &&
                    element.tinNo !== "" && element.identificationNo !== "" 
                ){
                    setDissableBtn(false)
                }/* else if(e.target.value.length == 0){
                    
                    setDissableBtn(true)
                } */
            });
        }else{
            setDissableBtn(true)
        }
        
    };
    const handleCheckBox =(event)=>{
        console.log(event.target.checked)
        if(event.target.checked === true){
            setIsChecked(true)
            setDissableBtn(false)
            //setAgree("YES")
            //console.log("yes")
          }else{
            setIsChecked(false)
            setDissableBtn(true)
            //setAgree("NO")
            //console.log("no")
          }
    }

    document.title = "Sign Up | JobSpace - Job Listing Template | Themesdesign";

    
    return (
        <React.Fragment  >
            {/*  <TopBar /> */}
            <NavBar />
            <div > 
            
                    <Container fluid='true' >
                    
                    <Row className="mt-3"></Row>
                    <Row className="mt-3"></Row>
                    
                    
                    <Row className="justify-content-center">
                    
                    
                        <Col className="headerMTcr" sm={6}>
                            <div className="CRcard-view ">
                                 <Form /* action="/" */ className="auth-form" >
                                    <Row>
                                        <h4 className=" ccpLabel mt-4 mb-2"> Company Profile Details (EDIT)</h4>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                size="sm"
                                                type="text"
                                                name="companyName"
                                                className="form-control-lg form-control"
                                                placeholder="Enter your company name"
                                                //autoComplete="off"
                                                value={values.companyName} 
                                                defaultValue={companyName}
                                                onChange={handleChange}
                                                
                                                //
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="contactNumberPhone"
                                                    className="form-control-lg form-control"
                                                    placeholder="Enter your contact number (PHONE)"
                                                    autoComplete="off"
                                                    value={values.contactNumberPhone} 
                                                    defaultValue={contactNumberPhone} 
                                                    onChange={handleChange}
                                                    
                                                    
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="email"
                                                    className="form-control-lg form-control"
                                                    placeholder="Enter your E-mail address"
                                                    autoComplete="off"
                                                    defaultValue={email}
                                                    onChange={handleChange}
                                                    value={values.email} 
                                                    
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="website"
                                                    className="form-control-lg form-control"
                                                    placeholder="Enter your website"
                                                    autoComplete="off"
                                                    defaultValue={website} 
                                                    onChange={handleChange}
                                                    value={values.website} 
                                                    
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm={1}></Col>
                                        <Col >
                                            <Form.Label className="companyAddressLabel">Company Address</Form.Label>
                                        </Col>
                                        <Col sm={1}></Col>
                                    </Row>
                                    <Row>
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="buildingName"
                                                    className="form-control-lg form-control"
                                                    placeholder="Enter your building Name/Floor"
                                                    autoComplete="off"
                                                    defaultValue={buildingName} 
                                                    onChange={handleChange}
                                                    value={values.buildingName} 
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="secRegNo"
                                                    className="form-control-lg form-control"
                                                    placeholder="Enter your SEC Registration No. "
                                                    autoComplete="off"
                                                    defaultValue={secRegNo} 
                                                    onChange={handleChange}
                                                    value={values.secRegNo}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="city"
                                                    className="form-control-lg form-control"
                                                    placeholder="Enter your City"
                                                    autoComplete="off"
                                                    defaultValue={city} 
                                                    onChange={handleChange}
                                                    value={values.city}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="birNo"
                                                    className="form-control-lg form-control"
                                                    placeholder="Enter your BIR Registration No. "
                                                    autoComplete="off"
                                                    defaultValue={birNo} 
                                                    onChange={handleChange}
                                                    value={values.birNo}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="stateProvinf"
                                                    className="form-control-lg form-control"
                                                    placeholder="Enter your State/Province/Region"
                                                    autoComplete="off"
                                                    defaultValue={stateProvinf} 
                                                    onChange={handleChange}
                                                    value={values.stateProvinf}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="sssRegNo"
                                                    className="form-control-lg form-control"
                                                    placeholder="Enter your SSS Registration No. "
                                                    autoComplete="off"
                                                    defaultValue={sssRegNo} 
                                                    onChange={handleChange}
                                                    value={values.sssRegNo}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="streetName"
                                                    className="form-control-lg form-control"
                                                    placeholder="Enter your Street Name"
                                                    autoComplete="off"
                                                    defaultValue={streetName} 
                                                    onChange={handleChange}
                                                    value={values.streetName}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="philHealth"
                                                    className="form-control-lg form-control"
                                                    placeholder="Enter your Phil Health No. "
                                                    autoComplete="off"
                                                    defaultValue={philHealth} 
                                                    onChange={handleChange}
                                                    value={values.philHealth}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="baranggay"
                                                    className="form-control-lg form-control"
                                                    placeholder="Enter your Barangay"
                                                    autoComplete="off"
                                                    defaultValue={baranggay} 
                                                    onChange={handleChange}
                                                    value={values.baranggay}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="hdmfNo"
                                                    className="form-control-lg form-control"
                                                    placeholder="Enter your HDMF No. "
                                                    autoComplete="off"
                                                    defaultValue={hdmfNo} 
                                                    onChange={handleChange}
                                                    value={values.hdmfNo}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="country"
                                                    className="form-control-lg form-control"
                                                    placeholder="Enter your Country"
                                                    autoComplete="off"
                                                    defaultValue={country} 
                                                    onChange={handleChange}
                                                    value={values.country}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="zipCode"
                                                    className="form-control-lg form-control"
                                                    placeholder="Enter your Zip Code. "
                                                    autoComplete="off"
                                                    defaultValue={zipCode} 
                                                    onChange={handleChange}
                                                    value={values.zipCode}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col sm={1}></Col>
                                        <Col >
                                            <Form.Label className="companyAddressLabel"> Company President Details</Form.Label>
                                        </Col>
                                        <Col sm={1}></Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="fullnamePresident"
                                                    className="form-control-lg form-control"
                                                    placeholder="Full Name"
                                                    autoComplete="off"
                                                    defaultValue={fullnamePresident} 
                                                    onChange={handleChange}
                                                    value={values.fullnamePresident}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="contactPresident"
                                                    className="form-control-lg form-control"
                                                    placeholder=" Contact No.(MOBILE) "
                                                    autoComplete="off"
                                                    defaultValue={contactPresident} 
                                                    onChange={handleChange}
                                                    value={values.contactPresident}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="emailPresident"
                                                    className="form-control-lg form-control"
                                                    placeholder="E-mail Address"
                                                    autoComplete="off"
                                                    defaultValue={emailPresident} 
                                                    onChange={handleChange}
                                                    value={values.emailPresident}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="identificationType"
                                                    className="form-control-lg form-control"
                                                    placeholder="Identification Type "
                                                    autoComplete="off"
                                                    defaultValue={identificationType} 
                                                    onChange={handleChange}
                                                    value={values.identificationType}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="tinNo"
                                                    className="form-control-lg form-control"
                                                    placeholder="Tin "
                                                    autoComplete="off"
                                                    defaultValue={tinNo} 
                                                    onChange={handleChange}
                                                    value={values.tinNo}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="identificationNo"
                                                    className="form-control-lg form-control"
                                                    placeholder="Identification No. "
                                                    autoComplete="off"
                                                    defaultValue={identificationNo} 
                                                    onChange={handleChange}
                                                    onInputChange={handleChange}
                                                    value={values.identificationNo}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col sm={1}></Col>
                                        <Col >
                                            <Form.Label className="companyAddressLabel"> Contact Details</Form.Label>
                                        </Col>
                                        <Col sm={1}></Col>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="email"
                                                    className="form-control-lg form-control"
                                                    placeholder="Tin "
                                                    autoComplete="off"
                                                    defaultValue={userdata[0].email} 
                                                    onChange={handleChange}
                                                    readOnly
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                        <Col>
                                            <div className="form-control-wrap">
                                                <Form.Control
                                                    size="sm"
                                                    type="text"
                                                    name="contact"
                                                    className="form-control-lg form-control"
                                                    placeholder="Identification No. "
                                                    autoComplete="off"
                                                    defaultValue={userdata[0].contact} 
                                                    onChange={handleChange}
                                                    onInputChange={handleChange}
                                                    readOnly
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={1}></Col>
                                    </Row>        
                                    
                                   <Row>
                                    <Col sm={9}></Col>
                                    <Col>
                                        <ButtonToolbar className="">
                                            <Button
                                                    type="submit"
                                                    className="btn btn-hover ml-auto mb-5 mt-5"
                                                    color="success"
                                                    size="sm"
                                                    onClick={handleUpdate}
                                                    //disabled={dissableBtn}
                                                >
                                                    {loading ? <Spinner size="lg" color="light" /> : "SUBMIT"}
                                                    
                                            </Button>&nbsp;&nbsp;
                                            <Button
                                                    //type="submit"
                                                    className="btn btn-hover mr-auto mb-5 mt-5"
                                                    color="danger"
                                                    size="sm"
                                                    onClick={handleBack}
                                                    //disabled={dissableBtn}
                                            >
                                                    BACK
                                            </Button>
                                        </ButtonToolbar></Col>
                                         <Col sm={1}></Col>
                                   </Row>
                                    
                                    
                                    
                                    
                                    {/*  */}
                                 </Form>
                            </div>
                        </Col>
                        
                        
                    </Row>
                    </Container>
                
                    <ScrolltoTop />
                    {/* <div >
                    <Row className="justify-content-center">
                <Col sm={2}></Col>
                    <Col  sm={2}>
                    <ThreeDots
                            visible={true}
                            height="80"
                            width="80"
                            color="#4fa94d"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass="align-center"
                            value={show1}
                            
                        />
                    </Col>
                    
                    </Row>
                    
                    </div> */}
            </div>
        </React.Fragment>
    );
    

  
};



export default CompanyRegisterEdit;