import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Input, Label, select,AccordionItem,AccordionBody,AccordionHeader,UncontrolledAccordion, FormGroup, Alert, Modal, ModalBody} from "reactstrap";
import moment from "moment";
//import { Button, Accordion, } from "react-bootstrap";

//Import Images
import { AppConfiguration } from "read-appsettings-json";
import { GetRequest, PostRequest } from '../../noser-dataaccess'
import axios from 'axios'
import dateFormat from 'dateformat';
import { Link , useNavigate} from "react-router-dom";


const CreateJobPosting = () => {

  const NavigateProfile = useNavigate();
  
  /* Job Details */
  const [jobDetails, setJobDetails] = useState([])
  const [idSlctd, setKeySession] = useState(JSON.parse(sessionStorage.getItem("key")));
  
  /* Job Overview Details */
  const [jobType, setJobType] = useState("")
  const [category, setCategory] = useState("")
  const [jobOverviewLocation, setJobOverviewLocation] = useState("")
  const [offeredSalary, setOfferedSalary] = useState("")
  const [posted, setPosted] = useState("")
  const [experience, setExperience] = useState("")
  const [gender, setGender] = useState("0")
  const [industry, setIndustry] = useState("")
  const [qualification, setQualification] = useState("")
  const [level, setLevel] = useState("")
  const [applied, setApplied] = useState("")

  
  const [toggleFilter, settoggleFilter] = useState(true)
  const [filterLabel, setfilterLabel] = useState("")

  /* post a job */
  const [jobTitle, setJobTitle] = useState("")
  const [hrBox, sethrBox] = useState(false);
  const [financeBox, setfinanceBox] = useState(false);
  const [marketingBox, setmarketingBox] = useState(false);
  const [fullTimeBox, setfullTimeBox] = useState(false);
  const [partTimeBox, setpartTimeBox] = useState(false);
  const [fixedTimeBox, setfixedTimeBox] = useState(false);

  const [collageGradBox, setcollageGradBox] = useState(false);
  const [collageUnderGradBox, setcollageUnderGradBox] = useState(false);
  const [highSchoolBox, sethighSchoolBox] = useState(false);
  const [elementaryBox, setelementaryBox] = useState(false);

  const [withExpBox, setwithExpBox] = useState(false);
  const [isCheckPhp, setPhp] = useState(false);
  const [isCheckUsd, setUsd] = useState(false);
  const [isCheckSgd, setSgd] = useState(false);
  const [isCheckAud, setAud] = useState(false);
  const [isCheckHkd, setHkd] = useState(false);
  const [isCheckmonthly, setMonthly] = useState(false);
  const [isCheckWeekly, setWeekly] = useState(false);
  const [isCheckDaily, setDaily] = useState(false);
  const [withoutExpBox, setwithoutExpBox] = useState(false);

  const [salaryOffer, setsalaryOffer] = useState("")
  const [maxsalaryOffer, setmaxsalaryOffer] = useState("")
  const [preferStartDate, setpreferStartDate] = useState(new Date())
  const [workingHours, setworkingHours] = useState("")
  const [workLocation, setworkLocation] = useState("")

  const [jobDesc, setJobDiscription] = useState("")
  const [skillRequirdAdd, setskillRequirdAdd] = useState("1")
  const [skillRequird1, setskillRequird1] = useState("")
  const [skillRequird2, setskillRequird2] = useState("")
  const [skillRequird3, setskillRequird3] = useState("")
  const [skillRequird4, setskillRequird4] = useState("")
  const [skillRequird5, setskillRequird5] = useState("")
  const [skillRequird0, setskillRequird0] = useState("")

  const [jobRespoAdd, setjobRespoAdd] = useState("1")
  const [jobRespo1, setjobRespo1] = useState("")
  const [jobRespo2, setjobRespo2] = useState("")
  const [jobRespo3, setjobRespo3] = useState("")
  const [jobRespo4, setjobRespo4] = useState("")
  const [jobRespo5, setjobRespo5] = useState("")
  const [jobRespo0, setjobRespo0] = useState("")
  const [compIndustry, setcompIndustry] = useState("")

 const [companyNameBox, setcompanyNameBox] = useState(false);
 const [confidentialBox, setconfidentialBox] = useState(false);
 const [jobPostingEndDate, setjobPostingEndDate] = useState("");
 const [switch1, setswitch1] = useState(false);
 const [switch2, setswitch2] = useState(false);
 const [table1, settable1] = useState([
  {
    "id":"0",
    "name":"dio"
  }
 ]);
 const [currency, setcurrency] = useState("")
 const [salaryBasis, setsalaryBasis] = useState("")
 const [minExp, setminExp] = useState("")
 const [maxExp, setmaxExp] = useState("")
 const [isshow, setisshow] = useState("")
 const [color, setcolor] = useState("")
 const [message, setmessage] = useState("")
 const [fade, setfade] = useState("")
 const [alerttype, setalerttype] = useState("")
 const [disableSave, setdisableSave] = useState(false);
 const [editData, seteditData] = useState([])
 const [editvalidation, seteditvalidation] = useState("")
 const [headerId, setheaderId] = useState("")
 const rowClasses = 'custom-row-class-success';
 const [companyData, setcompanyData] = useState([])
 const [companyProfile, setcompanyProfile] = useState([])
 const [jobPostedCount, setjobPosted] = useState([])
 const [userProfile, setuserProfile] = useState([])
 const [jobpostingNote, setjobpostingNote] = useState("")
 const [workingDays, setworkingDays] = useState("")

 /*  romel  */
 /*  currency */
 const [isDisabled, setIsDisabled] = useState(false);
 const [isLoading, setIsLoading] = useState(false);
 const [isRtl, setIsRtl] = useState(false);
 const [isClearable, setIsClearable] = useState(true);
 const [isSearchable, setIsSearchable] = useState(true);
 const [currencyDDL, setCurrencyDDL] = useState([
  {
    "id" : "0", "value" : "PHP", "label" : "PHP",
  },
  {
    "id" : "1", "value" : "USD", "label" : "USD",
  }
])
/* Salary Basis */
 const [isDisabledSlryBasis, setIsDisabledSlryBasis] = useState(false);
 const [isLoadingSlryBasis, setIsLoadingSlryBasis] = useState(false);
 const [isRtlSlryBasis, setIsRtlSlryBasis] = useState(false);
 const [isClearableSlryBasis, setIsClearableSlryBasis] = useState(true);
 const [isSearchableSlryBasis, setIsSearchableSlryBasis] = useState(true);
 const [currencySlryBasisDDL, setSalaryBasisDDL] = useState([
  {
    "id" : "0", "value" : "Monthly", "label" : "Monthly",
  },
  {
    "id" : "1", "value" : "Weekly", "label" : "Weekly",
  },
  {
    "id" : "2", "value" : "Daily", "label" : "Daily",
  },
])
const [skillList, setSkillsList] = useState([])
const [addSkills, setAddSkills] = useState("")
const [jobRespList, setAddResp] = useState([])
const [addRespnsiblty, setAddRespnsiblty] = useState("")
const [jobAppId, setJobAppId] = useState("")
const [jobDepartment_, setJobDept] = useState("")
// const [validateJob, setModalValidation] = useState(false)
const [validateJob, setModalValidation] = useState(false);
const openModal = () => setModalValidation(true);



  useEffect(() => {
    //getCandiProfile() 
    if(JSON.parse(sessionStorage.getItem("CompanyProfile")) === null || JSON.parse(sessionStorage.getItem("CompanyProfile")) === undefined || JSON.parse(sessionStorage.getItem("userCollection")) === null || JSON.parse(sessionStorage.getItem("userCollection")) === undefined){
    
      let companyProfileTmp = JSON.parse(sessionStorage.getItem("CompanyProfile"))
      setcompanyData(companyProfileTmp)
      let userProfileTmp = JSON.parse(sessionStorage.getItem("userCollection"))
      setuserProfile(userProfileTmp)
    
      window.location.href='/EmployerValidation'
      setModalValidation(true)
      return
      
    }  
    // // console.log("currencyDDL")
    // // console.log(currencyDDL)
    let companyProfileTmp = JSON.parse(sessionStorage.getItem("CompanyProfile"))
    setcompanyData(companyProfileTmp)
    let userProfileTmp = JSON.parse(sessionStorage.getItem("userCollection"))
    setuserProfile(userProfileTmp)

    let timeStamp =  moment(userProfileTmp[0].timestamp, 'hh:mm:ss');
    let timeStamp2 = userProfileTmp[0].timestamp
    //// console.log("timeStamp")
    //// console.log(timeStamp._i)
    let date = new Date();
    let showTime = date.getHours()  + ':' + date.getMinutes()  + ":" + date.getSeconds();
    //// console.log("showTime")
    //// console.log(showTime)

    let getCreatedDate = userProfileTmp[0].modifieddate
    const currentDate = new Date()
    const oldDate = new Date(getCreatedDate)
    
    let validateDate = currentDate - oldDate 
    let validateDate2 = new Date(oldDate)
    let getDays = validateDate/(1000*60*60*24)
    // console.log("getCreatedDate")
    // console.log(getCreatedDate)

    //past
    var fiveDaysAgo = new Date(new Date().setDate(new Date().getDate() - 5));
    //future
    var sevendays = new Date(new Date(getCreatedDate).setDate(new Date(getCreatedDate).getDate() + 7));

    let futureDate =  moment(new Date(sevendays)).format('MM/DD/YYYY');
    let datenow =  moment(new Date()).format('MM/DD/YYYY');

    if(userProfileTmp[0].basicInfoCompany === "0"){
      
      GetCompanybasicInfo(companyProfileTmp)
      if(futureDate === datenow){
        window.location.href='/EmployerValidation'
        setModalValidation(true)
        return
      }
    }
  
    if(userProfileTmp === 0 || companyProfileTmp === 0){
      window.location.href='/EmployerValidation'
      setModalValidation(true)
      return
    }
    
    let toEdit = JSON.parse(sessionStorage.getItem("jobPostedEdit"))
    if(JSON.parse(sessionStorage.getItem("jobPostedEdit")) !== null && JSON.parse(sessionStorage.getItem("jobPostedEdit")) !== undefined){
      
      seteditData(toEdit)
      OnLoadScreen(toEdit)
      //setJobTitle(toEdit.title)
    }
  
    //seteditData([toEdit])
    // console.log("companyProfileTmp")
    // console.log(companyProfileTmp)
    if(companyProfileTmp.length===0){
      setModalValidation(true)
    }
    else{
      GetCompanyProfile(companyProfileTmp)
    }
     
  },[]);

  const getCandiProfile=()=>{
   
    // console.log("handleGetCompanyChecklist")
    let filter_data = { }
    
    const Params = {
      "_collection" : "CandidatesProfile",
      "filter_data" : filter_data
    }
    // console.log("Params CandidatesProfile")
    // console.log(Params)
    // console.log("s")
     axios
     .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {

        // console.log("res.data CandidatesProfile")
        // console.log(res)

        let dataTmp = []
        let a
        let dataaa = res.data
        // console.log("data")
        // console.log(dataaa)
        let newstr2=""
        dataaa.CandidatesProfile.map(function(itm,i){
          let strJson = "{" + itm.substring(46, itm.length)
          let strJson2 = itm.substring(18, itm.length)
          newstr2 = strJson2.split("')")[0]
          let json = JSON.parse(strJson.replace(/'/g,'"'));
          json.headerId = newstr2
          // console.log(json.headerId)
          //setheaderId(json.headerId)
          dataTmp.push(json)
          //a = json
        })    
       // setheaderId(dataTmp[0].h)
        //updateCompanyRegisteredCheckList()
        // console.log("dataTmp")
        // console.log(dataTmp)
        /* dataTmp.forEach(element => {
            element.basicInfoCompany ="1"
        });*/
        // if(res.status === 200){
        //     navigate("/CompanyDocuments")
        // } 
       
        //sessionStorage.setItem("CompanyProfile", JSON.stringify(dataTmp))
    })
    .catch(error =>{
        alert("error getCandiProfile: " + error)
    })
};

  const GetCompanybasicInfo = async(companyProfileTmp) => {
    
    let filter_data = {"isDeleted": "0","userId":companyProfileTmp[0].userId}
    const dataTmp = []
    const Params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "CompanyRegisteredCheckList",
      "filter_data" : filter_data
    }
    
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {
      let data = res.data
      data.CompanyRegisteredCheckList.map(function(itm,i){
        const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
        const json = JSON.parse(strJson.replace(/'/g,'"'));
        dataTmp.push(json)
      })       
      // console.log("dataTmp GetCompanybasicInfo")
      // console.log(dataTmp)
      if(dataTmp[0].businessPermit == "0"){
        getCompanyPostAjob()
      }
      //setcompanyProfile(dataTmp)   
      
    })
  };

  const ToggleFilter = (e) => {
    ////// console.log(toggleFilter)
    toggleFilter = toggleFilter ? false : true
    if(toggleFilter){
      setfilterLabel("Hide Filter Details")
        
    }
    else{
      setfilterLabel("Show Filter Details")
    }
  }
  
  /* Get items */
  async function OnLoadScreen(toEdit){
    //// console.log("editData OnLoadScreen")
    //// console.log(toEdit)

    let formap = []
    let newHr=""
    formap.push(toEdit)
    formap.map(function(d){
      d.jobDepartment.map(function(i){
      
        if(i.department == "HR"){
          //// console.log(i.department)
          sethrBox(true)
        }
        if(i.department == "Finance"){
          setfinanceBox(true)
        }
        if(i.department == "Marketing"){
          setmarketingBox(true)
        }
      })

      d.jobTime.map(function(i){
      
        if(i.time == "Full Time"){
          setfullTimeBox(true)
        }
        if(i.time == "Part Time"){
          setpartTimeBox(true)
        }
        if(i.time == "Fixed Time"){
          setfixedTimeBox(true)
        }
      })

      d.educationalLevel.map(function(i){
      
        if(i.educlvl == "Collage Graduate"){
          setcollageGradBox(true)
        }
        if(i.educlvl == "Collage Under Graduate"){
          setcollageUnderGradBox(true)
        }
        if(i.educlvl == "Senior High School"){
          sethighSchoolBox(true)
        }
        if(i.educlvl == "Elementary"){
          setelementaryBox(true)
        }
      })

      d.applicantType.map(function(i){
      
        if(i.ApplicantType == "With Experience"){
          setwithExpBox(true)
        }
        if(i.ApplicantType == "Without Experience"){
          setwithoutExpBox(true)
        }
      })

      d.qualifications.map(function(i){
      
        if(d.qualifications.length > 0){

          if(i.id == "1"){
            setskillRequird1(i.skill)
          }
          if(i.id == "2"){
            let newEntry = skillRequirdAdd + "1"
            setskillRequirdAdd(newEntry)
            setskillRequird2(i.skill)
          }
          if(i.id == "3"){
            let newEntry = skillRequirdAdd + "1"
            setskillRequirdAdd(newEntry)
            setskillRequird3(i.skill)
          }
          if(i.id == "4"){
            let newEntry = skillRequirdAdd + "1"
            setskillRequirdAdd(newEntry)
            setskillRequird4(i.skill)
          }
          if(i.id == "5"){
            let newEntry = skillRequirdAdd + "1"
            setskillRequirdAdd(newEntry)
            setskillRequird5(i.skill)
          }
          if(i.id == "0"){
            let newEntry = skillRequirdAdd + "1"
            setskillRequirdAdd(newEntry)
            setskillRequird0(i.skill)
          }

        }

      })

      d.responsibility.map(function(i){
      
        if(d.responsibility.length > 0){
         
          if(i.id == "1"){
            setjobRespo1(i.responsibility)
          }
          if(i.id == "2"){
            //// console.log("d.responsibility.length > 0")
            //// console.log(i.id)
            let newEntry1 = jobRespoAdd + "1"
            setjobRespoAdd(newEntry1)
            setjobRespo2(i.responsibility)
          }
          if(i.id == "3"){
            let newEntry1 = jobRespoAdd + "1"
            setjobRespoAdd(newEntry1)
            setjobRespo3(i.responsibility)
          }
          if(i.id == "4"){
            let newEntry1 = jobRespoAdd + "1"
            setjobRespoAdd(newEntry1)
            setjobRespo4(i.responsibility)
          }
          if(i.id == "5"){
            let newEntry1 = jobRespoAdd + "1"
            setjobRespoAdd(newEntry1)
            setjobRespo5(i.responsibility)
          }
          if(i.id == "0"){
            let newEntry1 = jobRespoAdd + "1"
            setjobRespoAdd(newEntry1)
            setjobRespo0(i.responsibility)
          }
        }
      })
    })
    setJobTitle(toEdit.title)
    setsalaryOffer(toEdit.salaryMin)
    setmaxsalaryOffer(toEdit.salaryMax)
    setpreferStartDate(toEdit.startDate)
    setworkingHours(toEdit.workingHours)
    setworkLocation(toEdit.workLocation)
    setJobDiscription(toEdit.description)
    setcurrency(toEdit.salaryCurrency)
    setsalaryBasis(toEdit.salaryBasis)
    setminExp(toEdit.experienceMin)
    setmaxExp(toEdit.experienceMax)
    setjobPostingEndDate(toEdit.endDate)
    setheaderId(toEdit.headerId)
    seteditvalidation("1")
    setjobpostingNote(toEdit.jobPostingNote)
    setworkingDays(toEdit.workingDays)
    setAddResp(toEdit.responsibility)
    setSkillsList(toEdit.skillsExperience)
    setJobAppId(toEdit.id)

    /* Romel */
    console.log("toEdit")
    console.log(toEdit)
    console.log("toEdit.industry")
    console.log(toEdit.industry)
    setcompIndustry(toEdit.industry)
    setcurrency(toEdit.salaryCurrency)
    setsalaryBasis(toEdit.salaryBasis)
    setJobDept(toEdit.dept)
    if(toEdit.salaryCurrency==="Php"){
      setUsd(false)
      setSgd(false)
      setPhp(true)
      setHkd(false)
      setAud(false)
    }
    if(toEdit.salaryCurrency==="Usd"){
      setPhp(false)
      setSgd(false)
      setUsd(true)
      setHkd(false)
      setAud(false)
    }
    if(toEdit.salaryCurrency==="Sgd"){
      setUsd(false)
      setPhp(false)
      setSgd(true)
      setHkd(false)
      setAud(false)
    }
    if(toEdit.salaryCurrency==="Aud"){
      setUsd(false)
      setPhp(false)
      setSgd(true)
      setHkd(false)
      setAud(true)
    }
    if(toEdit.salaryCurrency==="Hkd"){
      setUsd(false)
      setPhp(false)
      setSgd(true)
      setAud(false)
      setHkd(true)
    }

    
    if(toEdit.salaryBasis==="Monthly"){
      setMonthly(true)
      setWeekly(false)
      setDaily(false)
    }
    if(toEdit.salaryBasis==="Weekly"){
      setMonthly(false)
      setWeekly(true)
      setDaily(false)
    }
    if(toEdit.salaryBasis==="Daily"){
      setMonthly(false)
      setWeekly(false)
      setDaily(true)
    }
  }

  const GetCompanyProfile = async(companyProfileTmp) => {
    
    let filter_data = {"isDeleted": "0","userId" : companyProfileTmp[0].userId}
    const dataTmp = []
    const Params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "CompanyProfile",
      "filter_data" : filter_data
    }
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {
      let data = res.data
      data.CompanyProfile.map(function(itm,i){
        const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
        const json = JSON.parse(strJson.replace(/'/g,'"'));
        dataTmp.push(json)
      })       
        
      //setcompanyProfile(dataTmp)   
      GetJobPosted(dataTmp); 
    })
  };

  const GetJobPosted = async(dataTmp) => {
    //// console.log("dataTmp GetJobPosted")
    //// console.log(dataTmp)
    let filter_data = {"isDeleted": "0", "companyId" : dataTmp[0].userId}
    let JobPostedTmp = []
    const Params = {
      "_collection" : "JobPosting",
      "filter_data" : filter_data
    }
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {

        let data = res.data
        let newstr2=""
        data.JobPosting.map(function(itm,i){
            let strJson = "{" + itm.substring(46, itm.length)
            let strJson2 = itm.substring(18, itm.length)
            newstr2 = strJson2.split("')")[0]
            let json = JSON.parse(strJson.replace(/'/g,'"'));
            json.headerId = newstr2
            JobPostedTmp.push(json)
        })       
         console.log("JobPostedTmp GetJobPosted")
         console.log(JobPostedTmp)

        setjobPosted(JobPostedTmp)
        
        // setJson(dataTmp)  
    })
    .catch(error =>{
        alert("error GetJobPosted: " + error)
    })
  };
  const getCompanyPostAjob = async() => {
    //// console.log("dataTmp GetJobPosted")
    //// console.log(dataTmp)
    let companyProfileTmp = JSON.parse(sessionStorage.getItem("CompanyProfile"))
    let filter_data = {"isDeleted": "0", "companyId" : companyProfileTmp[0].userId}
    let JobPostedTmp = []
    const Params = {
      "_collection" : "JobPosting",
      "filter_data" : filter_data
    }
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {

      let data = res.data
      let checkpostajobcount = data.JobPosting.length
      console.log("get JobPosting")
      console.log(data)
      setdisableSave(true)
      setalerttype("Warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("To further post a job please fill up Company Profile Details")
      setfade(true)
    })
    .catch(error =>{
      alert("error getCompanyPostAjob: " + error)
    })
  };


  /* End Get Items */

  /* Function Items */
  const submitClick =()=>{
    // alert("clicked")
    // if(jobType===""){

    // }
    // if(category===""){
      
    // }
    // if(jobOverviewLocation===""){
      
    // }
    // if(offeredSalary===""){
      
    // }
    // if(posted===""){
      
    // }
    // if(experience===""){
      
    // }
    if(gender==="0"){
     // alert("Please Select Gender")
      return
    }
    // if(industry===""){
      
    // }
    // if(qualification===""){
      
    // }
    // if(level===""){
      
    // }
    // if(applied===""){
      
    // }


    const dataList ={
      jobType : jobType,
      category : category,
      location : jobOverviewLocation,
      offeredSalary : offeredSalary,
      posted : posted,
      experience : experience,
      gender : gender,
      industry : industry,
      qualification : qualification,
      level : level,
      applied : applied,

      
      isDeleted : "0",
      isModified : "0",
      modifiedBy : "romel",
      modifiedDate : "04/30/2024",
      createdBy : "romel",
      createdDate : "04/30/2024",
    }
    //// console.log("dataList")
    //// console.log(dataList)
    
    const params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "JobOverview",
      "doc_data" : dataList
    }

    // axios
    // .post(AppConfiguration.Setting().mongoJobspace + "action/add", params )
    // .then(resp =>{
    //   let data = resp.data
    //   if(resp.data==="success"){
    //     alert("Success")
    //     OnLoadScreen()
    //   }
    //   else{
    //     alert("error")
    //   }
    // })
    // .catch(error =>{
    //   alert("error: " + error)
    // })
  }


  /* End Function Items */

  /* On Change Items */
 

  const onChangeJobType =(e)=>{
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setJobType(e.target.value)
  }
  const onChangeCategory =(e)=>{
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setCategory(e.target.value)
  }
  const onChangeJobOverViewLocation =(e)=>{
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setJobOverviewLocation(e.target.value)
  }
  const onChangeOfferedSalary =(e)=>{
    setOfferedSalary(e.target.value)
  }
  const onChangePosted =(e)=>{
    setPosted(e.target.value)
  }
  const onChangeExperience =(e)=>{
    setExperience(e.target.value)
  }
  const onChangeGender =(e)=>{
    if(e.target.value==="1"){
      setGender("Male")
    }
    else if((e.target.value==="2")){
      setGender("Female")
    }
    else{
      setGender("0")
    }
  }
  const onChangeIndustry =(e)=>{
    setIndustry(e.target.value)
  }
  const onChangeQualification =(e)=>{
    setQualification(e.target.value)
  }
  const onChangeLevel =(e)=>{
    setLevel(e.target.value)
  }
  const onChangeApplied =(e)=>{
    setApplied(e.target.value)
  }
  const onChangeworkingDays =(e)=>{
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setworkingDays(e.target.value)
  }



  const onChangeJobTitle =(e)=>{
    // if(e.target.value == "'"){
    //   setalerttype("warning!")
    //   setisshow(true)
    //   setcolor("warning")
    //   setmessage("Please Remove ' for now")
    //   setfade(true)
    // }else{
    //   setalerttype("")
    //   setisshow(false)
    //   setcolor("")
    //   setmessage("")
    //   setfade(true)
    // }
    setJobTitle(e.target.value.replace("'", "").replace('"', "").replace('{','').replace('}',''))
  }
  const onChangeDepartment = (e) =>{
    setJobDept(e.target.value.replace("'", "").replace('"', "").replace('{','').replace('}',''))
  }
  const onClickCheckBoxfull =(e)=> {
    //// console.log(e.target.checked)
    setfullTimeBox(e.target.checked)
    
  }
  const onClickCheckBoxPart =(e)=> {
    //// console.log(e.target.checked)
    setpartTimeBox(e.target.checked)
    
  }

  const onClickCheckBoxFixed =(e)=> {
    //// console.log(e.target.checked)
    setfixedTimeBox(e.target.checked)
   /*  if(e.target.checked == !fixedTimeBox){
      setfixedTimeBox(false)
    }else{
      setfixedTimeBox(true)
    } */
    
  }

  const onClickCheckBoxhr =(e)=> {
    //// console.log(e.target.checked)
    sethrBox(e.target.checked)
   /*  if(e.target.checked == !hrBox){
      sethrBox(false)
    }else{
      sethrBox(true)
    } */
    
  }

  const onClickCheckBoxfinance =(e)=> {
    //// console.log(e.target.checked)
    setfinanceBox(e.target.checked)
   /*  if(e.target.checked == !financeBox){
      setfinanceBox(false)
    }else{
      setfinanceBox(true)
    } */
    
  }

  const onClickCheckBoxmarketing =(e)=> {
    //// console.log(e.target.checked)
    setmarketingBox(e.target.checked)
   /*  if(e.target.checked == !marketingBox){
      setmarketingBox(false)
    }else{
      setmarketingBox(true)
    } */
    
  }

  const onClickCheckBoxcollageGrad =(e)=> {
    //// console.log(e.target.checked)
    setcollageGradBox(e.target.checked)
  /*   if(e.target.checked == !collageGradBox){
      setcollageGradBox(false)
    }else{
      setcollageGradBox(true)
    } */
    
  }

  const onClickCheckBoxcollageUnderGrad =(e)=> {
    //// console.log(e.target.checked)
    setcollageUnderGradBox(e.target.checked)
  /*   if(e.target.checked == !collageUnderGradBox){
      setcollageUnderGradBox(false)
    }else{
      setcollageUnderGradBox(true)
    } */
    
  }

  const onClickCheckBoxhighSchool =(e)=> {
    //// console.log(e.target.checked)
    sethighSchoolBox(e.target.checked)
   /*  if(e.target.checked == !highSchoolBox){
      sethighSchoolBox(false)
    }else{
      sethighSchoolBox(true)
    } */
    
  }

  const onClickCheckBoxelementary =(e)=> {
    //// console.log(e.target.checked)
    setelementaryBox(e.target.checked)
   /*  if(e.target.checked == !elementaryBox){
      setelementaryBox(false)
    }else{
      setelementaryBox(true)
    } */
    
  }
  

  const onClickCheckBoxwithExp =(e)=> {
    //// console.log(e.target.checked)
    setwithExpBox(e.target.checked)

   /*  if(e.target.checked == !withExpBox){
      setwithExpBox(false)
    }else{
      setwithExpBox(true)
    } */
    
  }

  const onClickCheckPhp =(e)=> {
    if(e.target.checked===true) {
      setUsd(false)
      setPhp(true)
      setSgd(false)
      setHkd(false)
      setAud(false)
      setcurrency("Php")
    }
  }
  const onClickCheckUsd =(e)=> {
    if(e.target.checked===true) {
      setUsd(true)
      setPhp(false)
      setSgd(false)
      setHkd(false)
      setAud(false)
      setcurrency("Usd")
    }
  }
  const onClickCheckSgd =(e)=> {
    if(e.target.checked===true) {
      setUsd(false)
      setPhp(false)
      setSgd(true)
      setHkd(false)
      setAud(false)
      setcurrency("Sgd")
    }
  }
  const onClickCheckAud =(e)=> {
    if(e.target.checked===true) {
      setUsd(false)
      setPhp(false)
      setSgd(false)
      setHkd(false)
      setAud(true)
      setcurrency("Aud")
    }
  }
  const onClickCheckHkd =(e)=> {
    if(e.target.checked===true) {
      setUsd(false)
      setPhp(false)
      setSgd(false)
      setAud(false)
      setHkd(true)
      setcurrency("Hkd")
    }
  }

  const onClickCheckMonthly =(e)=> {
    if(e.target.checked===true) {
      setMonthly(true)
      setWeekly(false)
      setDaily(false)
      setsalaryBasis("Monthly")
    }
  }
  const onClickCheckWeekly =(e)=> {
    if(e.target.checked===true) {
      setMonthly(false)
      setWeekly(true)
      setDaily(false)
      setsalaryBasis("Weekly")
    }
  }
  const onClickCheckDaily =(e)=> {
    if(e.target.checked===true) {
      setMonthly(false)
      setWeekly(false)
      setDaily(true)
      setsalaryBasis("Daily")
    }
  }

  const onClickCheckBoxwithoutExp =(e)=> {
    //// console.log(e.target.checked)
    setwithoutExpBox(e.target.checked)
   /*  if(e.target.checked == !withoutExpBox){
      setwithoutExpBox(false)
    }else{
      setwithoutExpBox(true)
    } */
    
  }

  const onChangesalaryOffer =(e)=>{
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setsalaryOffer(e.target.value)
  }

  const onChangemaxsalaryOffer =(e)=>{
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setmaxsalaryOffer(e.target.value)
  }

  const onChangepreferStartDate =(e)=>{
    setpreferStartDate(e.target.value)
  }

  const onChangeworkingHours =(e)=>{
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setworkingHours(e.target.value)
  }

  const onChangeworkLocation =(e)=>{
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setworkLocation(e.target.value)
  }

  const onChangejobDesc =(e)=>{
    // if(e.target.value == "'"){
    //   setalerttype("warning!")
    //   setisshow(true) 
    //   setcolor("warning")
    //   setmessage("Please Remove ' for now")
    //   setfade(true)
    // }else{
    //   setalerttype("")
    //   setisshow(false)
    //   setcolor("")
    //   setmessage("")
    //   setfade(true)
    // }
    setJobDiscription(e.target.value.replace("'", "").replace('"', "").replace('{','').replace('}',''))
  }

  const onClickCheckBoxcompanyName =(e)=> {
    //// console.log(e.target.checked)
    setcompanyNameBox(e.target.checked)
    /* if(e.target.checked == !companyNameBox){
      setcompanyNameBox(false)
    }else{
      setcompanyNameBox(true)
    } */
    
  }

  const onClickCheckBoxconfidential =(e)=> {
    //// console.log(e.target.checked)
    setconfidentialBox(e.target.checked)
   /*  if(e.target.checked == !confidentialBox){
      setconfidentialBox(false)
    }else{
      setconfidentialBox(true)
    } */
    
  }

  const onChangejobPostingEndDate = (e) => {
    setjobPostingEndDate(e.target.value)
  }
  const onchangeCompIndustry = (e) => {
    setcompIndustry(e.target.value)
  }

  /* End On Change Items */
  const onClickViewJobPost = (e) => {
   
    window.location.href='/EmployerJobPostingList'
    //window.location.href='/JobDescEmployer'
  }

  /* Proper Case */
  function toTitleCase(str) {
    const titleCase = str
      .toLowerCase()
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  
    return titleCase;
  }
  /* End */
  
  const oncheckJobPost = async() =>{
    let JobPostedTmp = []
    const Params = {
    "_collection" : "JobPosting",
    "filter_data" : {}
    }
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {

      let data = res.data
      let newstr2=""
      data.JobPosting.map(function(itm,i){
        let strJson = "{" + itm.substring(46, itm.length)
        let strJson2 = itm.substring(18, itm.length)
        newstr2 = strJson2.split("')")[0]
        let json = JSON.parse(strJson.replace(/'/g,'"'));
        json.headerId = newstr2
        JobPostedTmp.push(json)
      })
      onClickPostAJob(parseFloat(JobPostedTmp.length) + 1)
    })
    .catch(error =>{
        alert("error GetJobPosted: " + error)
    })
  }

  const onClickPostAJob=(JobPostId)=>{

    let companyProfileTmp = JSON.parse(sessionStorage.getItem("CompanyProfile"))
    console.log("jobPostedCount")
    console.log(jobPostedCount)

    var nf = new Intl.NumberFormat();
    // nf.format(number)
    // const amount = e.target.value
    // console.log(nf.format(amount))

   if(jobPostedCount.length !== 0){
    if( jobPostedCount.filter(q => q.companyId === companyProfileTmp[0].userId && q.active === "1" ) ){
      
      setalerttype("Warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Warning!")
      setfade(true)
      return
    }
   }

    let userProfileTmp = JSON.parse(sessionStorage.getItem("userCollection"))
    //console.log("userProfileTmp")
    //console.log(userProfileTmp)
    
    if( companyProfileTmp== null || companyProfileTmp == undefined ||companyProfileTmp.length == 0 ||companyProfileTmp[0].companyName == "" ){
      setalerttype("Warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Company profile Data cannot found Please relogin")
      setfade(true)
      return
    }
    if( userProfileTmp == null || userProfileTmp == undefined || userProfileTmp.length == 0 ){
      setalerttype("Warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("User login Data cannot found Please relogin")
      setfade(true)
      return
    }

    let preferStartDatetmp = moment(new Date(preferStartDate)).format('MM/DD/YYYY');
    //// console.log(createddate)
    let urgent = ""
    let deptTmp = ""
    let workTypeTmp = ""
    let educationTmp = ""
    let aplicantExpTmp = ""
    let workingDaysAndHrs = ""

    if(switch2 == true){
      urgent = "Urgent"
    }

    let alljobSkill = []

    if(skillRequird1 !== ""){
      alljobSkill.push(
        {
          "id":"1",
          "skill":skillRequird1
        }
      )
    }
    if(skillRequird2 !== ""){
      alljobSkill.push(
        {
          "id":"2",
          "skill":skillRequird2
        }
      )
    }
    if(skillRequird3 !== ""){
      alljobSkill.push(
        {
          "id":"3",
          "skill":skillRequird3
        }
      )
    }
    if(skillRequird4 !== ""){
      alljobSkill.push(
        {
          "id":"4",
          "skill":skillRequird4
        }
      )
    }
    if(skillRequird5 !== ""){
      alljobSkill.push(
        {
          "id":"5",
          "skill":skillRequird5
        }
      )
    }
    if(skillRequird0 !== ""){
      alljobSkill.push(
        {
          "id":"0",
          "skill":skillRequird0
        }
      )
    }

    let allRespo = []

    if(jobRespo1 !== ""){
      allRespo.push(
        {
          "id":"1",
          "responsibility":jobRespo1
        }
      )
    }
    if(jobRespo2 !== ""){
      allRespo.push(
        {
          "id":"2",
          "responsibility":jobRespo2
        }
      )
    }
    if(jobRespo3 !== ""){
      allRespo.push(
        {
          "id":"3",
          "responsibility":jobRespo3
        }
      )
    }
    if(jobRespo4 !== ""){
      allRespo.push(
        {
          "id":"4",
          "responsibility":jobRespo4
        }
      )
    }
    if(jobRespo5 !== ""){
      allRespo.push(
        {
          "id":"5",
          "responsibility":jobRespo5
        }
      )
    }
    if(jobRespo0 !== ""){
      allRespo.push(
        {
          "id":"0",
          "responsibility":jobRespo0
        }
      )
    }

    let checkJobDep = []
    if(hrBox == true){
      checkJobDep.push(
        {
          "id":"0",
          "department":"HR"
        }
      )
      
      deptTmp = deptTmp+"HR"
     
    }
    if(financeBox == true){
      checkJobDep.push(
        {
          "id":"2",
          "department":"Finance"
        }
      )
      if(deptTmp !== ""){
        deptTmp = deptTmp +  " | Finance"
      }else{
        deptTmp = deptTmp +  "Finance"
      }
      
    }
    if(marketingBox == true){
      checkJobDep.push(
        {
          "id":"3",
          "department":"Marketing"
        }
      )
      if(deptTmp !== ""){
        deptTmp = deptTmp +  " | Marketing"
      }else{
        deptTmp = deptTmp +  "Marketing"
      }
      //// console.log("deptTmp")
      //// console.log(deptTmp)
    }

    let allJobTime = []

    if(fullTimeBox == true){
      allJobTime.push(
        {
          "id":"0",
          "time":"Full Time"
        }
      )
    
      workTypeTmp = workTypeTmp +  "Full Time"
     
    }
    if(partTimeBox == true){
      allJobTime.push(
        {
          "id":"2",
          "time":"Part Time"
        }
      )
      if(workTypeTmp !== ""){
        workTypeTmp = workTypeTmp +  " | Part Time"
      }else{
        workTypeTmp = workTypeTmp +  "Part Time"
      }
    }
    if(fixedTimeBox == true){
      allJobTime.push(
        {
          "id":"3",
          "time":"Fixed Time"
        }
      )
      if(workTypeTmp !== ""){
        workTypeTmp = workTypeTmp +  " | Fixed Time"
      }else{
        workTypeTmp = workTypeTmp +  "Fixed Time"
      }
    }

    let alleducationalLvl= []
   
    if(collageGradBox == true){
      alleducationalLvl.push(
        {
          "id":"0",
          "educlvl":"Collage Graduate"
        }
      )
     
      educationTmp = educationTmp +  "educationTmp"
      
    }
    
    if(collageUnderGradBox == true){
      alleducationalLvl.push(
        {
          "id":"1",
          "educlvl":"Collage Under Graduate"
        }
      )
      if(educationTmp !== ""){
        educationTmp = educationTmp +  " | Collage Under Graduate"
      }else{
        educationTmp = educationTmp +  "Collage Under Graduate"
      }
    }
    // console.log(highSchoolBox)
    // console.log(elementaryBox)
    if(highSchoolBox == true){
      alleducationalLvl.push(
        {
          "id":"2",
          "educlvl":"Senior High School"
        }
      )
      if(educationTmp !== ""){
        educationTmp = educationTmp +  " | Senior High School"
      }else{
        educationTmp = educationTmp +  "Senior High School"
      }
    }
    if(elementaryBox == true){
      alleducationalLvl.push(
        {
          "id":"3",
          "educlvl":"Elementary"
        }
      )
      if(educationTmp !== ""){
        educationTmp = educationTmp +  " | Elementary"
      }else{
        educationTmp = educationTmp +  "Elementary"
      }
    }

    let allApplicantType= []

    if(withExpBox == true){
      allApplicantType.push(
        {
          "id":"0",
          "ApplicantType":"With Experience"
        }
      )
    
      aplicantExpTmp =  "With Experience"
      
    }
    if(withoutExpBox == true){
      allApplicantType.push(
        {
          "id":"1",
          "ApplicantType":"Without Experience"
        }
      )
    
      aplicantExpTmp  =  "Without Experience"
      
    }

    workingDaysAndHrs = workingDays + " " + workingHours
    let newdatetmp = new Date()
    let futureDate =  moment(new Date(newdatetmp)).format('MM/DD/YYYY');
    // console.log("futureDate newpostingIdtmp2")
    // console.log(futureDate)
    let applicationDate = dateFormat(futureDate).split(" ")
    let getMM = futureDate.split("/")[0]
    let getYear = applicationDate[3]
    let getYear2 = getYear[2]+getYear[3]
    // console.log("applicationDate newpostingIdtmp2")
    // console.log(applicationDate)
    let newpostingIdtmp1 = String(jobPostedCount.length+1)
    let newpostingIdtmp2 = "PH"+"-"+"JS"+"-"+getYear2+"-"+getMM+"-"+JobPostId
    // console.log("save newpostingIdtmp2")
    // console.log(newpostingIdtmp2)

    let toSave = {
      
      "postingId"       : String(newpostingIdtmp2),
      "title"           : toTitleCase(jobTitle),
      "companyId"       : companyProfileTmp[0].userId,
      "startDate"       : preferStartDatetmp,
      "endDate"         : jobPostingEndDate,
      "Urgency"         : urgent,
      "description"     : jobDesc,
      "type"            : "Freelance",
      "salaryMin"       : nf.format(salaryOffer),
      "salaryMax"       : nf.format(maxsalaryOffer),
      "salaryCurrency"  : currency,
      "salaryBasis"     : salaryBasis,
      "level"           : "Entry",
      "experienceMin"   : minExp,
      "experienceMax"   : maxExp,
      "keyWords"        : toTitleCase(jobTitle),
      "responsibility"  : jobRespList,
      "qualifications"  : alljobSkill,
      "workingHours"    : workingHours,
      "workLocation"    : toTitleCase(workLocation),
      "jobDepartment"   : checkJobDep,
      "jobTime"         : allJobTime,
      "educationalLevel": alleducationalLvl,
      "applicantType"   : allApplicantType,
      "skillsExperience": skillList,
      "companyProfile"  : companyProfileTmp[0].userId,
      "isDeleted"       : "0",
      "isPosted"        : "0",
      "isClosed"        : "0",
      "createdBy"       : userProfile[0].firstName,
      "createdDate"     : preferStartDatetmp,
      "updatedBy"       : userProfile[0].firstName,
      "updatedDate"     : preferStartDatetmp,
      "timeStamp"       : "2024041012345",
      "dept"            : toTitleCase(jobDepartment_),
      "workType"        : workTypeTmp,
      "education"       : educationTmp,
      "aplicantExp"     : aplicantExpTmp,
      "jobPostingNote"  : toTitleCase(jobpostingNote),
      "workingDays"     : toTitleCase(workingDays),
      "workingDaysAndHrs"  : toTitleCase(workingDaysAndHrs),
      "active"          : "1",
      "industry"          : toTitleCase(compIndustry),
      
    }

    if(editvalidation == ""){

      const saveParams = {
    
        "_collection" : "JobPosting",
        "doc_data"    : toSave,
  
      }
      console.log("onClickPostAJob toSave")
      console.log(saveParams)
  
      axios
      .post("http://206.189.95.201:8098/action/add", saveParams)
      .then(res => {
        let data = res.data
        setalerttype("Success!")
        setisshow(true)
        setcolor("success")
        setmessage(data)
        setfade(true)
        setdisableSave(true)
        let newbox = []
        newbox.push(toSave) 
        sessionStorage.setItem("key", JSON.stringify(jobTitle))
        window.location.href='/JobDescEmployer' 
       
      }).catch(error =>{
        setalerttype("Error!")
        setisshow(true)
        setcolor("danger")
        setmessage("error: " + error)
        setfade(true)
        setdisableSave(true)
        sessionStorage.removeItem("jobPostedEdit")
      })
    }
    else{
      
      let toEdit = JSON.parse(sessionStorage.getItem("jobPostedEdit"))
      let toUpdate = {
        "postingId"       : toEdit.postingId,
        "title"           : toTitleCase(jobTitle),
        "companyId"       : companyProfileTmp[0].userId,
        "startDate"       : preferStartDate,
        "endDate"         : jobPostingEndDate,
        "Urgency"         : urgent,
        "description"     : jobDesc,
        "type"            : "Freelance",
        "salaryMin"       : salaryOffer,
        "salaryMax"       : maxsalaryOffer,
        "salaryCurrency"  : currency,
        "salaryBasis"     : salaryBasis,
        "level"           : "Entry",
        "experienceMin"   : minExp,
        "experienceMax"   : maxExp,
        "keyWords"        : toTitleCase(jobTitle),
        "responsibility"  : jobRespList,
        "qualifications"  : alljobSkill,
        "workingHours"    : workingHours,
        "workLocation"    : toTitleCase(workLocation),
        "jobDepartment"   : checkJobDep,
        "jobTime"         : allJobTime,
        "educationalLevel": alleducationalLvl,
        "applicantType"   : allApplicantType,
        "skillsExperience": skillList,
        "companyProfile"  : companyProfileTmp[0].userId,
        "isDeleted"       : "0",
        "isPosted"        : "0",
        "isClosed"        : "0",
        "createdBy"       : userProfile[0].firstName,
        "createdDate"     : new Date(),
        "updatedBy"       : userProfile[0].firstName,
        "updatedDate"     : new Date(),
        "timeStamp"       : "2024041012345",
        "dept"            : toTitleCase(jobDepartment_),
        "workType"        : workTypeTmp,
        "education"       : educationTmp,
        "aplicantExp"     : aplicantExpTmp,
        "active"          : "1",
        "jobPostingNote"     : toTitleCase(jobpostingNote),
        "workingDays"     : toTitleCase(workingDays),
        "workingDaysAndHrs"  : toTitleCase(workingDaysAndHrs),
        "industry"          : toTitleCase(compIndustry),
      }

      const updateParams = {
    
        "_collection" : "JobPosting",
        "doc_data"    : toUpdate,
        "_id"         : jobAppId,
  
      }

      console.log("onClickPostAJob toUpdate")
      console.log(updateParams)
      axios
      .post("http://206.189.95.201:8098/action/update_one", updateParams)
      .then(res => {
        let data = res.data
        setalerttype("Success!")
        setisshow(true)
        setcolor("success")
        setmessage(data)
        setfade(true)
        setdisableSave(true)
        let newbox = []
        newbox.push(updateParams) 
        sessionStorage.setItem("toJobDescEmployer", JSON.stringify(newbox))
        sessionStorage.setItem("key", JSON.stringify(toTitleCase(jobTitle)))
        window.location.href='/JobDescEmployer'
       
      }).catch(error =>{
          
        //// console.log("error: " + error)
        setalerttype("Error!")
        setisshow(true)
        setcolor("danger")
        setmessage("error: " + error)
        setfade(true)
        setdisableSave(true)
        sessionStorage.removeItem("jobPostedEdit")
  
      })
    }
  }

  function onClickAddSKill(e){
    // let newEntry = skillRequirdAdd + "1"
    // setskillRequirdAdd(newEntry)
    // let _id = skillRequird0.length + 1
    // let dataList = skillRequird0
    // dataList.push({
    //   "id" : String(_id),
    //   "value" : ""
    // })
    // setskillRequird0(dataList)
    // console.log("dataList")
    // console.log(dataList)
    // console.log("skillRequird0")
    // console.log(skillRequird0)
    if(addSkills!==""){
      let skillListTmp = skillList
  
      let today = new Date()
      let newId = moment(today).format('HHMMSS');
      const obj = {
        "id":newId.toString(),
        "skill"        :   addSkills,
        "isModified"    :   "0",
        "isDeleted"     :   "0",  
      }
      skillListTmp.push(obj)      
      console.log(skillListTmp)
      setAddSkills("")
    }
    else{
      console.log("no data")
    }
  }
  
  const onChangejobpostingNote = (e) => {
    // if(e.target.value == "'"){
    //   setalerttype("warning!")
    //   setisshow(true)
    //   setcolor("warning")
    //   setmessage("Please Remove ' for now")
    //   setfade(true)
    // }else{
    //   setalerttype("")
    //   setisshow(false)
    //   setcolor("")
    //   setmessage("")
    //   setfade(true)
    // }
    setjobpostingNote(e.target.value.replace("'", "").replace('"', "").replace('{','').replace('}',''))
  }
  const onChangeskillRequird0 =(e)=>{
    setAddSkills(e.target.value.replace("'", "").replace('"', "").replace('{','').replace('}',''))
  }
  const onChangeskillRequird1 = (e) => {
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setskillRequird1(e.target.value)
  }
  const onChangeskillRequird2 = (e) => {
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setskillRequird2(e.target.value)
  }
  const onChangeskillRequird3 = (e) => {
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setskillRequird3(e.target.value)
  }
  const onChangeskillRequird4 = (e) => {
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setskillRequird4(e.target.value)
  }
  const onChangeskillRequird5 = (e) => {
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setskillRequird5(e.target.value)
  }

  function onClickjobRespoAdd(e){
    // //// console.log("start onClickjobRespoAdd")
    // let newEntry2 = jobRespoAdd + "1"
    // setjobRespoAdd(newEntry2)
    if(addRespnsiblty!==""){
      let skillListTmp = jobRespList
  
      let today = new Date()
      let newId = moment(today).format('HHMMSS');
      const obj = {
        "id":newId.toString(),
        "responsibility"        :   addRespnsiblty,
        "isModified"    :   "0",
        "isDeleted"     :   "0",  
      }
      skillListTmp.push(obj)      
      console.log(skillListTmp)
      setAddRespnsiblty("")
    }
    else{
      console.log("no data")
    }
  }
  
  const onChangejobRespo0 = (e) => {
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setjobRespo0(e.target.value)
  }
  const onChangejobRespo1 = (e) => {
    // if(e.target.value == "'"){
    //   setalerttype("warning!")
    //   setisshow(true)
    //   setcolor("warning")
    //   setmessage("Please Remove ' for now")
    //   setfade(true)
    // }else{
    //   setalerttype("")
    //   setisshow(false)
    //   setcolor("")
    //   setmessage("")
    //   setfade(true)
    // }
    setAddRespnsiblty(e.target.value.replace("'", "").replace('"', "").replace('{','').replace('}',''))
  }
  const onChangejobRespo2 = (e) => {
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setjobRespo2(e.target.value)
  }
  const onChangejobRespo3 = (e) => {
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setjobRespo3(e.target.value)
  }
  const onChangejobRespo4 = (e) => {
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setjobRespo4(e.target.value)
  }
  const onChangejobRespo5 = (e) => {
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setjobRespo5(e.target.value)
  }

  const onChangecurrency = (e) => {
    // setcurrency(e.target.value)
    // // console.log("e")
    // // console.log(e)
    if(e === null){
      setcurrency("")
    }
    else {
      setcurrency(e.value)
    }
  }

  const onChangesalaryBasis = (e) => {
    // // console.log(e.value)
    if(e === null){
      setsalaryBasis("")
    }
    else {
      setsalaryBasis(e.value)
    }
  }

  const onChangeminExp = (e) => {
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setminExp(e.target.value)
  }

  const onChangemaxExp = (e) => {
    if(e.target.value == "'"){
      setalerttype("warning!")
      setisshow(true)
      setcolor("warning")
      setmessage("Please Remove ' for now")
      setfade(true)
    }else{
      setalerttype("")
      setisshow(false)
      setcolor("")
      setmessage("")
      setfade(true)
    }
    setmaxExp(e.target.value)
  }

  
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#eefeea",
      marginBottom: "0.8%",
      borderStyle: "outset",
      // className: "basic-single text-muted"
      // color: "#562d2d"
      fontSize: "14px"
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // // Removes weird border around container
      // boxShadow: state.isFocused ? null : null,
      // "&:hover": {
      //   // Overwrittes the different states of border
      //   borderColor: state.isFocused ? "red" : "blue"
      // }
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    })
  };
  
  const handleDeleteSkills = (key) => {
    console.log("key")
    console.log(key)
    let skillList_ = skillList.filter(x=> x.id !== key)
    console.log("skillList_")
    console.log(skillList_)
    setSkillsList(skillList_)
    // skillList = []
    // this.setState({skillList:skillList})x=>String(x.id)===String(key)

  }
  
  const handleDeleteResponse = (key) => {
    console.log("key")
    console.log(key)
    let RespoList_ = jobRespList.filter(x=> x.id !== key)
    console.log("RespoList_")
    console.log(RespoList_)
    setAddResp(RespoList_)
    // skillList = []
    // this.setState({skillList:skillList})x=>String(x.id)===String(key)

  }
  return (
    <React.Fragment>
      <Card className="job-detail overflow-hidden mt-1">
        <CardBody className="p-4" style={{ marginTop : "3%"}}>
        <Alert isOpen={isshow} color={color} fade={fade} className={isshow ? 'display-block' : 'display-none'}>
            <div className={color}></div> 
            {message}
          </Alert>
        <Row>
          <Label className="mt-1" style={{color:"#0A6219", fontSize: "20px", fontWeight: "bold", marginLeft: "1%"}}>Create Job Post</Label><br />
            <Col lg={4}>
              <Card style={{ borderColor: "#ffffff"}}>
                <CardBody>
                  <Label className="mb-3" style={{fontSize: "18px", fontWeight: "bold"}}>Job Details</Label> 
                  <Row>
                    <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        placeholder="Job Title"
                        style={{ borderStyle: "outset",marginBottom:"5px",background:"#F0FEED"}}
                        value={jobTitle}
                        onChange={onChangeJobTitle}
                    />
                  </Row>
                  <Row>
                    <Input
                          type="text"
                          className="form-control"
                          id="nameControlInput"
                          placeholder="Department"
                          style={{ borderStyle: "outset",marginBottom:"5px",background:"#F0FEED"}}
                          value={jobDepartment_}
                          onChange={onChangeDepartment}
                      />
                  </Row>
                  <UncontrolledAccordion
                    defaultOpen={[
                      '1',
                      '2'
                    ]}
                    stayOpen
                  >
                    <AccordionItem>
                      <AccordionHeader targetId="2" >
                      <b style={{fontSize:"14px", }}>Employee Type</b>
                      </AccordionHeader>
                      <AccordionBody accordionId="2">
                        <FormGroup check>
                          <Input type="checkbox" onClick={onClickCheckBoxfull} checked={fullTimeBox} style={{border: "1px solid green"}}/>
                          <Label >
                            Full Time
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" onClick={onClickCheckBoxPart} checked={partTimeBox}  style={{border: "1px solid green"}}/>
                          <Label >
                            Part Time
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" onClick={onClickCheckBoxFixed} checked={fixedTimeBox}  style={{border: "1px solid green"}}/>
                          <Label >
                            Fixed Time
                          </Label>
                        </FormGroup>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="3">
                        <b style={{fontSize:"14px"}}>Educational Level</b>
                      </AccordionHeader>
                      <AccordionBody accordionId="3">
                        <FormGroup check>
                          <Input type="checkbox" onClick={onClickCheckBoxcollageGrad} checked={collageGradBox}  style={{border: "1px solid green"}}/>
                          <Label >
                          College  Graduate
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" onClick={onClickCheckBoxcollageUnderGrad} checked={collageUnderGradBox}  style={{border: "1px solid green"}}/>
                          <Label >
                          College Under Graduate
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" onClick={onClickCheckBoxhighSchool} checked={highSchoolBox}  style={{border: "1px solid green"}}/>
                          <Label >
                            Senior High School
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" onClick={onClickCheckBoxelementary} checked={elementaryBox}  style={{border: "1px solid green"}}/>
                          <Label >
                            Elementary
                          </Label>
                        </FormGroup>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="4">
                        <b style={{fontSize:"14px"}}>Applicant Type</b>
                        </AccordionHeader>
                        <AccordionBody accordionId="4">
                          <FormGroup check>
                            <Input type="checkbox" onClick={onClickCheckBoxwithExp} checked={withExpBox}  style={{border: "1px solid green"}}/>
                            <Label >
                              With Experienced
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Input type="checkbox" onClick={onClickCheckBoxwithoutExp} checked={withoutExpBox}  style={{border: "1px solid green"}}/>
                            <Label >
                              Without Experience
                            </Label>
                          </FormGroup>
                          { withExpBox == true ?<Input
                              type="text"
                              className="form-control mt-2"
                              id="nameControlInput"
                              placeholder="Min Experience (6 Months)"
                              style={{ borderStyle: "outset",marginBottom:"5px",background:"#F0FEED"}}
                              value={minExp}
                              onChange={onChangeminExp}
                            />:null }
                            { withExpBox == true ?<Input
                              type="text"
                              className="form-control mt-2"
                              id="nameControlInput"
                              placeholder="Max Experience(1 Year)"
                              style={{ borderStyle: "outset",marginBottom:"5px",background:"#F0FEED"}}
                              value={maxExp}
                              onChange={onChangemaxExp}
                            />:null }
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="5">
                      <b style={{fontSize:"14px"}}>Currency</b>
                      </AccordionHeader>
                      <AccordionBody accordionId="5">
                        <FormGroup check>
                          <Input type="checkbox" onClick={onClickCheckPhp} checked={isCheckPhp}  style={{border: "1px solid green"}}/>
                          <Label >
                            PHP
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" onClick={onClickCheckUsd} checked={isCheckUsd}  style={{border: "1px solid green"}}/>
                          <Label >
                            USD
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" onClick={onClickCheckSgd} checked={isCheckSgd}  style={{border: "1px solid green"}}/>
                          <Label >
                            SGD
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" onClick={onClickCheckAud} checked={isCheckAud}  style={{border: "1px solid green"}}/>
                          <Label >
                            AUD
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" onClick={onClickCheckHkd} checked={isCheckHkd}  style={{border: "1px solid green"}}/>
                          <Label >
                            HKD
                          </Label>
                        </FormGroup>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="6">
                        <b style={{fontSize:"14px"}}>Salary Basis</b>
                      </AccordionHeader>
                      <AccordionBody accordionId="6">
                        <FormGroup check>
                          <Input type="checkbox" onClick={onClickCheckMonthly} checked={isCheckmonthly}  style={{border: "1px solid green"}}/>
                          <Label >
                            Monthly
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" onClick={onClickCheckWeekly} checked={isCheckWeekly}  style={{border: "1px solid green"}}/>
                          <Label >
                            Weekly
                          </Label>
                        </FormGroup><FormGroup check>
                          <Input type="checkbox" onClick={onClickCheckDaily} checked={isCheckDaily}  style={{border: "1px solid green"}}/>
                          <Label >
                            Daily
                          </Label>
                        </FormGroup>
                      </AccordionBody>
                    </AccordionItem>
                  </UncontrolledAccordion>
                  <Input
                    type="number"
                    className="form-control mt-2"
                    id="nameControlInput"
                    placeholder="Min Salary Offer"
                    style={{ borderStyle: "outset",marginBottom:"5px",background:"#F0FEED"}}
                    value={salaryOffer}
                    onChange={onChangesalaryOffer}
                  />
                  <Input
                    type="number"
                    className="form-control"
                    id="nameControlInput"
                    placeholder="Max Salary Offer"
                    style={{ borderStyle: "outset",marginBottom:"5px",background:"#F0FEED"}}
                    value={maxsalaryOffer}
                    onChange={onChangemaxsalaryOffer}
                  />
                  <Input
                    type="text"
                    className="form-control"
                    id="nameControlInput"
                    placeholder="Working Hours"
                    style={{ borderStyle: "outset",marginBottom:"5px",background:"#F0FEED", marginTop: "1%"}}
                    value={workingHours}
                    onChange={onChangeworkingHours}
                  />
                  <Input
                    type="text"
                    className="form-control"
                    id="nameControlInput"
                    placeholder="Working Days"
                    style={{ borderStyle: "outset",marginBottom:"5px",background:"#F0FEED"}}
                    value={workingDays}
                    onChange={onChangeworkingDays}
                  />
                  <Input
                    type="text"
                    className="form-control"
                    id="nameControlInput"
                    placeholder="Assigned Work Location"
                    style={{ borderStyle: "outset",marginBottom:"5px",background:"#F0FEED"}}
                    value={workLocation}
                    onChange={onChangeworkLocation}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card className="mt-1" style={{ borderRadius: 5, borderColor: "#ffffff"}}>
                <CardBody>
                  <Label className="mb-2" style={{fontSize: "18px", fontWeight: "bold"}}>Job Description</Label> 
                  <Row className="mt-1">
                    <Input
                      type="textarea"
                      className="form-control"
                      id="nameControlInput"
                      placeholder="Description"
                      style={{ borderStyle: "outset",marginBottom:"5px",background:"#F0FEED"}}
                      value={jobDesc}
                      onChange={onChangejobDesc}
                    />
                  </Row>

                  <Label className="mb-2 mt-3" style={{fontSize: "18px", fontWeight: "bold"}}>Skills Required</Label> 
                  <Row className="mt-2">
                    <Input
                      type="text"
                      className="form-control"
                      id="nameControlInput"
                      placeholder="Skill Required"
                      style={{ borderStyle: "outset",marginBottom:"5px",background:"#F0FEED"}}
                      value={addSkills}
                      onChange={onChangeskillRequird0}
                    />
                    <button type="submit" className="btn btn-link ml-auto" style={{ width:"10px", /* marginLeft:"415px", */ height:"40px"}}  onClick={onClickAddSKill}>
                      <Label className="success" style={{ marginLeft: "-7px", fontSize:"25px" , marginTop:"-10px",color:"green"}}>
                        +
                      </Label>
                    </button>
                    <div className='overflow-y'>
                      <Col lg={12}>
                        <div style={{marginLeft: "1%", fontSize : "14px"}} >
                          {skillList.map((itm, key)=> 
                            <div className="job-detail-desc mt-2" /* key={d.id } */>
                              <ul className="job-detail-list list-unstyled mb-0 text-muted">
                                {/* <i className="mdi mdi-circle">  */}
                                <Label job-detail-list list-unstyled mb-0 text-muted style={{fontSize: "14px"}} ><b onClick={ ()=> handleDeleteSkills(itm.id)}>(-)</b>&nbsp;&nbsp;&nbsp;{itm.skill}</Label>
                                {/* </i> */}
                              </ul>
                            </div>
                          )}
                        </div>
                      </Col>
                    </div>
                  </Row>
                  {/* <h5 className="modal-title mt-5" id="staticBackdropLabel">
                    JOB RESPONSIBILITIES
                  </h5> */}
                  <Label className="mb-2 mt-3" style={{fontSize: "18px", fontWeight: "bold"}}>Job Responsibilities</Label> 
                  <Row className="mt-2">
                    <Input
                      type="text"
                      className="form-control"
                      id="nameControlInput"
                      placeholder="Job Responsibilities"
                      style={{ borderStyle: "outset",marginBottom:"5px",background:"#F0FEED"}}
                      value={addRespnsiblty}
                      onChange={onChangejobRespo1}
                    />
                    <button type="submit" className="btn btn-link ml-auto" style={{ width:"10px", /* marginLeft:"415px", */ height:"40px"}}  onClick={onClickjobRespoAdd}>
                      <Label style={{ marginLeft: "-7px", fontSize:"25px" , marginTop:"-10px",color:"green"}}>
                        +
                      </Label>
                    </button>
                    <div className='overflow-y'>
                      <Col lg={12}>
                        <div style={{marginLeft: "1%", fontSize : "14px"}} >
                          {jobRespList.map((itm, key)=> 
                            <div className="job-detail-desc mt-2" /* key={d.id } */>
                              <ul className="job-detail-list list-unstyled mb-0 text-muted">
                                  <Label job-detail-list list-unstyled mb-0 text-muted style={{fontSize: "14px"}} ><b onClick={ ()=> handleDeleteResponse(itm.id)}>(-)</b>&nbsp;&nbsp;&nbsp;{itm.responsibility}</Label>
                              </ul>
                            </div>
                          )}
                        </div>
                      </Col>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card className="mt-3" style={{ borderRadius: 5, borderColor: "#ffffff"}}>
                <CardBody>
                  <UncontrolledAccordion
                    defaultOpen={[
                        '1',
                    ]}
                    stayOpen
                    className="mt-4"
                    // style={{ fontSize: "14px", fontWeight: "bold"}}
                  >
                    <AccordionItem>
                      <AccordionHeader targetId="1">
                        <b style={{fontSize:"14px"}}>Company Profile</b>
                      </AccordionHeader>
                      <AccordionBody accordionId="1">
                        <FormGroup check>
                          <Input type="checkbox" onClick={onClickCheckBoxcompanyName}  style={{border: "1px solid green"}}/>
                          <Label >
                            Company Name
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input type="checkbox" onClick={onClickCheckBoxconfidential}  style={{border: "1px solid green"}}/>
                          <Label >
                            Confidential
                          </Label>
                        </FormGroup>
                      </AccordionBody>
                    </AccordionItem>
                  </UncontrolledAccordion>
                  <FormGroup style={{marginTop:"10px"}}>
                  <Label style={{fontSize: "18px", fontWeight: "bold"}}> Company Industry</Label>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="nameControlInput"
                    placeholder="Company Industry"
                    style={{ borderStyle: "outset",marginBottom:"5px",background:"#F0FEED"}}
                    // value={jobpostingNote}
                    value={compIndustry}
                    onChange={onchangeCompIndustry}
                  />
                  </FormGroup>
                  <FormGroup style={{marginTop:"10px"}}>
                  <Label style={{fontSize: "18px", fontWeight: "bold"}}> Job Posting End Date</Label>
                  <Input
                    type="date"
                    className="form-control"
                    id="nameControlInput"
                    placeholder="MM-DD-YYYY"
                    style={{ borderStyle: "outset",marginBottom:"5px",background:"#F0FEED"}}
                    value={jobPostingEndDate}
                    onChange={onChangejobPostingEndDate}
                  />
                  </FormGroup>
                  
                  <FormGroup switch>
                  <Label style={{fontSize: "14px"}}> Post no. of application submitted</Label>
                    <Input
                      type="switch"
                      checked={switch1}
                      style={{border: "1px solid green"}}
                      onClick={() => {
                        setswitch1(!switch1);
                      }}
                    />
                  </FormGroup>
                  
                  <FormGroup switch>
                    <Label style={{fontSize: "14px"}}> Urgent Job Posting Requirements</Label>
                    <Input
                      type="switch"
                      checked={switch2}
                      style={{border: "1px solid green"}}
                      onClick={() => {
                        setswitch2(!switch2);
                      }}
                    />
                  </FormGroup>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="nameControlInput"
                    placeholder="Job Posting Note"
                    style={{ borderStyle: "outset",marginBottom:"5px",background:"#F0FEED"}}
                    value={jobpostingNote}
                    onChange={onChangejobpostingNote}
                  />
                  
                  <button disabled={disableSave} type="submit" className="btn btn-success w-100 mt-3"  onClick={oncheckJobPost}>
                    Post a Job
                  </button>
                  <button type="submit" className="btn w-100 mt-2"  onClick={onClickViewJobPost} style={{ /* backgroundColor: "#AEC51E", */ color: "#000000", border: "1px solid green"}}>
                    View Job Post
                  </button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div
        className="modal fade"
        id="validateJob"
        tabIndex="-1"
        aria-labelledby="validateJob"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={validateJob} toggle={openModal} centered>
            <ModalBody className="p-5" style={{backgroundColor: "#fcffb9"}}>
              <Row>
                <Col sm={12}>
                  <p style={{ alignItems: "center", fontSize: "14px", color : "#000000"/* , marginLeft: "2%" */}}>To experience the full benefits of JobSpace features.</p>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={12}>
                  <p style={{textAlign: "justify", fontSize: "14px", color : "#000000"}}>Complete your Registration by uploading the Company details and documents needed within Seven (7) days. Otherwise your job posted will be deactivated, and you cannot create another job posting</p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={12}>
                  <p style={{textAlign: "justify", fontSize: "14px", color : "#000000", marginLeft: "25%"}}>Go to <Link style={{color: "green"}} to="/companyRegisterview">My Profile </Link>to update.</p>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateJobPosting;
