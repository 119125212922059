import React from "react";

// import Layout1 from "../pages/Home/Layout1/Layout1";
// import Layout2 from "../pages/Home/Layout2/Layout2";
// import Layout3 from "../pages/Home/Layout3/Layout3";

//Company Section
import AboutUs from "../pages/Company/AboutUs/AboutUs";
import Services from "../pages/Company/Services/Services";
import Team from "../pages/Company/Team/Team";
import Pricing from "../pages/Company/Pricing/Pricing";
import PrivacyAndPolicy from "../pages/Company/PrivacyAndPolicy/PrivacyAndPolicy";
import Faqs from "../pages/Company/Faqs/Faqs";

//Jobs Section
import JobList from "../pages/Jobs/JobList/JobList";
import JobList2 from "../pages/Jobs/JobList2/JobList2";
import JobGrid from "../pages/Jobs/JobGrid/JobGrid";
import JobGrid2 from "../pages/Jobs/JobGrid2/JobGrid2";
import JobDetails from "../pages/Jobs/JobDetails/JobDetails";
import JobsCategories from "../pages/Jobs/JobsCategories/JobsCategories";
import JobDetailsDescription from "../pages/Jobs/JobDetails/JobDetailsDescription";
import JobVacancy from "../pages/Jobs/JobGrid/JobVacancy";

//Candidate and Company Section
import CandidateList from "../pages/CandidateAndCompany/CandidateList/CandidateList";
import CandidateGrid from "../pages/CandidateAndCompany/CandidateGrid/CandidateGrid";
import CandidateDetails from "../pages/CandidateAndCompany/CandidateDetails/CandidateDetails";
import CompanyList from "../pages/CandidateAndCompany/CompanyList/CompanyList";
import CompanyDetails from "../pages/CandidateAndCompany/CompanyDetails/CompanyDetails";
import ApplicationPage from "../pages/Home/JobApplication/ApplicationPage";
import AppliedJobs from "../pages/Home/JobApplication/AppliedJobs";
import SuccessScreen from "../pages/Home/JobApplication/SuccessScreen";
import Resume from "../pages/Jobs/JobGrid/Resume";
import ApplicationFormCreate from "../pages/Home/JobApplication/ApplicationFormCreate";
import ApplicationFormEdit from "../pages/Home/JobApplication/ApplicationFormEdit";
import ApplicationFormView from "../pages/Home/JobApplication/ApplicationFormView";
import ApplyResume from "../pages/Home/JobApplication/ApplyResume";
import ApplyCompProf from "../pages/Home/JobApplication/ApplyCompProf";
import CandidateProfileApplication from "../pages/Home/JobApplication/CandidateProfileApplication";

//Blog Section
import Blog from "../pages/Blog/Blog/Blog";
import BlogGrid from "../pages/Blog/BlogGrid/BlogGrid";
import BlogModern from "../pages/Blog/BlogModern/BlogModern";
import BlogMasonary from "../pages/Blog/BlogMasonary/BlogMasonary";
import BlogDetails from "../pages/Blog/BlogDetails/BlogDetails";
import BlogAuther from "../pages/Blog/BlogAuther/BlogAuther";

//Contacts
import Contact from "../pages/Contact/Contact";

//AuthPages
import SignIn from "../pages/ExtraPages/SignIn";
import SignUp from "../pages/ExtraPages/SignUp";
import SignOut from "../pages/ExtraPages/SignOut";
import ResetPassword from "../pages/ExtraPages/ResetPassword";
import ComingSoon from "../pages/ExtraPages/ComingSoon";
import Error404 from "../pages/ExtraPages/Error404";
import Components from "../pages/ExtraPages/Components/Components";
import CFVerify from "../pages/ExtraPages/CFVerify";
import ContinueR from "../pages/ExtraPages/ContinueR";
import CompanyRegister from "../pages/ExtraPages/CompanyRegister";
import CompanyRegisterView from "../pages/ExtraPages/CompanyRegisterView";
import CompanySuccess from "../pages/ExtraPages/CompanySuccess";
import CompanyDocuments from "../pages/ExtraPages/CompanyDocuments";
import CompanyDocumentsView from "../pages/ExtraPages/CompanyDocumentsView";
import CompanyDocumentsEdit from "../pages/ExtraPages/CompanyDocumentsEdit";
import DocumentsSuccess from "../pages/ExtraPages/DocumentsSuccess";
import CompanyRegisterEdit from "../pages/ExtraPages/CompanyRegisterEdit";
//profile section(User Profile)
import BookMarkJobPost from "../pages/Profile/BookMarkJobPost/BookMarkJobPost";
import ManageJobs from "../pages/Profile/ManageJobs/ManageJobs";
import BookMarkJobs from "../pages/Profile/BookMarkJobs/BookMarkJobs";
import MyProfile from "../pages/Profile/MyProfile/MyProfile";
import CompanyProfileInfo from "../pages/Profile/MyProfile/CompanyProfileInfo";

//Employer
import CreateJobPosting from "../pages/Employer/CreateJobPosting";
import EmployerJobPostingList from "../pages/Employer/EmployerJobPostingList";
import ListOfCandidate from "../pages/Employer/ListOfCandidate";
import ShortListed from "../pages/Employer/ShortListed";
import KeepWarmCandidates from "../pages/Employer/KeepWarmCandidates";
import NotQualifiedCandidates from "../pages/Employer/NotQualifiedCandidates";
import PreScreenCandidates from "../pages/Employer/PreScreenCandidates";
import EmployerValidation from "../pages/Employer/EmployerValidation";
import JobDescEmployer from "../pages/Employer/JobDescEmployer";


//Home Section
const Layout1 = React.lazy(() => import('../pages/Home/Layout1/Layout1'));
const Layout2 = React.lazy(() => import('../pages/Home/Layout2/Layout2'));
const Layout3 = React.lazy(() => import('../pages/Home/Layout3/Layout3'));

const userRoutes = [
  //profile Section(User Profile)
  { path: "/bookmarkjobpost", component: <BookMarkJobPost /> },
  { path: "/myprofile", component: <MyProfile /> },
  { path: "/companyprofile", component: <CompanyProfileInfo /> },
  { path: "/bookmarkjobs", component: <BookMarkJobs /> },
  { path: "/managejobs", component: <ManageJobs /> },

  //Components Section(Extra Pages)
  { path: "/components", component: <Components /> },

  //Contact
  { path: "/contact", component: <Contact /> },

  // Blog Section
  { path: "/blogauther", component: <BlogAuther /> },
  { path: "/blogdetails", component: <BlogDetails /> },
  { path: "/blogmodern", component: <BlogModern /> },
  { path: "/blogmasonary", component: <BlogMasonary /> },
  { path: "/bloggrid", component: <BlogGrid /> },
  { path: "/blog", component: <Blog /> },

  //Candidate and Company Section
  { path: "/companydetails", component: <CompanyDetails /> },
  { path: "/companylist", component: <CompanyList /> },
  { path: "/candidatedetails", component: <CandidateDetails /> },
  { path: "/candidategrid", component: <CandidateGrid /> },
  { path: "/candidatelist", component: <CandidateList /> },
  { path: "/ApplicationPage", component: <ApplicationPage /> },
  { path: "/Resume", component: <Resume /> },
  { path: "/AppliedJobs", component: <AppliedJobs /> },
  { path: "/SuccessScreen", component: <SuccessScreen /> },
  { path: "/ApplicationFormCreate", component: <ApplicationFormCreate /> },
  { path: "/ApplicationFormEdit", component: <ApplicationFormEdit /> },
  { path: "/ApplicationFormView", component: <ApplicationFormView /> },
  { path: "/ApplyResume", component: <ApplyResume /> },
  { path: "/ApplyCompProf", component: <ApplyCompProf /> },
  { path: "/CandidateProfileApplication", component: <CandidateProfileApplication /> },

  //Jobs Section
  { path: "/JobDetailsDescription", component: <JobDetailsDescription /> },
  { path: "/jobscategories", component: <JobsCategories /> },
  { path: "/jobdetails", component: <JobDetails /> },
  { path: "/jobgrid2", component: <JobGrid2 /> },
  { path: "/jobgrid", component: <JobVacancy /> },
  { path: "/joblist2", component: <JobList2 /> },
  { path: "/joblist", component: <JobList /> },

  //Company Section
  { path: "/faqs", component: <Faqs /> },
  { path: "/privacyandpolicy", component: <PrivacyAndPolicy /> },
  { path: "/pricing", component: <Pricing /> },
  { path: "/team", component: <Team /> },
  { path: "/services", component: <Services /> },
  { path: "/aboutus", component: <AboutUs /> },

  //Home Section
  { path: "/layout3", component: <Layout3 /> },
  { path: "/layout2", component: <Layout1 /> },
  { path: "/", component: <JobVacancy /> },

   //Employer
   { path: "/CreateJobPosting", component: <CreateJobPosting /> }, // post a job
   { path: "/EmployerJobPostingList", component: <EmployerJobPostingList /> }, // active job posting
   { path: "/ListOfCandidate", component: <ListOfCandidate /> }, // candidate list
   { path: "/ShortListed", component: <ShortListed /> }, //short list
   { path: "/NotQualifiedCandidates", component: <NotQualifiedCandidates /> }, //view NotQualifiedCandidates
   { path: "/KeepWarmCandidates", component: <KeepWarmCandidates /> }, //view KeepWarmCandidates
   { path: "/PreScreenCandidates", component: <PreScreenCandidates /> }, //view PreScreenCandidates
   { path: "/EmployerValidation", component: <EmployerValidation /> }, //view validation
   { path: "/JobDescEmployer", component: <JobDescEmployer /> },//view JobDescEmployer
   
   

   //Extra Pages
   { path: "/cfverify", component: <CFVerify /> }
];

const authRoutes = [
  { path: "/error404", component: <Error404 /> },
  { path: "/comingsoon", component: <ComingSoon /> },
  { path: "/resetpassword", component: <ResetPassword /> },
  { path: "/signout", component: <SignOut /> },
  { path: "/signup", component: <SignUp /> },
  { path: "/signin", component: <SignIn /> },
  { path: "/cfverify", component: <CFVerify /> },
  { path: "/ContinueR", component: <ContinueR /> },
  { path: "/CompanyRegister", component: <CompanyRegister /> },
  { path: "/CompanyRegisterView", component: <CompanyRegisterView /> },

  { path: "/CompanySuccess", component: <CompanySuccess /> },
  { path: "/CompanyDocuments", component: <CompanyDocuments /> },
  { path: "/CompanyDocumentsView", component: <CompanyDocumentsView /> },
  { path: "/CompanyDocumentsEdit", component: <CompanyDocumentsEdit /> },

  { path: "/DocumentsSuccess", component: <DocumentsSuccess /> },
  { path: "/CompanyRegisterEdit", component: <CompanyRegisterEdit /> },
];
export { userRoutes, authRoutes };
