import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AppConfiguration } from "read-appsettings-json";
import moment from 'moment';
//import { GetRequest, PostRequest } from '../../../../noser.dataaccess';
import { GetRequest, PostRequest } from '../../noser-dataaccess';
//import useAxios from './useAxios';
//import ValidationInfo from './ValidationInfo';

const useForm = (callback  ) => {
  const [values, setValues] = useState({
    email             : "",//
    username          : "",//
    name              : "",
    company           : "",//
    contact           : "",
    address           : "",
    password          : "",//
    confirm_password  : "",

    emailCheck        : "",
    userIdCheck       : "",
    reguserid         : "",
    date              : "",
    defaultId         : "",
    parent            : "",
    parentID          : "",
    companyID         : "",

  });

  const [wdata,setwdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorVal, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [valueDataRegister, setValueDataRegister] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [registerData, setRegisterData] = useState(JSON.parse(sessionStorage.getItem("handleGetRegister")));
  const [parent, setParent] = useState("");
  const [parentID, setParentID] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isEmployer, setIsEmployer] = useState(false);
  const [dissableBtn, setDissableBtn] = useState(true);
  const [employer,setemployer] =useState("0");
  //const [candidate,setCandidate] =useState("0");
  const [agree, setAgree]=useState("");
  const [disAgree ,setDisAgree]=useState("");
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
    ...values,
    [name]: value
    });
  };

  const handleCheckBox =(event)=>{
    console.log(event.target.checked)
    if(event.target.checked === true){
      setIsChecked(true)
      setDissableBtn(false)
      setAgree("YES")
      console.log("yes")
    }else{
      setIsChecked(false)
      setDissableBtn(true)
      setAgree("NO")
      console.log("no")
    }
  }
  const handleEmployer=(event)=>{
    console.log(event.target.checked)
    if(event.target.checked === true){
      setIsEmployer(true)
      setemployer("1")
      //setCandidate("0")
    }else{
      setIsEmployer(false)
      setemployer("0")
     // setCandidate("1")
    }
  }


  

  const handleSubmit = (event) => {
    console.log("submit")
    // console.log(valueDataRegister)
    event.preventDefault();
    setErrors(validation(values));
    setIsSubmitting(true)
  };

  /* const handleGetRegister = async() => {
    let response = await GetRequest({
      url :"http://128.199.133.50:9091/" + "action/get",
      params : {
        "dbname" 	:	"NOSERDBDEV",
        "_collection"	: 	"Client",
        "filter_data" : {}
      }
    })
    const data = response["ResultList"]
    setValueDataRegister(data)
    console.log("w1")
    console.log(data)
    sessionStorage.setItem("handleGetRegister", JSON.stringify(data))
    handlew();

  } */


  const validation =()=>{
    let errors = {};
  
    wdata.map(function(item,idx){
      console.log(item)
      if (item.email === values.email) { // if exist
        errors.email = 'email already exist';
      }
      /* if (item.userId === values.username) { // if exist
        errors.username = 'username already exist';
      } 
      if (item.company === values.company) { // if exist
        errors.company = 'company already exist';
      }  */
    })

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!values.email) {
      errors.email = 'This field is required';
    } else if (!pattern.test(values.email)) {
      errors.email = 'Email address is invalid';
    }  

   /*  if (!values.username) {
      errors.username = 'This field is required';
    } */

    if (!values.firstName) {
        errors.firstName = 'This field is required ';
    };
    if (!values.lastName) {
      errors.lastName = 'This field is required ';
    };

    /* if (!values.company) {
        errors.company = 'This field is required';
    } */

    if (!values.contact) {
        errors.contact = 'This field is required';
    }

    /* if (!values.address) {
        errors.address = 'This field is required';
    } */

    
    if (!values.password) {
      errors.password = 'This field is required';
    } else if (values.password.length < 6) {
      errors.password = 'Password needs to be 6 characters or more';
    }

    if (!values.confirm_password) {
      errors.confirm_password = 'This field is required';
    } else if (values.confirm_password !== values.password) {
      errors.confirm_password = 'Passwords do not match';
    }

    return errors;
  }

  useEffect(
    () => {
      //registerData[i]["email"]
      handleGetUserCollection();
      
      if (Object.keys(errors).length === 0 && isSubmitting) {
        //setIsSubmitting(false)
        callback();
        // console.log("useEffect IF")
        let d = new Date();
        let createddate = moment(d).format('MM/DD/YYYY');
        let w = d.toLocaleTimeString()
        //User for Client ID
        let today = new Date()
        let clientId = moment(today).format('MMYYYYhhmmss');
        let candidate = ""
        if(employer === "1"){
          candidate = "0"
        }else{
          candidate = "1"
        }
        
        let dataList = {
          "email"                   : values.email,
          "userId"                  : clientId,
          "firstName"               : values.firstName,
          "lastName"                : values.lastName,
          "contact"                 : values.contact,
          "password"                : values.password,
          "isEmployer"              : employer,
          "isCandidate"             : candidate,
          "createddate"             : createddate,
          "modifieddate"            : createddate,
          "timestamp"               : w,
          "isDeleted"               : "0",
          "isDraft"                 : "0",
          "roleId"                  : "",
          "agreeTermsAndCondition"  : agree
        }
         console.log("_data")
         console.log(dataList)
        sessionStorage.setItem("userdata", JSON.stringify([dataList]))
        sessionStorage.setItem("userdata1", JSON.stringify(dataList))

       /* const params = {
          // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
          "_collection" : "jobSignUp",
          "doc_data" : dataList
        }
         console.log("Client")
         console.log(params)
        sessionStorage.setItem("userdata", JSON.stringify([dataList]))
       


        axios
        .post(AppConfiguration.Setting().mongoJobspace + "action/add", params )
        .then(res => {
          console.log("res")
          console.log(res.data)
          // console.log(`welcome to Noser Technology Ph`)
          // console.log(res)
          // sessionStorage.setItem("Client", JSON.stringify(_data))
          // sessionStorage.setItem("uname", _data[0] ['name'])
          //commenitemsDatated by Clifford 02/09/2022
          //no need to get id from Client colelction
          //id is now generated and pass as parameter to this.handleParentCompany(parentId)
         // handleGetCompany();          //code below replaces above this.handleGetCompany()
          ////////////this.handleParentCompany(clientId)
          //this.handleGetCompanyID()
          // window.location.reload(false);
        })
        .catch(error=>{
           console.log("error: " + error)
           console.log("ewwow")
        }) */
      }
    },
    [errors]
  );

  const handleGetUserCollection=async(e)=>{
    console.log("handle change on input start")
    let filter_data = {
      "isDeleted" : "0", 
      //"email"     : values.email, 
      //"password"  : value3
    }
    
    const Params = {
      "_collection" : "UserCollection",
      "filter_data" : filter_data
    }
    console.log("Params")
    console.log(Params)
    await axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {

      let dataTmp = []

      let dataa = res.data
      console.log(res)
      console.log("data")
      console.log(dataa)
      let newstr2=""
      dataa.UserCollection.map(function(itm,i){
          let strJson = "{" + itm.substring(46, itm.length)
          let strJson2 = itm.substring(18, itm.length)
          newstr2 = strJson2.split("')")[0]
          let json = JSON.parse(strJson.replace(/'/g,'"'));
          json.headerId = newstr2
          dataTmp.push(json)
      })    
     setwdata(dataTmp)
     
    })

    console.log("handle change on input start")
  }



  return { handleChange, handleSubmit, values, errors, errorVal,isChecked,isEmployer,dissableBtn,handleCheckBox,handleEmployer,modal,openModal ,handleGetUserCollection};
};

export default useForm;