import React, { useState,useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Modal,
  ModalBody,
  //Form,
  FormGroup,
  Label,
  Spinner,
  /* Alert, */
  Button
} from "reactstrap";
import axios from 'axios';
import { AppConfiguration } from "read-appsettings-json";
import moment from 'moment';
//import useForm from "./useForm";
import InputGroup from 'react-bootstrap/InputGroup';
import Resizer from "react-image-file-resizer";




import {  Card,   CardBody } from "reactstrap";

import lightLogo from "../../assets/images/logo-light.png";
import darkLogo from "../../assets/images/logo-dark.png";

import signUpImage from "../../assets/images/auth/sign-up.png";
//import useForm from "../../wcomponents/SignUp/useForm";
import Icon from "../../wcomponents/Icon/Icon";
import "../../wcomponents/w.css"
import NavBar from "../../Layout/CommonLayout/NavBar"//"../CommonLayout/NavBar";
import ScrolltoTop from "../../components/ScrolltoTop";
import TopBar from "../../Layout/CommonLayout/TopBar";
import { setSelection } from "@testing-library/user-event/dist/cjs/event/selection/setSelection.js";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { FormControl } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
// import Alert from 'react-bootstrap/Alert';
import { useForm } from "react-hook-form";

import {Alert} from "reactstrap";

const CompanyDocumentsView = ({submitForm }) => {
    const [userdata, setUserdata] = useState(JSON.parse(sessionStorage.getItem("userdata")));
    const navigate = useNavigate();
    const [pagePath, setPagePath] = useState(true)
    const [show, setShow] = useState(false);
    const [dissableBtn, setDissableBtn] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const { errors, register, handleSubmit, control } = useForm();
    const [secCertificate,setSecCertififacte] =useState();
    const [companyArticles, setcompanyArticles] = useState("")
    const [BirCertificate, setBirCertificate] = useState("")
    const [businessPermit, setbusinessPermit] = useState("")
    const [companyOwnerId, setcompanyOwnerId] = useState("")
    const [doleRegistered, setdoleRegistered] = useState("")
    const [peza,setPeza] = useState("")
    const [headerId, setheaderId] = useState("")
    const [arr1, setarr] = useState([])
    
    const [isshow, setisshow] = useState(false)
    const [color, setcolor] = useState("")
    const [message, setmessage] = useState("")
    const [fade, setfade] = useState("")
    const [alerttype, setalerttype] = useState("")
    const [disableSave, setdisableSave] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(
        () => {
            console.log("CompanyDocuments")
            setLoading(true)
          handleGetCompanyDocs()
        },
        []
    );
    const handleGetCompanyDocs = async() => {
        console.log("handleGetCompanyDocs")
        let filter_data = {
          //"isDeleted" : "0", 
          "userId" : userdata[0].userId,
          //"isDeleted" : "0",
         /*  "firstName" : firstName,
          "lastName"  : lastName, */
        }
        
        const Params = {
          "_collection" : "CompanyDocuments",
          "filter_data" : filter_data
        }
        console.log("Params")
        console.log(Params)
        console.log("s")
         axios
         .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {

            console.log("res.data")
            console.log(res)
    
            let dataTmp = []
            let a
            let dataaa = res.data
            console.log("data")
            console.log(dataaa)
            let newstr2=""
            dataaa.CompanyDocuments.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let strJson2 = itm.substring(18, itm.length)
                newstr2 = strJson2.split("')")[0]
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                json.headerId = newstr2
                console.log(json.headerId)
                //setheaderId(json.headerId)
                dataTmp.push(json)
                a = json
            })    
            console.log(dataTmp)
             console.log(dataTmp[0].secCertificate.name)
            setheaderId(dataTmp[0].headerId)
            setSecCertififacte(dataTmp[0].secCertificate.name)
            setcompanyArticles(dataTmp[0].companyArticles.name)
            setBirCertificate(dataTmp[0].birCertificate.name)
            setbusinessPermit(dataTmp[0].businessPermit.name)
            setcompanyOwnerId(dataTmp[0].companyOwnerId.name)
            setdoleRegistered(dataTmp[0].doleRegistered.name)
            setPeza(dataTmp[0].peza.name)
            setLoading(false)
        })
        .catch(error =>{
            alert("error: " + error)
        })
    };
    const handleCheckBox =(event)=>{
        console.log(event.target.checked)
        if(event.target.checked === true){
            setIsChecked(true)
            setDissableBtn(false)
            //setAgree("YES")
            //console.log("yes")
          }else{
            setIsChecked(false)
            setDissableBtn(true)
            //setAgree("NO")
            //console.log("no")
          }
    };

    const updateDoc=()=>{
        setLoading(true)
        navigate("/CompanyDocumentsEdit")
    }

    return (
        <React.Fragment  >
         {/*  <TopBar /> */}
           <NavBar />
          <div > 
            
                  <Container fluid='true' >
                   
                   <Row className="mt-3"></Row>
                   <Row className="mt-3"></Row>
                   
                    <Row className="justify-content-center">
                    
                    <Col sm={5} >
                        <div className="CRcard-two">
                           <Form /* onSubmit={handleSubmit(onFormSubmit)} */>
                                <Alert isOpen={isshow} color={color} fade={fade} className={isshow ? 'display-block' : 'display-none'}>
                                    <div className={color}></div> 
                                    {message}
                                </Alert>
                                <Row>
                                    <Form.Text className=" ml5percent mt-4 ">To ensure the legitimacy of your company and the job hiring you posted,</Form.Text>
                                </Row>
                                <Row>
                                    <Form.Text className="ml5percent  ">below list of company documents are required</Form.Text>
                                </Row>
                                <Row>
                                <h4 className=" ccpLabel mt-4 mb-2">Uploaded Company Documents (view)</h4>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <Form.Label className="ml5percent mt-2">SEC Certificate of Registration</Form.Label>
                                    </Col>
                                    <Col sm={5}>
                                        <Form.Control
                                            //style={{width: "280px"}}
                                            className="" type="text" size="sm"
                                            name="secCertificate"
                                            defaultValue={secCertificate} 
                                            //onChange={(e)=>secFile(e)}
                                            readOnly
                                        />
                                    </Col>
                                    <Col sm={1}></Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={6}>
                                        <Form.Label className="ml5percent mt-2">Company Articles of Incorporation</Form.Label>
                                    </Col>
                                    <Col sm={5}>
                                        <Form.Control
                                            //style={{width: "280px"}}
                                            className="" type="text" size="sm"
                                            name="companyArticles"
                                            defaultValue={companyArticles} 
                                            //onChange={(e)=>secFile(e)}
                                            readOnly
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={6}>
                                        <Form.Label className="ml5percent mt-2">BIR Certificate of Registration </Form.Label>
                                    </Col>
                                    <Col sm={5}>
                                        <Form.Control  
                                            //style={{width: "280px"}}
                                            className="" type="text" size="sm"
                                            name="BirCertificate"
                                            defaultValue={BirCertificate} 
                                            //onChange={(e)=>secFile(e)}
                                            readOnly
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={6}>
                                        <Form.Label className="ml5percent mt-2">Business Permit</Form.Label>
                                    </Col>
                                    <Col sm={5}>
                                            <Form.Control  
                                                //style={{width: "280px"}}
                                                className="" type="text" size="sm"
                                                name="businessPermit"
                                                defaultValue={businessPermit} 
                                                //onChange={(e)=>secFile(e)}
                                                readOnly
                                            />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={6}>
                                        <Form.Label className="ml5percent mt-2">Company Owner/President ID </Form.Label>
                                    </Col>
                                    <Col sm={5}>
                                        <Form.Control   
                                           //style={{width: "280px"}}
                                           className="" type="text" size="sm"
                                           name="companyOwnerId"
                                           defaultValue={companyOwnerId} 
                                           //onChange={(e)=>secFile(e)}
                                           readOnly
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={6}>
                                        <Form.Label className="ml5percent mt-2">DOLE Registration</Form.Label>
                                    </Col>
                                    <Col sm={5}>
                                        <Form.Control 
                                             //style={{width: "280px"}}
                                           className="" type="text" size="sm"
                                           name="doleRegistered"
                                           defaultValue={doleRegistered} 
                                           //onChange={(e)=>secFile(e)}
                                           readOnly
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={6}>
                                        <Form.Label className="ml5percent mt-2">PEZA/Free Port Certificate (Optional)</Form.Label>
                                    </Col>
                                    <Col sm={5}>
                                        <Form.Control
                                            //style={{width: "280px"}}
                                            className="" type="text" size="sm"
                                            name="peza"
                                            defaultValue={peza} 
                                            //onChange={(e)=>secFile(e)}
                                            readOnly
                                        />
                                    </Col>
                                </Row>
                                {/* <div className=" mb-1 margincheck form-check mt-5">
                                    <Input
                                    
                                      className=" form-check-input"
                                      type="checkbox"
                                      //id="flexCheckDefault"
                                      onChange={handleCheckBox}
                                      //value={isChecked}
                                      name="isChecked"
                                      //isValid
                                    />
                                    <p
                                      className=" checktxt form-check-label"
                                      htmlFor="flexCheckDefault"
                                     
                                    >
                                      By clicking �SUBMIT� you confirm that the details and documents
                                        stated and uploaded are correct  and true. You also agree to 
                                        JobSpace�s Privacy  Policy, on collection of personal data ensures
                                        veracity of corporate documents prior to use of JobSpace
                                        job posting features{" "}
                                    </p>
                                    <text
                                      className=" checktxt form-check-label"
                                      htmlFor="flexCheckDefault"
                                     
                                    >
                                        
                                    </text>
                                </div> */}
                                <div className="center">
                                    {/* <Button
                                        type="submit"
                                        className="btn btn-hover mr-auto mb-5 mt-5"
                                        color="success"
                                        size="sm"
                                        onClick={handleSubmitFILES}
                                        >
                                            SUBMIT
                                    </Button> */}
                                    <Button
                                        //type="submit"
                                        style={{marginLeft : "90px"}}
                                        className="btn btn-hover mr-auto mb-3 mt-5"
                                        color="success"
                                        size="sm"
                                        onClick={updateDoc}
                                        //disabled={dissableBtn}
                                    >
                                        
                                        {loading ? <Spinner size="lg" color="light" /> : "UPDATE DOCS"}
                                    </Button>
                                    {/* <Button type="submit"
                                        className="btn btn-hover mr-auto mb-5 mt-5"
                                        color="success"
                                        size="sm"
                                        onClick={updateDoc}
                                        
                                    >Update
                                    </Button> */}
                                    
                                </div>
                                {/* <Button  onClick={copyToClipboard} className="" size="sm" variant="dark">BACK</Button> */}
                               
                           </Form>
                        </div>
                    </Col>

                        
                      
                    </Row>
                    {/* <Alert className="alerthieght" show={isshow} variant="success">
                        <h5 className="successsubmited"> Succesfully submitted</h5>
                        
                            <Button className="btn btn-hover mr-auto mb-5 mt-5"
                            color="danger"
                            size="sm"  onClick={() => setShow(false)} variant="outline-success">
                                Close 
                            </Button>
                        
                    </Alert> */}
                  </Container>
                
                  <ScrolltoTop />
          </div>
        </React.Fragment>
      );

}

export default CompanyDocumentsView;