import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Input, Label, select,AccordionItem,AccordionBody,AccordionHeader,UncontrolledAccordion, FormGroup} from "reactstrap";
import moment from "moment";
//import { Button, Accordion, } from "react-bootstrap";

//Import Images
import { AppConfiguration } from "read-appsettings-json";
import { GetRequest, PostRequest } from '../../noser-dataaccess'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import { Link, useNavigate } from "react-router-dom";
import paginationFactory from 'react-bootstrap-table2-paginator';
// import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import '../../noser-hris.css';

const EmployerJobPostingList = () => {

    const [listOfJobs, setDlistOfJobs] = useState([]);
    const [mainGrid, setmainGrid] = useState([
        {
            "id":"1",
            "postingId":"1",
            "name":"tasyo",
            "title":"tambay",
            "expectedSalary":"100,000",
            "city":"makati",
            "resume":"",
            "ScheduleInterview":"",
            "action2":"",
        },
        {
            "id":"phr-1-2-3",
            "postingId":"phr-1-2-3",
            "name":"dio",
            "title":"food tester",
            "expectedSalary":"40,000",
            "city":"taguig",
            "resume":"",
            "ScheduleInterview":"",
        }
    ]);
    const [companyProfiles, setcompanyProfiles] = useState([]);
    const [jobPosted, setjobPosted] = useState([]);
    const [search, setsearch] = useState("");
    const [statusData, setstatusData] = useState([
       
        {
           "id":"1",
           "label":"New",
           "value":"NW"
        },
        {
            "id":"2",
            "label":"pre-screen",
            "value":"PS"
        },
        {
            "id":"3",
            "label":"keep warm",
            "value":"KW"
        },
        {
            "id":"4",
            "label":"shorlisted",
            "value":"SL"
        },
        {
            "id":"5",
            "label":"Unmatched",
            "value":"Unmatched"
        },
    ]);
    const [jobRef, setjobRef] = useState("");
    const [pstingDateFilter, setpstingDateFilter] = useState("");
    const [jobPostStatus, setjobPostStatus] = useState("");
    const [jobtitlefilter, setjobtitlefilter] = useState("");
    const [endDateFilter, setendDateFilter] = useState("");
    const [applicantLists, setApplicantList] = useState([]);



    const rowClasses = 'custom-row-class-success';


    useEffect(() => {
       // OnLoadScreen()
        GetCompanyProfile()
        GetApplicants()
        let toEdit = JSON.parse(sessionStorage.getItem("jobPostedEdit"))
       /*  if(JSON.parse(sessionStorage.getItem("jobPostedEdit")) !== null){
            GetCandidatesFilterd(toEdit)
        }else{
            GetCandidates()
        } */
        sessionStorage.removeItem("validate")
        sessionStorage.removeItem("JobPostingList")
    },[]);

    const helperBuildCriteria=()=>{

        ///// FOR TRANSAC DATE FILTER SOON
       /*  let checkDateStart = moment(this.state.startDate).format('MM/DD/YYYY');
        let checkDateEnd = moment(this.state.endDate).format('MM/DD/YYYY');
        const checkAll = this.state.deductionSummaryGrid

        let objCheckList = []
        for (let i = 0; i < checkAll.length; i++) {
            //////////////// // console.log(checkAll[i])
            if (checkAll[i]["transactionDate"] >= checkDateStart &&  checkAll[i]["transactionDate"] <= checkDateEnd ){
                objCheckList.push(checkAll[i])
                ////////////// // console.log(objCheckList)
            }else{

            }
        } */

        //let newtransacDate = new Date(this.state.transacDate)
        let _transacDate = ""//moment(newtransacDate).format('MM/DD/YYYY');
        

        let _filterData = ""
       
        let _jobRefNo = ""
        let _jobtitle = ""
        let _postingDate = ""
        let _EndDate = ""
        let _status = "" 

        //let transactionDate = (this.state.transactionDate) ? moment(this.state.transactionDate).format('MM/DD/YYYY') : ""
        
        if (jobRef != "" || jobRef !== "") {
            _jobRefNo = "postingId" + ":" + jobRef + ","
        }
        if (pstingDateFilter != "" || pstingDateFilter !== "") {
            _postingDate = "postingId" + ":" + pstingDateFilter + ","
        }
        if (endDateFilter != "" || endDateFilter !== "") {
            _EndDate = "endDate" + ":" + endDateFilter + ","
        }
        if (jobtitlefilter != "" || jobtitlefilter !== "") {
            _jobtitle = "title" + ":" + jobtitlefilter + ","
        }
        
     /*    if (this.state.docNo != "" || this.state.docNo !== "") {
            _docNo = "docNo" + ":" + this.state.docNo + ","
        }
        if ( this.state.selectedClientName != "" || this.state.selectedClientName !== "" ) {
             _client = "clientName" + ":" + this.state.selectedClientName + ","
        }
        if ( this.state.selectedstatus!= "" || this.state.selectedstatus!== "" ) {
            _status= "status" + ":" + this.state.selectedstatus+ ","
        }
        if ( this.state.filterTranscDate!= "" || this.state.filterTranscDate!== "" ) {
            _transacDate = "transacDate" + ":" + this.state.filterTranscDate+ ","
        } */
      
        _filterData = _jobRefNo + _status + _jobtitle + _postingDate + _EndDate
        //// // console.log("_transacDate _transacDate _transacDate _transacDate")
        //// // console.log(_transacDate)
        return _filterData
    }

    const handleChangeSearch = () => {
        /* if(this.state.payrollDate === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please select payroll date!",
                fade            :   true
            })
            return
        } */

        let _fdata = helperBuildCriteria()
        let _filterData = _fdata.split(",")
        let _objfd = {}

        let jsonData = {};
        for (let i = 0; i < _filterData.length - 1; i++) { 
            let columnName = _filterData[i].split(":")[0]
            _objfd[columnName] = _filterData[i].split(":")[1];
        }
        // // console.log("filter_data")
        // // console.log(_objfd)

        GetFilterResult(_objfd);
    }

    const GetFilterResult = async(_objfd) => {

        let filter_data = {}
        let dataTmp = []

        const Params = {
          "_collection" : "Candidates",
          "filter_data" : _objfd
        }
        // // console.log("Params GetFilterResult")
        // // console.log(Params)
        axios
        .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {

            let data = res.data
            // // console.log("data")
            // // console.log(data)
            let newstr2=""
            data.Candidates.map(function(itm,i){
                //// // console.log("itm")
                //// // console.log(itm)
                let strJson = "{" + itm.substring(46, itm.length)
                let strJson2 = itm.substring(18, itm.length)
                newstr2 = strJson2.split("')")[0]
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                json.headerId = newstr2
                dataTmp.push(json)
            })    
            //// // console.log("dataTmp GetCandidates" )
            //// // console.log(dataTmp)

            setDlistOfJobs(dataTmp)   
            setmainGrid(dataTmp) 
        })
        .catch(error =>{
            alert("error: " + error)
        })
    }
    const GetApplicants = async() =>{
        
        let filter_data = {}
        const _dataTmp = []
        const Params = {
        // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
        "_collection" : "Candidates",
        "filter_data" : {}
        }
        axios
        .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {
        let data = res.data
        data.Candidates.map(function(itm,i){
            const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
            const json = JSON.parse(strJson.replace(/'/g,'"'));
            _dataTmp.push(json)
        })
        // // console.log("_dataTmp")
        // // console.log(_dataTmp)
        setApplicantList(_dataTmp); 
        GetJobPosted(_dataTmp)
        })
    }

    const GetCandidatesFilterd = async(toEdit) => {

        let filter_data = {postingId:toEdit.postingId }
        let dataTmp = []

        const Params = {
          "_collection" : "Candidates",
          "filter_data" : filter_data
        }
        //// // console.log("Params GetCandidatesFilterd")
        //// // console.log(Params)
        axios
        .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {

            let data = res.data
            //// // console.log("data")
            //// // console.log(data)
            let newstr2=""
            data.Candidates.map(function(itm,i){
                //// // console.log("itm")
                //// // console.log(itm)
                let strJson = "{" + itm.substring(46, itm.length)
                let strJson2 = itm.substring(18, itm.length)
                newstr2 = strJson2.split("')")[0]
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                json.headerId = newstr2
                dataTmp.push(json)
            })    
            //// // console.log("dataTmp GetCandidates" )
            //// // console.log(dataTmp)

            setDlistOfJobs(dataTmp)   
            setmainGrid(dataTmp) 
        })
        .catch(error =>{
            alert("error: " + error)
        })
    }

    const GetCandidates = async() => {

        let filter_data = {"isDeleted": "0", }
        let dataTmp = []

        const Params = {
          "_collection" : "Candidates",
          "filter_data" : filter_data
        }
        axios
        .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {

            let data = res.data
            //// // console.log("data")
            //// // console.log(data)
            let newstr2=""
            data.Candidates.map(function(itm,i){
                //// // console.log("itm")
                //// // console.log(itm)
                let strJson = "{" + itm.substring(46, itm.length)
                let strJson2 = itm.substring(18, itm.length)
                newstr2 = strJson2.split("')")[0]
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                json.headerId = newstr2
                dataTmp.push(json)
            })    
            //// // console.log("dataTmp GetCandidates" )
            //// // console.log(dataTmp)

            setDlistOfJobs(dataTmp)   
            setmainGrid(dataTmp) 
        })
        .catch(error =>{
            alert("error: " + error)
        })
    }

    const GetCompanyProfile = async() => {
        let filter_data = {"isDeleted": "0", }
        let dataTmp = []
        const Params = {
          "_collection" : "CompanyProfile",
          "filter_data" : filter_data
        }
        axios
        .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {

            let data = res.data
            let newstr2=""
            data.CompanyProfile.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let strJson2 = itm.substring(18, itm.length)
                newstr2 = strJson2.split("')")[0]
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                json.headerId = newstr2
                dataTmp.push(json)
            })    

            //// // console.log("dataTmp GetCompanyProfile")
            //// // console.log(dataTmp)
            setcompanyProfiles(dataTmp)   
        })
        .catch(error =>{
            alert("error: " + error)
        })
    };

    const GetJobPosted = async(itmList) => {
        const isValidate = JSON.parse(sessionStorage.getItem("userCollection"))
        console.log("isValidate")
        console.log(isValidate)
        let _dataLst = []
        try{
          let resp = await GetRequest({
            url : AppConfiguration.Setting().mongoJobspace + "action/get",
            params : {
              "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
              "_collection" : "JobPosting",
              "filter_data" : {}
            }
          })
          if(resp.status==="1"){
            // // console.log("resp")
            // // console.log(resp)
            // let arr = resp.JobPosting.filter(f => applicantLists.some(item => item.postingId === f.postingId))
            resp.JobPosting.map(function(itm,i){
                let applicants = itmList.filter( x => x.title === itm.title)
                // console.log("applicants.length")
                // console.log(applicants.length)
                _dataLst.push({
                    Urgency : itm.Urgency,
                    active : itm.active,
                    aplicantExp : itm.aplicantExp,
                    applicantType : itm.applicantType,
                    companyId : itm.companyId,
                    companyProfile : itm.companyProfile,
                    createdBy : itm.createdBy,
                    createdDate : itm.createdDate,
                    dept : itm.dept,
                    description : itm.description,
                    education : itm.education,
                    educationalLevel : itm.educationalLevel,
                    endDate : itm.endDate,
                    experienceMax : itm.experienceMax,
                    experienceMin : itm.experienceMin,
                    id : itm.id,
                    isClosed : itm.isClosed,
                    isDeleted : itm.isDeleted,
                    isPosted : itm.isPosted,
                    jobDepartment : itm.jobDepartment,
                    jobPostingNote : itm.jobPostingNote,
                    jobTime : itm.jobTime,
                    keyWords : itm.keyWords,
                    level : itm.level,
                    postingId : itm.postingId,
                    qualifications : itm.qualifications,
                    responsibility : itm.responsibility,
                    salaryBasis : itm.salaryBasis,
                    salaryCurrency : itm.salaryCurrency,
                    salaryMax : itm.salaryMax,
                    salaryMin : itm.salaryMin,
                    skillsExperience : itm.skillsExperience,
                    startDate : itm.startDate,
                    timeStamp : itm.timeStamp,
                    title : itm.title,
                    type : itm.type,
                    updatedBy : itm.updatedBy,
                    updatedDate : itm.updatedDate,
                    workLocation : itm.workLocation,
                    workType : itm.workType,
                    workingDays : itm.workingDays,
                    workingDaysAndHrs : itm.workingDaysAndHrs,
                    workingHours : itm.workingHours,
                    industry : itm.industry,
                    applicants : String(applicants.length),
                })
            })  
            
            setmainGrid(_dataLst.filter(List => isValidate.some(itm => itm.userId === List.companyId)))   
          }
        }
        catch(error){
          // setMessage(error.message)
          // alert("error: " + error.message)
        }
    };

    const handleView=(row)=>{
        //// // console.log("handleView")
        //// // console.log(row)
        sessionStorage.setItem("jobPostedEdit", JSON.stringify(row))
        window.location.href='/CreateJobPosting'
      
    }

 
  
    /* Get items */
    async function OnLoadScreen(){
        // setJobType("")
        // setCategory("")
        // setJobOverviewLocation("")
        // setOfferedSalary("")
        // setPosted("")
        // setExperience("")
        // setGender("0")
        // setIndustry("")
        // setQualification("")
        // setLevel("")
        // setApplied("")
    }

    const rowEvents = {
      /*   onClick: (e, row, rowIndex) => {
            //// // console.log("onClick")
            //// // console.log(rowIndex)
            //handleView(row)
           
        }, */
     
    };

    const handleViewResume=(row)=>{
        //// // console.log("handleViewResume")
        //// // console.log(row)
        sessionStorage.setItem("ListOfCandidate", JSON.stringify(row))
        window.location.href='/ListOfCandidate'
      
    }

    const handleDownloadResume=(row)=>{
        //// // console.log("handleDownloadResume")
        //// // console.log(row)
        //sessionStorage.setItem("jobPostedEdit", JSON.stringify(row))
       // window.location.href='/CreateJobPosting'
      
    }

    const handleSchedInterview=(row)=>{
        //// // console.log("handleSchedInterview")
        //// // console.log(row)
        //sessionStorage.setItem("jobPostedEdit", JSON.stringify(row))
       // window.location.href='/CreateJobPosting'
      
    }

    const onChangeSearch=(e)=>{
        //// // console.log("handleSearch")
        //// // console.log(e.target.value)
        setsearch(e.target.value)
        //// // console.log(listOfJobs)
        if(e.target.value === ""){
            //// // console.log("search === blank")
            setmainGrid(listOfJobs)
        }
        //sessionStorage.setItem("jobPostedEdit", JSON.stringify(row))
       // window.location.href='/CreateJobPosting'
      
    }
    
    const onClickSearch =()=>{
        let newarry = []
        listOfJobs.map(function(d){
         
            if(d.postingId == search){
                //// // console.log("onClickSearch")
                //// // console.log(d)
                setmainGrid([d])
            }
            if(d.title == search){
                //// // console.log("onClickSearch")
                //// // console.log(d)
                setmainGrid([d])
            } 
            if(d.name == search){
                //// // console.log("onClickSearch")
                //// // console.log(d)
                setmainGrid([d])
            } 
            if(d.expectedSalary == search){
                //// // console.log("onClickSearch")
                //// // console.log(d)
                setmainGrid([d])
            } 
            if(d.city == search){
                //// // console.log("onClickSearch")
                //// // console.log(d)
                setmainGrid([d])
            }
          
        })
    }

    const GridDataModified=(oldValue, newValue, id, column , index)=>{
        mainGrid.map(function(item,i,index) {
            if (item.id === id){
                item.isModified = newValue!=oldValue ? "1" : "0"
               /*  // // console.log("GridDataModified item")
                // // console.log(item.isModified)
                // // console.log(item.headerId) */
                if(item.isModified == "1"){
                    saveGrid(item)
                 
                }
               
                
                
            }
        })
    }

    const saveGrid=(item)=>{

        const updateParams = {
    
            "_collection" : "Candidates",
            "doc_data"    : item,
            "_id"         : item.headerId,
      
        }

    
        // // console.log("saveGrid updateParams")
        // // console.log(updateParams)
      
        axios
        .post("http://206.189.95.201:8098/action/update_one", updateParams)
        .then(res => {
            let data = res.data
            
            // // console.log("update_one data")
            // // console.log(data)
            // // console.log("update_one data end")
          
        
        }).catch(error =>{
            
            // // console.log("error: " + error)
          
    
        })
    }

    const onChangejobRef = (e) => {
        setjobRef(e.target.value)
    }
    const onChangepstingDateFilter = (e) => {
        setpstingDateFilter(e.target.value)
    }
    const onChangejobPostStatus = (e) => {
        setjobPostStatus(e.target.value)
    }
    const onChangejobtitlefilter = (e) => {
        setjobtitlefilter(e.target.value)
    }
    const onChangeendDateFilter = (e) => {
        setendDateFilter(e.target.value)
    }
    function handleClickColumn (key){
        // // // console.log("key")
        // // // console.log(key)
        sessionStorage.setItem("key", JSON.stringify(key))
        // navigateJobDetails("/JobDescEmployer")
    }
    function handleNavigateApplicants (key){
        // // // console.log("key")
        // // // console.log(key)
        sessionStorage.setItem("JobPostingList", JSON.stringify(key))
        sessionStorage.setItem("validate", JSON.stringify(1))
        // navigateJobDetails("/JobDescEmployer")
    }
    
    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
        }) => (
        <div className="btn-group" role="group">
            {
                options.map((option) => {
                    const isSelect = currSizePerPage === `${option.page}`;
                    return (
                    <button
                        key={ option.text }
                        type="button"
                        onClick={ () => onSizePerPageChange(option.page) }
                        className={ `btn ${isSelect ? 'btn-primary' : '#F0FEED'}` }
                    >
                        { option.text }
                    </button>
                    );
                })
            }
        </div>
    );

    const columns = [
        {
            dataField   : 'postingId',
            text        : 'Job Post Reference',
            editable:false,
            headerStyle : () => {
                return { width  : "20%",  fontWeight : "bold" };
            },
            style:{/*  */ whiteSpace: 'nowrap'},
            formatter   :   (cell, row, isSelect) => {
                return (
                    <div style={{}}>
                        <Link style={{height:'20px',paddingTop:'0',marginTop:'-4px', fontSize:'14px', color : "#0A6219"}} 
                            variant="link" to="/JobDescEmployer" onClick={() =>handleClickColumn(row.title)}
                        >
                            <b><u>{row.postingId}</u></b>
                        </Link>
                    </div>
                )
            },
        },
        {
            dataField   : 'title',
            text        : 'Job Title',
            editable:false,
            headerStyle : () => {
                return { width  : "30%", fontWeight : "bold" };
            },
            style:{/*  */ whiteSpace: 'nowrap', },
            formatter   :   (cell, row, isSelect) => {
                return (
                    <Link style={{height:'20px',paddingTop:'0',marginTop:'-4px',fontSize:'14px', color : "#0A6219"}} 
                        variant="link" to="/JobDescEmployer" onClick={() =>handleClickColumn(row.title)}
                    ><b><u>{row.title}</u></b></Link>
                )
            },
        },
        {
            dataField   : 'startDate',
            text        : 'Posted Date',
            editable:false,
            headerStyle : () => {
                return { width  : "10%", fontWeight : "bold" };
            },
            style:{/*  */ whiteSpace: 'nowrap',fontSize:'14px', color: "#000000"}
        },
        {
            dataField   : 'endDate',
            text        : 'End Date',
            editable:false,
            headerStyle : () => {
                return { width  : "10%" , fontWeight : "bold"};
            },
            style:{/*  */ whiteSpace: 'nowrap',fontSize:'14px', color: "#000000"}
        },
        {
            dataField   : 'createdBy',
            text        : 'Posted By',
            editable:false,
            headerStyle : () => {
                return { width  : "10%", fontWeight : "bold" };
            },
            style:{/*  */ whiteSpace: 'nowrap', fontSize:'14px', color: "#000000"}
        },
        {

            dataField   : 'applicants',
            text        : 'Applicants',
            editable:false,
            headerStyle : () => {
                return { width  : "10%" , fontWeight : "bold"};
            },
            style:{/*  */ whiteSpace: 'nowrap',  },
            formatter   :   (cell, row, isSelect) => {
                return (
                    <Link style={{height:'20px',paddingTop:'0',marginTop:'-4px',fontSize:'14px', color : "#0A6219"}} 
                        variant="link" to="/ListOfCandidate" onClick={() =>handleNavigateApplicants(row)}
                        ><b>{row.applicants}</b>
                    </Link>
                )
            },
        },
        {
            dataField   : 'action',
            text        : 'Action',
            editable:false,
            headerStyle : () => {
                return { width  : "10%", fontWeight : "bold"};
            },
            style:{/*  */ whiteSpace: 'nowrap', color: "#000000"},
            formatter   :   (cell, row, isSelect) => {
                if(row)
                return (
                    <div>
                    <i className="mdi mdi-power" id="1" style={{padding: 6}}></i>
                    <i className="mdi mdi-refresh" id="2"style={{marginLeft:"2%", padding: 6}}></i>
                    <i className="mdi mdi-grease-pencil" id="3"style={{marginLeft:"2%", padding: 6}}></i>
                    </div>
                );
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    console.log(e.target.id);
                    if(e.target.id === "3"){
                        handleView(row)
                    }
                },
            }
        },
        
       
    ]

    const selectRow = {
        //mode: 'checkbox',
        clickToSelectAndEditCell: false,
        hideSelectColumn: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            let disable = true
            listOfJobs.map(function(item,i){
                if(item.id===row.id)
                {
                    ////// // console.log(row)
                    item.isDeleted = isSelect ? "1" : "0"
                    item.isModified = isSelect ? "1" : "0"
                    disable = isSelect ? false : true
                }
            })
        }
    };

    function handleClickClear (){
        sessionStorage.removeItem("validate")
        sessionStorage.removeItem("JobPostingList")
        sessionStorage.removeItem("jobPostedEdit")
    }
    return (
        <React.Fragment>
            <Card className="job-detail overflow-hidden mt-1" >
                <CardBody className="p-4" style={{ marginTop : "3%", border: "1px solid blue"}}>
                    <Row>
                        <Col lg={1}></Col>
                        <Col lg={10}>
                            <Label style={{ color: "#0A6219", marginTop:"25px", fontSize: "20px"}}><b>Job Posting ( <Link  onClick={() => handleClickClear()} to="/CreateJobPosting"><i><u>Create</u></i></Link> )</b></Label>
                            <Card className="mt-1" style={{ borderColor: "green"}}>
                                
                                <Row className="mt-4" >
                                    <Col lg={1}>
                                    </Col>
                                    <Col lg={2}>
                                        <Label  style={{ marginTop:"10px", marginLeft:'29px', color:"#0A6219", fontSize: "14px", fontWeight: "bold"}}>Job Post Reference : </Label>
                                    </Col>
                                    <Col lg={2}>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="nameControlInput"
                                            //placeholder="Max Salary Offer"
                                            style={{ borderStyle: "outset",marginLeft:"-55px"/* marginBottom:"5px",background:"#F0FEED" */}}
                                            value={jobRef}
                                            onChange={onChangejobRef}
                                                
                                        />
                                    </Col>
                                    <Col lg={2}>
                                        <Label  style={{ marginTop:"10px", marginLeft:'29px',color:"#0A6219" , fontSize: "14px", fontWeight: "bold"}}>Posted Date : </Label>
                                    </Col>
                                    <Col lg={2}>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="nameControlInput"
                                            //placeholder="Max Salary Offer"
                                            style={{ borderStyle: "outset",marginLeft:"-85px"/* marginBottom:"5px",background:"#F0FEED" */}}
                                            value={pstingDateFilter}
                                            onChange={onChangepstingDateFilter}
                                                
                                        />
                                    </Col>
                                    {/* <Col lg={2}>
                                        <Label  style={{ marginTop:"10px", marginLeft:'29px',color:"#0A6219" , fontSize: "14px"}}>Job Post Status : </Label>
                                    </Col>
                                    <Col lg={2}>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="nameControlInput"
                                            //placeholder="Max Salary Offer"
                                            style={{ borderStyle: "outset",marginLeft:"-85px"}}
                                            value={jobPostStatus}
                                            onChange={onChangejobPostStatus}
                                                
                                        />
                                    </Col> */}
                                    
                                </Row>
                                <Row className="mt-3"  style={{  marginBottom:"20px",}}>
                                    <Col lg={1}>
                                    </Col>
                                    <Col lg={2}>
                                        <Label  style={{ marginTop:"10px", marginLeft:'29px', color:"#0A6219" , fontSize: "14px", fontWeight: "bold"}}>Job Title : </Label>
                                    </Col>
                                    <Col lg={2}>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="nameControlInput"
                                            //placeholder="Max Salary Offer"
                                            style={{ borderStyle: "outset",marginLeft:"-55px"/* marginBottom:"5px",background:"#F0FEED" */}}
                                            value={jobtitlefilter}
                                            onChange={onChangejobtitlefilter}
                                                
                                        />
                                    </Col>
                                    <Col lg={2}>
                                        <Label  style={{ marginTop:"10px", marginLeft:'29px',color:"#0A6219" , fontSize: "14px", fontWeight: "bold"}}>End Date : </Label>
                                    </Col>
                                    <Col lg={2}>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="nameControlInput"
                                            //placeholder="Max Salary Offer"
                                            style={{ borderStyle: "outset",marginLeft:"-85px"/* marginBottom:"5px",background:"#F0FEED" */}}
                                            value={endDateFilter}
                                            onChange={onChangeendDateFilter}
                                        />
                                    </Col>
                                    <Col lg={2}>
                                        <button type="submit" className="btn-success noser-button-md" onClick={handleChangeSearch} style={{minWidth : "10%", textAlign: "center", marginRight : "2%", backgroundColor: "green", border: "2px solid green", fontWeight: "bold", color : "white"}}>
                                            Search
                                        </button>
                                        <button type="submit" className="noser-button-md" /* onClick={handleChangeSearch} */ style={{minWidth : "10%", textAlign: "center", backgroundColor: "transparent", border: "1px solid green", fontWeight: "bold", color : "green"}}>
                                            Clear
                                        </button>
                                    </Col>
                                </Row>
                                {/* <Row className="mt-3 "  style={{  marginBottom:"5px",marginLeft:"80%"}}>
                                    <Col  lg={12}>
                                        <button type="submit" className="btn" onClick={handleChangeSearch} style={{minWidth : "10%", textAlign: "center", marginRight : "2%", backgroundColor: "transparent", border: "2px solid green", fontWeight: "bold", color : "green"}}>
                                            Search
                                        </button>
                                        <button type="submit" className="btn"  style={{minWidth : "10%", textAlign: "center", backgroundColor: "transparent", border: "1px solid green", fontWeight: "bold", color : "green"}}>
                                            Clear
                                        </button>
                                    </Col>
                                </Row> */}
                            </Card>
                        </Col>
                        <Col lg={1}></Col>
                    </Row>
                    <Row className="mt-2">
                        <Col lg={1}></Col>
                        <Col lg={10}>
                            <Card className="mt-2" style={{ borderColor: "green", padding: 20}}>
                                <Row>   
                                    <Col lg={12}>                    
                                        <BootstrapTable
                                            keyField="headerId"
                                            data={ mainGrid.sort((a, b) => a.id < b.id ? 1 : -1) }
                                            columns={ columns }
                                            bordered={ true }
                                            rowEvents={ rowEvents }
                                            // rowClasses="rowmargin"
                                            // className="rowmargin"
                                            noDataIndication={ () => <div>No record found.</div> }
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    GridDataModified(oldValue, newValue, row.id, column.dataField , row)
                                                    }
                                                })
                                            }
                                            // pagination={ paginationFactory({sizePerPageRenderer}) }
                                            selectRow = { selectRow }
                                            // striped
                                            hover
                                            condensed
                                        />
                                    </Col> 
                                </Row>
                            </Card>
                        </Col>
                        <Col lg={1}></Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default EmployerJobPostingList;
