import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Card, CardBody, Col, Row, Input, Label, select, Text, Modal, ModalBody, } from "reactstrap";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

const SuccessScreen = () => {
const navigateAppliedJobs = useNavigate();

const [ appliedList , setAppliedList] = useState(JSON.parse(sessionStorage.getItem("successdata")))
const [ gmail, setGmail] = useState("kanotix@gmail.com")

const [validate, setValidate] = useState("0")

useEffect(() => { 
  // OnLoadScreen() 
  const isValidate = JSON.parse(sessionStorage.getItem("userCollection"))
  if (isValidate === null){
    setValidate("0")
  }
  else {
    setValidate("1")
  }
},[]);
function handleNavigateAppliedJobs() {
  navigateAppliedJobs("/AppliedJobs");
};
return (
  <React.Fragment>
    <Row >
      <Col sm={4}>
      </Col>
      <Col sm={4} style={{marginTop : "15%"}} >
        {/* <Card style={{marginTop : "45%"}} > */}
          {/* <CardBody className="p-4" > */}
            <p style={{textAlign: "center", alignItems: "center", fontSize: 16, /* fontWeight: "bold", */ fontFamily: "inherit"}}>
              <label>Your application for</label> <br/>
              <label className="mt-1"> <b style={{color:"green"}}>{appliedList.title}</b> with {appliedList.companyName}</label> <br/>
              <label className="mt-1">was already submitted.</label> <br/>

              <div style={{marginTop: "4%"}}></div>
              <label>You may view your application reference in</label> <br/>
                <label className="mt-1"> {validate === "0"? <b style={{color:"green"}} onClick={()=>alert("navigate")}>{appliedList.email}</b> : <b style={{color:"green"}} onClick={handleNavigateAppliedJobs}>Applied Jobs</b> }</label> <br/>
            </p>
          {/* </CardBody> */}
        {/* </Card> */}
      </Col>
      <Col sm={4}>
      </Col>
    </Row>
  </React.Fragment>
)};
export default SuccessScreen;