import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Card, CardBody, Col, Row, Input, Label, select, Text, Modal, ModalBody, Alert, FormGroup,} from "reactstrap";
import { Link } from "react-router-dom";
import moment from 'moment';

import { AppConfiguration } from "read-appsettings-json";
import { GetRequest, PostRequest } from '../../../noser-dataaccess'
import axios from 'axios'
import dateFormat from 'dateformat';
import { useNavigate } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Switch from "react-switch";


const ApplicationPage = ({ length = 6 }) => {
  const inputs = useRef([])
  const navigate = useNavigate();
  const navigateSignIn = useNavigate();
  const navigateSignUp = useNavigate();
  const navigateCandidProf = useNavigate();
  const navigateSuccScreen = useNavigate();
  const navigateResume = useNavigate();
  const navigateJobPost = useNavigate();
  const navigateDirectApply = useNavigate();
  const navigateAppliedJob = useNavigate();


  const [applydisbled, setApplyDsbld] = useState(true);
  const [submitResume, setModalResume] = useState(false);
  const openModalResume = () => setModalResume(!submitResume);
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);
  const [modalOtp, setModalOtp] = useState(false);
  const openModalOtp = () => setModalOtp(!modalOtp);
  const [modalScreen1, setfrstModal] = useState(false);
  const openfrstModal = () => setfrstModal(!modalScreen1);
  const [modalScreen2, setScndModal] = useState(false);
  const openScndModal = () => setScndModal(!modalScreen2);
  const [modalScreen3, setThrdModal] = useState(false);
  const openThrdModal = () => setThrdModal(!modalScreen3);
  const [applybyresume, setModalApplyByResume] = useState(false);
  const openModalApplyResume = () => setModalApplyByResume(!applybyresume);

  /* Job Details */
  const [jobDetails, setJobDetails] = useState([])
  const [jobPostingId, setJPSession] = useState(JSON.parse(sessionStorage.getItem("key")));
  const [idSlctd, setKeySession] = useState(JSON.parse(sessionStorage.getItem("companyId")));
  const [userCol, setUserCol] = useState();
  const [jobDesc, setJobDiscription] = useState("")
  const [skillAndExp, setSkillExpr] = useState([])
  const [responsibility, setResponsibilities] = useState([])
  const [qualifications, setQualifications] = useState([])
  const [companyName, setCompanyName] = useState("")
  const [title, setTitle] = useState("")
  const [companyNumber, setCompNumber] = useState("")
  const [companyEmail, setCompEmail] = useState("")
  const [companyWebsite, setCompPage] = useState("")
  const [companyLocation, setCompanyLocation] = useState("")
  const [landMark, setLocation] = useState("")
  const [employeeIdTmp, setEmployeeId] = useState("")

  /* Job Overview Details */
  const [jobType, setJobType] = useState("")
  const [category, setCategory] = useState("")
  const [jobOverviewLocation, setJobOverviewLocation] = useState("")
  const [offeredSalary, setOfferedSalary] = useState("")
  const [posted, setPosted] = useState("")
  const [experience, setExperience] = useState("")
  const [gender, setGender] = useState("0")
  const [industry, setIndustry] = useState("")
  const [qualification, setQualification] = useState("")
  const [level, setLevel] = useState("")
  const [applied, setApplied] = useState("")
  const [applicationDate, setApplicationDate] = useState("")
  const [companyId, setCompanyId] = useState("")
  const [message, setMessage] = useState("")

  // drop down job overview
  const [_jobDepartment, setJobDepartment] = useState("")
  const [_jobEmploymentType, setEmploymentType] = useState("")
  const [_jobExprnc, setJobExp] = useState("")
  const [_jobQualification, setJobQuali] = useState("")
  const [_jobEducLvl, setJobEducLvl] = useState("")
  const [jobDepartment, setjobDept] = useState([])
  const [employementType, setApplicantType] = useState([])
  const [educLevel, setEdLevel] = useState([])
  const [empJobType, setEmpJobType] = useState([])
  const [jobDetailsPostingId, setPostingJobId] = useState("")
  const [validate, setValidate] = useState("")
  const [emailAdd, setEmail] = useState("")
  const [fullName, setFullName] = useState("")
  const [contactNo, setContactNo] = useState("") 
  const [dataPrivacy, setDataPrivacy] = useState("N")
  const [btnDIsbld, setButtons] = useState(true)
  const [filesResume, setFilePdf] = useState("") 
  const [mdlDataPrvcy, setOpenModalPrivcy] = useState(false)
  const [values, setValues] = useState({
    one             : "",//
    two          : "",//
    three              : "",
    four           : "",//
    five           : "",
    six           : "",
  });
  const [empId, setEmpId] = useState("") 
  const [verified, setVerified] = useState("0") 
  const [isCheckbox, setCheckbox] = useState(true) 
  const [isCompleted, setDataRsitrct] = useState("") 
  const [_createdDate, setCreatedDate] = useState("") 
  const [_endDate, setEnddate] = useState("") 
  const [numberApplcants, SetNumApplicants] = useState("") 
  const [ date , setDate] = useState("")
  const [workingDays, setWorkingDays] = useState("") 
  const [expRequired, setExpRequired] = useState("") 
  const [getWorkLocation, setWorkLocation] = useState("")

  /* Message */
  const [isshow, setisshow] = useState("")
  const [color, setcolor] = useState("")
  const [messageAlert, setmessagealert] = useState("")
  const [fade, setfade] = useState("")
  const [alerttype, setalerttype] = useState("")
  const [compIndustry, setCompIndustry] = useState("")
  // const [otp, setOtp] = useState([])
  const [otp, setOtp] = useState(Array(length).fill(''));
  const [getOtp, setOTP]= useState("1,2,3,4,5,6")

  useEffect(() => {
    GetCompanyProfile()
    setDate(new Date())
    const isValidate = JSON.parse(sessionStorage.getItem("userCollection"))
    if (isValidate === null){
      setValidate("0")
      setUserCol("")
      setEmployeeId("")
      GetTestRestrict()
    }
    else {
      setValidate("1")
      setUserCol(isValidate[0].userId)
      setEmployeeId(isValidate[0].userId)
      GetTestRestrict(isValidate[0].userId)
    }
  },[]);
  const GetCompanyProfile = async() => {
    let filter_data = {"isDeleted": "0"}
    const dataTmp = []
    const Params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "CompanyProfile",
      "filter_data" : filter_data
    }
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {
      let data = res.data
      data.CompanyProfile.map(function(itm,i){
        const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
        const json = JSON.parse(strJson.replace(/'/g,'"'));
        dataTmp.push(json)
      })       
        
      // setJson(dataTmp)  
      GetJobDescription(dataTmp); 
    })
  };
  const GetJobDescription =async(_dataList)=> {
    // console.log("_dataList")
    // console.log(_dataList)
    let jobPosting_ = ""
    try{
      let resp = await GetRequest({
        url : AppConfiguration.Setting().mongoJobspace + "action/get",
        params : {
          "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
          "_collection" : "JobPosting",
          "filter_data" : {}
        }
      })
      if(resp.status==="1"){
        const jobPostingList = resp.JobPosting.filter(x=>x.id===jobPostingId)
        const compProfList = _dataList.filter(x => jobPostingList.some(itm => itm.companyId === x.userId))
        // console.log("jobPostingList")
        // console.log(jobPostingList)
        // // console.log("resp.JobPosting")
        // // console.log(resp.JobPosting)
        setJobDiscription(jobPostingList[0].description)
        setTitle(jobPostingList[0].title)
        setSkillExpr(jobPostingList[0].skillsExperience)
        setResponsibilities(jobPostingList[0].responsibility)
        setQualifications(jobPostingList[0].qualifications)

        let compTmp = (compProfList[0].companyName).toLowerCase()
        let toCaseComp = toTitleCase(compTmp)
        setCompanyName(toCaseComp)
        setCompanyId(compProfList[0].id)
        setJobDetails(resp.JobPosting.filter(x=>x.id===idSlctd))
        setCompNumber(compProfList[0].mobile)
        let emailTmp = (compProfList[0].email).toLowerCase()
        let toCaseEmail = toTitleCase(emailTmp)
        setCompEmail(toCaseEmail)
        let webTmp = (compProfList[0].website).toLowerCase()
        let toCaseWeb = toTitleCase(webTmp)
        setCompPage(toCaseWeb)
        // let _location = compProfList[0].locationUnit + " " + compProfList[0].locationBuilding + " " + compProfList[0].locationStreetNo + " " + compProfList[0].locationStreet + " " + compProfList[0].locationCity + " " + compProfList[0].locationProvince + " " + compProfList[0].locationZipCode
        // setCompanyLocation(_location)
        let landMark = (compProfList[0].city).toLowerCase() //+ "," + " " + fltrData[0].locationProvince
        // let landMarkTmp = companyProfList[0].email
        let toCaseLandmark = toTitleCase(landMark)
        setLocation(toCaseLandmark)
        let applicationDate = dateFormat(jobPostingList[0].endDate).split(" ")
        // let s1 = applicationDate[0]
        let s2 = applicationDate[1]
        let s3 = applicationDate[2]
        let s4 = applicationDate[3]
        let joinDate = s2 + " " + s3 + ", " + s4
        let spliAppEnd = joinDate
        // // console.log("compProfList[0]")
        // // console.log(compProfList[0])
        setApplicationDate(spliAppEnd)
        setWorkLocation(jobPostingList[0].workLocation)

        // let x1 = jobPostingList[0].jobDepartment
        // let x2 = jobPostingList[0].jobTime
        // let x3 = jobPostingList[0].applicantType
        // let x4 = jobPostingList[0].qualifications
        // let x5 = jobPostingList[0].educationalLevel

        // setJobDepartment(x1[0].department)
        // setEmploymentType(x2[0].department)
        // setJobExp(x3[0].ApplicantType)
        // setJobQuali(x4[0].skill)
        // setJobEducLvl(x5[0].educlvl)

        setJobDepartment(jobPostingList[0].dept)
        setEmploymentType(jobPostingList[0].workType)
        setJobExp(jobPostingList[0].aplicantExp)
        let _salaryTmp = toTitleCase(jobPostingList[0].salaryCurrency) + " " + jobPostingList[0].salaryMin + " " + "-" + " " + toTitleCase(jobPostingList[0].salaryCurrency)  + " " + jobPostingList[0].salaryMax
        // let _salaryTmp = jobPostingList[0].salaryMin + " " + "-" + " " + jobPostingList[0].salaryMax
        setOfferedSalary(_salaryTmp)
        // setJobQuali(x4[0].skill)
        setJobEducLvl(jobPostingList[0].education)
        setPostingJobId(jobPostingList[0].postingId)
        setCreatedDate(jobPostingList[0].createdDate)
        setEnddate(jobPostingList[0].endDate)
        setExpRequired(jobPostingList[0].experienceMin)
        // // console.log("fltrData[0].city")
        // // console.log(fltrData[0].city)
        // jobPosting_ = jobPostingList[0].postingId
        GetNumOfApplicants(jobPostingList[0].title)
        setWorkingDays(jobPostingList[0].workingDaysAndHrs)
        setCompIndustry(jobPostingList[0].industry)
      }
      // // console.log()
      // // console.log()
    }
    catch(error){
      // setMessage(error.message)
      alert("error: " + error.message)
    }
  };
  const GetNumOfApplicants = async(postingId) => {
    let filter_data = {"title" : postingId}
    const _dataTmp = []
    const Params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "Candidates",
      "filter_data" : filter_data
    }
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {
      let data = res.data
      data.Candidates.map(function(itm,i){
        const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
        const json = JSON.parse(strJson.replace(/'/g,'"'));
        _dataTmp.push(json)
      })
      // console.log("postingId")
      // console.log(postingId)
      SetNumApplicants(String(parseFloat(data.Candidates.length))); 
    })
  };
  const GetTestRestrict = async(empId) => {
    // // console.log("userCol")
    // // console.log(typeof userCol === "undefined")
    let fltrUser = ""
    // let _fltrUser = userCol.length===0 || userCol === null ? "" : userCol
    if(typeof empId === "undefined"){
      // console.log("if")
      fltrUser = ""
    }
    else{
      // console.log("else")
      fltrUser = empId
    }
    console.log("empId")
    console.log(empId)
    let filter_data = {"isDeleted": "0", "userId" : fltrUser}
    const dataTmp = []
    const Params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "CandidateRegisteredCheckList",
      "filter_data" : filter_data
    }
    console.log("Params")
    console.log(Params)
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {
      let data = res.data
      if(data.CandidateRegisteredCheckList.length === 0){
        setDataRsitrct("0"); 
      }
      else{
        // let getUserId = JSON.parse(sessionStorage.getItem("userCollection"))
        data.CandidateRegisteredCheckList.map(function(itm,i){
          const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
          const json = JSON.parse(strJson.replace(/'/g,'"'));
          dataTmp.push(json)
        })       
          
        // setJson(dataTmp)  
        setDataRsitrct(dataTmp[0].basicInfoCandidate); 
        // console.log("dataTmp")
        // console.log(dataTmp)
      }
    })
    .catch(error =>{
      console.log("error")
      console.log(error)
    })
  };

  function toTitleCase(str) {
    const titleCase = str
      .toLowerCase()
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  
    return titleCase;
  }

  
  const onChangeEmail =(e)=>{
    setEmail(e.target.value)
    if(e.target.value!==""){
      setisshow(false)
    }
  }
  const onChangeFullName =(e)=>{
    setFullName(e.target.value)
    if(e.target.value!==""){
      setisshow(false)
    }
  }
  const onChangeContactNo =(e)=>{
    if(e.target.value.length >= 0 && e.target.value.length < 12){
      setContactNo(e.target.value)
    }
    if(e.target.value!==""){
      setisshow(false)
    }
  }
  const onChangeCategory =(e)=>{
    setCategory(e.target.value)
  }
  const onChangeJobOverViewLocation =(e)=>{
    setJobOverviewLocation(e.target.value)
  }
  const onChangeOfferedSalary =(e)=>{
    setOfferedSalary(e.target.value)
  }
  const onChangePosted =(e)=>{
    setPosted(e.target.value)
  }
  const onChangeExperience =(e)=>{
    setExperience(e.target.value)
  }
  const onChangeIndustry =(e)=>{
    setIndustry(e.target.value)
  }
  const onChangeLevel =(e)=>{
    setLevel(e.target.value)
  }
  const onChangeApplied =(e)=>{
    setApplied(e.target.value)
  }
  const onChangeMessage =(e)=>{
    setMessage(e.target.value)
    if(e.target.value!==""){
      setisshow(false)
    }
  }
  const handleChangeDepartmnt=(e)=>{
    setJobDepartment(e.target.value)
  } 
  const handleChangeEmploymentType=(e)=>{
    setEmploymentType(e.target.value)
  } 
  const handleChangeExprnce=(e)=>{
    setJobExp(e.target.value)
  } 
  const handleChangeQualification=(e)=>{
    setJobQuali(e.target.value)
  } 
  const handleChangeEducLvl=(e)=>{
    setJobEducLvl(e.target.value)
  } 
  const onChangeCheckbox=(e)=>{
    // console.log(e.target.checked)
    if(e.target.checked===true){
      // alert("checked")
      setDataPrivacy("Y")
      // setCheckbox(false)
      // setApplyDsbld(false)
      if(filesResume==="" && contactNo==="" && fullName==="" && emailAdd===""){
        setButtons(true)
      }
      else {
        setButtons(false)
      }
      if(message===""){
        setApplyDsbld(true)
      }
      else {
        setApplyDsbld(false)
      }
      // setOpenModalPrivcy(true)
      // openModal(openModal)
    }
    else{
      // alert("not checked")
      setDataPrivacy("N")
      setButtons(true)
      // setCheckbox(true)
      setApplyDsbld(true)
    }
  } 
  const onChangeCheckboxResume=(e)=>{
    // console.log(e.target.checked)
    if(e.target.checked===true){
      // alert("checked")
      setDataPrivacy("Y")
      setisshow(false)
      setCheckbox(false)
      // setOpenModalPrivcy(true)
      // openModal(openModal)
    }
    else{
      // alert("not checked")
      setDataPrivacy("N")
      setCheckbox(true)
    }
  } 
  // const handleChange = (e, index) => {
  //   if (index < length - 1) {
  //     inputs.current[index + 1].focus();
  //   }
  //   const { name, value } = e.target;
  //   setValues({
  //   ...values,
  //   [name]: value
  //   });
  // };
  const onclickVerify = () =>{
    // setVerified("1")
    setModalOtp(false)
    handleApplyGuest()
    
  }


  const handleApplySignedIn = () =>{
    const dateTmp = moment(date).format('LLLL').split(", ")
    const s1 = dateTmp[1]
    const s2 = dateTmp[2]
    const combDate = s1 + " " + s2
    // // console.log("combDate")
    // // console.log(combDate)

    const data = {
      email : emailAdd,
      name : fullName,
      contact : contactNo,
      message : message,
      jobDepartment : _jobDepartment,
      employementType : _jobEmploymentType,
      experience : _jobExprnc,
      qualification : _jobQualification,
      educLevel : _jobEducLvl,
      expectedSalary : offeredSalary,
      city : landMark,
      industry : "",
      level : "",
      isRegister : "1",
      resume : filesResume,
      dataPrivacy : dataPrivacy,
      postingId : jobDetailsPostingId,
      title : title,
      companyName : companyName,
      employeeId : employeeIdTmp,
      status : "NW",
      createdDate : _createdDate,
      endDate : _endDate,
      jobDocNo : numberApplcants,
      dateApplied : combDate,

      createdDate : moment(date).format("MM/DD/YYYY"),
      createdBy : employeeIdTmp,
      isModified : "0",
      modifiedDate : moment(date).format("MM/DD/YYYY"),
      modifiedby : employeeIdTmp,
      isDeleted : "0",
    }
    // console.log("data")
    // console.log(data)
    
    const params = {
      "_collection" : "Candidates",
      "doc_data" : data
    }

    axios
    .post(AppConfiguration.Setting().mongoJobspace + "action/add", params )
    .then(resp =>{
      // let data = resp.data
      if(resp.data==="success"){
        sessionStorage.setItem("successdata", JSON.stringify(data))
        navigateSuccScreen("/SuccessScreen")
      }
      else{
        alert("error")
      }
    })
    .catch(error =>{
      alert("error: " + error)
    })
  }
  const handleApplyGuest = () =>{
    const dateTmp = moment(date).format('LLLL').split(", ")
    const s1 = dateTmp[1]
    const s2 = dateTmp[2]
    const combDate = s1 + " " + s2

    const data = {
      email : emailAdd,
      name : fullName,
      contact : contactNo,
      message : message,
      jobDepartment : _jobDepartment,
      employementType : _jobEmploymentType,
      experience : _jobExprnc,
      qualification : _jobQualification,
      educLevel : _jobEducLvl,
      expectedSalary : offeredSalary,
      city : landMark,
      industry : "",
      level : "",
      isRegister : "0",
      resume : filesResume,
      dataPrivacy : dataPrivacy,
      postingId : jobDetailsPostingId,
      title : title,
      companyName : companyName,
      employeeId : employeeIdTmp,
      status : "NW",
      createdDate : _createdDate,
      endDate : _endDate,
      jobDocNo : numberApplcants,
      dateApplied : combDate,

      createdDate : moment(date).format("MM/DD/YYYY"),
      createdBy : employeeIdTmp,
      isModified : "0",
      modifiedDate : moment(date).format("MM/DD/YYYY"),
      modifiedby : employeeIdTmp,
      isDeleted : "0",
    }
    // console.log("data")
    // console.log(data)
    
    const params = {
      "_collection" : "Candidates",
      "doc_data" : data
    }

    axios
    .post(AppConfiguration.Setting().mongoJobspace + "action/add", params )
    .then(resp =>{
      // let data = resp.data
      if(resp.data==="success"){
        sessionStorage.setItem("successdata", JSON.stringify(data))
        navigateSuccScreen("/SuccessScreen")
      }
      else{
        alert("error")
      }
    })
    .catch(error =>{
      alert("error: " + error)
    })
  }
  const handleApplyNoResume = ()=>{
    const dateTmp = moment(date).format('LLLL').split(", ")
    const s1 = dateTmp[1]
    const s2 = dateTmp[2]
    const combDate = s1 + " " + s2

    const data = {
      email : emailAdd,
      name : fullName,
      contact : contactNo,
      message : message,
      jobDepartment : _jobDepartment,
      employementType : _jobEmploymentType,
      experience : _jobExprnc,
      qualification : _jobQualification,
      educLevel : _jobEducLvl,
      expectedSalary : offeredSalary,
      city : landMark,
      industry : "",
      level : "",
      isRegister : "1",
      resume : "",
      dataPrivacy : dataPrivacy,
      postingId : jobDetailsPostingId,
      title : title,
      companyName : companyName,
      employeeId : employeeIdTmp,
      status : "NW",
      createdDate : _createdDate,
      endDate : _endDate,
      jobDocNo : numberApplcants,
      dateApplied : combDate,

      createdDate : moment(date).format("MM/DD/YYYY"),
      createdBy : employeeIdTmp,
      isModified : "0",
      modifiedDate : moment(date).format("MM/DD/YYYY"),
      modifiedby : employeeIdTmp,
      isDeleted : "0",
    }
    // console.log("data")
    // console.log(data)
    
    const params = {
      "_collection" : "Candidates",
      "doc_data" : data
    }

    axios
    .post(AppConfiguration.Setting().mongoJobspace + "action/add", params )
    .then(resp =>{
      // let data = resp.data
      if(resp.data==="success"){
        sessionStorage.setItem("successdata", JSON.stringify(data))
        navigateSuccScreen("/SuccessScreen")
      }
      else{
        alert("error")
      }
    })
    .catch(error =>{
      alert("error: " + error)
    })
  }
  const NavigateSignIn=()=>{
    // // console.log(companyId)
    // // console.log(jobPostingId)
    sessionStorage.setItem("companyId", JSON.stringify(companyId))
    sessionStorage.setItem("key", JSON.stringify(jobPostingId))
    // // console.log(jobPostingId)
    navigateSignIn("/signin");
  }
  const NavigateSignUp=()=>{
    // // console.log(companyId)
    // // console.log(jobPostingId)
    // sessionStorage.setItem("companyId", JSON.stringify(companyId))
    // sessionStorage.setItem("key", JSON.stringify(jobPostingId))
    // // console.log(jobPostingId)
    navigateSignUp("/signup");
  }
  const handleNavigateProfile = () =>{
    navigateCandidProf("/CandidateProfileApplication");
  }
  const NavigateJobPost = () =>{
    navigateJobPost("/JobDetailsDescription");
    setThrdModal(false)
  }
  const UploadResume=(e)=>{
    // console.log(e.target.files[0])
    if(typeof e.target.files[0] === "undefined"){
      setFilePdf("")
      setisshow(false)
    }
    else {
      setFilePdf(e.target.files[0].name)
    }
  }
  const handleChangeSwitch =(e)=>{
  }
  const handleOpenModal =(e)=>{
    setfrstModal(false)
    setScndModal(true)
  }
  const handleOpenModal2 =(e)=>{
    setScndModal(false)
    setThrdModal(true)
  }
  const handleOpenResume =(e)=>{
    // setScndModal(false)
    setThrdModal(false)
    // setModalResume(true)
    navigateDirectApply("/ApplyCompProf")
  }
  const handleDirectbyResume =()=>{
    // setfrstModal(false)
    // setCheckbox(true)
    // setDataPrivacy("N")
    // setFilePdf("")
    // setModalApplyByResume(true)
    navigateResume("/ApplyResume")
  }
  const SubmitValidate = () =>{
    if(emailAdd===""){
      setalerttype("Danger!")
      setisshow(true)
      setcolor("danger")
      setmessagealert("Please input email address.")
      setfade(true)
      return

    }
    else if(fullName===""){
      setalerttype("Danger!")
      setisshow(true)
      setcolor("danger")
      setmessagealert("Please input full name.")
      setfade(true)
      return

    }
    else if(contactNo===""){
      setalerttype("Danger!")
      setisshow(true)
      setcolor("danger")
      setmessagealert("Please input contact no.")
      setfade(true)
      return

    }
    else if(message===""){
      setalerttype("Danger!")
      setisshow(true)
      setcolor("danger")
      setmessagealert("Please input message.")
      setfade(true)
      return

    }
    else if(filesResume===""){
      setalerttype("Danger!")
      setisshow(true)
      setcolor("danger")
      setmessagealert("Please upload resume.")
      setfade(true)
      return

    }
    else if(dataPrivacy==="N"){
      setalerttype("Danger!")
      setisshow(true)
      setcolor("danger")
      setmessagealert("Please accept data privacy!")
      setfade(true)
      return

    }
    else{
      setModalOtp(true)
    }
  }
  const handleChange = (e, index) => {
    const { value } = e.target;

    if (value.match(/^\d$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < length - 1) {
        inputs.current[index + 1].focus();
      }
    }

    if (value === '' && index > 0) {
      inputs.current[index - 1].focus();
    }
  };
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (index > 0) {
        e.preventDefault();
        inputs.current[index - 1].focus();
      }
      console.log("Backspace")
      console.log(index )
      if(index === 5){
        setOTP(otp[5] = "")
        inputs.current[index].value = ""
      }
      if(index === 4){
        setOTP(otp[4] = "")
        inputs.current[index].value = ""
      }
      if(index === 3){
        setOTP(otp[3] = "")
        inputs.current[index].value = ""
      }
      if(index === 2){
        setOTP(otp[2] = "")
        inputs.current[index].value = ""
      }
      if(index === 1){
        setOTP(otp[1] = "")
        inputs.current[index].value = ""
      }
      if(index === 0){
        setOTP(otp[0] = "")
        inputs.current[index].value = ""
      }
    }
  };
  return (
    <React.Fragment>
      <Card className="job-detail overflow-hidden mt-1">
        <CardBody className="p-4" style={{ marginTop : "4%",marginLeft: "18%"}}>
          <Alert isOpen={isshow} color={color} fade={fade} className={isshow ? 'display-block' : 'display-none'} style={{maxWidth: "85%"}}>
              <div className={color}></div> 
            <b>{messageAlert}</b>
          </Alert>
          <Row>
            <div>
              <Row>
                <Label className="mb-1" style={{ fontSize: "18px",color: "#000000", font: "inter"}}>
                  Applying for
                </Label>
                <Col md={8}>
                  <Label className="mt-2" style={{color:"#0A6219", fontSize: "20px"}}><b>{title}</b></Label><br />
                  <Label className="mb-1 text-muted" style={{ fontSize: "18px", marginTop: "-1%"}}>{companyName}</Label>
                </Col>
              </Row>
              <Row>
                <div className="mt-3 mt-lg-0">
                  <li className="list-inline-item">
                    <p className="text-muted mb-0" style={{fontSize: "14px"}}>
                      <i className="mdi mdi-map-marker"></i>{" "}
                      {landMark}
                    </p>
                  </li>
                </div>
              </Row>
              <Row>
                <div className="mt-3 mt-lg-0">
                  <li className="list-inline-item">
                    <p className="text-muted mb-0" style={{fontSize: "14px"}}>
                      <i className="mdi mdi-clock"></i>{" "}
                        {workingDays}
                    </p>
                  </li>
                </div>
              </Row>
            </div>
          </Row>
          <Row>
            <Col lg={4}>
              <Card className="mt-3" style={{ borderRadius: 5, borderColor: "#a4a4a4"}}>
                <CardBody>
                  <Label className="modal-title" id="staticBackdropLabel" style={{fontSize: "14"}}>
                    <b>Job Overview</b>
                  </Label>
                  <Row className="mt-2">
                    <Label column sm="5" className="text-muted">
                      Employment Type:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Employment Type"
                        style={{borderRadius: 0 ,fontSize: "14px", color: "#000000", borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={_jobEmploymentType}
                        // onChange={onChangeOfferedSalary}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Label column sm="5" className="text-muted">
                      Industry:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Industry"
                        style={{borderRadius: 0 ,fontSize: "14px", color: "#000000", borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={compIndustry}
                        onChange={onChangeExperience}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Label column sm="5" className="text-muted">
                    Location:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Location"
                        style={{borderRadius: 0 ,fontSize: "14px", color: "#000000", borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={landMark}
                        // onChange={onChangeJobOverViewLocation}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Label column sm="5" className="text-muted">
                      Offered Salary:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Offered Salary"
                        style={{ borderRadius: 0 ,fontSize: "14px", color: "#000000", borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={offeredSalary}
                        // onChange={onChangeCategory}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Label column sm="5" className="text-muted">
                      Department:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Department"
                        style={{ borderRadius: 0 ,fontSize: "14px", color: "#000000", borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={_jobDepartment}
                        // disabled={true}
                        // onChange={onChangeJobType}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Label column sm="5" className="text-muted">
                      Experience:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Experience"
                        style={{borderRadius: 0 ,fontSize: "14px", color: "#000000", borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={expRequired}
                        // onChange={onChangeExperience}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Label className="mt-2" style={{fontSize: "14px"}}>
                      Number of applicants: &nbsp; <Link style={{color: "#0A6219"}} /* to="/AppliedJobs"  onClick={ () => navigateAppliedJob("/AppliedJobs") } */><b >{numberApplcants}</b></Link>
                    </Label>
                  </Row>
                  <Label style={{marginTop: "15%", fontSize: "14px"}}>
                    Application ends: <b>{applicationDate}</b>
                  </Label>
                </CardBody>
              </Card>
            </Col>
            <Col sm={1}></Col>
            <Col lg={5}  style={{marginTop: "-1.5%"}}>
              <Row>
                <Col>
                {validate === "1"? 
                  <p className="mb-1" style={{fontSize: "14px", color: "#000000"}}>
                    <b>Cover letter</b>
                  </p>
                  :
                  <p className="mb-1" style={{fontSize: "14px", color: "#000000"}}>
                    <b>Provide the details to apply for the job</b>
                  </p>
                }
                  <Card style={{ backgroundColor: "white", borderRadius: 5, border : "1px solid green"}}>
                    <CardBody style={{padding: "7%"}}>
                      <Row style={{marginTop: "-3%"}}>
                      {validate === "1"? null:
                        <Input
                          type="text"
                          className="form-control"
                          id="nameControlInput"
                          placeholder="E-mail Address"
                          style={{ borderStyle: "outset", backgroundColor: "#E0FDDB", color : "#000000"}}
                          value={emailAdd}
                          onChange={onChangeEmail}
                        />
                      }
                      </Row>
                      <Row className="mt-2">
                        {validate === "1"? null:
                          <Input
                            type="text"
                            className="form-control"
                            id="nameControlInput"
                            placeholder="Full Name"
                            style={{ borderStyle: "outset", backgroundColor: "#E0FDDB", color : "#000000"}}
                            value={fullName}
                            onChange={onChangeFullName}
                          />
                        }
                      </Row>
                      <Row className="mt-2">
                        {validate === "1"? null:
                          <Input
                            type="number"
                            className="form-control"
                            id="nameControlInput"
                            placeholder="Contact Number"
                            style={{ borderStyle: "outset", backgroundColor: "#E0FDDB", color : "#000000"}}
                            value={contactNo}
                            // maxLength={11}
                            maxLength="11"
                            onChange={onChangeContactNo}
                          />
                        }
                      </Row>
                      <Row className="mt-2">
                        {/* <Label className="form-label" for="inputGroupFile01" style={{fontWeight: "bold"}}>
                        Message
                        </Label> */}
                        <Input
                          type="textarea"
                          className="form-control"
                          id="nameControlInput"
                          placeholder="Message"
                          style={{ /* borderStyle: "outset", */ backgroundColor: "#E0FDDB", color : "#000000"}}
                          value={message}
                          onChange={onChangeMessage}
                          rows={7}
                          // aria-rowspan={3}
                        />
                      </Row>
                      <Row className="mt-2">
                        <Col sm={1}>
                          <Input
                            style={{border: "1px solid green"}}
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            // to="otpscreen"
                            onClick={e =>onChangeCheckbox(e)}
                          />
                        </Col>
                        <Col lg={11}>
                        {validate === "0"? 
                          <p className="text-muted mb-0" style={{ textAlign: "justify", fontSize : "9px", marginLeft : "-5%"}}>
                            By uploading your resume and apply for this job, you agreed to our 
                            <b style={{color: "#0A6219"}} to ="datapolicy" onClick={openModal}> Data Privacy Policy </b>
                            of collecting, storing and using your personal data as described in our policy 
                            to matches the candidate skill needed by the employers. 
                          </p>
                          :
                          <p className="text-muted mb-0" style={{ textAlign: "justify", fontSize : "14px", marginLeft : "-4%"}}>
                            When you apply for this job, your JobSpace profile including any verified credentials will be accessed by the employer. Other employers and recruiters
                            can also approach you with job opportunities based on your profile access settings.
                          </p>
                        }
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Row>
                  <div className="mb-1 mt-1">
                    <Row>
                      {validate === "0"? 
                        <Input
                          type="file"
                          className="form-control"
                          id="inputGroupFile01"
                          onChange={UploadResume}
                        />:
                        null
                      }
                    </Row>
                  </div>
                  </Row>
                  <Col>
                    <Row className="mt-3">
                      <Col>
                        {validate === "0"? 
                          <button type="submit" className="btn btn-success noser-button-md" to="otpscreen" onClick={SubmitValidate}  style={{minWidth : "20%", textAlign: "center", marginLeft: "31.5%"}}>
                            Submit Application
                          </button>
                        :
                        null
                        }
                      </Col>
                      <Col>
                        {validate === "0"? 
                          <button type="submit" /* disabled={btnDIsbld} */ onClick={NavigateSignIn} className="btn btn-success noser-button-md" style={{marginLeft: "15%"}}>
                            Sign-in To Apply
                          </button>
                          :
                          null
                        }
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row className="mt-3">
                      {validate === "0"? 
                        <button type="submit" /* disabled={btnDIsbld} */ onClick={NavigateSignUp} className="btn btn-success noser-button-md"style={{width : "67%", marginLeft: "16%"}}>
                          Register
                        </button>
                        :
                        <button type="submit" className="btn btn-success noser-button-md" disabled={applydisbled} onClick={openfrstModal} style={{width : "67%", marginLeft: "16%"}}>
                          Apply
                        </button>
                      }
                    </Row>
                  </Col>
                </Col>
              </Row>
              <Row className="mt-2">
              </Row>
              {/* {validate === "0"? 
              <Row className="mt-2">
                <Col>
                  <Row className="mt-2" style={{marginLeft: "0.1%", marginRight : "0.1%"}}>
                    <button
                      type="submit" 
                      className="btn"
                      style={{backgroundColor: "#E0FDDB", alignContent: 'center'}}
                      onClick={()=>alert("clicked")}
                    >
                      <b>Continue with Google</b>
                    </button>
                  </Row>
                  <Row className="mt-2" style={{marginLeft: "0.1%", marginRight : "0.1%"}}>
                    <button
                      type="submit" 
                      className="btn"
                      style={{backgroundColor: "#E0FDDB", alignContent: 'center'}}
                      onClick={()=>alert("clicked")}
                    >
                      <b>Continue with Facebook</b>
                    </button>
                  </Row>
                  <Row className="mt-2" style={{marginLeft: "0.1%", marginRight : "0.1%"}}>
                    <button
                      type="submit" 
                      className="btn"
                      style={{backgroundColor: "#E0FDDB", alignContent: 'center'}}
                      onClick={()=>alert("clicked")}
                    >
                      <b>Continue with Apple</b>
                    </button>
                  </Row>
                </Col>
              </Row>
              : 
              null
              } */}
            </Col>
            <Col lg={2}></Col>
          </Row>
        </CardBody>
      </Card>
      {/* Modals */}
      <div
        className="modal fade"
        id="otpscreen"
        tabIndex="-1"
        aria-labelledby="otpscreen"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modalOtp} toggle={openModalOtp} centered>
            <ModalBody className="modal-body p-5" style={{ backgroundColor: "#F0FEED"}}>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openModalOtp}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <Row>
                <h4 className="mb-1" style={{color: "#000000"}}>
                  Verify your application
                </h4>
              </Row>
              <Row>
                <h5 className="mb-1 mt-2" style={{fontSize: "14px", color: "#000000"}}>
                  Mobile Number
                </h5>
              </Row>
              <Card className="mt-4">
                <CardBody>
                  <Row className="mt-3">
                    <Col sm={10} className="mb-1"  style={{fontSize: "14px", color: "#000000"}}>
                      <label>We have sent an OTP to your</label>
                    </Col>
                    <Col sm={2}>
                      <label><u style={{color: "green", fontSize: 14,textAlign: "right"}}>Change</u></label>
                    </Col>
                  </Row>
                  <Row /* className="mt-1" */>
                    <Col sm={12} className="mb-1" style={{fontSize: "14px", color: "#000000"}}>
                      <label>mobile number &nbsp;&nbsp;&nbsp; <b style={{color: "green"}}><u> {contactNo} </u></b></label>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                  <Col sm={12} className="mb-1" style={{fontSize: "14px", color: "#000000"}}>
                    Enter OTP
                  </Col>
                  </Row>
                  {<FormGroup>
                    {otp.map((_, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={otp[index]}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => (inputs.current[index] = el)}
                        style={{
                          width: '40px',
                          height: '40px',
                          margin: '0 5px',
                          textAlign: 'center',
                          fontSize: '18px',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                        }}
                      />
                    ))}
                  </FormGroup>}
                  <Row>
                    <Form.Text className="mb-1"  style={{fontSize: "14px", color: "#000000"}}>  &nbsp;&nbsp;&nbsp;&nbsp;Didn't receive your OTP? <Button  variant="link" className="text-decoration-underline" style={{color: "green"}}>Resend</Button></Form.Text>
                  </Row>
                  <Row>
                  <Col sm={12}>
                    <button 
                      className="btn btn-success noser-button-md"
                      style={{minWidth : "45%"}}
                      // variant="success"
                      onClick={onclickVerify}
                    > 
                      Verify Mobile Number
                    </button>
                  </Col>
                  
                  </Row>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <div
        className="modal fade"
        id="datapolicy"
        tabIndex="-1"
        aria-labelledby="datapolicy"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modal} toggle={openModal} centered>
            <ModalBody className="modal-body p-5" style={{backgroundColor: "#F0FEED"}}>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <Row>
                <h9 style={{color: "#00000", fontSize : "14px", fontWeight: "bolder", textAlign: "center"}}>
                  DATA PRIVACY POLICY
                </h9>
              </Row>
              <p style={{marginTop: "6%", textAlign:"justify", fontSize : "14px",}}>
                We believe you should be able to access and control your personal information no matter where you live. Depending on how you use JobSpace, you may have the
                right to request access to, correct, amend, delete, restrict, or object to certain uses of your personal information.

                <div style={{marginTop: "3%"}}></div>
                We will not charge you more or provide you with a different level of service if you exercise any of these rights, Please note that a number of these rights
                apply only in certain circumstances, and all of these rights may be limited by law.

                <div style={{marginTop: "3%"}}></div>
                We will retain your personal data only as long as necessary to fulfill the purposes for which we have collected it. To determine the appropriate retension period,
                we consider the amount, nature and sensitivity of your personal data, the potential risk of harm from unauthorized use or disclosure of your personal data,
                the purposes for which we process your personal data and whether we can achieve those purposes through other means and the applicable legal requirements.

                <div style={{marginTop: "3%"}}></div>
                We will also retain and use your personal information to extent necessary to comply with our legal obligations, resolve disputes and enforce our policies.

                <div style={{marginTop: "3%"}}></div>
                If you stop using our services or if you delete your account with us, we will delete your information or store your information in an aggregated and anonymized format.

              </p>
              <Row>
                <Col sm={3}>
                </Col>
                <Col sm={3}>
                  <label style={{fontSize: "14px", fontWeight : "bolder", marginTop: "3%"}}>
                    I Agree
                  </label>
                </Col>
                <Col sm={5}>
                  <Switch
                    checked={true}
                    onChange={handleChangeSwitch}
                    // onClick={()=>handleClickSwitch()}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Col>
                <Col sm={1}>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalScreen1"
        tabIndex="-1"
        aria-labelledby="modalScreen1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modalScreen1} toggle={openfrstModal} centered>
            <ModalBody className="modal-body p-5">
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openfrstModal}
                  className="btn-close"
                  data-bs-dismiss="modalScreen1"
                  aria-label="Close"
                ></button>
              </div>
              <Row>
                <h5 className="text-muted mb-1" style={{fontSize: "14px"}} /* style={{marginLeft: "10%"}} */>
                  <b>Continue your applications via</b>
                </h5>
              </Row>
              <Card style={{backgroundColor: "#F0FEED"}}>
                <CardBody>
                  <Row>
                    <Col lg={3}>
                    </Col>
                    <Col lg={7}>
                    { isCompleted === "0" ? 
                      <button type="submit" className="btn mt-5 noser-button-md" onClick={handleOpenModal} style={{ minWidth : "10%", textAlign: "center", backgroundColor : "#F0FEED", borderColor: "green", borderWidth: 2, borderRadius: 10, fontWeight: "bold"}}>
                        JobSpace Profile
                      </button>
                      : 
                      <button type="submit" className="btn mt-5 noser-button-md" onClick={openModalResume} style={{ minWidth : "10%", textAlign: "center", backgroundColor : "#F0FEED", borderColor: "green", borderWidth: 2, borderRadius: 10, fontWeight: "bold"}}>
                        JobSpace Profile
                      </button>
                    }
                    </Col>
                    <Col lg={2}>
                    </Col>
                  </Row>
                  <Row >
                    <p className="text-muted mb-1" style={{textAlign: "center", fontSize: "14px"}}>Your full JobSpace profile will be visible to the employer</p>
                  </Row>
                  <Row>
                    <Col sm={3}>
                    </Col>
                    <Col sm={7} >
                      <button type="submit" className="btn mt-5 noser-button-md" onClick={handleDirectbyResume} style={{ minWidth : "10%", textAlign: "center", backgroundColor : "#F0FEED", borderColor: "green", borderWidth: 2, borderRadius: 10, fontWeight: "bold"}}>
                        Resume Upload
                      </button>
                    </Col>
                    <Col sm={2}>
                    </Col>
                  </Row>
                  <Row >
                    <p className="text-muted mb-1" style={{textAlign: "center", fontSize: "14px"}}>Let your resume apply for you, Your Uploaded file</p><br />  
                    <p className="text-muted mb-1" style={{textAlign: "center", fontSize: "14px"}}>will be sent to the employers</p>
                  </Row>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalScreen2"
        tabIndex="-1"
        aria-labelledby="modalScreen2"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modalScreen2} toggle={openScndModal} centered>
            <ModalBody className="modal-body p-5">
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openScndModal}
                  className="btn-close"
                  data-bs-dismiss="modalScreen2"
                  aria-label="Close"
                ></button>
              </div>
              <Card style={{backgroundColor: "#F0FEED"}}>
                <CardBody>
                  <Row sm={12}>
                    <p className="text-muted mb-1" style={{textAlign:"justify", fontWeight: "bolder", justifyContent: "center", fontSize: "14px"}}>You still need to fill out other needed details in your profile. This might not make your application stand-out.</p>
                  </Row>
                  <Row className="mt-3">
                    <p className="text-muted mb-1" style={{textAlign: "center", fontWeight: "bolder", fontSize: "14px"}}>Do you want to proceed your application?</p>  
                  </Row>
                  <Row className="mt-5">
                    <Col sm={1}>
                    </Col>
                    <Col sm={10}>
                      <button type="submit" className="btn btn-success" onClick={handleOpenResume} style={{minWidth : "20%", textAlign: "center", marginLeft: "20%"}}>
                        Yes
                      </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <button type="submit" className="btn btn-success" onClick={handleOpenModal2} style={{minWidth : "20%", textAlign: "center"}}>
                        No
                      </button>
                    </Col>
                    <Col sm={1}>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalScreen3"
        tabIndex="-1"
        aria-labelledby="modalScreen3"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modalScreen3} toggle={openThrdModal} centered>
            <ModalBody className="modal-body p-5">
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openThrdModal}
                  className="btn-close"
                  data-bs-dismiss="modalScreen3"
                  aria-label="Close"
                ></button>
              </div>
              <Card style={{backgroundColor: "#F0FEED"}}>
                <CardBody>
                  <Row className="mt-3">
                    <p className="text-muted mb-1" style={{textAlign: "center", fontWeight: "bolder", fontSize: "14px"}}>Do you want to proceed editing your profile?</p>  
                  </Row>
                  <Row className="mt-5">
                    <Col sm={2}>
                    </Col>
                    <Col sm={9}>
                      <button type="submit" className="btn btn-success" onClick={handleNavigateProfile} style={{minWidth : "20%", textAlign: "center"}}>
                        Yes, continue
                      </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <button type="submit" className="btn btn-success" onClick={NavigateJobPost} style={{minWidth : "20%", textAlign: "center"}}>
                        No
                      </button>
                    </Col>
                    <Col sm={1}>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <div
        className="modal fade"
        id="submitResume"
        tabIndex="-1"
        aria-labelledby="submitResume"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={submitResume} toggle={openModalResume} centered>
            <ModalBody className="modal-body p-5">
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openModalResume}
                  className="btn-close"
                  data-bs-dismiss="submitResume"
                  aria-label="Close"
                ></button>
              </div>
              <Row>
                <h5 className="text-muted mb-1" style={{ fontSize: "14px"}}>
                  <b>Applying for</b>
                </h5>
                <Col md={8}>
                  <h2 className="mt-3" style={{color:"#376837"}}>{title}</h2>
                  <h5 className="mb-1">{companyName}</h5>
                </Col>
              </Row>
              <Row>
                <div className="mt-3 mt-lg-0">
                  <li className="list-inline-item">
                    <p className="text-muted mb-0" style={{ fontSize: "14px"}}>
                      <i className="mdi mdi-map-marker"></i>{" "}
                      {getWorkLocation}
                    </p>
                  </li>
                </div>
              </Row>
              <Row>
                <div className="mt-3 mt-lg-0">
                  <li className="list-inline-item">
                    <p className="text-muted mb-0" style={{ fontSize: "14px"}}>
                      <i className="mdi mdi-clock"></i>{" "}
                      {_jobEmploymentType}
                    </p>
                  </li>
                </div>
              </Row>
              <div className="mb-4 mt-5">
                {/* <Input
                  type="file"
                  className="form-control"
                  id="inputGroupFile01"
                  onChange={UploadResume}
                /> */}
              </div>
                <button type="submit" className="btn btn-success mt-5" onClick={handleApplyNoResume} style={{textAlign: "center", marginLeft: "30%"}}>
                  Submit Application
                </button>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <div
        className="modal fade"
        id="applybyresume"
        tabIndex="-1"
        aria-labelledby="applybyresume"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={applybyresume} toggle={openModalApplyResume} centered>
            <ModalBody className="modal-body p-5">
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openModalApplyResume}
                  className="btn-close"
                  data-bs-dismiss="applybyresume"
                  aria-label="Close"
                ></button>
              </div>
              <Row>
                <Input
                  type="file"
                  className="form-control"
                  id="inputGroupFile01"
                  onChange={UploadResume}
                />
              </Row>
              <Row lg={12} className="mt-2">
                <Col lg={1}>
                  <Input
                    style={{border: "1px solid green"}}
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    // to="otpscreen"
                    onClick={e =>onChangeCheckboxResume(e)}
                  />
                </Col>
                <Col lg={11}>
                  <p className="text-muted mb-0" style={{ fontSize: "9px", textAlign: "justify", marginLeft: "-12%"}}>
                    By uploading your resume and apply for this job, you agreed to our 
                    <b style={{color: "#0A6219"}} to ="datapolicy" onClick={openModal}> Data Privacy Policy </b>
                    of collecting, storing and using your personal data as described in our policy 
                    to matches the candidate skill needed by the employers. 
                  </p>
                </Col>
              </Row>
              <button type="submit" disabled={isCheckbox} className="btn btn-success mt-5" onClick={handleApplySignedIn} style={{minWidth : "20%", textAlign: "center", marginLeft: "30%"}}>
                Submit Resume
              </button>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ApplicationPage;
