import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Input, Label, select,AccordionItem,AccordionBody,AccordionHeader,UncontrolledAccordion, FormGroup, Alert} from "reactstrap";
import moment from "moment";
//import { Button, Accordion, } from "react-bootstrap";

//Import Images
import { AppConfiguration } from "read-appsettings-json";
import { GetRequest, PostRequest } from '../../../noser-dataaccess'
import axios from 'axios'

const Resume = () => {
  const [color, setcolor] = useState("")
  const [message, setmessage] = useState("")
  const [fade, setfade] = useState("")

  const [skillAndExp, setSkillExpr] = useState([
    {
      "id":"1",
      "name":"nexeus mio"
    },
    {
      "id":"2",
      "name":"nethan marco"
    }
  ])

 const rowClasses = 'custom-row-class-success';
  useEffect(() => {
  },[]);


  return (
    <React.Fragment>
      <Card className="job-detail overflow-hidden mt-1">
        
        <CardBody style={{ marginTop : "7%", borderColor: "#F0FEED"}} >
        <Row>
          <Col lg={2}>
          </Col>
          <Col lg={5}>
            <Card /* className="mt-5" */ style={{ background: "#F0FEED"}}>
              <CardBody >
                <Row className="mt-2" style={{ background: "#F0FEED"}}>
                  <button 
                    //type="submit" 
                    style={{
                      border:"none",
                      //background:"#ffffff",
                      //position:"absolute",
                      top:0,
                      right:0,
                      width:"110px",
                      height: "110px",
                      borderRadius:"50%",
                      cursor:"pointer"
                    }}
                    //onClick={onClickSearch}
                  
                  >
                    <i className="mdi mdi-face-recognition" aria-hidden="true" 
                    style={{
                    
                      fontSize:"50px"
                      
                      }} > </i>
                  </button>
                  <Col>
                    <Input
                      type="input"
                      className="form-control"
                      id="nameControlInput"
                      placeholder="name"
                      style={{background:"#F7FEF6", border:"none"}}
                      // //value={jobDesc}
                      // disabled={true}
                    />
                      <Input
                      type="input"
                      className="form-control"
                      id="nameControlInput"
                      placeholder="Gmail"
                      style={{background:"#F7FEF6", border:"none"}}
                      // //value={jobDesc}
                      // disabled={true}
                    />
                      <Input
                      type="input"
                      className="form-control"
                      id="nameControlInput"
                      placeholder="Number"
                      style={{background:"#F7FEF6", border:"none"}}
                      // //value={jobDesc}
                      // disabled={true}
                    />
                  </Col>
                  
                </Row>
                {/* <Row className="mt-4" style={{ background: "#F0FEED", background:"#EEEEEE"}}> <h6></h6></Row> */}
                <hr style={{color: "#000000"}}/>
                <h6 className="mt-4">Resume Summary</h6>
                <Row className="mt-2" style={{ background: "#F0FEED"}}>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="nameControlInput"
                    // placeholder="resume"
                    style={{background:"#F7FEF6"}}
                    // value={jobDesc}
                    // disabled={true}
                  />
                </Row>
                <h6 className="mt-2">Work Experience</h6>
                <Row className="mt-2" style={{ background: "#F0FEED"}}>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="nameControlInput"
                    // placeholder="resume"
                    style={{background:"#F7FEF6"}}
                    // value={jobDesc}
                    // disabled={true}
                  />
                </Row>
                <h6 className="mt-2">Educational Background</h6>
                <Row className="mt-2" style={{ background: "#F0FEED"}}>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="nameControlInput"
                    // placeholder="resume"
                    style={{background:"#F7FEF6"}}
                    // value={jobDesc}
                    // disabled={true}
                  />
                </Row>
                <h6 className="mt-2">Job Preferences</h6>
                <Row className="mt-2" style={{ background: "#F0FEED"}}>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="nameControlInput"
                    // placeholder="resume"
                    style={{background:"#F7FEF6"}}
                    // value={jobDesc}
                    // disabled={true}
                  />
                </Row>
                <h6 className="mt-2">Trainings and Certifications</h6>
                <Row className="mt-2" style={{ background: "#F0FEED"}}>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="nameControlInput"
                    // placeholder="resume"
                    style={{background:"#F7FEF6"}}
                    // value={jobDesc}
                    // disabled={true}
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ borderRadius: 5, borderColor: "#F0FEED"}}>
              <CardBody style={{ backgroundColor: "#F0FEED"}}>
                <h6>Skills</h6>
                <div style={{marginLeft: "1%", background: "#F0FEED"}} >
                  {skillAndExp.map((d)=> 
                    <div className="job-detail-desc mt-2" key={d.id}>
                      <ul className="job-detail-list list-unstyled mb-0 text-muted">
                        <i className="mdi mdi-circle">
                          &nbsp;&nbsp;&nbsp;{d.name}
                        </i>
                      </ul>
                    </div>
                  )}
                </div>

                <h6 className="mt-5">Online Presence</h6>
                <Row className="mt-2" style={{ background: "#F0FEED"}}>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="nameControlInput"
                    // placeholder="resume"
                    style={{background:"#F7FEF6"}}
                    // value={jobDesc}
                    // disabled={true}
                  />
                </Row>
                <h6 className="mt-5">Salary Expected</h6>
                <Row className="mt-2" style={{ background: "#F0FEED"}}>
                  <Input
                    type="text"
                    className="form-control"
                    id="nameControlInput"
                    // placeholder="(P)"
                    style={{background:"#F7FEF6"/* , textAlign: "right" */}}
                    // value={jobDesc}
                    // disabled={true}
                  />
                </Row>
                <h6 className="mt-5">Personal Details</h6>
                <Row className="mt-2" style={{ background: "#F0FEED"}}>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="nameControlInput"
                    // placeholder="resume"
                    style={{background:"#F7FEF6"}}
                    // value={jobDesc}
                    // disabled={true}
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={2}>
          </Col>
        </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Resume;
