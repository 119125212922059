import React, { useRef, useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Input, Label, select, Modal, ModalBody,} from "reactstrap";

//Import Images
import JobDetailImage from "../../../assets/images/job-detail.jpg";
import JobImage10 from "../../../assets/images/featured-job/img-10.png";
import { Icon } from "@iconify/react/dist/offline.js";
import { AppConfiguration } from "read-appsettings-json";
import { GetRequest, PostRequest } from '../../../noser-dataaccess'
import axios from 'axios'
import dateFormat from 'dateformat';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";
//Import Images
import { Form } from "react-bootstrap";
import { Route, Link, Routes, useLocation, useSearchParams, useNavigate   } from "react-router-dom";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";


const JobDetailsDescription = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentPath = window.location.href;
  /* Redirect Pages  */
  const navigate = useNavigate();
  const navigateSignIn = useNavigate();
  const navigateSignUp = useNavigate();
  const navigateCandidProf = useNavigate();
  const navigateSuccScreen = useNavigate();
  const navigateResume = useNavigate();
  const navigateJobPost = useNavigate();
  const navigateDirectApply = useNavigate();
  const NavigateApplication = useNavigate();
  const navigateCompOvrview = useNavigate();
  /* End Redirect Pages */

  /* Modals */
  const [applydisbled, setApplyDsbld] = useState(true);
  const [submitResume, setModalResume] = useState(false);
  const openModalResume = () => setModalResume(!submitResume);
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);
  const [modalOtp, setModalOtp] = useState(false);
  const openModalOtp = () => setModalOtp(!modalOtp);
  const [modalScreen1, setfrstModal] = useState(false);
  const openfrstModal = () => setfrstModal(!modalScreen1);
  const [modalScreen2, setScndModal] = useState(false);
  const openScndModal = () => setScndModal(!modalScreen2);
  const [modalScreen3, setThrdModal] = useState(false);
  const openThrdModal = () => setThrdModal(!modalScreen3);
  const [applybyresume, setModalApplyByResume] = useState(false);
  const openModalApplyResume = () => setModalApplyByResume(!applybyresume);
  const [modalhris, setModalHris] = useState(false);
  const openHrisModal = () => setModalHris(!modalhris);
  /* End modals */

  /* Job Details */
  const [jobDetails, setJobDetails] = useState([])
  const [idSlctd, setKeySession] = useState(JSON.parse(sessionStorage.getItem("key")));
  const [jobDesc, setJobDiscription] = useState("")
  const [skillAndExp, setSkillExpr] = useState([])
  const [responsibility, setResponsibilities] = useState([])
  // const [qualifications, setQualifications] = useState([])
  const [companyName, setCompanyName] = useState("")
  const [title, setTitle] = useState("")
  const [companyNumber, setCompNumber] = useState("")
  const [companyEmail, setCompEmail] = useState("")
  const [companyWebsite, setCompPage] = useState("")
  const [companyLocation, setCompanyLocation] = useState("")
  const [landMark, setLocation] = useState("")
  const [jobNotes, setJobNotes] = useState("")

  /* Job Overview Details */
  const [jobType, setJobType] = useState("")
  const [category, setCategory] = useState("")
  const [jobOverviewLocation, setJobOverviewLocation] = useState("")
  const [offeredSalary, setOfferedSalary] = useState("")
  const [posted, setPosted] = useState("")
  const [experience, setExperience] = useState([])
  const [gender, setGender] = useState("0")
  const [industry, setIndustry] = useState("")
  const [_qualification, setQualifications] = useState("")
  const [qualification, setQualification] = useState([])
  const [level, setLevel] = useState("")
  const [applied, setApplied] = useState("")
  const [applicationDate, setApplicationDate] = useState("")
  const [companyId, setCompanyId] = useState("")
  const [jobPostingId, setJobPostingId] = useState("")
  const [jobDepartment, setjobDept] = useState([])
  const [employementType, setApplicantType] = useState([])
  const [educLevel, setEdLevel] = useState([])
  const [empJobType, setEmpJobType] = useState([])
  const [jobDetailsPostingId, setPostingJobId] = useState("")
  const [validate, setValidate] = useState("")
  const [numberApplcants, SetNumApplicants] = useState("") 
  const [workingDays, setWorkingDays] = useState("") 
  const [expRequired, setExpRequired] = useState("") 
  const [jobId, setJobId] = useState("") 
  

  // drop down job overview
  const [_jobDepartment, setJobDepartment] = useState("")
  const [_jobEmploymentType, setEmploymentType] = useState("")
  const [_jobExprnc, setJobExp] = useState("")
  const [_jobQualification, setJobQuali] = useState("")
  const [_jobEducLvl, setJobEducLvl] = useState("")
  // const [modal, setModal] = useState(false);
  // const openModal = () => setModal(!modal);
  const [checked, setChecked] = React.useState(true);
  const [filesResume, setFilePdf] = useState("") 
  const [dataPrivacy, setDataPrivacy] = useState("N")
  const [message, setMessage] = useState("")
  const [isCheckbox, setCheckbox] = useState(true) 
  const [ date , setDate] = useState("")
  const [employeeIdTmp, setEmployeeId] = useState("")
  const [_createdDate, setCreatedDate] = useState("") 
  const [_endDate, setEnddate] = useState("") 
  const [emailAdd, setEmail] = useState("")
  const [fullName, setFullName] = useState("")
  const [contactNo, setContactNo] = useState("")
  const [isCompleted, setDataRsitrct] = useState("") 

  
  const [employeeNameDDL, setemployeeName] = useState([])
  const [generateLink, setgenerateLink] = useState("") 
  const [profileId, setprofileId] = useState("") 
  const [isHris, setHris] = useState("") 
  const [posting_id, setPostingId] = useState("")
  const [compIndustry, setCompIndustry] = useState("")
  const [checkValidation, setCheckValidation] = useState("")
  const [getWorkLocation, setWorkLocation] = useState("")
  const textRef = useRef();

  useEffect(() => {
    GetCompanyProfile()
    const isValidate = JSON.parse(sessionStorage.getItem("userCollection"))
    console.log("isValidate")
    console.log(isValidate)
    if (isValidate === null){
      setValidate("0")
      setEmployeeId("")
      GetTestRestrict("")
      setEmail("")
      setFullName("")
      setContactNo("")
    }
    else {
      setValidate("1")
      setEmployeeId(isValidate[0].userId)
      let fullNameTmp = isValidate[0].firstName + " " + isValidate[0].lastName
      setEmail(isValidate[0].email)
      setFullName(fullNameTmp)
      setContactNo(isValidate[0].contact)
      GetTestRestrict(isValidate[0].userId)
    }
    
    const params = new URLSearchParams(window.location.search)
    let id = params.get('postingId')
    if(id===null){
      setCheckValidation("0")
    }
    else{
      setCheckValidation("1")
    }
  },[]);
  
  /* Get items */
  async function OnLoadScreen(){
    setJobType("")
    setCategory("")
    setJobOverviewLocation("")
    setOfferedSalary("")
    setPosted("")
    setExperience("")
    setGender("0")
    setIndustry("")
    // setQualification("")
    setLevel("")
    setApplied("")
  }
  const GetCompanyProfile = async() => {
    let filter_data = {"isDeleted": "0"}
    const dataTmp = []
    const Params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "CompanyProfile",
      "filter_data" : filter_data
    }
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {
      let data = res.data
      data.CompanyProfile.map(function(itm,i){
        const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
        const json = JSON.parse(strJson.replace(/'/g,'"'));
        dataTmp.push(json)
      })       
        
      // setJson(dataTmp)  
      // // console.log("dataTmp")
      // // console.log(dataTmp)
      GetJobDescription(dataTmp); 
    })
  };
  const GetJobDescription =async(_dataList)=> {
    // // // console.log("_dataList")
    // // // console.log(_dataList)
    
    const params = new URLSearchParams(window.location.search)
    let id = params.get('postingId')
    let profile = params.get('profileId')
    let ishris = ""
    let ispostingId = ""
    if(id===null){
      // alert("null")
      // console.log("if")
      ishris="0"
    }
    else{
      // alert("not null")
      // console.log("else")
      ishris="1"
      ispostingId=id
    }
    try{
      let resp = await GetRequest({
        url : AppConfiguration.Setting().mongoJobspace + "action/get",
        params : {
          "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
          "_collection" : "JobPosting",
          "filter_data" : {}
        }
      })
      if(resp.status==="1"){
        setJobPostingId(idSlctd)
        // console.log("ishris")
        // console.log(ishris)
        // // console.log("ispostingId")
        // // console.log(ispostingId)
        let jobPostingList = []
        let companyProfList = []
        if(ishris==="0"){
          console.log("if")
          // console.log(jobPostingList[0].id)
          // setJobId(jobPostingList[0].id)
          // setJobPostingId(idSlctd)
          jobPostingList = resp.JobPosting.filter(x=>x.id===idSlctd)
          companyProfList = _dataList.filter(List => jobPostingList.some(itm => itm.companyId === List.userId))
          // console.log("if")

          // Job Posting
          setJobId(jobPostingList[0].id)
          setJobDiscription(jobPostingList[0].description)
          setTitle(jobPostingList[0].title)
          setSkillExpr(jobPostingList[0].skillsExperience)
          setResponsibilities(jobPostingList[0].responsibility)
          setQualification(jobPostingList[0].qualifications)
          setJobDetails(resp.JobPosting.filter(x=>x.id===idSlctd))
          setjobDept(jobPostingList[0].jobDepartment)
          setApplicantType(jobPostingList[0].applicantType)
          setEdLevel(jobPostingList[0].educationalLevel)
          let _salaryTmp = toTitleCase(jobPostingList[0].salaryCurrency) + " " + jobPostingList[0].salaryMin + " " + "-" + " " + toTitleCase(jobPostingList[0].salaryCurrency)  + " " + jobPostingList[0].salaryMax
          // let _salaryTmp = jobPostingList[0].salaryMin + " " + "-" + " " + jobPostingList[0].salaryMax
          setOfferedSalary(_salaryTmp)
          setEmpJobType(jobPostingList[0].jobTime)
          setJobNotes(jobPostingList[0].jobPostingNote)
          setWorkingDays(jobPostingList[0].workingDaysAndHrs)
          // setIndustry(jobPostingList[0].keyWords)
          setCreatedDate(jobPostingList[0].createdDate)
          setEnddate(jobPostingList[0].endDate)
          setCompIndustry(jobPostingList[0].industry)
  
          // let x1 = jobPostingList[0].jobDepartment
          // let x2 = jobPostingList[0].jobTime
          // let x3 = jobPostingList[0].applicantType
          // let x4 = jobPostingList[0].qualifications
          // let x5 = jobPostingList[0].educationalLevel
  
          setJobDepartment(jobPostingList[0].dept)
          setEmploymentType(jobPostingList[0].workType)
          setJobExp(jobPostingList[0].aplicantExp)
          setExpRequired(jobPostingList[0].experienceMin)
          setJobEducLvl(jobPostingList[0].education)
          setPostingJobId(jobPostingList[0].postingId)
          setWorkLocation(jobPostingList[0].workLocation)
          // setjobId(jobPostingList[0].id) // python id
  
          let applicationDate = dateFormat(jobPostingList[0].endDate).split(" ")
          // let s1 = applicationDate[0]
          let s2 = applicationDate[1]
          let s3 = applicationDate[2]
          let s4 = applicationDate[3]
          let joinDate = s2 + " " + s3 + ", " + s4
          let spliAppEnd = joinDate
          setApplicationDate(spliAppEnd)
  
          // company  profile
          let compTmp = (companyProfList[0].companyName).toLowerCase()
          let toCaseComp = toTitleCase(compTmp)
          setCompanyName(toCaseComp)
          setCompanyId(companyProfList[0].id)
          setCompNumber(companyProfList[0].contactNumberPhone)
          let emailTmp = (companyProfList[0].email).toLowerCase()
          let toCaseEmail = toTitleCase(emailTmp)
          setCompEmail(toCaseEmail)
          let webTmp = (companyProfList[0].website).toLowerCase()
          let toCaseWeb = toTitleCase(webTmp)
          setCompPage(toCaseWeb)
          let _location = /* companyProfList[0].baranggay + " " +  */companyProfList[0].city + " " + companyProfList[0].country //+ " " + companyProfList[0].locationStreet + " " + companyProfList[0].locationCity + " " + companyProfList[0].locationProvince + " " + companyProfList[0].locationZipCode
          let lowerLoc = (_location).toLowerCase()
          let toCaseLoc = toTitleCase(lowerLoc)
          setCompanyLocation(toCaseLoc)
          let landMark = (companyProfList[0].city).toLowerCase() //+ "," + " " + fltrData[0].locationProvince
          // let landMarkTmp = companyProfList[0].email
          let toCaseLandmark = toTitleCase(landMark)
          setLocation(toCaseLandmark)
          GetNumOfApplicants(jobPostingList[0].title)
        }
        else{
          console.log("else")
          // console.log(jobPostingList[0].id)
          // setJobPostingId(idSlctd)
          jobPostingList = resp.JobPosting.filter(x=>x.postingId===ispostingId)
          // // console.log("jobPostingList")
          // // console.log(jobPostingList)
          companyProfList = _dataList.filter(List => jobPostingList.some(itm => itm.companyId === List.userId))
          // console.log("companyProfList")
          // console.log(companyProfList)
          // console.log("else qwerty")

          // Job Posting
          setJobId(jobPostingList[0].id)
          setJobDiscription(jobPostingList[0].description)
          setTitle(jobPostingList[0].title)
          setSkillExpr(jobPostingList[0].skillsExperience)
          setResponsibilities(jobPostingList[0].responsibility)
          setQualification(jobPostingList[0].qualifications)
          setJobDetails(resp.JobPosting.filter(x=>x.id===idSlctd))
          setjobDept(jobPostingList[0].jobDepartment)
          setApplicantType(jobPostingList[0].applicantType)
          setEdLevel(jobPostingList[0].educationalLevel)
          let _salaryTmp = jobPostingList[0].salaryMin + " " + "-" + " " + jobPostingList[0].salaryMax
          setOfferedSalary(_salaryTmp)
          setEmpJobType(jobPostingList[0].jobTime)
          setJobNotes(jobPostingList[0].jobPostingNote)
          setWorkingDays(jobPostingList[0].workingDaysAndHrs)
          // setIndustry(jobPostingList[0].keyWords)
          setCreatedDate(jobPostingList[0].createdDate)
          setEnddate(jobPostingList[0].endDate)
          setCompIndustry(jobPostingList[0].industry)
          setWorkLocation(jobPostingList[0].workLocation)
          // console.log("jobPostingList[0].workLocation")
          // console.log(jobPostingList[0].workLocation)
  
          // let x1 = jobPostingList[0].jobDepartment
          // let x2 = jobPostingList[0].jobTime
          // let x3 = jobPostingList[0].applicantType
          // let x4 = jobPostingList[0].qualifications
          // let x5 = jobPostingList[0].educationalLevel
  
          setJobDepartment(jobPostingList[0].dept)
          setEmploymentType(jobPostingList[0].workType)
          setJobExp(jobPostingList[0].aplicantExp)
          setExpRequired(jobPostingList[0].experienceMin)
          setJobEducLvl(jobPostingList[0].education)
          setPostingJobId(jobPostingList[0].postingId)
  
          let applicationDate = dateFormat(jobPostingList[0].endDate).split(" ")
          // let s1 = applicationDate[0]
          let s2 = applicationDate[1]
          let s3 = applicationDate[2]
          let s4 = applicationDate[3]
          let joinDate = s2 + " " + s3 + ", " + s4
          let spliAppEnd = joinDate
          setApplicationDate(spliAppEnd)
  
          // company  profile
          let compTmp = (companyProfList[0].companyName).toLowerCase
          console.log("compTmp")
          console.log(compTmp)
          let toCaseComp = toTitleCase(compTmp)
          setCompanyName(toCaseComp)
          setCompanyId(companyProfList[0].id)
          setCompNumber(companyProfList[0].contactNumberPhone)
          let emailTmp = (companyProfList[0].email).toLowerCase
          let toCaseEmail = toTitleCase(emailTmp)
          setCompEmail(toCaseEmail)
          let webTmp = (companyProfList[0].website).toLowerCase
          let toCaseWeb = toTitleCase(webTmp)
          setCompPage(toCaseWeb)
          let _location = /* companyProfList[0].baranggay + " " +  */companyProfList[0].city + " " + companyProfList[0].country //+ " " + companyProfList[0].locationStreet + " " + companyProfList[0].locationCity + " " + companyProfList[0].locationProvince + " " + companyProfList[0].locationZipCode
          let lowerLoc = (_location).toLowerCase
          let toCaseLoc = toTitleCase(lowerLoc)
          setCompanyLocation(toCaseLoc)
          let landMark = (companyProfList[0].city).toLowerCase //+ "," + " " + fltrData[0].locationProvince
          // let landMarkTmp = companyProfList[0].email
          let toCaseLandmark = toTitleCase(landMark)
          setLocation(toCaseLandmark)
          GetNumOfApplicants(jobPostingList[0].title)
        }
      }
    }
    catch(error){
      // setMessage(error.message)
      // alert("error: " + error.message)
    }
  };
  const GetNumOfApplicants = async(postingId) => {
    let filter_data = {"title" : postingId}
    const _dataTmp = []
    const Params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "Candidates",
      "filter_data" : filter_data
    }
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {
      let data = res.data
      data.Candidates.map(function(itm,i){
        const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
        const json = JSON.parse(strJson.replace(/'/g,'"'));
        _dataTmp.push(json)
      })
      SetNumApplicants(String(parseFloat(data.Candidates.length))); 
    })
    GetEmployeeDDL()
  };
  const GetEmployeeDDL = async() => {
        
    const params = {
        "IpAddress":"0.0.0.0",
        "ClientId":"",
        "UserId":"0"
    }
    await axios
    .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetJobSpaceEmployeeProfiles", params)
    .then(res => {
        const data = res.data.employees;
        // console.log("GetJobSpaceEmployeeProfiles")
        // console.log(data)
        setemployeeName(data)
    })
    .catch(error=>{
        // this.setState({
        //     isloading   :   false,
        //     alerttype   :   "Error!",
        //     isshow      :   true,
        //     color       :   "danger",
        //     message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
        //     fade        :   true
        // })
    })
    // GetCompanyProfile()
  };

  function toTitleCase(str) {
    const titleCase = str
      .toLowerCase()
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  
    return titleCase;
  }
  
  const GetTestRestrict = async(empId) => {
    // // // console.log("userCol")
    // // // console.log(typeof userCol === "undefined")
    let fltrUser = ""
    // let _fltrUser = userCol.length===0 || userCol === null ? "" : userCol
    if(typeof empId === "undefined"){
      // // console.log("if")
      fltrUser = ""
    }
    else{
      // // console.log("else")
      fltrUser = empId
    }
    // // console.log("empId")
    // // console.log(empId)
    let filter_data = {"isDeleted": "0", "userId" : fltrUser}
    const dataTmp = []
    const Params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "CandidateRegisteredCheckList",
      "filter_data" : filter_data
    }
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {
      let data = res.data
      if(data.CandidateRegisteredCheckList.length === 0){
        console.log("if")
        setDataRsitrct("0"); 
      }
      else{
        // let getUserId = JSON.parse(sessionStorage.getItem("userCollection"))
        data.CandidateRegisteredCheckList.map(function(itm,i){
          const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
          const json = JSON.parse(strJson.replace(/'/g,'"'));
          dataTmp.push(json)
        })       
          
        // setJson(dataTmp)  
        console.log("else")
        setDataRsitrct("1"); 
        // // // console.log("dataTmp[0].basicInfoCandidate")
        // // // console.log(dataTmp[0].basicInfoCandidate)
      }
    })
  };

  /* End Get Items */

  /* Function Items */
  const submitClick =()=>{

    const dataList ={
      department : _jobDepartment,
      offeredSalary : offeredSalary,
      location : landMark,
      employementType : _jobEmploymentType,
      experience : _jobExprnc,
      industry : "",
      qualification : _jobQualification,
      level : "",
      educationLevel : _jobEducLvl,
      NoOfApplcnts : "",
      companyId : companyId,
      jobPostingId : idSlctd,
      status : "NW",
      
      isDeleted : "0",
      isModified : "0",
      modifiedBy : "romel",
      modifiedDate : "04/30/2024",
      createdBy : "romel",
      createdDate : "04/30/2024",
    }
    // // console.log("dataList")
    // // console.log(dataList)
    
    const params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "JobOverview",
      "doc_data" : dataList
    }

    axios
    .post(AppConfiguration.Setting().mongoJobspace + "action/add", params )
    .then(resp =>{
      let data = resp.data
      if(resp.data==="success"){
        alert("Success")
        OnLoadScreen()
      }
      else{
        alert("error")
      }
    })
    .catch(error =>{
      alert("error: " + error)
    })
  }

  /* End Function Items */

  /* On Change Items */

  const onChangeCategory =(e)=>{
    setCategory(e.target.value)
  }
  const onChangeJobOverViewLocation =(e)=>{
    setJobOverviewLocation(e.target.value)
  }
  const onChangeExperience =(e)=>{
    setExperience(e.target.value)
  }
  const onChangeIndustry =(e)=>{
    setIndustry(e.target.value)
  }
  const onChangeLevel =(e)=>{
    setLevel(e.target.value)
  }
  const onChangeApplied =(e)=>{
    setApplied(e.target.value)
  }
  const handleChangeSwitch =(e)=>{
    // setChecked(true);
    // // // console.log(e)
    
    if(checkValidation==="0"){
      // not hris related
      sessionStorage.setItem("companyId", JSON.stringify(companyId))
      sessionStorage.setItem("key", JSON.stringify(jobPostingId))
      // // // console.log(jobPostingId)
      navigate("/ApplicationPage");
    }
    else{
      // hris related
      CheckIfExists()
      // setModalHris(true
    }
  }
  const CheckIfExists =async()=>{
     
    const params = new URLSearchParams(window.location.search)
    let id = params.get('profileId')

    
    let filter_data = {"isDeleted": "0", "UserId" : id}
    const dataTmp = []
    const Params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "CandidatesProfile",
      "filter_data" : filter_data
    }
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {
      let data = res.data
      data.CandidatesProfile.map(function(itm,i){
        const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
        const json = JSON.parse(strJson.replace(/'/g,'"'));
        dataTmp.push(json)
      })       
        
      // setJson(dataTmp)  
      console.log("dataTmp")
      console.log(dataTmp)
      if(dataTmp.length>0){
        console.log("exixst")
    
          let toNavAppl = {
            "fullName"                : dataTmp[0].FirstName + " " + dataTmp[0].MiddleName + " " + dataTmp[0].LastName,
            "mobileNumber"            : dataTmp[0].MobileNumber,
            "emailAddress"            : dataTmp[0].EmailAddress,
            "employeeId"              : dataTmp[0].UserId,
          }
          sessionStorage.setItem("companyId", JSON.stringify(companyId))
          sessionStorage.setItem("key", JSON.stringify(jobId))
          sessionStorage.setItem("dataHris", JSON.stringify(toNavAppl))
          console.log(companyId)
          console.log(jobId)
          console.log(toNavAppl)
          // NavigateApplication("/ApplyCompProf")
      }
      else{
        setModalHris(true)
        // console.log("no record")
      }
    })

  }
  const handleClickApply =()=>{
    // alert("clicked")
    if(checkValidation==="0"){
      // not hris related
      setfrstModal(true)
    }
    else{
      // hris related
      setModalHris(true)
    }
  }
  function handleNavCompany (){
    // alert("test")
    navigateCompOvrview("/JobDescEmployer");
  }

   /* End On Change Items */

   /* Modal Functions */
   const handleOpenModal =(e)=>{
    setfrstModal(false)
    setScndModal(true)
  }
  const handleOpenModal2 =(e)=>{
    setScndModal(false)
    setThrdModal(true)
  }
  const handleOpenResume =(e)=>{
    // setScndModal(false)
    setThrdModal(false)
    // setModalResume(true)
    navigateDirectApply("/ApplyCompProf")
  }
  const handleDirectbyResume =()=>{
    // setfrstModal(false)
    // setCheckbox(true)
    // setDataPrivacy("N")
    // setFilePdf("")
    // setModalApplyByResume(true)
    navigateResume("/ApplyResume")
  }
   /*  End Modal Functions */

  const handleNavigateProfile = () =>{
    navigateCandidProf("/CandidateProfileApplication");
  }
  const NavigateSignUp = () =>{
    setModalHris(false)
    navigateSignUp("/signup")
  }
  const NavigateJobPost = () =>{
    navigateJobPost("/JobDetailsDescription");
    setThrdModal(false)
  }
  const UploadResume=(e)=>{
    // // console.log(e.target.files[0])
    if(typeof e.target.files[0] === "undefined"){
      setFilePdf("")
    }
    else {
      setFilePdf(e.target.files[0].name)
    }
  }
  const onChangeCheckboxResume=(e)=>{
    // // console.log(e.target.checked)
    if(e.target.checked===true){
      // alert("checked")
      setDataPrivacy("Y")
      if(message==="") {
        setCheckbox(true)
      }
      else {
        setCheckbox(false)
      }
      // setOpenModalPrivcy(true)
      // openModal(openModal)
    }
    else{
      // alert("not checked")
      setDataPrivacy("N")
      setCheckbox(true)
    }
  } 
  const handleApplySignedIn = () =>{
    const dateTmp = moment(date).format('LLLL').split(", ")
    const s1 = dateTmp[1]
    const s2 = dateTmp[2]
    const combDate = s1 + " " + s2
    // // // console.log("combDate")
    // // // console.log(combDate)

    const data = {
      email : emailAdd,
      name : fullName,
      contact : contactNo,
      message : message,
      jobDepartment : _jobDepartment,
      employementType : _jobEmploymentType,
      experience : _jobExprnc,
      qualification : _jobQualification,
      educLevel : _jobEducLvl,
      expectedSalary : offeredSalary,
      city : landMark,
      industry : "",
      level : "",
      isRegister : "1",
      resume : filesResume,
      dataPrivacy : dataPrivacy,
      postingId : jobDetailsPostingId,
      title : title,
      companyName : companyName,
      employeeId : employeeIdTmp,
      status : "NW",
      createdDate : _createdDate,
      endDate : _endDate,
      jobDocNo : numberApplcants,
      dateApplied : combDate,

      createdDate : moment(date).format("MM/DD/YYYY"),
      createdBy : employeeIdTmp,
      isModified : "0",
      modifiedDate : moment(date).format("MM/DD/YYYY"),
      modifiedby : employeeIdTmp,
      isDeleted : "0",
    }
    // // console.log("data")
    // // console.log(data)
    
    const params = {
      "_collection" : "Candidates",
      "doc_data" : data
    }

    axios
    .post(AppConfiguration.Setting().mongoJobspace + "action/add", params )
    .then(resp =>{
      // let data = resp.data
      if(resp.data==="success"){
        sessionStorage.setItem("successdata", JSON.stringify(data))
        navigateSuccScreen("/SuccessScreen")
      }
      else{
        alert("error")
      }
    })
    .catch(error =>{
      alert("error: " + error)
    })
  }

  // START FUNCTION GENERATE LINK
  const handleChangeEmployee = (e) => {
    // // console.log("e")
    // // console.log(e)
    
    if(e.length === 0) {
      setprofileId("")
      return
    }
    queryParams.set("postingId", jobDetailsPostingId);
    //const newSearch = `?${queryParams.toString()}&profileid=${e[0].profileId}`;
    const url = `?postingId=${jobDetailsPostingId}&profileId=${e[0].profileId}`;
    navigate({ search: url });
    // // console.log("url")
    // // console.log(url)
    // setgenerateLink(currentPath + "?" + "postingid" + "=" + jobDetailsPostingId + "&" + "profileid" + "=" + e[0].profileId)
    setgenerateLink("http://uat.jobspace.com.ph:3000/JobDetailsDescription" + url)
    // // // console.log(currentPath + "?postingid=" + jobDetailsPostingId + "&" + "profileid=" + e[0].profileId)
  }

  const copyToClipboard = (e) => {
    let copyText = textRef.current.value;
    let isCopy = copy(copyText);
    // // console.log("isCopy")
    // // console.log(isCopy)
    if (isCopy === true) {
      toast.success("Copied!");
    }
  };
  function FormatDate(date) {
      let m = moment(date, 'MM-DD-YYYY');
      return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
  }
  // END FUNCTION GENERATE LINK


  /* Get profile hris */
  const GetProfileHris = async() =>{
    const urlparams = new URLSearchParams(window.location.search)
    let employeeId = urlparams.get('employeeId')

    const params = {
      "IpAddress":"0.0.0.0",
      "ClientId":"",
      "UserId":"0",
      "EmployeeId":employeeId
    }
    console.log("params")
    console.log(params)
    await axios
    .post(AppConfiguration.Setting().noserapiendpoint + "Employee/getEmployeeInformation", params)
    .then(res => {
      const data = res.data;
      // console.log("data: 749")
      // console.log(data)
      SubmitSignUp(data)
    })
    .catch(error=>{
      console.log("Error: " + error)
    })
  }
  const SubmitSignUp =async(dataList) =>{
        
    let _newDate = new Date();
    let createddate = moment(_newDate).format('MM/DD/YYYY');
    let localtimeString = _newDate.toLocaleTimeString()
    let today = new Date()

    let obj = {
      "email"                   : dataList.emailAddress,
      "userId"                  : dataList.userId,
      "firstName"               : dataList.firstName,
      "lastName"                : dataList.lastName,
      "contact"                 : dataList.mobileNumber,
      "password"                : "Ecapsboj12345*!",
      "isEmployer"              : "0",
      "isCandidate"             : "1",
      "createddate"             : createddate,
      "modifieddate"            : createddate,
      "timestamp"               : localtimeString,
      "isDeleted"               : "0",
      "isDraft"                 : "0",
      "roleId"                  : "",
      "agreeTermsAndCondition"  : "YES"
    }
    const params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "UserCollection",
      "doc_data" : obj
    };
    // console.log(" submit params")
    // console.log(params)

    await axios
    .post(AppConfiguration.Setting().mongoJobspace + "action/add",  params )
    .then(res => {
      const data = res.data;
      // console.log(res.status)
      // console.log(data)
      if(res.status=="200"){
        //NavigateApplication("/ApplyCompProf")
      }
        
    })
    .catch(error=>{
      console.log("An error occured while processing your request, Please contact your System Administrator for : " + error.message)
      // this.setState(  {
      //     isloading       :   false,
      //     alerttype       :   "Error!",
      //     isshow          :   true,
      //     color           :   "danger",
      //     message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
      //     fade            :   true
      // })
    })
    SubmitCandidprofile(dataList)
  }
  const SubmitCandidprofile =async(dataList) =>{
    // console.log("dataList")
    // console.log(dataList)
    let _newDate = new Date();
    let createddate = moment(_newDate).format('MM/DD/YYYY');
    let localtimeString = _newDate.toLocaleTimeString()
    let today = new Date()
    let clientId = moment(today).format('MMYYYYhhmmss');
    
    let toNavAppl = {
      "fullName"                : dataList.firstName + " " + dataList.middleName + " " + dataList.lastName,
      "mobileNumber"            : dataList.mobileNumber,
      "emailAddress"            : dataList.emailAddress,
      "employeeId"              : dataList.userId,

    }

    const obj = {
      "IpAddress"                 : "0.0.0.0",
      "CandidateProfileId"        : clientId,
      "UserId"                    : dataList.userId,
      "StatusId"                  : "2",
      "PositionId"                : dataList.positionId,
      "DateApplied"               : createddate,
      "IdPicture"                 : dataList.fileImage,
      "Resume"                    : dataList.uploadResume,
      "Title"                     : dataList.title,
      "FirstName"                 : dataList.firstName,
      "NickName"                  : dataList.nickName,
      "MiddleName"                : dataList.middleName,
      "LastName"                  : dataList.lastName,
      "SuffixName"                : dataList.suffixName,
      "EmailAddress"              : dataList.emailAddress,
      "MobileNumber"              : dataList.mobileNumber,
      "OfficeNumber"              : dataList.officeNumber,
      "HomePhoneNumber"           : dataList.homePhoneNumber,
      "Facebook"                  : dataList.facebook,
      "Twitter"                   : dataList.twitter,
      "NationalityId"             : dataList.nationalityId,
      "ReligionId"                : dataList.religionId,
      "Gender"                    : dataList.gender,
      "CivilStatus"               : dataList.civilStatus,
      "DateOfBirth"               : dataList.dateOfBirth,
      "AgeYear"                   : dataList.ageYear,
      "AgeMonth"                  : dataList.ageMonth,
      "PlaceOfBirth"              : dataList.placeOfBirth,
      "Height"                    : dataList.height.replace("'","").replace('"',"").replace("'","").replace('"',""),
      "Weight"                    : dataList.weight,
      "SpecialSkills"             : dataList.specialSkills,
      "LanguageSpoken"            : dataList.languageSpoken,
      "EmergencyContactName"      : dataList.emergencyContactName,
      "EmergencyContactRelation"  : dataList.emergencyContactRelation,
      "EmergencyContactNumber"    : dataList.emergencyContactNumber,
      "Hobbies"                   : "",
      "TINNumber"                 : dataList.tinNumber,
      "SSSNumber"                 : dataList.sssNumber,
      "PHICNumber"                : dataList.phicNumber,
      "HDMFNumber"                : dataList.hdmfNumber,
      "LivingArrangementId"       : dataList.livingArrangementId,
      "Arrangement"               : dataList.livingArrangement,
      "BloodTypeId"               : dataList.bloodTypeId,
      "BloodType"                 : dataList.bloodType,
      "timestamp"                 : localtimeString,
      "createddate"               : createddate,
      "isDeleted"                 : "0",

      "applicantAddresses"        : dataList.applicantAddresses,
      "educationAttainments"      : dataList.educationAttainments,
      "employmentHistories"       : dataList.employmentHistories,
      "familyBackgrounds"         : dataList.familyBackgrounds,
      "others"                    : dataList.others,
      "characterReferences"       : dataList.characterReferences,
    };
    // console.log("obj")
    // console.log(obj)

    const params = {
      "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "CandidatesProfile",
      "doc_data" : obj
    };
    // console.log(" submit params")
    // console.log(params)

    await axios
    .post(AppConfiguration.Setting().mongoJobspace + "action/add",  params )
    .then(res => {
      const data = res.data;
      // console.log(res.status)
      // console.log(data)
      if(res.status=="200"){
        // checklistcandi(clientId)
        sessionStorage.setItem("companyId", JSON.stringify(companyId))
        sessionStorage.setItem("key", JSON.stringify(jobId))
        sessionStorage.setItem("dataHris", JSON.stringify(toNavAppl))
        // console.log(companyId)
        // console.log(jobId)
        // console.log(toNavAppl)
        NavigateApplication("/ApplyCompProf")
      }
    })
    .catch(error=>{
      console.log("An error occured while processing your request, Please contact your System Administrator for : " + error.message)
      // this.setState(  {
      //     isloading       :   false,
      //     alerttype       :   "Error!",
      //     isshow          :   true,
      //     color           :   "danger",
      //     message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
      //     fade            :   true
      // })
    })
  }
  const checklistcandi=async(clientId)=>{
    console.log("checklistcandi")
    const urlparams = new URLSearchParams(window.location.search)
    let employeeId = urlparams.get('employeeId')
    let filter_data = {
      "isDeleted" : "0", 
      "userId" : employeeId,
    };

    console.log("filter_data")
    console.log(filter_data)
    const Params = {
      "_collection" : "CandidateRegisteredCheckList",
      "filter_data" : filter_data
    }

    console.log("Params")
    console.log(Params)
    await axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {
      // console.log("get data CandidateRegisteredCheckList ")
      console.log("res.data")
      console.log(res)

      let dataTmp = []
      let a
      let dataaa = res.data
      // console.log("data")
      // console.log(dataaa)
      let newstr2=""
      dataaa.CandidateRegisteredCheckList.map(function(itm,i){
        let strJson = "{" + itm.substring(46, itm.length)
        let strJson2 = itm.substring(18, itm.length)
        newstr2 = strJson2.split("')")[0]
        let json = JSON.parse(strJson.replace(/'/g,'"'));
        json.headerId = newstr2
        // console.log(json.headerId)
        //setheaderId(json.headerId)
        dataTmp.push(json)
        a = json
      })    
      // setheaderId(dataTmp[0].h)
      //updateCompanyRegisteredCheckList()
      // console.log("CandidateRegisteredCheckList gewt dataTmp")
      // console.log(dataTmp)
      dataTmp.forEach(element => {
          element.basicInfoCandidate ="1"
      });
      
      if(res.status === 200){
          updateCandidateChecklist(a,dataTmp,clientId)
      }
    })
    .catch(error =>{
      console.log("An error occured while processing your request, Please contact your System Administrator for : " + error.message)
      // this.setState(  {
      //     isloading       :   false,
      //     alerttype       :   "Error!",
      //     isshow          :   true,
      //     color           :   "danger",
      //     message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
      //     fade            :   true
      // })
      return
    })
  }

  const updateCandidateChecklist= async(a,dataTmp,clientId)=>{
    console.log("updateCandidateChecklist")
  
    console.log(a)
    console.log(dataTmp)
    console.log(clientId)

    let d = new Date();
    let createddate = moment(d).format('MM/DD/YYYY');
    let w = d.toLocaleTimeString()
    //User for Client ID
    let today = new Date()
    //let clientId = moment(today).format('MMYYYYhhmmss');
    let obj = {
      "userId"                : employeeIdTmp,
      "employerId"            : clientId,
      "education"             : a.education, 
      "basicInfoCandidate"    : "1",
      "workExperience"        : a.workExperience,
      "isDeleted"             : "0",
      "createdDate"             :a.createdDate,
      "timestamp"               : a.timestamp,
      "isDraft"                 : "0",
      "isModifiedDate"          : w
    }

    // console.log("obj updateCandidateChecklist")
    // console.log(obj)
    const updateParams = {

      "_collection" : "CandidateRegisteredCheckList",
      "doc_data"    : obj,
      "_id"         :a.headerId,

    }

    // console.log(" toUpdate updateCandidateChecklist")
    // console.log(updateParams)

    axios
    .post("http://206.189.95.201:8098/action/update_one", updateParams)
    .then(res => {
      let data = res.data
      console.log(data)
      console.log(res.status)
      if(res.status === 200){
        // this.handleGetCompanyChecklist2()
      }
    }).catch(error =>{
        console.log("An error occured while processing your request, Please contact your System Administrator for : " + error.message)
        // this.setState(  {
        //     isloading       :   false,
        //     alerttype       :   "Error!",
        //     isshow          :   true,
        //     color           :   "danger",
        //     message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
        //     fade            :   true
        // })
        return
    })
  }
  return (
    <React.Fragment>
      <Card className="job-detail overflow-hidden mt-1">
        <CardBody className="p-4" style={{ marginTop : "4%"}}>
          <Row>
            <Col sm={5} style={{ marginLeft: "6%"}}>
              <div>
                <Row>
                  <Col md={8}>
                    {/* <h4 className="mt-3" style={{color:"#376837"}}>{title}</h4>
                    <h5 className="mb-1" onClick={handleNavCompany}>{companyName}</h5> */}
                    <Label className="mt-2" style={{color:"#0A6219", fontSize: "20px"}}><b>{title}</b></Label><br />
                    <Label className="mb-1" onClick={handleNavCompany} style={{ fontSize: "18px", marginTop: "-1%", color: "#00000"}}>{companyName}</Label>
                  </Col>
                </Row>
                <Row>
                  <div className="mt-3 mt-lg-0">
                    <li className="list-inline-item">
                      <p className="mb-0" style={{fontSize: "14px", color: "#00000"}}>
                        <i className="mdi mdi-map-marker"></i>{" "}
                        {landMark}
                      </p>
                    </li>
                  </div>
                </Row>
                <Row>
                  <div className="mt-3 mt-lg-0">
                    <li className="list-inline-item">
                      <p className="mb-0" style={{fontSize: "14px", color: "#00000"}}>
                        <i className="mdi mdi-clock"></i>{" "}
                          {workingDays}
                      </p>
                    </li>
                  </div>
                </Row>
              </div>

              <div className="mt-4">
                <Row className="g-2">
                  <p style={{color:"#376837", fontSize: "14px"}}>
                    <b>{jobNotes}</b>
                  </p>
                </Row>
              </div>
              <div className="mt-4">
                <Label className="mb-3" style={{fontSize: "14px", fontWeight: "bold", }}>Job Description:</Label> 
                <div className="job-detail-desc" style={{marginLeft: "1%"}} >
                  <p className="mb-0" style={{fontSize: "14px", textAlign:"justify", color: "#00000"}}>{jobDesc}</p>
                </div>
              </div>

              <div className="mt-4">
                <Label className="mb-3" style={{fontSize: "14px", fontWeight: "bold"}}>Job Responsibilities:</Label> 
                <div style={{marginLeft: "1%", fontSize : "14px"}} >
                  {responsibility.map((itm, key)=> 
                    <div className="job-detail-desc mt-2" /* key={d.id } */ style={{textAlign:"justify"}}>
                      <ul className="job-detail-list list-unstyled mb-0">
                        <Row style={{marginTop: "-2%"}}>
                          <Col sm={1}>
                            <i className="mdi mdi-circle-small" style={{color: "#000000"}}></i>
                          </Col>
                          <Col sm={11}>
                            <Label style={{marginLeft: "-5%", color: "#00000"}}>
                              {itm.responsibility}
                            </Label>
                          </Col>
                        </Row>
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-4">
                <Label className="mb-3" style={{fontSize: "14px", fontWeight: "bolder"}}>Skills Required:</Label> 
                <div style={{marginLeft: "1%", fontSize : "14px"}} >
                  {skillAndExp.map((itm, key)=> 
                    <div className="job-detail-desc mt-2" /* key={d.id } */ style={{textAlign:"justify"}}>
                      <ul className="job-detail-list list-unstyled mb-0">
                        <Row style={{marginTop: "-2%"}}>
                          <Col sm={1}>
                            <i className="mdi mdi-circle-small" style={{color: "#000000"}}></i>
                          </Col>
                          <Col sm={11}>
                            <Label style={{marginLeft: "-5%", color: "#00000"}}>
                              {itm.skill}
                            </Label>
                          </Col>
                        </Row>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col sm={3}>
              <Card className="mt-5" style={{ borderRadius: 5, borderColor: "#a4a4a4"}}>
                <CardBody>
                  <Label className="modal-title" id="staticBackdropLabel" style={{fontSize: "14"}}>
                    <b>Job Overview</b>
                  </Label>
                  <Row className="mt-2">
                    <Label column sm="5" style={{color: "#00000"}}>
                      Employment Type:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Employment Type"
                        style={{borderRadius: 0 ,fontSize: "14px", color: "#000000", borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={_jobEmploymentType}
                        // onChange={onChangeOfferedSalary}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Label column sm="5" style={{color: "#00000"}}>
                      Industry:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Industry"
                        style={{borderRadius: 0 ,fontSize: "14px"/* , fontWeight: "bold" */, color: "#000000", borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={compIndustry}
                        onChange={onChangeExperience}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Label column sm="5" style={{color: "#00000"}}>
                    Location:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Location"
                        style={{borderRadius: 0 ,fontSize: "14px"/* , fontWeight: "bold" */, color: "#000000", borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={getWorkLocation}
                        // onChange={onChangeJobOverViewLocation}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Label column sm="5" style={{color: "#00000"}}>
                      Offered Salary:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Offered Salary"
                        style={{borderRadius: 0 ,fontSize: "14px"/* , fontWeight: "bold" */, color: "#000000" , borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={offeredSalary}
                        // onChange={onChangeCategory}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Label column sm="5" style={{color: "#00000"}}>
                      Department:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Department"
                        style={{borderRadius: 0 ,fontSize: "14px"/* , fontWeight: "bold" */, color: "#000000", borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={_jobDepartment}
                        // disabled={true}
                        // onChange={onChangeJobType}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Label column sm="5" style={{color: "#00000"}}>
                      Experience:
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Experience"
                        style={{borderRadius: 0 ,fontSize: "14px"/* , fontWeight: "bold" */, color: "#000000", borderLeft :"0px", borderTop :"0px", borderBottom: "1px solid", borderRight : "0px"}}
                        value={expRequired}
                        // onChange={onChangeExperience}
                      />
                    </Col>
                  </Row>

                  {/* <Row className="mt-2">
                    <Input
                      type="text"
                      className="form-control"
                      id="nameControlInput"
                      placeholder="Qualification"
                      style={{borderStyle: "outset"}}
                      value={_jobQualification}
                      onChange={onChangeApplied}
                    />
                  </Row>
                  <Row className="mt-2">
                    <Input
                      type="text"
                      className="form-control"
                      id="nameControlInput"
                      placeholder="Level"
                      style={{borderStyle: "outset"}}
                      value={industry}
                      onChange={onChangeIndustry}
                    />
                  </Row> */}
                  {/* <Row className="mt-2">
                    <Input
                      type="text"
                      className="form-control"
                      id="nameControlInput"
                      placeholder="Educational Level"
                      style={{borderStyle: "outset", fontSize: "14px"}}
                      value={_jobEducLvl}
                      // onChange={onChangeQualification}
                    />
                  </Row> */}
                  <Row className="mt-2">
                    {/* <Input
                      type="text"
                      className="form-control"
                      id="nameControlInput"
                      placeholder="No. of Applicants"
                      style={{borderStyle: "outset"}}
                      value={numberApplcants}
                      onChange={onChangeLevel}
                    /> */}
                    <Label className="mt-2" >
                      Number of applicants: &nbsp; <Link style={{color: "#0A6219" ,fontSize: "14px"}} /* to="/AppliedJobs" onClick={ () => navigateAppliedJob("/AppliedJobs") } */><b >{numberApplcants}</b></Link>
                    </Label>
                  </Row>
                  <Label style={{marginTop: "15%" ,fontSize: "14px"}}>
                    Application ends: <b>{applicationDate}</b>
                  </Label>
                  <br />
                  <Label style={{fontSize: "14px"}}>
                    <b>{jobDetailsPostingId}</b>
                  </Label>
                  <Col>
                    <Row className="mt-3">
                      <Col>
                        {validate==="1" ? 
                          <button type="submit" className="btn btn-success" /* to="datapolicy" */ onClick={openfrstModal} style={{width : "80%", marginLeft: "15%"}}>
                            Apply Now
                          </button>
                          :
                          <button type="submit" className="btn btn-success" /* to="datapolicy" */ onClick={handleChangeSwitch} style={{width : "80%", marginLeft: "15%"}}>
                            Apply Now
                          </button>
                        }
                      </Col>
                      <Col>
                        <button type="submit" className="btn" disabled={validate==="0"? true : false} onClick={submitClick} style={{width : "80%", backgroundColor: "#E0FDDB"}}>
                          Save
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </CardBody>
              </Card>
            </Col>
            <Col sm={3}>
              <Card className="mt-5" style={{ borderRadius: 5, borderColor: "#a4a4a4", width: "65%"}}>
                <CardBody>
                  <Row className="mt-2">
                    <h5 className="modal-title" id="staticBackdropLabel">
                      <Col lg={3}>
                      </Col>
                      <Col lg={9}>
                        <Label className="mb-1" style={{ fontSize: "18px", }}>{companyName}</Label>
                      </Col>
                    </h5>
                  </Row>
                  <Row className="mt-2">
                    <Label className="mdi mdi-phone" style={{ fontSize: "14px" }}>&nbsp;&nbsp;&nbsp;{companyNumber}</Label>
                  </Row>
                  <Row className="mt-2">
                    <Label className="mdi mdi-mail" style={{ fontSize: "14px" }}>&nbsp;&nbsp;&nbsp;{companyEmail}</Label>
                  </Row>
                  <Row className="mt-2">
                    <Label className="uil uil-globe" style={{ fontSize: "14px" }}>&nbsp;&nbsp;&nbsp;{companyWebsite}</Label>
                  </Row>
                  <Row className="mt-2">
                    <Label className="mdi mdi-map-marker" style={{ fontSize: "14px" }}>&nbsp;&nbsp;&nbsp;{companyLocation}</Label>
                  </Row>
                  {/* <div className="mt-3">
                    <Link
                      onClick={openModal}
                      data-bs-toggle="modal"
                    >
                      Share Link
                    </Link>
                  </div> */}
                </CardBody>
              </Card>
            </Col>
            <Col lg={1}></Col>
          </Row>
        </CardBody>
      </Card>
      <div
        className="modal fade"
        id="modalScreen2"
        tabIndex="-1"
        aria-labelledby="modalScreen2"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modalScreen2} toggle={openScndModal} centered>
            <ModalBody className="modal-body p-5">
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openScndModal}
                  className="btn-close"
                  data-bs-dismiss="modalScreen2"
                  aria-label="Close"
                ></button>
              </div>
              <Card style={{backgroundColor: "#F0FEED"}}>
                <CardBody>
                  <Row sm={12}>
                    <p className="text-muted mb-1" style={{textAlign:"justify", fontWeight: "bolder", justifyContent: "center", fontSize : "14px"}}>You still need to fill out other needed details in your profile. This might not make your application stand-out.</p>
                  </Row>
                  <Row className="mt-3">
                    <p className="text-muted mb-1" style={{textAlign: "center", fontWeight: "bolder", fontSize : "14px"}}>Do you want to proceed your application?</p>  
                  </Row>
                  <Row className="mt-5">
                    <Col sm={1}>
                    </Col>
                    <Col sm={10}>
                      <button type="submit" className="btn btn-success" onClick={handleOpenResume} style={{minWidth : "20%", textAlign: "center", marginLeft: "20%"}}>
                        Yes
                      </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <button type="submit" className="btn btn-success" onClick={handleOpenModal2} style={{minWidth : "20%", textAlign: "center"}}>
                        No
                      </button>
                    </Col>
                    <Col sm={1}>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalScreen1"
        tabIndex="-1"
        aria-labelledby="modalScreen1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modalScreen1} toggle={openfrstModal} centered>
            <ModalBody className="modal-body p-5">
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openfrstModal}
                  className="btn-close"
                  data-bs-dismiss="modalScreen1"
                  aria-label="Close"
                ></button>
              </div>
              <Row>
                <h5 className="text-muted mb-1" style={{fontSize : "14px"}}>
                  <b>Continue your applications via</b>
                </h5>
              </Row>
              <Card style={{backgroundColor: "#F0FEED"}}>
                <CardBody>
                  <Row>
                    <Col lg={3}>
                    </Col>
                    <Col lg={7}>
                    { isCompleted === "0" ? 
                      <button type="submit" className="btn mt-5" onClick={handleOpenModal} style={{ minWidth : "10%", textAlign: "center", backgroundColor : "#F0FEED", borderColor: "green", borderWidth: 2, borderRadius: 10, fontWeight: "bold"}}>
                        JobSpace Profile
                      </button>
                      : 
                      <button type="submit" className="btn mt-5" onClick={handleOpenResume} style={{ minWidth : "10%", textAlign: "center", backgroundColor : "#F0FEED", borderColor: "green", borderWidth: 2, borderRadius: 10, fontWeight: "bold"}}>
                        JobSpace Profile
                      </button>
                    }
                    </Col>
                    <Col lg={2}>
                    </Col>
                  </Row>
                  <Row >
                    <p className="text-muted mb-1" style={{textAlign: "center", fontSize : "14px"}}>Your full JobSpace profile will be visible to the employer</p>
                  </Row>
                  <Row>
                    <Col sm={3}>
                    </Col>
                    <Col sm={7} >
                      <button type="submit" className="btn mt-5" onClick={handleDirectbyResume} style={{ minWidth : "10%", textAlign: "center", backgroundColor : "#F0FEED", borderColor: "green", borderWidth: 2, borderRadius: 10, fontWeight: "bold"}}>
                        Resume Upload
                      </button>
                    </Col>
                    <Col sm={2}>
                    </Col>
                  </Row>
                  <Row >
                    <p className="text-muted mb-1" style={{textAlign: "center", fontSize : "14px"}}>Let your resume apply for you, Your Uploaded file</p><br />  
                    <p className="text-muted mb-1" style={{textAlign: "center", fontSize : "14px"}}>will be sent to the employers</p>
                  </Row>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalScreen3"
        tabIndex="-1"
        aria-labelledby="modalScreen3"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modalScreen3} toggle={openThrdModal} centered>
            <ModalBody className="modal-body p-5">
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openThrdModal}
                  className="btn-close"
                  data-bs-dismiss="modalScreen3"
                  aria-label="Close"
                ></button>
              </div>
              <Card style={{backgroundColor: "#F0FEED"}}>
                <CardBody>
                  <Row className="mt-3">
                    <p className="text-muted mb-1" style={{textAlign: "center", fontWeight: "bolder", fontSize : "14px"}}>Do you want to proceed editing your profile?</p>  
                  </Row>
                  <Row className="mt-5">
                    <Col sm={2}>
                    </Col>
                    <Col sm={9}>
                      <button type="submit" className="btn btn-success" onClick={handleNavigateProfile} style={{minWidth : "20%", textAlign: "center"}}>
                        Yes, continue
                      </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <button type="submit" className="btn btn-success" onClick={() =>setThrdModal(false) } style={{minWidth : "20%", textAlign: "center"}}>
                        No
                      </button>
                    </Col>
                    <Col sm={1}>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <div
        className="modal fade"
        id="applybyresume"
        tabIndex="-1"
        aria-labelledby="applybyresume"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={applybyresume} toggle={openModalApplyResume} centered>
            <ModalBody className="modal-body p-5">
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openModalApplyResume}
                  className="btn-close"
                  data-bs-dismiss="applybyresume"
                  aria-label="Close"
                ></button>
              </div>
              <Row>
                <Input
                  type="file"
                  className="form-control"
                  id="inputGroupFile01"
                  onChange={UploadResume}
                />
              </Row>
              <Row lg={12} className="mt-2">
                <Col lg={1}>
                  <Input
                    style={{border: "1px solid green"}}
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    // to="otpscreen"
                    onClick={e =>onChangeCheckboxResume(e)}
                  />
                </Col>
                <Col lg={11}>
                  <p className="text-muted mb-0" style={{ textAlign: "justify", fontSize : "9px"}}>
                    {/* By uploading your resume and apply for this job, you agreed to our 
                    <b style={{color: "#0A6219"}} to ="datapolicy" onClick={openModal}> Data Privacy Policy </b>
                    of collecting, storing and using your personal data as described in our policy 
                    to matches the candidate skill needed by the employers.  */}
                    When you apply for this job, your JobSpace profile including any verified credentials will be accessed by the employer. Other employers and recruiters
                    can also approach you with job opportunities based on your profile access settings.
                  </p>
                </Col>
              </Row>
              <button type="submit" disabled={isCheckbox} className="btn btn-success mt-5" onClick={handleApplySignedIn} style={{minWidth : "20%", textAlign: "center", marginLeft: "30%"}}>
                Submit Resume
              </button>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalhris"
        tabIndex="-1"
        aria-labelledby="modalhris"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modalhris} toggle={openHrisModal} centered>
            <ModalBody className="modal-body p-5">
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openHrisModal}
                  className="btn-close"
                  data-bs-dismiss="modalhris"
                  aria-label="Close"
                ></button>
              </div>
              <Card style={{backgroundColor: "#F0FEED"}}>
                <CardBody>
                  <Row className="mt-3">
                    <p className="text-muted mb-1" style={{textAlign: "center", fontWeight: "bolder", fontSize : "14px"}}>You have an existing profile in HRIS, do you want to use it to apply?.</p>  
                  </Row>
                  <Row className="mt-5">
                    <Col sm={3}>
                    </Col>
                    <Col sm={9}>
                      <button type="submit" className="btn btn-success" onClick={GetProfileHris} style={{minWidth : "20%", textAlign: "center"}}>
                        Yes
                      </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <button type="submit" className="btn btn-success" onClick={NavigateSignUp} style={{minWidth : "20%", textAlign: "center"}}>
                        No
                      </button>
                    </Col>
                    <Col sm={1}>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
        </div>
      </div>
      {/* START MODAL GENERATE LINK */}

      <div
        className="modal fade"
        id="hireNow"
        tabIndex="-1"
        aria-labelledby="hireNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modal} toggle={openModal} centered>
            <ModalBody className="p-5">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  GENERATE LINK
                </h5>
                <hr/>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Col sm="12">
              <Typeahead       
                                      
                  labelKey='employeeName'
                  id="basic-examplex"
                  onChange={handleChangeEmployee}
                  options={employeeNameDDL}
                  placeholder="Select employee"
              />
              </Col>
            </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                <Col sm="12">
                  <Form.Control type="text" disabled readOnly value={generateLink} ref={textRef}/>
                </Col>
              </Form.Group>
              <button type="submit" className="btn btn-primary w-100"  onClick={copyToClipboard}>
                Copy Link
              </button>
            </ModalBody>
          </Modal>
        </div>
      </div>
      {/* END MODAL GENERATE LINK */}
    </React.Fragment>
  );
};

export default JobDetailsDescription;
