import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AppConfiguration } from "read-appsettings-json";
import moment from 'moment';
//import { GetRequest, PostRequest } from '../../../../noser.dataaccess';
//import { GetRequest, PostRequest } from '../../../noser.dataaccess';
//import useAxios from './useAxios';
//import ValidationInfo from './ValidationInfo';

const useForm = (callback  ) => {
  const [values, setValues] = useState({
    email             : "",//
    username          : "",//
    name              : "",
    company           : "",//
    contact           : "",
    address           : "",
    password          : "",//
    confirm_password  : "",

    emailCheck        : "",
    userIdCheck       : "",
    reguserid         : "",
    date              : "",
    defaultId         : "",
    parent            : "",
    parentID          : "",
    companyID         : "",

    firstName         : "",
    lasttName         : "",

  });

  const [loading, setLoading] = useState(false);
  const [errorVal, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [valueDataRegister, setValueDataRegister] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [registerData, setRegisterData] = useState(JSON.parse(sessionStorage.getItem("handleGetRegister")));
  const [parent, setParent] = useState("");
  const [parentID, setParentID] = useState("");

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
    ...values,
    [name]: value
    });
    console.log(name + " +"+ value)
  };

  // const GetShortName=(company)=> {
  //   let _list = company.split(" ")
  //   // console.log("GetShortName")
  //   // console.log(_list)
  //   let sname = ""
  //   if (_list[0].length > 6) {
  //       sname = _list[0].substring(0, 6)
  //   } else {
  //       sname = _list[0]
  //   }
  //   return sname
  // }

  const BuildList = (dataListTmp) => {

    let itemList = []
    let id       = []
    let name     = []
    let company  = []
    let isDelete = []

    for (let i = 0; i < dataListTmp.length; i++) {
      let s1              = dataListTmp[i].split("':")
      let idClean         = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
      let IdClean2        = idClean.split("',")[0]
      let nameTmp         = s1[3].split("',")[0]
      let companytmp      = s1[5].split("',")[0]
      let isDeletetmp     = s1[4].split("',")[0].replace("'}","")
      //// console.log(isDelete)
      id.push(IdClean2.replace(" '",""))
      name.push(nameTmp.replace(" '","").replace("'",""))
      company.push(companytmp.replace(" '","").replace("'",""))
      isDelete.push(isDeletetmp.replace("'",""))
    }

    for (let i = 0; i < id.length; i++) {
      let objBuildList = {
        "id"             :   id[i],
        "name"           :   name[i],
        "company"        :   company[i],
        "isDelete"       :   isDelete[i],
      }
      itemList.push(objBuildList)
    }
    return itemList
  }

  const handleSubmit = (event) => {
    console.log("submit jobspace")
    // console.log(valueDataRegister)
    event.preventDefault();
    console.log("test submit")
    setErrors(validation(values));
    setIsSubmitting(true)
  };

  const handleGetRegister = async() => {
    /*  let response = await GetRequest({
    url :"http://128.199.133.50:9091/" + "action/get",
    params : {
        "dbname" 	:	"NOSERDBDEV",
        "_collection"	: 	"Client",
        "filter_data" : {}
    }
    })
    const data = response["ResultList"]
    setValueDataRegister(data)
    console.log("w1")
    console.log(data)
    sessionStorage.setItem("handleGetRegister", JSON.stringify(data))
    handlew(); */
  }


  


  const validation =()=>{
    let errors = {};
  
    /* valueDataRegister.map(function(item,idx){
      console.log(item)
      if (item.email === values.email) { // if exist
        errors.email = 'email already exist';
      }
      if (item.userId === values.username) { // if exist
        errors.username = 'username already exist';
      } 
      if (item.company === values.company) { // if exist
        errors.company = 'company already exist';
      } 
    }) */

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!values.email) {
      errors.email = 'This field is required';
    } else if (!pattern.test(values.email)) {
      errors.email = 'Email address is invalid';
    }  

    if (!values.username) {
      errors.username = 'This field is required';
    }

    if (!values.firstName) {
        errors.firstName = 'This field is required ';
    };
    if (!values.lasttName) {
      errors.lasttName = 'This field is required ';
    };

    /* if (!values.company) {
        errors.company = 'This field is required';
    } */

    if (!values.contact) {
        errors.contact = 'This field is required';
    }

    /* if (!values.address) {
        errors.address = 'This field is required';
    } */

    
    if (!values.password) {
      errors.password = 'This field is required';
    } else if (values.password.length < 6) {
      errors.password = 'Password needs to be 6 characters or more';
    }

    if (!values.confirm_password) {
      errors.confirm_password = 'This field is required';
    } else if (values.confirm_password !== values.password) {
      errors.confirm_password = 'Passwords do not match';
    }

    return errors;
  }

  useEffect(
    () => {
      console.log("effect")
      //registerData[i]["email"]
     // handleGetRegister();
      if (Object.keys(errors).length === 0 && isSubmitting) {
        //setIsSubmitting(false)
        console.log("1111111111")
       //callback();
        // console.log("useEffect IF")
        let d = new Date();
        let createddate = moment(d).format('MM/DD/YYYY');
        let w = d.toLocaleTimeString()
        //User for Client ID
        let today = new Date()
        let clientId = moment(today).format('MMYYYYhhmmss');
        let _data = {
          "clientId"      : clientId,
          "email"         : values.email,
          "userId"        : values.username,
          "firstName"          : values.firstName,
          "lasttName": values.lasttName,
         // "company"       : values.company,
          "contact"       : values.contact,
          //"address"       : values.address,
          "password"      : values.password,
          //"createdby"     : values.name,
          "createddate"   : createddate,
          "modifiedby"    : values.name,
          "modifieddate"  : createddate,
          "timestamp"     : w,
         // "subsription"   : "trial",
          "isDeleted"     : "0",
          "isDraft"       : "0",
         // "role"          : "1",
        }
         console.log("_data")
         console.log(_data)

        const params = {
          "dbname" 	:	"CIAMDBUAT",
          "_collection" : "jobSignUp",
          "filter_data" : _data
        }
         console.log("jobSignUp  params")
         console.log(params)
        sessionStorage.setItem("Client", JSON.stringify([_data]))
        


        axios
        .post("http://206.189.95.201:8096/" + "action/add", params)
        .then(res => {
          // console.log(`welcome to Noser Technology Ph`)
          console.log(res)
          console.log(res.data)
          // sessionStorage.setItem("Client", JSON.stringify(_data))
          // sessionStorage.setItem("uname", _data[0] ['name'])
          //commenitemsDatated by Clifford 02/09/2022
          //no need to get id from Client colelction
          //id is now generated and pass as parameter to this.handleParentCompany(parentId)
          //handleGetCompany();          //code below replaces above this.handleGetCompany()
          ////////////this.handleParentCompany(clientId)
          //this.handleGetCompanyID()
          // window.location.reload(false);
        })
        .catch(error=>{
          console.log("error: " + error)
          // console.log("ewwow")
        })
      }
      console.log("dulo")
    },
    
    [errors]
  );



  return { handleChange, handleSubmit, values, errors, errorVal };
};

export default useForm;
