import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Input, Label, select,AccordionItem,AccordionBody,AccordionHeader,UncontrolledAccordion, FormGroup} from "reactstrap";
import moment from "moment";
//import { Button, Accordion, } from "react-bootstrap";

//Import Images
import { AppConfiguration } from "read-appsettings-json";
import { GetRequest, PostRequest } from '../../noser-dataaccess'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";

const EmployerValidation = () => {

    const [listOfJobs, setDlistOfJobs] = useState([]);
    const [mainGrid, setmainGrid] = useState([
        {
            "id":"1",
            "postingId":"1",
            "name":"tasyo",
            "title":"tambay",
            "expectedSalary":"100,000",
            "city":"makati",
            "resume":"",
            "ScheduleInterview":"",
            "action2":"",
        },
        {
            "id":"phr-1-2-3",
            "postingId":"phr-1-2-3",
            "name":"dio",
            "title":"food tester",
            "expectedSalary":"40,000",
            "city":"taguig",
            "resume":"",
            "ScheduleInterview":"",
        }
    ]);
    const [companyProfiles, setcompanyProfiles] = useState([]);
    const [jobPosted, setjobPosted] = useState([]);
    const [search, setsearch] = useState("");
    const [statusData, setstatusData] = useState([
       
        {
           "id":"1",
           "label":"New",
           "value":"NW"
        },
        {
            "id":"2",
            "label":"pre-screen",
            "value":"PS"
        },
        {
            "id":"3",
            "label":"keep warm",
            "value":"KW"
        },
        {
            "id":"4",
            "label":"shorlisted",
            "value":"SL"
        },
        {
            "id":"5",
            "label":"Unmatched",
            "value":"Unmatched"
        },
    ]);
    const [jobRef, setjobRef] = useState("");
    const [pstingDateFilter, setpstingDateFilter] = useState("");
    const [jobPostStatus, setjobPostStatus] = useState("");
    const [jobtitlefilter, setjobtitlefilter] = useState("");
    const [endDateFilter, setendDateFilter] = useState("");



    const rowClasses = 'custom-row-class-success';


    useEffect(() => {
       // OnLoadScreen()
        //GetCompanyProfile()
        //let toEdit = JSON.parse(sessionStorage.getItem("jobPostedEdit"))
       /*  if(JSON.parse(sessionStorage.getItem("jobPostedEdit")) !== null){
            GetCandidatesFilterd(toEdit)
        }else{
            GetCandidates()
        } */
      
        //GetJobPosted()
    },[]);

    const helperBuildCriteria=()=>{

        ///// FOR TRANSAC DATE FILTER SOON
       /*  let checkDateStart = moment(this.state.startDate).format('MM/DD/YYYY');
        let checkDateEnd = moment(this.state.endDate).format('MM/DD/YYYY');
        const checkAll = this.state.deductionSummaryGrid

        let objCheckList = []
        for (let i = 0; i < checkAll.length; i++) {
            //////////////console.log(checkAll[i])
            if (checkAll[i]["transactionDate"] >= checkDateStart &&  checkAll[i]["transactionDate"] <= checkDateEnd ){
                objCheckList.push(checkAll[i])
                ////////////console.log(objCheckList)
            }else{

            }
        } */

        //let newtransacDate = new Date(this.state.transacDate)
        let _transacDate = ""//moment(newtransacDate).format('MM/DD/YYYY');
        

        let _filterData = ""
       
        let _jobRefNo = ""
        let _jobtitle = ""
        let _postingDate = ""
        let _EndDate = ""
        let _status = "" 

        //let transactionDate = (this.state.transactionDate) ? moment(this.state.transactionDate).format('MM/DD/YYYY') : ""
        
        if (jobRef != "" || jobRef !== "") {
            _jobRefNo = "postingId" + ":" + jobRef + ","
        }
        if (pstingDateFilter != "" || pstingDateFilter !== "") {
            _postingDate = "postingId" + ":" + pstingDateFilter + ","
        }
        if (endDateFilter != "" || endDateFilter !== "") {
            _EndDate = "endDate" + ":" + endDateFilter + ","
        }
        if (jobtitlefilter != "" || jobtitlefilter !== "") {
            _jobtitle = "title" + ":" + jobtitlefilter + ","
        }
        
     /*    if (this.state.docNo != "" || this.state.docNo !== "") {
            _docNo = "docNo" + ":" + this.state.docNo + ","
        }
        if ( this.state.selectedClientName != "" || this.state.selectedClientName !== "" ) {
             _client = "clientName" + ":" + this.state.selectedClientName + ","
        }
        if ( this.state.selectedstatus!= "" || this.state.selectedstatus!== "" ) {
            _status= "status" + ":" + this.state.selectedstatus+ ","
        }
        if ( this.state.filterTranscDate!= "" || this.state.filterTranscDate!== "" ) {
            _transacDate = "transacDate" + ":" + this.state.filterTranscDate+ ","
        } */
      
        _filterData = _jobRefNo + _status + _jobtitle + _postingDate + _EndDate
        //console.log("_transacDate _transacDate _transacDate _transacDate")
        //console.log(_transacDate)
        return _filterData
    }

    const handleChangeSearch = () => {
        /* if(this.state.payrollDate === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please select payroll date!",
                fade            :   true
            })
            return
        } */

        let _fdata = helperBuildCriteria()
        let _filterData = _fdata.split(",")
        let _objfd = {}

        let jsonData = {};
        for (let i = 0; i < _filterData.length - 1; i++) { 
            let columnName = _filterData[i].split(":")[0]
            _objfd[columnName] = _filterData[i].split(":")[1];
        }
        console.log("filter_data")
        console.log(_objfd)

        GetFilterResult(_objfd);
    }

    const GetFilterResult = async(_objfd) => {

        let filter_data = {}
        let dataTmp = []

        const Params = {
          "_collection" : "Candidates",
          "filter_data" : _objfd
        }
        console.log("Params GetFilterResult")
        console.log(Params)
        axios
        .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {

            let data = res.data
            console.log("data")
            console.log(data)
            let newstr2=""
            data.Candidates.map(function(itm,i){
                //console.log("itm")
                //console.log(itm)
                let strJson = "{" + itm.substring(46, itm.length)
                let strJson2 = itm.substring(18, itm.length)
                newstr2 = strJson2.split("')")[0]
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                json.headerId = newstr2
                dataTmp.push(json)
            })    
            //console.log("dataTmp GetCandidates" )
            //console.log(dataTmp)

            setDlistOfJobs(dataTmp)   
            setmainGrid(dataTmp) 
        })
        .catch(error =>{
            alert("error: " + error)
        })
    }

    const GetCandidatesFilterd = async(toEdit) => {

        let filter_data = {postingId:toEdit.postingId }
        let dataTmp = []

        const Params = {
          "_collection" : "Candidates",
          "filter_data" : filter_data
        }
        //console.log("Params GetCandidatesFilterd")
        //console.log(Params)
        axios
        .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {

            let data = res.data
            //console.log("data")
            //console.log(data)
            let newstr2=""
            data.Candidates.map(function(itm,i){
                //console.log("itm")
                //console.log(itm)
                let strJson = "{" + itm.substring(46, itm.length)
                let strJson2 = itm.substring(18, itm.length)
                newstr2 = strJson2.split("')")[0]
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                json.headerId = newstr2
                dataTmp.push(json)
            })    
            //console.log("dataTmp GetCandidates" )
            //console.log(dataTmp)

            setDlistOfJobs(dataTmp)   
            setmainGrid(dataTmp) 
        })
        .catch(error =>{
            alert("error: " + error)
        })
    }

    const GetCandidates = async() => {

        let filter_data = {"isDeleted": "0", }
        let dataTmp = []

        const Params = {
          "_collection" : "Candidates",
          "filter_data" : filter_data
        }
        axios
        .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {

            let data = res.data
            //console.log("data")
            //console.log(data)
            let newstr2=""
            data.Candidates.map(function(itm,i){
                //console.log("itm")
                //console.log(itm)
                let strJson = "{" + itm.substring(46, itm.length)
                let strJson2 = itm.substring(18, itm.length)
                newstr2 = strJson2.split("')")[0]
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                json.headerId = newstr2
                dataTmp.push(json)
            })    
            //console.log("dataTmp GetCandidates" )
            //console.log(dataTmp)

            setDlistOfJobs(dataTmp)   
            setmainGrid(dataTmp) 
        })
        .catch(error =>{
            alert("error: " + error)
        })
    }

    const GetCompanyProfile = async() => {
        let filter_data = {"isDeleted": "0", }
        let dataTmp = []
        const Params = {
          "_collection" : "CompanyProfile",
          "filter_data" : filter_data
        }
        axios
        .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {

            let data = res.data
            let newstr2=""
            data.CompanyProfile.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let strJson2 = itm.substring(18, itm.length)
                newstr2 = strJson2.split("')")[0]
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                json.headerId = newstr2
                dataTmp.push(json)
            })    

            //console.log("dataTmp GetCompanyProfile")
            //console.log(dataTmp)
            setcompanyProfiles(dataTmp)   
          
            
            // setJson(dataTmp)  
            //GetJobPosted(dataTmp); 
        })
        .catch(error =>{
            alert("error: " + error)
        })
    };

    const GetJobPosted = async() => {
        let companyProfileTmp = JSON.parse(sessionStorage.getItem("CompanyProfile"))
        let filter_data = {"isDeleted": "0", "companyId" : companyProfileTmp[0].userId}
        let JobPostedTmp = []
        const Params = {
          "_collection" : "JobPosting",
          "filter_data" : filter_data
        }
        //console.log("Params GetJobPosted")
        //console.log(Params)
        axios
        .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {

            let data = res.data
            let newstr2=""
            data.JobPosting.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let strJson2 = itm.substring(18, itm.length)
                newstr2 = strJson2.split("')")[0]
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                json.headerId = newstr2
                JobPostedTmp.push(json)
            })       
            JobPostedTmp.map(function(d){
                if(d.active =="1"){
                    d.status = "Active"
                }else{
                     d.status = "Deactive"
                }
            })
            //console.log("JobPostedTmp GetJobPosted")
            //console.log(JobPostedTmp)

            setjobPosted(JobPostedTmp)
            setmainGrid(JobPostedTmp) 
            
            // setJson(dataTmp)  
        })
        .catch(error =>{
            alert("error: " + error)
        })
    };

    const toProfile=()=>{
        //console.log("handleView")
        //console.log(row)
        window.location.href='/ContinueR'
      
    }
    const toSupport=()=>{
        //console.log("handleView")
        //console.log(row)
        window.location.href='/ContinueR'
      
    }

 
  
    /* Get items */
    async function OnLoadScreen(){
        // setJobType("")
        // setCategory("")
        // setJobOverviewLocation("")
        // setOfferedSalary("")
        // setPosted("")
        // setExperience("")
        // setGender("0")
        // setIndustry("")
        // setQualification("")
        // setLevel("")
        // setApplied("")
    }

    const rowEvents = {
      /*   onClick: (e, row, rowIndex) => {
            //console.log("onClick")
            //console.log(rowIndex)
            //handleView(row)
           
        }, */
     
    };

    const handleViewResume=(row)=>{
        //console.log("handleViewResume")
        //console.log(row)
        sessionStorage.setItem("viewResume", JSON.stringify(row))
        window.location.href='/ViewResume'
      
    }

    const handleDownloadResume=(row)=>{
        //console.log("handleDownloadResume")
        //console.log(row)
        //sessionStorage.setItem("jobPostedEdit", JSON.stringify(row))
       // window.location.href='/CreateJobPosting'
      
    }

    const handleSchedInterview=(row)=>{
        //console.log("handleSchedInterview")
        //console.log(row)
        //sessionStorage.setItem("jobPostedEdit", JSON.stringify(row))
       // window.location.href='/CreateJobPosting'
      
    }

    const onChangeSearch=(e)=>{
        //console.log("handleSearch")
        //console.log(e.target.value)
        setsearch(e.target.value)
        //console.log(listOfJobs)
        if(e.target.value === ""){
            //console.log("search === blank")
            setmainGrid(listOfJobs)
        }
        //sessionStorage.setItem("jobPostedEdit", JSON.stringify(row))
       // window.location.href='/CreateJobPosting'
      
    }
    
    const onClickSearch =()=>{
        let newarry = []
        listOfJobs.map(function(d){
         
            if(d.postingId == search){
                //console.log("onClickSearch")
                //console.log(d)
                setmainGrid([d])
            }
            if(d.title == search){
                //console.log("onClickSearch")
                //console.log(d)
                setmainGrid([d])
            } 
            if(d.name == search){
                //console.log("onClickSearch")
                //console.log(d)
                setmainGrid([d])
            } 
            if(d.expectedSalary == search){
                //console.log("onClickSearch")
                //console.log(d)
                setmainGrid([d])
            } 
            if(d.city == search){
                //console.log("onClickSearch")
                //console.log(d)
                setmainGrid([d])
            }
          
        })
    }

    const GridDataModified=(oldValue, newValue, id, column , index)=>{
        mainGrid.map(function(item,i,index) {
            if (item.id === id){
                item.isModified = newValue!=oldValue ? "1" : "0"
               /*  console.log("GridDataModified item")
                console.log(item.isModified)
                console.log(item.headerId) */
                if(item.isModified == "1"){
                    saveGrid(item)
                 
                }
               
                
                
            }
        })
    }

    const saveGrid=(item)=>{

        const updateParams = {
    
            "_collection" : "Candidates",
            "doc_data"    : item,
            "_id"         : item.headerId,
      
        }

    
        console.log("saveGrid updateParams")
        console.log(updateParams)
      
        axios
        .post("http://206.189.95.201:8098/action/update_one", updateParams)
        .then(res => {
            let data = res.data
            
            console.log("update_one data")
            console.log(data)
            console.log("update_one data end")
          
        
        }).catch(error =>{
            
            console.log("error: " + error)
          
    
        })
    }

    const onChangejobRef = (e) => {
        setjobRef(e.target.value)
    }
    const onChangepstingDateFilter = (e) => {
        setpstingDateFilter(e.target.value)
    }
    const onChangejobPostStatus = (e) => {
        setjobPostStatus(e.target.value)
    }
    const onChangejobtitlefilter = (e) => {
        setjobtitlefilter(e.target.value)
    }
    const onChangeendDateFilter = (e) => {
        setendDateFilter(e.target.value)
    }

    const columns = [
        {

            dataField   : 'postingId',
            text        : 'Job Post Reference',
            editable:false,
            headerStyle : () => {
            
                return { width  : "14%",color:"green" };
            },
            style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }

        },
        {

            dataField   : 'title',
            text        : 'Job Title',
            editable:false,
            headerStyle : () => {
                return { width  : "14%",color:"green" };
            },
            style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }
            

        },
        {

            dataField   : 'startDate',
            text        : 'Posted Date',
            editable:false,
            headerStyle : () => {
                return { width  : "14%",color:"green" };
            },
            style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }
            

        },
        {

            dataField   : 'endDate',
            text        : 'End Date',
            editable:false,
            headerStyle : () => {
                return { width  : "14%",color:"green" };
            },
            style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }
            

        },
        {

            dataField   : 'createdBy',
            text        : 'Posted By',
            editable:false,
            headerStyle : () => {
                return { width  : "14%",color:"green" };
            },
            style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }
            

        },
        {

            dataField   : 'status',
            text        : 'Status',
            editable:false,
            classes: 'demo-key-row',
            headerStyle : () => {
                return { width  : "14%",color:"green" };
            },
            style:{/* textAlign: 'right', */ whiteSpace: 'nowrap',color:"green",fontStyle:"bold" },
            
            

        },
        {

            dataField   : 'action',
            text        : 'Action',
            editable:false,
            headerStyle : () => {
                return { width  : "14%",color:"green" };
            },
            style:{whiteSpace: 'nowrap', },
            formatter   :   (cell, row, isSelect) => {
                if(row)
                return (
                    <div>
                    <i className="mdi mdi-power" id="1" style={{margin:"20px"}} ></i>
                    <i className="mdi mdi-refresh" id="2"/* style={{margin:"5px"}} */></i>
                    <i className="mdi mdi-grease-pencil" id="3"style={{margin:"20px"}}></i>
                    </div>
                );
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {

                    
                    console.log(e.target.id);
                    //   //console.log(column);
                    //   //console.log(columnIndex);
                    //   //
                    //   //console.log(rowIndex);
                    //   alert('Click on Product ID field');

                    if(e.target.id == "3"){
                        //handleView(row)
                        console.log(row);
                    }
                },
            }
            

        },
       /*  {

            dataField   : 'action2',
            text        : '',
            editable:false,
            headerStyle : () => {
                return { width  : "14%",color:"green" };
            },
            style:{whiteSpace: 'nowrap', },
            formatter   :   (cell, row, isSelect) => {
                if(row)
                return (
                    <i className="mdi mdi-account-group" style={{fontSize:"25px"}}></i>
                );
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    //handleViewCandidates(row)
                    //   //console.log(e);
                    //   //console.log(column);
                    //   //console.log(columnIndex);
                    //   //console.log(row);
                    //   //console.log(rowIndex);
                    //   alert('Click on Product ID field');
                },
            }
            

        }, */
        
       
    ]

   /*  const selectRow = {
        //mode: 'checkbox',
        clickToSelectAndEditCell: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            let disable = true
            listOfJobs.map(function(item,i){
                if(item.id===row.id)
                {
                    ////console.log(row)
                    item.isDeleted = isSelect ? "1" : "0"
                    item.isModified = isSelect ? "1" : "0"
                    disable = isSelect ? false : true
                }
            })
        }
    }; */

    return (
        <React.Fragment >
            <Card className="job-detail overflow-hidden mt-1" >
                <CardBody className="p-4" style={{ marginTop : "5%",marginLeft:"25%"}}>
                <Row>
                    <Col lg={8}>
                        <Card className="mt-2" style={{ borderColor: "green"}}>
                            <CardBody style={{ background:"#EAEAEA", margin :5}}>
                            <p style={{  marginTop:"15px", marginLeft:"29px",fontSize:"23px"}}>To Continue on this Page, kindly update your <button style={{border:"transparent",background:"#EAEAEA",color: "green",fontWeight:"bold"}} onClick={toProfile}>Profile</button> and provide the neccessary documents.</p>
                            <p style={{  marginTop:"15px", marginLeft:"29px",fontSize:"23px"}}>We need to verify your account, so you can fully experience the JobSpace Feature</p>
                            <p style={{  marginTop:"15px", marginLeft:"29px",fontSize:"23px"}}>If you need assistance, you may contact out <button style={{border:"transparent",background:"#EAEAEA",color: "green",fontWeight:"bold"}}onClick={toSupport}>Support Team</button>. We are available Mon-Fri from 8:00-5:00.</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default EmployerValidation;
